import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';

@Component({
  selector: 'app-app-perfil',
  templateUrl: './app-perfil.component.html',
  styleUrls: ['./app-perfil.component.scss'],
})
export class AppPerfilComponent implements OnInit, OnDestroy {

  authUsuario: any = null;
  cuentaUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_PERFIL';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  defaultImagePerfil = 'assets/img/app_perfil.png';

  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private utilitario: UtilitariosService,
    private alertCtr: AlertController,
    private modalController: ModalController,
  ) {
    console.log('App: Perfil');
    this.header.pagina = '/';
    this.header.titulo = 'PERFIL';
  }

  ngOnInit() {
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {

              this.authUsuario = getAuth.data;
              this.infoCuenta();


            } else {
              console.log('Seguimiento de errores -> ', getAuth);
              this.cerrarVentana();
            }
          })


        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }


  async infoCuenta() {
    this.redatwater.consultarFullCuentaUsuario().then((getData: any) => {
      if (getData) {

        console.log('Full data cuenta usuario -> ', getData);

        if (getData?.infoUsuario) {

          this.cuentaUsuario = getData.infoUsuario;

        } else {
          console.log('Seguimiento de errores -> ', getData);
        }

      } else {
        console.log('Seguimiento de errores -> ', getData);
        this.cerrarVentana();
        //this.navCtrl?.navigateRoot(['/logout']);
      }

    }).catch((err: any) => {
      this.cerrarVentana();
      console.log('Seguimiento de errores -> ', err);
    });
  }

  seleccionarIdioma(idioma) {
    if (idioma) {
      //console.log('Seleccionar idioma -> ', idioma);
      this.selectedLanguage = idioma;
      //console.log('Idioma seleccionado: ' + this.selectedLanguage);
      this.languageChanged(this.selectedLanguage);
      this.idiomaSeleccionado = idioma;

      this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
        this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
          this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
          this.itemsIdioma = this.translateConfigService.getItemsIdioma();
          this.translateConfigService.getItemsIdioma();
          if (this.itemsIdiomaApp && this.itemsIdioma) {
            //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
            //console.log('Parametros de idioma -> ', this.itemsIdioma);
            //console.log('Test 2 -> ', this.itemsIdiomaApp.app_loading);

          } else {
            this.translateConfigService.resetIdioma(this.idiomaPagina);
          }
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }
  }

  languageChanged(idioma: string) {
    this.translateConfigService.setLanguage(idioma);
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss();
  }

}
