import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController, AlertController, ActionSheetController, LoadingController } from '@ionic/angular';
import { Subscription, interval } from 'rxjs';
import { AgregarDireccionComponent } from 'src/app/components/app-clientes/agregar-direccion/agregar-direccion.component';
import { Header, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { PayMetodosComponent } from '../../cartera/pay-metodos/pay-metodos.component';
import { SeleccionarTarjetaComponent } from '../../cartera/seleccionar-tarjeta/seleccionar-tarjeta.component';
import { AlertService } from 'src/app/services/alert.service';
import { WompiService } from 'src/app/services/wompi.service';
import { InfoTarjetaComponent } from '../../cartera/info-tarjeta/info-tarjeta.component';
import { AgregarTarjetaComponent } from '../../cartera/agregar-tarjeta/agregar-tarjeta.component';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-carrito-pedido',
  templateUrl: './carrito-pedido.component.html',
  styleUrls: ['./carrito-pedido.component.scss'],
})
export class CarritoPedidoComponent implements OnInit {

  @Input() pedidoOriginal: any;
  @Input() pedidoComercios: any;
  @Input() preciosPedido: any;

  private subscriptionTransaccion: Subscription = new Subscription();

  viewTarjetaAquahabiente = false;
  viewTarjetasCredito = false;

  listaTarjetasCartera = new Array<any>();
  infoTarjetaAquahabiente: any;

  intSolicitud: boolean = true;

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  aquaBot = {
    msg: '',
    estado: ''
  };

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'TIENDAS_AGUA_CARRITO';

  authUsuario: any = null;
  defaultImage = 'assets/img/default.png';

  comercioProductos: any;
  cantidadComercios: number = 0;
  tarifaDomicilio: number = 0;
  tarifaPropina: number = 0;

  pedido: any = null;
  subTotal: number = 0;
  domicilio: number = 0;
  totalPedido: number = 0;
  propina: number = 0;

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Establecer ubicación.',
    coordenadas: {
      lat: null,
      lng: null
    }
  };

  entregaDomicilio: any = {
    tipo_domicilio: '',
    dejar_puerta: 'puerta'
  }

  metodoSeleccionado: any = {
    metodo: null,
    cartera: null,
    transaccionId: null,
    carteraId: null,
    transaccionPasarela: null,
  };
  metodoPay: any = '';

  procesoTransaccion: any;
  tiempoRecargaTransaccion = 6000;


  constructor(
    private navCtrl: NavController,
    private loadingService: LoadingService, 
    private modalController: ModalController,
    private toast: ToastService,
    private redatwater: RedatwaterService,
    private translateConfigService: TranslateConfigService,
    private aquabot: AquabotService,
    private geolocalizacion: GeolocalizacionService,
    private auth: AuthService,
    private alertController: AlertService,
    private wompi: WompiService,
    private loadingCtrl: LoadingController,
    private actionSheetCtrl: ActionSheetController,
    private carrito: CarritoService,
    private ventanaAlerta: AlertService, 
    private utilitario: UtilitariosService,
    private alertCtr: AlertController
  ) {
    console.log('Ventana -> Pedido de Carrito');
    this.header.pagina = '/tiendas';
    this.header.titulo = 'Tu Pedido';
  }

  ngOnInit() {

    console.log('Pedido Original -> ', this.pedidoOriginal);
    console.log('Pedido Comercios -> ', this.pedidoComercios);
    console.log('Pedido $ -> ', this.preciosPedido);

    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {


          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {

              this.authUsuario = getAuth.dat;
              this.loadPedido();
              this.loadUbicacionActual();

            } else {
              this.navCtrl?.navigateRoot(['/logout']);
            }
          });

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });


  }

  async loadPedido() {
    this.carrito.getPedidoObservable().subscribe((getData: any) => {
      console.log('Carrito -> ', getData);
      if (getData) {
        this.pedido = getData;
        if (this.pedido.productos?.length) {

          this.subTotal = this.preciosPedido.subTotal;
          this.domicilio = this.preciosPedido.domicilio;
          this.totalPedido = this.preciosPedido.totalPedido;
          this.propina = this.preciosPedido.propina;

        } else {
          this.cerrarVentana();
        }
      } else {
        console.log('Seguimiento de errores -> ', getData);

      }
    });
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss();
  }

  // CONFIRMAR PEDIDO
  confirmarPedido() {

    console.log('Carrito -> confirmar pedido! ', this.pedido);
    if ((this.totalPedido > 0 && this.subTotal > 0 && this.domicilio > 0) && (this.entregaDomicilio.tipo_domicilio && this.entregaDomicilio.dejar_puerta)) {

      if (this.metodoSeleccionado.metodo === 'nequi') {

        this.loadingService.showLoading('¡Tu transacción en proceso!', 'assets/img/loading_splash.png');
        this.procesoPay(this.metodoPay);

      } else if (this.metodoSeleccionado.metodo === 'tarjeta') {

        this.loadingService.showLoading('¡Tu transacción en proceso!', 'assets/img/loading_splash.png');
        this.procesoPay(this.metodoSeleccionado.cartera);

      } else {
        console.log('Seguimiento de errores -> ', this.metodoSeleccionado.metodo);
      }


    } else {
      this.alertController.msgAppCenter('¡Confirmar compra!', '<span class="text-success">Campos obligatorios:</span> <br> * Dirección de entrega <br>* Método de pago <br>* Articulos seleccionados ');
      console.log('Seguimiento de errores -> ', this.totalPedido, this.subTotal, this.domicilio);
    }

  }

  async appUbicacion() {
    this.ventanaDireccion();
  }

  async loadUbicacionActual() {
    this.geolocalizacion.loadUbicacionActual().then((ubicacion: any) => {
      console.log('App Ubicacion actual -> ' + JSON.stringify(ubicacion));

      this.direccionActual = ubicacion;

    }).catch(error => {
      console.log('App -> Error: ' + error);
    });
  }

  async ventanaDireccion() { // Agregar direccion para pedidos
    const modalAdd = await this.modalController.create({
      component: AgregarDireccionComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data.direccion);
      this.direccionActual = {
        place_id: data.id,
        direccion: data.direccion,
        coordenadas: {
          lat: data.latitud,
          lng: data.longitud
        }
      }

      this.toast.msgAppBottom('¡Dirección actualizada con éxito!', 'success');
      
    } else {
      this.toast.msgAppBottom('¡Oops! Dirección para pedidos incorrecta.', 'danger');
    }
  }

  async seleccionarTarjeta() {
    if (this.listaTarjetasCartera) {
      const listaTarjetas = this.listaTarjetasCartera;
      const modalAdd = await this.modalController.create({
        component: SeleccionarTarjetaComponent,
        mode: 'ios',
        swipeToClose: true,
        componentProps: { listaTarjetas }
      });
      await modalAdd.present();
      const { data } = await modalAdd.onWillDismiss();
      if (data?.id) {
        console.log('Finalizar datos -> ' + JSON.stringify(data));
        this.metodoSeleccionado.metodo = 'tarjeta';
        this.metodoSeleccionado.cartera = data;

      } else {
        console.log('Seguimiento de errores -> ', data);
      }
    } else {

    }
  }

  async procesoPay(data: any) {

    if (data) {

      console.log('Procesar Pago -> ', data);

      if (this.metodoSeleccionado.metodo === 'nequi') {

        let parametrosPay = {
          payTotal: this.totalPedido,
          nequi: this.metodoPay.nequi
        }

        this.wompi.enviarPagoNequi(parametrosPay).then((dataWompi: any) => {

          console.log('Info Transaccion de carrito -> ' + JSON.stringify(dataWompi));
          this.metodoSeleccionado.transaccionPasarela = dataWompi;

          if (this.metodoSeleccionado.transaccionPasarela.data.status === 'APPROVED') {

            this.loadingService.hideLoading();
            this.almacenarPedidoPasarela(this.metodoSeleccionado.transaccionPasarela);

          } else if (this.metodoSeleccionado.transaccionPasarela.data.status === 'PENDING') {

            this.verificarProcesoTransaccion(this.metodoSeleccionado.transaccionPasarela);

          } else {
            this.loadingService.hideLoading();
            console.log('Ventana -> Error estado transaccion ' + dataWompi);
          }


        }).catch(error => {
          console.log('Ventana -> Error proceso con nequi. ' + error);
          this.alertController.msgAppCenter('¡Error en recarga de saldo!', 'Parece que algo salió mal.');
          this.wompi.establecerTokerPay();
          this.loadingService.hideLoading();
        });

      } else if (this.metodoSeleccionado.metodo === 'tarjeta') {

        let parametrosPay = {
          payTotal: this.totalPedido,
          tarjeta: data
        }

        this.wompi.payCarritoCompras(parametrosPay.tarjeta, parametrosPay.payTotal).then((dataWompi: any) => {


          console.log('Info Transaccion de carrito -> ' + JSON.stringify(dataWompi));

          this.metodoSeleccionado.transaccionPasarela = dataWompi;
          this.metodoSeleccionado.carteraId = this.metodoSeleccionado.cartera.id;


          if (this.metodoSeleccionado.transaccionPasarela.data.status === 'APPROVED') {

            this.loadingService.hideLoading();
            this.almacenarPedidoPasarela(this.metodoSeleccionado.transaccionPasarela);

          } else if (this.metodoSeleccionado.transaccionPasarela.data.status === 'PENDING') {

            this.verificarProcesoTransaccion(this.metodoSeleccionado.transaccionPasarela);

          } else {
            this.loadingService.hideLoading();
            console.log('Ventana -> Error estado transaccion ' + dataWompi);
          }

        }).catch(error => {
          console.log('Ventana -> Error proceso con tarjeta. ' + error);
          this.alertController.msgAppCenter('¡Error en recarga de saldo!', 'Parece que algo salió mal con tu tarjeta.');
          this.loadingService.hideLoading();

        });

      } else {
        console.log('Seguimiento de errores -> ', data);
        this.loadingService.hideLoading();
      }

    }
  }

  async almacenarPedidoPasarela(dataWompi: any) {

    console.log('Seguimiento -> Almacenar Pago pasarela: ', dataWompi);

    if (this.metodoSeleccionado.metodo === 'nequi') {

      const parametros = {
        tipo: 3,
        total_pagar: this.totalPedido,
        pay: dataWompi.data.data
      };

      this.redatwater.almacenarCompraOnlineWompiNEQUI(parametros).then((data: any) => {
        console.log('Ventana -> Transaccion exitosa.' + JSON.stringify(data));

        if (data.cartera?.id) {

          this.metodoSeleccionado.transaccionId = data.cartera; // Id Registro de compra online
          this.finalizarCompraCarrito(dataWompi);

        } else {
          console.log('Seguimiento de errores -> ', data);
        }

      }).catch(error => {
        console.log('Ventana -> Error almacenar compra: ' + error);
      });

    } else if (this.metodoSeleccionado.metodo === 'tarjeta') {

      const parametros = {
        tipo: 3,
        total_pagar: this.totalPedido,
        pay: dataWompi.data.data
      };

      this.redatwater.almacenarCompraOnlineWompi(parametros).then((data: any) => {

        console.log('Ventana -> Transaccion exitosa.' + JSON.stringify(data));

        if (data.cartera?.id) {

          console.log('Seguimiento respuesta transaccion -> ', data);

          this.metodoSeleccionado.transaccionId = data.cartera.id; // Id Registro de compra online

          this.finalizarCompraCarrito(dataWompi);

        } else {
          console.log('Seguimiento de errores -> ', data);
        }

      }).catch(error => {
        console.log('Ventana -> Error almacenar compra: ' + error);
      });

    } else {
      console.log('Seguimiento de errores -> ', this.metodoSeleccionado);
    }

  }

  async metodosPay() {

    this.intSolicitud = true;
    let actionLinks = [];

    actionLinks.push({
      text: 'Cartera',
      icon: 'wallet-outline',
      handler: () => {
        this.seleccionarTarjeta();
      }
    });

    actionLinks.push({
      text: 'Métodos de pago',
      icon: 'ribbon-outline',
      handler: () => {
        this.carteraMetodosPay();
      }
    });

    actionLinks.push({
      text: 'Cerrar ventana',
      icon: 'close',
      role: 'cancel',
      handler: () => {

      }
    })

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Paga a tu manera:',
      buttons: actionLinks
    });
    await actionSheet.present();

  }

  async carteraMetodosPay() {
    const totalPago = this.totalPedido;
    const categoriaPago = 'DIGITAL_PAY';
    const modalAdd = await this.modalController.create({
      component: PayMetodosComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { totalPago, categoriaPago }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    console.log('Seguimiento modal -> Metodo de Pago: ', data);
    if (data) {
      this.metodoPay = data['metodo'];
      console.log('Seguimiento -> Parametros metodo: ', this.metodoPay);

      if (this.metodoPay) {

        this.metodoSeleccionado.metodo = this.metodoPay.metodo;

        if (this.metodoSeleccionado.metodo === 'nequi') {

          if (this.metodoPay.nequi?.celular) {

            this.metodoPay.payTotal = this.totalPedido;

          } else {
            console.log('Seguimiento de errores -> ', this.metodoPay.nequi);
          }

        } else {
          console.log('Seguimiento de errores -> ¿Metodo de pago?');
        }


      } else {
        this.alertController.msgAppCenter('¡Recargar saldo!', 'Error información de tarjeta.');
      }
    }
  }

  getTRecargaProcesoTransaccion() {
    return this.tiempoRecargaTransaccion;
  }

  async verificarProcesoTransaccion(data: any) {
    this.procesoTransaccion = interval(this.getTRecargaProcesoTransaccion());
    this.subscriptionTransaccion.add(
      this.procesoTransaccion.subscribe(() => {
        console.log('Procesando...');
        if (this.metodoSeleccionado.metodo === 'tarjeta') {
          this.verificarTarjeta(data);
        } else if (this.metodoSeleccionado.metodo === 'nequi') {
          this.verificarNequi(data);
        } else {

        }
      })
    );
  }

  async verificarTarjeta(data: any) {

    this.wompi.verificarPagoTarjeta(data).then((dataPay: any) => {
      if (dataPay?.estado) {
        console.log('Seguimiento -> ', dataPay);
        if (dataPay.estado === 'esperando') {

          console.log('Wompi: Transaccion Tarjeta -> Esperado....');

        } else if (dataPay.estado === 'aprobado') {

          console.log('Wompi: Transaccion Tarjeta -> Aprobado!');
          if (this.subscriptionTransaccion) {
            this.subscriptionTransaccion.unsubscribe();
          }
          this.subscriptionTransaccion = null;
          clearInterval(this.procesoTransaccion);

          this.loadingService.hideLoading();


          if (this.intSolicitud) {
            this.wompi.establecerTokerPay();
            this.intSolicitud = false;
            this.almacenarPedidoPasarela(dataPay);
          }



        } else if (dataPay.estado === 'rechazado') {

          console.log('Wompi: Transaccion Tarjeta -> Rechazado!');
          if (this.subscriptionTransaccion) {
            this.subscriptionTransaccion.unsubscribe();
          }
          this.subscriptionTransaccion = null;
          clearInterval(this.procesoTransaccion);

          this.loadingService.hideLoading(); 

          this.ventanaAlerta.msgAppCenter('AVISO', 'Tu transacción ha sido rechazada.');

          this.wompi.establecerTokerPay();

        } else {
          console.log('Seguimiento de errores -> ', dataPay);
        }
      } else {
        console.log('Seguimiento de errores -> ', dataPay);
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }


  async verificarNequi(data: any) {
    this.wompi.verificarNequiPago(data).then((dataPay: any) => {
      if (dataPay?.estado) {
        console.log('Seguimiento -> ', dataPay);
        if (dataPay.estado === 'esperando') {

          console.log('Wompi: Transaccion NEQUI -> Esperado....');

        } else if (dataPay.estado === 'aprobado') {

          console.log('Wompi: Transaccion NEQUI -> Aprobado!');
          if (this.subscriptionTransaccion) {
            this.subscriptionTransaccion.unsubscribe();
          }
          this.subscriptionTransaccion = null;
          clearInterval(this.procesoTransaccion);

          this.loadingService.hideLoading();


          if (this.intSolicitud) {
            this.wompi.establecerTokerPay();
            this.almacenarPedidoPasarela(dataPay);
            this.intSolicitud = false;
          }



        } else if (dataPay.estado === 'rechazado') {

          console.log('Wompi: Transaccion NEQUI -> Rechazado!');
          if (this.subscriptionTransaccion) {
            this.subscriptionTransaccion.unsubscribe();
          }
          this.subscriptionTransaccion = null;
          clearInterval(this.procesoTransaccion);


          this.loadingService.hideLoading();

          this.wompi.establecerTokerPay();

        } else {
          console.log('Seguimiento de errores -> ', dataPay);
        }
      } else {
        console.log('Seguimiento de errores -> ', dataPay);
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  async abrirTarjeta() {
    if (this.infoTarjetaAquahabiente) {
      console.log('Ventana -> Info de tarjeta Aquahabiente...');
      const tarjetaAquahabiente = this.infoTarjetaAquahabiente;
      const listaTarjetasCartera = this.listaTarjetasCartera;
      const modalAdd = await this.modalController.create({
        component: InfoTarjetaComponent,
        mode: 'ios',
        swipeToClose: true,
        componentProps: { tarjetaAquahabiente, listaTarjetasCartera }
      });
      await modalAdd.present();
      const { data } = await modalAdd.onWillDismiss();
      if (data) {
        console.log('Finalizar datos -> ' + data);
      }
    }
  }


  async carteraConsultarTarjetas() {
    console.log('Ventana -> Consultar tarjetas...');
    this.redatwater.carteraTarjetas().then((tarjetasData: any) => {
      //console.log('Cartera -> Tarjetas: ' + JSON.stringify(tarjetasData));
      this.viewTarjetasCredito = true;
      this.listaTarjetasCartera = tarjetasData;
    }).catch(error => {
      console.log('Ventana -> Error cartera consultar tarjetas. ' + error);
    });
  }

  async tarjetaAquahabienteInfo() {
    console.log('Ventana -> Consultar tarjeta Aquahabiente...');
    this.redatwater.infoTarjetaAquahabiente().then((tarjeta: any) => {
      //console.log('Cartera -> Tarjeta Aquahabiente: ' + JSON.stringify(tarjeta));
      this.viewTarjetaAquahabiente = true;
      this.infoTarjetaAquahabiente = tarjeta;
    }).catch(error => {
      console.log('Ventana -> Error consultar tarjeta Aquahabiente. ' + error);
    });
  }


  finalizarCompraCarrito(dataPay: any) {
    console.log('Seguimiento -> Finalizar compra! ', dataPay);
    if (dataPay) {
      let parametrosCompra = {
        tipo_domicilio: 'entrega',
        metodo_pago_id: 3, // WOMPI
        total_pago: this.totalPedido,
        domicilio: this.domicilio,
        propina: this.preciosPedido.propina,
        direccion: this.direccionActual.direccion,
        direccion_tipo_domicilio: this.entregaDomicilio.tipo_domicilio,
        direccion_dejar_puerta: this.entregaDomicilio.dejar_puerta,
        pedido_productos: this.pedidoComercios,
        transaccion_id: this.metodoSeleccionado.transaccionId,
        cartera_id: this.metodoSeleccionado.carteraId
      }
      this.redatwater.hacerCompraCarrito(parametrosCompra).then((getCompra: any) => {
        console.log('Seguimiento -> Compra ', getCompra);
        if (getCompra?.pedido) {

          console.log('Compra tienda -> ', getCompra);
          this.limpiarCarrito();
          this.cerrarVentana();

        } else {
          console.log('Seguimiento de errores -> ', getCompra);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });

    } else {
      console.log('Seguimiento de errores -> ', dataPay);
    }
  }


  limpiarCarrito() {

    console.log('Carrito -> limpiar carrito! ');
    this.carrito.clearCarrito();
  }

}
