import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AquabotService } from '../services/aquabot.service';

@Injectable()

export class AuthFalseGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private aquabot: AquabotService,
    private navCtrl: NavController
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve, reject) => {
      this.auth.loadAuthToken().then(async (getUsuario: any) => {
        console.log('Usuario Auth ->', getUsuario);
        if (getUsuario) {
          if (getUsuario?.app_token) {
            if (getUsuario.modulo === 'clientes' ) {
              this.aquabot.redireccionarInterface('redatwater-cliente');
            } else if (getUsuario.modulo === 'usuarios') {
              this.aquabot.redireccionarInterface('redatwater-usuario');
            } else {
              this.aquabot.redireccionarInterface('redatwater');
            }
          } else {
            resolve(false);
          }
        } else {
          resolve(true);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
        resolve(false);
      });
    });

  }

}
