import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { LoadingController } from '@ionic/angular';
import { Subscription, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUbicacionActual } from '../interfaces/book.interface';
import { AlertService } from './alert.service';
import { ToastService } from './toast.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { UbicacionService } from './ubicacion.service';
import { LoadingService } from './loading.service';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeolocalizacionService {

  public apiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

  private authUbicacionSubject = new BehaviorSubject<any>(null); // Inicializar con valor null
  authUbicacion$ = this.authUbicacionSubject.asObservable();

  geolocalizacionData = {
    lat: '',
    lng: ''
  }

  ubicacionActual: IUbicacionActual = {
    place_id: '',
    direccion: '',
    coordenadas: {
      lat: null,
      lng: null
    }
  }

  constructor(
    private http: HttpClient,
    private alertController: AlertService,
    private loadingService: LoadingService,
    private ubicacion: UbicacionService,
  ) {
    console.log('App -> Servicio Geolocalizacion.');
    this.getAuthUbicacionObservable('app_ubicacion').subscribe();
    this.checkLocation();
  }

  async checkLocation() {

    const coordinates = await this.getCurrentLocation();

    if (!coordinates || !coordinates.latitude || !coordinates.longitude) {
      console.log('La geolocalización está desactivada o no disponible.');
    } else {
      console.log(`Latitud: ${coordinates.latitude}, Longitud: ${coordinates.longitude}`);
    }
  }

  async getCurrentLocation() {
    try {
      const coordinates = await Geolocation.getCurrentPosition();
      return coordinates.coords;
    } catch (error) {
      console.error('Error al obtener la ubicación:', error);
      this.alertController.msgAppCenter('RED AT-WATER', '¡Tu experiencia es nuestra prioridad! <span class="text-success">Verifica y activa tu GPS otorgando permisos a la App.</span>');
      return null;
    }
  }

  getAuthUbicacionObservable(identifier: string): Observable<any> {
    return from(this.loadUbicacionActual()).pipe(
      map(data => ({ identifier, data })), // Agregar una etiqueta a los datos emitidos
      shareReplay(1)
    );
  }


  async initGeo() {
    console.log('Geolocalizacion...');
    this.ubicacion.getUbicacionGPS().then((dataUbicacion: any) => {
      if (dataUbicacion) {
        this.setGeolocalizacionData(dataUbicacion);
      } else {
        console.log('Seguimiento de errores -> ', dataUbicacion);
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  async setGeolocalizacionData(data: any) {
    if (data) {
      this.geolocalizacionData = data;
    }
  }

  async dataGeolocalizacion(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getGeolocalizacionData().then((data: any) => {
        if (data) {
          resolve(data);
        } else {
          reject(false);
        }
      });
    });
    //return this.getGeolocalizacionData();
  }

  async getGeolocalizacionData() {
    return this.geolocalizacionData;
  }

  async establecerUbicacionActual(tipo: string): Promise<any> {

    return new Promise((resolve, reject) => {
      if (tipo === 'geolocalizacion') {
        console.log('Estableciendo Ubicación automatica...');
        this.ubicacion.getUbicacionGPS().then((data: any) => {
          const coordenadas = data;
          console.log('Coordenadas -> ' + JSON.stringify(coordenadas));
          this.consultarDireccionCoordenadas(coordenadas.lat, coordenadas.lng).then((dataUbicacion: any) => {
            console.log('Coordenadas, consultar direccion -> ', dataUbicacion);
            this.ubicacionActual = {
              place_id: dataUbicacion.place_id,
              direccion: dataUbicacion.formatted_address,
              coordenadas: {
                lat: dataUbicacion.geometry.location.lat,
                lng: dataUbicacion.geometry.location.lng
              }
            }

            // OK 
            this.setUbicacionActualSession(this.ubicacionActual);
            resolve(this.ubicacionActual);

          }).catch(error => {
            console.log('Error Consultar direccion -> ' + error);
            resolve(false);
          });
        }).catch((err: any) => {
          console.log('Geolocalizacion -> Error con coordenadas.', err);
          resolve(false);
        });
      } else if (tipo === 'texto') {
        console.log('Estableciendo Ubicación manual...');

      }
    });

  }

  async consultarDireccionCoordenadas(lat: number, lng: number): Promise<any> {
    if (lat && lng) {
      console.log('Coordenadas ->', lat + ' & ' + lng);

      //this.splashLoadingService.loadingApp(true, 'Dirección actual...', 'assets/img/favicon.png');

      return new Promise((resolve, reject) => {
        const apiPath = this.apiUrl + '?key=' + environment.ApiKeyGoogleMaps + '&latlng=' + lat + ',' + lng + '&sensor=false';
        this.http.get(apiPath).subscribe((data: any) => {
          console.log('Peticion direccion por coordenadas() ->', data);
          if (data.status === 'OK') {
            //console.log('Respuesta -> ' + JSON.stringify(data.results[0]));
            const resultadoUbicacion = data.results[0];
            //console.log('Consulta -> Direccion: ', resultadoUbicacion.formatted_address);

            resolve(resultadoUbicacion);
          } else {

            this.alertController.msgAppCenter('Notificación', '¡Intente nuevamente!');
            reject(false);
          }
        });
      });
    }

  }

  async consultarDireccionTexto(direccion: any): Promise<any> {

    //this.splashLoadingService.loadingApp(true, 'Dirección actual...', 'assets/img/favicon.png');

    return new Promise((resolve, reject) => {
      const apiPath = this.apiUrl + '?key=' + environment.ApiKeyGoogleMaps + '&address=' + direccion.description;
      this.http.get(apiPath).subscribe((data: any) => {
        console.log('Peticion coordenadas() ->', data);

        if (data.status === 'OK') {
          const coordenadas = data.results[0].geometry.location;
          console.log('Coordenadas de busqueda ->', coordenadas);

          resolve(data.results[0]);
        } else {
          this.alertController.msgAppCenter('Notificación', '¡Intente nuevamente!');

          reject(false);
        }
      });
    });

  }

  // Crear registro token session
  async setUbicacionActualSession(ubicacion: any) {
    if (ubicacion) {
      Preferences.set({
        key: 'app_ubicacion_actual',
        value: JSON.stringify(ubicacion),
      });
      this.setAuthUbicacion(ubicacion);
      console.log('Geolocalizacion -> Session de Ubicacion actual almacenada.');

    }
  }

  // Get registro token session
  async getUbicacionActualSession() {
    const appToken = Preferences.get({ key: 'app_ubicacion_actual' });
    if (appToken) {
      return appToken;
    }
  }

  async loadUbicacionActual(): Promise<any> {

    return new Promise((resolve, reject) => {

      this.getUbicacionActualSession().then((ubicacion: any) => {
        if (ubicacion.value) {
          //console.log("Geolocalizacion -> Ubicacion actual: " + ubicacion.value);

          resolve(JSON.parse(ubicacion.value));
          this.authUbicacionSubject.next({ identifier: 'app_ubicacion', data: ubicacion }); // Enviar el nuevo valor a través del BehaviorSubject

        } else {

          console.log('Geolocalizacionn -> no hay sesion.');
          this.establecerUbicacionActual('geolocalizacion');
          resolve(false);

        }

      }).catch((err: any) => {

        console.log('Geolocalizacion -> Erro al cargar.', err);
        resolve(false);

      });
    });
  }

  setAuthUbicacion(ubicacion: any) {
    if (ubicacion) {
      this.authUbicacionSubject.next({ identifier: 'app_ubicacion', data: ubicacion }); // Enviar el nuevo valor a través del BehaviorSubject
    }
  }

}
