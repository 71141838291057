import { Component } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen, SplashScreenPlugin } from '@capacitor/splash-screen';
import { ActionSheetController, AlertController, LoadingController, MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { FirebaseService } from './services/firebase.service';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { AlertService } from './services/alert.service';
import { UbicacionService } from './services/ubicacion.service';
import { AuthService } from './services/auth.service';
import { RedatwaterService } from './services/redatwater.service';
import { TranslateConfigService } from './services/translate-config.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AquabotService } from './services/aquabot.service';
import { AppNotificacionesComponent } from './components/app/app-notificaciones/app-notificaciones.component';
import { AppMensajesComponent } from './components/app/app-mensajes/app-mensajes.component';
import { AppBonificacionesComponent } from './components/app/app-bonificaciones/app-bonificaciones.component';
import { AppPerfilComponent } from './components/app/app-perfil/app-perfil.component';
import { AgregarDireccionComponent } from './components/app-clientes/agregar-direccion/agregar-direccion.component';
import { IUbicacionActual } from './interfaces/book.interface';
import { ToastService } from './services/toast.service';
import { CarteraComponent } from './components/app/cartera/cartera.component';
import { DatosFacturacionComponent } from './components/app/cartera/datos-facturacion/datos-facturacion.component';
import { HelpComponent } from './components/app/aquabot/help/help.component';
import { RegistroPrevendedorComponent } from './components/auth/registro-prevendedor/registro-prevendedor.component';
import { RegistroDomiciliarioComponent } from './components/auth/registro-domiciliario/registro-domiciliario.component';
import { AgregarPedidoVendedorComponent } from './components/app-usuarios/pedidos-vendedores/agregar-pedido-vendedor/agregar-pedido-vendedor.component';
import { PedidosVendedoresComponent } from './components/app-usuarios/pedidos-vendedores/pedidos-vendedores.component';
import { PedidosDomiciliarioComponent } from './components/app-usuarios/pedidos-domiciliario/pedidos-domiciliario.component';
import { PedidosAbiertosDomiciliarioComponent } from './components/app-usuarios/pedidos-domiciliario/pedidos-abiertos-domiciliario/pedidos-abiertos-domiciliario.component';
import { PedidosClienteComponent } from './components/app-clientes/pedidos-cliente/pedidos-cliente.component';
import { AppCarritoComponent } from './components/app/app-carrito/app-carrito.component';
import { SeleccionarClienteComponent } from './components/app-usuarios/seleccionar-cliente/seleccionar-cliente.component';
import { NotificacionesService } from './services/notificaciones.service';
import { AppSistemascercaComponent } from './components/app-sistemascerca/app-sistemascerca.component';
import { TiendasUsuarioComponent } from './components/app-usuarios/tiendas-usuario/tiendas-usuario.component';
import { AppInteresComponent } from './components/app/app-interes/app-interes.component';
import { AppUsabilidadComponent } from './components/app/app-usabilidad/app-usabilidad.component';
import { AppPubliaquaYoutubeComponent } from './components/app-publiaqua-youtube/app-publiaqua-youtube.component';
import { AppGuiarapidaComponent } from './components/app/app-guiarapida/app-guiarapida.component';
import { AsociarSuministroAguaComponent } from './components/app-clientes/solicitar-agua/solicitar-agua-seleccionar-ticket/asociar-suministro-agua/asociar-suministro-agua.component';
import { AppUsabilidadService } from './services/app-usabilidad.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  authUsuario: any = null;
  cuentaUsuario: any = null;

  videoComofunciona: string = null;

  camposCuenta: any = {
    nombres: '',
    apellidos: '',
    correo: '',
    celular: '',
    sexo: '',
    password_seguridad: '',
    img: '',
    ciudad: '',
    direccion: '',
    codigo_postal: '',
    fecha_nacimiento: '',
    tarjeta_id: '',
  };
  progress: number = 0;

  ubicacion: any = {
    direccion: null,
    lat: null,
    lng: null
  }

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Establecer ubicación.',
    coordenadas: {
      lat: null,
      lng: null
    }
  };

  imgSistema = '';
  precioActualLitroSistema = 0;
  coberturaCajerosATWATER: boolean = false;
  cajerosCercanos: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP';

  viewNavPrevendedor: boolean = true;
  viewNavRepartidor: boolean = true;

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  viewTarjetaAquahabiente = false;
  infoTarjetaAquahabiente: any;

  usuarioDisponible: boolean = false;

  constructor(
    private platform: Platform,
    private menu: MenuController,
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private notificaciones: NotificacionesService,
    private auth: AuthService,
    private appUsabilidad: AppUsabilidadService,
    private alertCtr: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private aquabot: AquabotService,
    private toast: ToastService,
    private socialCompartir: SocialSharing,
    private appUbicacion: UbicacionService,
    private modalController: ModalController,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    private insomnia: Insomnia,
    private ventanaAlerta: AlertService
  ) {
    console.log('Iniciando componentes...');

    this.initializeApp();
    this.conexionRedATWATER();

  }

  async conexionRedATWATER() {

    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          // Suscribirse al BehaviorSubject para obtener las actualizaciones del usuario autenticado
          this.auth.authUsuario$.subscribe((authUsuario: any) => {
            console.log('Usuario Auth ->', authUsuario);
            if (authUsuario?.data) {
              this.authUsuario = authUsuario.data;
              this.consultarFullCuenta();
              this.tarjetaAquahabienteInfo();
            }
          });

          // Llamar al método para obtener el usuario autenticado cuando la aplicación se inicia
          this.auth.getAuthUsuarioObservable('app_auth').subscribe();
          //this.navHelp();

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });


  }

  seleccionarIdioma(idioma) {
    if (idioma) {
      //console.log('Seleccionar idioma -> ', idioma);
      this.selectedLanguage = idioma;
      //console.log('Idioma seleccionado: ' + this.selectedLanguage);
      this.languageChanged(this.selectedLanguage);
      this.idiomaSeleccionado = idioma;

      this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
        this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
          this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
          this.itemsIdioma = this.translateConfigService.getItemsIdioma();
          this.translateConfigService.getItemsIdioma();
          if (this.itemsIdiomaApp && this.itemsIdioma) {
            //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
            //console.log('Parametros de idioma -> ', this.itemsIdioma);
            //console.log('Test 2 -> ', this.itemsIdiomaApp.app_loading);

          } else {
            this.translateConfigService.resetIdioma(this.idiomaPagina);
          }
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }
  }

  languageChanged(idioma: string) {
    this.translateConfigService.setLanguage(idioma);
  }

  async consultarFullCuenta() {
    this.redatwater.consultarFullCuentaUsuario().then((getData: any) => {
      if (getData) {

        console.log('Full data cuenta usuario -> ', getData);
        this.cuentaUsuario = getData;

        if (this.cuentaUsuario.infoUsuario.app_disponible === '2') {
          console.log('Usuario disponible -> ', this.cuentaUsuario.infoUsuario.app_disponible);
          this.usuarioDisponible = true;
        } else {
          console.log('Usuario no disponible -> ', this.cuentaUsuario.infoUsuario.app_disponible);
          this.usuarioDisponible = false;
        }

        this.camposCuenta.nombres = this.cuentaUsuario.infoUsuario.nombre;
        this.camposCuenta.apellidos = this.cuentaUsuario.infoUsuario.apellidos;
        this.camposCuenta.correo = this.cuentaUsuario.infoUsuario.email;
        this.camposCuenta.celular = this.cuentaUsuario.infoUsuario.tel;
        this.camposCuenta.sexo = this.cuentaUsuario.infoUsuario.sexo;
        //this.camposCuenta.password_seguridad = this.cuentaUsuario.infoUsuario.;
        this.camposCuenta.img = this.cuentaUsuario.infoUsuario.img;
        this.camposCuenta.ciudad = this.cuentaUsuario.infoUsuario.ciudad;
        this.camposCuenta.direccion = this.cuentaUsuario.infoUsuario.direccion;
        this.camposCuenta.codigo_postal = this.cuentaUsuario.infoUsuario.codigo_postal;
        this.camposCuenta.fecha_nacimiento = this.cuentaUsuario.infoUsuario.fecha_nacimiento;

        this.progresoInformacionCuenta();

      } else {
        console.log('Seguimiento de errores -> ', getData);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');

      }

    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  /* Nav Asistencia */
  navAsistencia() {
    this.menu.close('custom');
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/chat', 'sitio oficial');
  }

  /* Nav Como funciona? */
  navComoFunciona() {
    this.menu.close('custom');
    if (this.videoComofunciona) {
      console.log('Video -> ', this.videoComofunciona);
      this.aquabot.enlaceSugerido(this.videoComofunciona, 'youtube');
    } else {
      console.log('Seguimiento de errores -> ', this.videoComofunciona);
    }
  }

  /* Nav Cambiar de idioma */
  navCambiarIdioma() {
    this.menu.close('custom');
    this.cambiarIdioma();
  }

  /* Nav Portal transaccional */
  navPortalTransaccional() {
    this.menu.close('custom');
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/auth', 'portal transaccional');
  }

  navUsuarioTiendas() {
    this.menu.close('custom');
    console.log('Nav -> Tiendas usuario');
    this.navCtrl?.navigateRoot(['/tiendas-usuario']);
  }

  navVendedorConsultarCliente() {
    this.menu.close('custom');
    console.log('Nav -> Registrar venta vendedor');
    this.viewConsultarCliente();
  }

  async viewConsultarCliente() {
    const modalAdd = await this.modalController.create({
      component: SeleccionarClienteComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Seguimiento -> Data consultar Cliente: ', data);
    }
  }

  navVendedorHistorialVentas() {
    this.menu.close('custom');
    console.log('Nav -> Historial ventas vendedor');
    this.viewHistorialVentasVendedor();
  }

  async viewHistorialVentasVendedor() {
    const modalAdd = await this.modalController.create({
      component: PedidosVendedoresComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Seguimiento -> Data historial pedidos: ', data);
    }
  }

  navDomiciliarioPedidosDisponibles() {
    this.menu.close('custom');
    console.log('Nav -> Pedidos disponibles domiciliario');
    this.viewPedidosDisponiblesDomiciliario();
  }

  async viewPedidosDisponiblesDomiciliario() {
    const modalAdd = await this.modalController.create({
      component: PedidosAbiertosDomiciliarioComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Seguimiento -> Data pedidos disponibles: ', data);
    }
  }

  navDomiciliarioHistorialEntregas() {
    this.menu.close('custom');
    console.log('Nav -> Historial entregas domiciliario');
    this.viewHistorialEntregas();
  }

  async viewHistorialEntregas() {
    const modalAdd = await this.modalController.create({
      component: PedidosDomiciliarioComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Seguimiento -> Data historial de entregas: ', data);
    }
  }

  /* Web oficial */
  webOficial() {
    this.menu.close('custom');
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage', 'sitio oficial');
  }

  // OTROS **************************************************************
  async establecerUbicacion() {
    this.appUbicacion.getUbicacionGPS().then((res) => {
      console.log('Seguimiento -> Ubicacion Actual()', res);
      if (res) {
        this.ubicacion.lat = res.lat;
        this.ubicacion.lng = res.lng;
        this.ubicacion.direccion = res.direccion;
      }
    }).catch((err: any) => {
      this.ventanaAlerta.msgAppCenter('RED AT-WATER', 'Activa el GPS para establecer ubicación.');
      console.log('Seguimiento de errores -> ', err);
    });
  }

  async initializeApp() {
    try {
      //this.establecerUbicacion();
      if (this.platform.is('capacitor')) { // ¿Movile?
        await SplashScreen.hide();
        if (this.platform.is('android')) {
          //StatusBar.setBackgroundColor({ color: '#CDCDCD' });

        }
        this.insomnia.keepAwake().then(() => {
          console.log('Seguimiento -> Bloquedo de Pantalla: ****** Activo ******');
        }).catch(err => {
          console.log('Seguimiento de errores -> Bloquedo de Pantalla: ', err);
        });

        //await StatusBar.setStyle({ style: Style.Light });
        await StatusBar.hide();

      }
    } catch (error) {
      console.log('Seguimiento -> Ejecutando App Web.... ', error);
    }
  }

  async cambiarIdioma() {
    console.log('Cambiar de idioma');
    this.listaIdiomas();
  }

  async listaIdiomas() {

    console.log('Seleccionar idioma: ');

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: '',
      inputs: [
        {
          label: 'Spanish',
          type: 'radio',
          name: 'idioma',
          value: 'es',
        },
        {
          label: 'English',
          type: 'radio',
          name: 'idioma',
          value: 'en',
        },
      ],
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cerrar!');
          }
        }, {
          text: 'Aceptar',
          handler: (data) => {
            console.log('Idioma seleccionado -> ', data);
            if (data) {
              console.log('Ok!');
              this.seleccionarIdioma(data);
            }
          }
        }
      ]
    });

    await alert.present();

  }

  async terminos_web() {
    console.log('Navegador -> Portal Transaccional: Terminos y condiciones');
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/terminosycondiciones', 'portal transaccional');
  }

  /*
  MENU
  */

  navNotificaciones() {
    console.log('Menu -> Notificaciones');
    this.menu.close('custom');
    this.viewNotificaciones();
  }

  async viewNotificaciones() {
    const modalAdd = await this.modalController.create({
      component: AppNotificacionesComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Seguimiento -> Data notificaciones: ', data);
    }
  }

  navMensajes() {
    console.log('Menu -> Mensajes');
    this.menu.close('custom');
    this.viewMensajes();
  }

  async viewMensajes() {
    const modalAdd = await this.modalController.create({
      component: AppMensajesComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Seguimiento -> Data mensajes: ', data);
    }
  }

  navBonificaciones() {
    console.log('Menu -> Bonificaciones');
    this.menu.close('custom');
    this.viewBonificaciones();
  }

  async viewBonificaciones() {
    const modalAdd = await this.modalController.create({
      component: AppBonificacionesComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Seguimiento -> Data bonificaciones: ', data);
    }
  }


  navPerfil() {
    console.log('Menu -> Perfil');
    this.menu.close('custom');
    this.viewPerfil();
  }

  async viewPerfil() {
    const modalAdd = await this.modalController.create({
      component: AppPerfilComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Seguimiento -> Data perfil: ', data);
    }
  }


  navDirecciones() {
    console.log('Menu -> Direcciones');
    this.menu.close('custom');
    this.viewDirecciones();
  }

  async viewDirecciones() {
    const modalAdd = await this.modalController.create({
      component: AgregarDireccionComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar direcciones -> ' + data.direccion);
      this.direccionActual = {
        place_id: data.id,
        direccion: data.direccion,
        coordenadas: {
          lat: data.latitud,
          lng: data.longitud
        }
      }
    } else {
      this.toast.msgAppBottom('¡Oops! Dirección incorrecta.', 'danger');
    }
  }

  navMetodoPay() {
    console.log('Menu -> Metodos de pago');
    this.menu.close('custom');
    this.viewCartera();
  }

  async viewCartera() { // Cartera
    const modalAdd = await this.modalController.create({
      component: CarteraComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }


  navTemasInteres() {
    console.log('Seguimiento -> Nav Temas de Interes.');
    this.ventanaTemasIntereses();
  }

  async ventanaTemasIntereses() { // Ok PEDIDO
    console.log('Ventana -> Temas de interes!');
    const modalAdd = await this.modalController.create({
      component: AppInteresComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();

    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }

  navDatosFacturacion() {
    console.log('Menu -> Datos de facturacion');
    this.menu.close('custom');
    this.viewFacturacion();
  }

  async viewFacturacion() { // Datos facturacion
    const modalAdd = await this.modalController.create({
      component: DatosFacturacionComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }

  navHelp() {
    console.log('Menu -> Ayuda');
    this.menu.close('custom');
    this.viewHelp();
  }

  async viewHelp() {
    const modalAdd = await this.modalController.create({
      component: HelpComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }

  // SOLO PARA CLIETES
  navQuieroPrevendedor() {
    console.log('Menu -> Quiero ser pre vendedor');
    this.menu.close('custom');
    this.viewRegistroPrevendedor();
  }

  async viewRegistroPrevendedor() {
    const modalAdd = await this.modalController.create({
      component: RegistroPrevendedorComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }

  navQuieroDomiciliario() {
    console.log('Menu -> Quiero ser domiciliario');
    this.menu.close('custom');
    this.viewRegistroDomiciliario();
  }

  async viewRegistroDomiciliario() {
    const modalAdd = await this.modalController.create({
      component: RegistroDomiciliarioComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }

  // END CLIENTES

  navTerminos() {
    console.log('Menu -> Notificaciones');
    this.menu.close('custom');
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/terminosycondiciones', 'sitio oficial');
  }

  navPoliticas() {
    console.log('Menu -> Politicas');
    this.menu.close('custom');
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/aspectoslegales', 'sitio oficial');
  }

  navDatosPersonales() {
    console.log('Menu -> Datos personales');
    this.menu.close('custom');
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/aspectoslegales', 'sitio oficial');
  }

  navLogout() {
    console.log('Menu -> Cerrar sesion');
    this.confirmarSalir();
  }

  navHistorialPedidos() {
    console.log('Menu -> Cerrar sesion');
    this.menu.close('custom');
    this.historialPedidos();
  }


  async historialPedidos() {
    const modalAdd = await this.modalController.create({
      component: PedidosClienteComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> historial pedidos ' + data);
    }
  }

  async ventanaDetalleCarrito() { // Detalle producto
    const modalAdd = await this.modalController.create({
      component: AppCarritoComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    } else {
      this.toast.msgAppBottom('¡Oops! Detalle de producto incorrecta.', 'danger');
    }
  }

  async navCarritoCompras() {
    console.log('Seguimiento -> Detalle de carrito ');
    this.menu.close('custom');
    this.ventanaDetalleCarrito();
  }

  portalTransaccional() {
    console.log('Portal transaccional -> Iniciar sesion. ', this.authUsuario.codigo);
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/auth?us_co=' + this.authUsuario.codigo, 'portal transaccional');
  }

  // INICAR SESION => PORTAL TRANSACCIONAL
  iniciarPortalTransaccional() {
    console.log('Portal transaccional -> Auto Iniciar sesion. ', this.authUsuario.codigo, this.authUsuario.app_token);
    if (this.authUsuario.codigo && this.authUsuario.app_token) {
      this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/auth/loginApp?numero=' + this.authUsuario.codigo + '&auth_app=' + this.authUsuario.app_token, 'portal transaccional');
    } else {
      this.portalTransaccional();
    }
  }


  navDashboard() {

    this.menu.close('custom');
    console.log('Menu -> Dashboard! ');

    if (this.authUsuario.usuario === 'root') {

      this.aquabot.redireccionarInterface('redatwater');

    } else if (this.authUsuario.usuario === 'empresa') {

      this.aquabot.redireccionarInterface('redatwater');

    } else if (this.authUsuario.usuario === 'usuario') {

      this.aquabot.redireccionarInterface('redatwater-usuario');

    } else if (this.authUsuario.usuario === 'cliente') {

      this.aquabot.redireccionarInterface('redatwater-cliente');

    } else if (this.authUsuario.usuario === 'referido') {

      this.aquabot.redireccionarInterface('redatwater-cliente');

    }

  }

  async navTiendasAgua() {
    console.log('Seguimiento -> Sistemas');
    this.menu.close('custom');
    this.ventanaTiendasCerca();
  }

  async ventanaTiendasCerca() { // Tiendas cerca
    const modalAdd = await this.modalController.create({
      component: TiendasUsuarioComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data.direccion);
    }
  }

  navSistemas() {
    console.log('Seguimiento -> Sistemas');
    this.menu.close('custom');
    this.ventanaSistemasCerca();
  }

  async ventanaSistemasCerca() { // Sistemas cercanos
    const modalAdd = await this.modalController.create({
      component: AppSistemascercaComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();

    if (data) {
      console.log('Finalizar datos -> ' + data.direccion);

    }
  }

  appUsabilidadNav() {
    console.log('Seguimiento -> App Usabilidad');
    this.menu.close('custom');
    this.ventanaAppUsabilidad();
  }

  async ventanaAppUsabilidad() { // Ajustes de usabilidad
    const modalAdd = await this.modalController.create({
      component: AppUsabilidadComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    this.appUsabilidad.establecerFontSize();
    if (data) {
      console.log('Finalizar datos -> ' + data.direccion);

    }
  }

  async tarjetaAquahabienteInfo() {
    console.log('Ventana -> Consultar tarjeta Aquahabiente...');
    this.redatwater.infoTarjetaAquahabiente().then((tarjeta: any) => {
      console.log('Cartera -> Tarjeta Aquahabiente: ' + JSON.stringify(tarjeta));
      this.viewTarjetaAquahabiente = true;
      this.infoTarjetaAquahabiente = tarjeta;
      if (this.infoTarjetaAquahabiente?.id) {
        this.camposCuenta.tarjeta_id = this.infoTarjetaAquahabiente.id;
      }
    }).catch(error => {
      console.log('Ventana -> Error consultar tarjeta Aquahabiente. ' + error);
      this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
    });
  }

  viewYoutubePubliaqua() {
    console.log('Anuncios Youtube');
    this.viewPubliAquaYoutube();
  }

  async viewPubliAquaYoutube() { // Publi Aqua Youtube
    const modalAdd = await this.modalController.create({
      component: AppPubliaquaYoutubeComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }

  viewGuiRapida(usuario: string, tipo: string, grupo?: string) {
    console.log('Guia Rapida -> ', usuario, tipo, grupo);
    this.ventanaGuiaRapida(usuario, tipo, grupo);
  }



  async ventanaGuiaRapida(usuario: string, tipo: string, grupo_usuario: string) { // Publi Aqua Youtube

    const modalAdd = await this.modalController.create({
      component: AppGuiarapidaComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { usuario, tipo, grupo_usuario }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }

  async ventanaAsociarSuministro() { // Publi Aqua Youtube
    const modalAdd = await this.modalController.create({
      component: AsociarSuministroAguaComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }


  navReferirCuenta(usuario: string, tipo: string) {
    console.log('Referir cuenta a web');
    /*
    const mensaje = ;
    const asunto = ;
    const imagen = ''; // URL de la imagen a compartir // referidos/auth/registrarse?c=10347990
    const enlace = ; // Enlace web
*/

    let tituloMensaje = '';
    let imagenMensaje = '';
    let asuntoMensaje = '';
    let enlaceMensaje = '';
    console.log('Seguimiento -> Tipo: ', usuario);

    switch (usuario) {

      case 'usuario':
        console.log('Seguimiento -> Usuario: ');

        switch (tipo) {

          case 'recordatorio_suministro_agua':
            console.log('Seguimiento -> Tipo de referencia: ', tipo);

            tituloMensaje = `¡Hola! 💧 ¡Es hora de mantenerte hidratado y fresco! Renueva tu suministro de agua con ${this.authUsuario.nombre} y disfruta de la mejor calidad con RED AT-WATER ©. ¡Código de Red: ${this.authUsuario.codigo}! ¡Haz tu pedido o compra ahora y mantén la frescura a tu alcance!`;
            imagenMensaje = 'https://bancodelagua.com/app/assets/templates/homepage/image/red_app_domicilio_aquahabiente.jpg';
            asuntoMensaje = '¡Renueva tu suministro de agua con RED AT-WATER ©!';
            enlaceMensaje = 'https://bancodelagua.com/app/es/homepage/descargarAppPlaystore?tipo_app=bancodelagua';

            break;

          case 'invitacion_ser_domiciliario':

            console.log('Seguimiento -> Tipo de referencia: ', tipo);

            tituloMensaje = `¡Hola! 🚚 ¡Soy ${this.authUsuario.nombre}, tu Domiciliario en RED AT-WATER! Disfruta de calidad en casa con la comodidad de nuestro servicio a domicilio. ¡Código de Red: ${this.authUsuario.codigo}! Haz tu pedido o compra ahora.`;
            imagenMensaje = 'https://bancodelagua.com/app/assets/templates/homepage/image/red_app_aquahabiente.jpg';
            asuntoMensaje = '¡Haz tu pedido ahora con tu Domiciliario en RED AT-WATER ©!';
            enlaceMensaje = 'https://bancodelagua.com/app/es/homepage/descargarAppPlaystore?tipo_app=bancodelagua';


            break;

          case 'invitacion_ser_distribuidor':
            console.log('Seguimiento -> Tipo de referencia: ', tipo);

            tituloMensaje = `¡Hola! 🌟 ¡Soy ${this.authUsuario.nombre}, tu Distribuidor@ exclusivo en RED AT-WATER! Experimenta un servicio personalizado y obtén tu agua de calidad con comodidad. ¡Código de Red: ${this.authUsuario.codigo}! Haz tu pedido o compra ahora.`;
            imagenMensaje = 'https://bancodelagua.com/app/assets/templates/homepage/image/red_app_aquahabiente.jpg';
            asuntoMensaje = '¡Haz tus pedidos ahora con tu Distribuidor@ en RED AT-WATER!';
            enlaceMensaje = 'https://bancodelagua.com/app/es/homepage/descargarAppPlaystore?tipo_app=bancodelagua';


            break;

          case 'invitacion_ser_pre_vendedor':
            console.log('Seguimiento -> Tipo de referencia: ', tipo);

            tituloMensaje = `¡Hola ${this.authUsuario.nombre}! 🚀 Sé parte de la experiencia Aquahabiente © con tu prevendedor confiable. Código de Red: ${this.authUsuario.codigo}. ¡Haz tus pedidos o compras ahora mismo y disfruta de la comodidad en tus manos!`;
            imagenMensaje = 'https://bancodelagua.com/app/assets/templates/homepage/image/red_app_aquahabiente.jpg';
            asuntoMensaje = '¡Haz tus pedidos ahora en Red AT-WATER ©!';
            enlaceMensaje = 'https://bancodelagua.com/app/es/homepage/descargarAppPlaystore?tipo_app=bancodelagua';

            break;

        }

        break;

      case 'cliente':
        console.log('Seguimiento -> Cliente: ');

        switch (tipo) {

          case 'invitacion_referido':

            console.log('Seguimiento -> Tipo de referencia: ', tipo);
            // https://bancodelagua.com/app/es/homepage/get_rec?tr=ref&pi=1&tu=1&co_ref=20028369
            tituloMensaje = `¡Hola! 🌟 Te invito a unirte a mi Red Aquahabiente para disfrutar juntos de increíbles beneficios, Código: ${this.authUsuario.nombre}`;
            asuntoMensaje = 'Únete a mi Red Aquahabiente y disfruta de grandes beneficios';
            imagenMensaje = 'https://bancodelagua.com/app/assets/templates/homepage/image/red_referidos_aquahabiente.jpg'; // URL de la imagen a compartir
            enlaceMensaje = this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/get_rec?tr=ref&pi=1&tu=1&co_ref=' + this.authUsuario.codigo; // Enlace web

            break;

        }

        break;

    }

    let parametrosData = {
      tituloMensaje: tituloMensaje,
      imagenMensaje: imagenMensaje,
      asuntoMensaje: asuntoMensaje,
      enlaceMensaje: enlaceMensaje
    };

    console.log('Parametros -> Compartir ', parametrosData);
    this.compartirHazConexiones(parametrosData);
  }

  async compartirHazConexiones(parametros: any) {
    if (parametros) {
      const loading = await this.loadingCtrl.create({
        message: 'Cargando...',
        spinner: 'crescent',
        showBackdrop: true
      });
      loading.present();

      this.socialCompartir.share(parametros.tituloMensaje, parametros.asuntoMensaje, parametros.imagenMensaje, parametros.enlaceMensaje)
        .then(() => {
          loading.dismiss();
          console.log('Compartido exitosamente: ', this.authUsuario);
        })
        .catch((error) => {
          loading.dismiss();
          console.error('Seguimiento de errores -> Error al compartir', error);
        });
    } else {
      console.log('Seguimiento de errores -> ', parametros);
    }

  }

  async progresoInformacionCuenta() {
    const totalFields = Object.keys(this.camposCuenta).length;
    const filledFields = Object.values(this.camposCuenta).filter(field => field !== '').length;
    this.progress = ((filledFields / totalFields) * 100);
    console.log('Proceso de información de cuenta: ', this.progress);
  }

  async confirmarSalir() {
    if (this.authUsuario?.id) {
      console.log('Seguimiento -> Salir de App');
      const alert = await this.alertCtr.create({
        cssClass: 'my-custom-class',
        header: 'RED AT-WATER',
        message: '¿Esta seguro de cerrar sesión?',
        buttons: [
          {
            text: 'Cerrar ventana',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cerrar!');
            }
          }, {
            text: 'Si, Aceptar',
            handler: (data) => {
              console.log('Ok!');

              this.menu.close('custom');
              this.navCtrl?.navigateRoot(['/logout']);

            }
          }
        ]
      });
      await alert.present();
    } else {
      console.log('Seguimiento de errores -> Parametros salir ', this.authUsuario);
    }
  }

  async completarInfo() {
    console.log('Completar información de cuenta');
    this.iniciarPortalTransaccional();
  }

}
