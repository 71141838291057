import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { RedatwaterService } from 'src/app/services/redatwater.service';

@Component({
  selector: 'app-app-publiaqua-youtube',
  templateUrl: './app-publiaqua-youtube.component.html',
  styleUrls: ['./app-publiaqua-youtube.component.scss'],
})
export class AppPubliaquaYoutubeComponent implements OnInit, AfterViewInit {
  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  };

  @ViewChild('youtubePlayer', { static: false }) youtubePlayer: ElementRef;

  videoIds = new Array<any>();

  currentVideoIndex: number = 0;
  isLoading: boolean = true;
  isMuted: boolean = true;

  private player: any; // Agregar una propiedad para el reproductor de YouTube

  constructor(
    private loadingCtrl: LoadingController,
    private redatwater: RedatwaterService,
    private modalController: ModalController
  ) {
    console.log('Ventana -> Youtube');
    this.header.pagina = '/redatwater';
    this.header.titulo = 'PubliAqua';
  }

  ngOnInit() {


  }

  ngAfterViewInit() {
    
    this.redatwater.consultarAnunciosFullYoutube().then((dataAnuncios: any) => {

      if (dataAnuncios) {

        //console.log("Full Anuncios -> " + JSON.stringify(dataAnuncios));
        dataAnuncios.forEach((anuncio: any) => {
          console.log('Anuncio Youtube -> ' + anuncio.id);
          if (anuncio?.youtube_video_id) {
            this.videoIds.push(anuncio.youtube_video_id);
          }
        });

        this.initPlayer();
        
      } else {
        console.log('Seguimiento de errores -> ', dataAnuncios);
      }

    }).catch(error => {
      console.log('App -> Error consultar anuncions: ' + error);
    });


  }

  async initPlayer() {

    if (this.videoIds?.length) {
      
      const loading = await this.loadingCtrl.create({
        message: 'Cargando...',
        spinner: 'crescent',
        showBackdrop: true
      });
      //loading.present();

      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      (window as any)['onYouTubeIframeAPIReady'] = () => {
        this.player = new (window as any).YT.Player(this.youtubePlayer.nativeElement, {
          height: '100%',
          width: '100%',
          videoId: this.videoIds[this.currentVideoIndex],
          playerVars: {
            autoplay: 1,
            controls: 0,
            modestbranding: 1,
            showinfo: 0,
            rel: 0,
            loop: 1,
            mute: false,
          },
          events: {

            onReady: (event: any) => {
              event.target.playVideo();
              //loading.dismiss();
              this.isLoading = false;
            },
            onStateChange: (event: any) => {
              if (event.data === (window as any).YT.PlayerState.ENDED) {
                this.playNextVideo();
              }
              //loading.dismiss();
            },
          },
        });
      };

    } else {
      console.log('Seguimiento de errors -> Youtube');
    }
  }

  loadVideo(videoId: string) {
    if (this.player) {
      this.player.loadVideoById(videoId);
    } else {
      console.error('Elemento no encontrado: youtubePlayer');
      this.isLoading = false;
    }
  }

  playNextVideo() {
    this.isLoading = true;
    this.currentVideoIndex = (this.currentVideoIndex + 1) % this.videoIds.length;
    this.loadVideo(this.videoIds[this.currentVideoIndex]);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  }


  cerrarVentana() {
    console.log('Cerrar ventana!');
    if (this.player) {
      this.player.destroy();
    }
    this.modalController.dismiss();
  }

}
