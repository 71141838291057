import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
// Firebase
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Usuario } from '../interfaces/book.interface';
import { AuthService } from './auth.service';
import { Subscription, interval } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { AlertService } from './alert.service';


@Injectable()

export class FirebaseService {

  private subscriptionUbicacion: Subscription = new Subscription();

  appUbicacion = {
    lat: null,
    lng: null
  };

  usuarioApp: Usuario;
  actualizarUbicacion: any;

  //apiUrl = 'https://bancodelagua.com/channel';

  // Parametros 
  getID: string = null;

  idRoot = 'fac9661f75ea23bb209a730736afe8bc';

  authUsuario: any = {
    id: null
  };

  constructor(
    private FireStore: AngularFirestore,
    private auth: AuthService,
    private loadingCtrl: LoadingController,
    private alertController: AlertService,
    public datepipe: DatePipe
  ) {

    console.log('App: RED AT-WATER');
    console.log('Iniciando Firebase...');
    
    this.initFirebase();
    console.log('Service -> firebase!');
  }

  async initFirebase() {
    const loading = await this.loadingCtrl.create({
      message: 'RED AT-WATER...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    loading.dismiss();

  }

  /* ************************************************************ */
  /* ***** Firebase **** */
  /* ************************************************************ */

  // Registrar
  createDoc<tipo>(data: tipo, enlace: string, idDoc: string) {
    const itemsCollection: AngularFirestoreCollection<tipo> = this.FireStore.collection<tipo>(enlace);
    return itemsCollection.doc(idDoc).set(data);
  }

  // Generar ID
  createIdDoc(): string {
    return this.FireStore.createId();
  }

  // Eliminar
  deleteDoc(path) {
    return this.FireStore.doc(path).delete();
  }

  // Actualizar
  updateDoc(path, data) {
    return this.FireStore.doc(path).update(data);
  }

  // Get consultar
  getDoc<tipo>(path) {
    const doc: AngularFirestoreDocument<tipo> = this.FireStore.doc(path);
    return doc.valueChanges();
  }

  // Get lista
  getCollection<tipo>(path) {
    const itemsCollection: AngularFirestoreCollection<tipo> = this.FireStore.collection<tipo>(path);
    return itemsCollection.valueChanges();
  }

  // Get lista Where
  getCollectionWhere<tipo>(path: string, whereParametro: string, whereValue: string) {
    const dataCollection: AngularFirestoreCollection<tipo> = this.FireStore.collection<tipo>(
      path, ref =>
      ref.where(whereParametro, '==', whereValue)

    );
    return dataCollection.valueChanges();
  }

  // Get lista Multi-Where
  getCollectionMultiWhere<tipo>(path: string, whereParametro: string, whereValue: string[], orderParametro: string, orderValue: any) {
    const dataCollection: AngularFirestoreCollection<tipo> = this.FireStore.collection<tipo>(
      path, ref =>
      ref.where(whereParametro, 'array-contains-any', whereValue)
        .orderBy(orderParametro, orderValue)
    );
    return dataCollection.valueChanges();
  }

  // Get lista Where & Limit
  getCollectionWhereLimit<tipo>(path: string, whereParametro: string, whereValue: string, orderParametro: string, orderValue: any, limitEnd: number, limitStart?: number) {
    let dataCollection: AngularFirestoreCollection<tipo>;
    if (limitStart === null) {
      dataCollection = this.FireStore.collection<tipo>(
        path, ref =>
        ref.where(whereParametro, '==', whereValue)
          .orderBy(orderParametro, orderValue)
          .limit(limitEnd)
      );
      return dataCollection.valueChanges();
    }

    dataCollection = this.FireStore.collection<tipo>(
      path, ref =>
      ref.where(whereParametro, '==', whereValue)
        .orderBy(orderParametro, orderValue)
        .startAfter(limitStart)
        .limit(limitEnd)
    );
    return dataCollection.valueChanges();
  }

  // Get lista Multi-ParametroWhere
  getCollectionMultiParametroWhere<tipo>(path: string, whereParametroOne: string, whereValueOne: string, whereParametroTwo: string, whereValueTwo: string, orderParametro: string, orderValue: any) {
    const dataCollection: AngularFirestoreCollection<tipo> = this.FireStore.collection<tipo>(
      path, ref =>
      ref.where(whereParametroOne, '==', whereValueOne)
        .where(whereParametroTwo, '==', whereValueTwo)
        .orderBy(orderParametro, orderValue)
    );
    return dataCollection.valueChanges();
  }

  async getTokenAuth(usuario: string) {
    if (usuario !== null && usuario !== undefined) {
      console.log('Consultando Token  ->', usuario);
      const path = '/b_usuarios/' + usuario;
      return this.getDoc<Usuario>(path);
    }
  }


}


