import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController, Platform } from '@ionic/angular';
import { Header, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { EscanerService } from 'src/app/services/escaner.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TextSpeechService } from 'src/app/services/text-speech.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { AppComollegarComponent } from '../../app-comollegar/app-comollegar.component';
import { ToastService } from 'src/app/services/toast.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-infosistema-cerca',
  templateUrl: './infosistema-cerca.component.html',
  styleUrls: ['./infosistema-cerca.component.scss'],
})
export class InfosistemaCercaComponent implements OnInit {

  @Input() sistemaSeleccionado: any;

  authUsuario: any = null;

  slidePubliAqua = [];
  slideItem: any = {
    'titulo': '',
    'info': '',
    'boton': '',
    'boton_url': '',
    'imagen': 'assets/img/default.png'
  };

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_INFOSISTEMA';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 1,
    pagina: '',
    titulo: ''
  }

  anunciosPubliAqua: Array<any> = Array();
  anunciosLista: any = [];
  slideAnuncios: any[] = [];
  totalAnuncios = 0;

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Establecer ubicación.',
    coordenadas: {
      lat: null,
      lng: null
    }
  };

  defaultImage = 'assets/img/default.png';

  sistemaDisponible = '';
  sistemaMantenimiento = '';

  constructor(
    private translateConfigService: TranslateConfigService,
    private ventanaAlerta: AlertService,
    private redatwater: RedatwaterService,
    private socialCompartir: SocialSharing,
    private auth: AuthService,
    private navCtrl: NavController,
    private modalController: ModalController,
    private alertCtr: AlertController,
    private toast: ToastService,
    private platform: Platform,
    private escaner: EscanerService,
    private geolocalizacion: GeolocalizacionService,
    private loadingCtrl: LoadingController,
    private aquabot: AquabotService,
    private utilitario: UtilitariosService,
    private textSpeech: TextSpeechService
  ) {
    console.log('App -> Perfil de Sistema!');
    this.header.pagina = '/';
    this.header.titulo = 'DETALLE DE SISTEMA';
  }

  ngOnInit() {

    console.log('Info sistema -> ', this.sistemaSeleccionado);

    this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getUsuario: any) => {
      console.log('Usuario Auth ->', getUsuario);
      if (getUsuario?.data) {
        this.authUsuario = getUsuario.data;
        console.log('Seguimiento -> Auth dispositivo: ', this.authUsuario);
        this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
          this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
            this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
            this.itemsIdioma = this.translateConfigService.getItemsIdioma();
            this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

            if (this.itemsIdiomaApp && this.itemsIdioma) {
              //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
              //console.log('Parametros de idioma -> ', this.itemsIdioma);
              //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);

              this.aquaBot = this.aquabot.appMsgAquaBot({
                msg: this.itemsIdiomaApp.aquabot_msg_cajeros,
                estado: 'text-primary'
              });

              this.publiAquaSlide();
              this.loadUbicacionActual();

              if (this.sistemaSeleccionado.data.sistema.disponible === '2') {
                this.sistemaDisponible = 'Si';
              } else {
                this.sistemaDisponible = 'No';
              }

              if (this.sistemaSeleccionado.data.sistema.en_mantenimiento === '2') {
                this.sistemaMantenimiento = 'En mantenimiento';
              } else {
                this.sistemaMantenimiento = '';
              }

            } else {
              this.translateConfigService.resetIdioma(this.idiomaPagina);
            }
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      } else {
        this.navCtrl?.navigateRoot(['/logout']);
      }
    });

  }

  ngOnDestroy() {

  }

  async loadUbicacionActual() {
    this.geolocalizacion.loadUbicacionActual().then((ubicacion: any) => {
      console.log('App Ubicacion actual -> ' + JSON.stringify(ubicacion));
      this.direccionActual = ubicacion;

    }).catch(error => {
      console.log('App -> Error: ' + error);
    });
  }


  seleccionarIdioma(idioma) {
    if (idioma) {
      //console.log('Seleccionar idioma -> ', idioma);
      this.selectedLanguage = idioma;
      //console.log('Idioma seleccionado: ' + this.selectedLanguage);
      this.languageChanged(this.selectedLanguage);
      this.idiomaSeleccionado = idioma;

      this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
        this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
          this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
          this.itemsIdioma = this.translateConfigService.getItemsIdioma();
          this.translateConfigService.getItemsIdioma();
          if (this.itemsIdiomaApp && this.itemsIdioma) {
            //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
            //console.log('Parametros de idioma -> ', this.itemsIdioma);
            //console.log('Test 2 -> ', this.itemsIdiomaApp.app_loading);

            this.textSpeech.hablar(this.itemsIdioma.hablar_app_intrucciones);
          } else {
            this.translateConfigService.resetIdioma(this.idiomaPagina);
          }
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }
  }

  languageChanged(idioma: string) {
    this.translateConfigService.setLanguage(idioma);
  }


  async publiAquaSlide() {

    // Consultar Anuncios
    this.redatwater.consultarAnunciosFull().then((dataAnuncios: any) => {
      if (dataAnuncios) {
        //console.log("Full Anuncios -> " + JSON.stringify(dataAnuncios));
        dataAnuncios.forEach((anuncio: any) => {
          console.log('Anuncio -> ' + anuncio.id);
          this.slidePubliAqua.push({
            'titulo': anuncio.titulo,
            'info': this.utilitario.removeHtmlTags(anuncio.descripcion),
            'boton': 'Ver promocion ...',
            'boton_url': anuncio.link,
            'imagen': anuncio.file
          });
        });

        this.slidePubliAqua.push({
          'titulo': '¡10% de descuento!',
          'info': 'Disfruta en tiendas seleccionadas',
          'boton': 'Ver tiendas ...',
          'boton_url': '',
          'imagen': 'assets/img/publiaqua/descuento.png'
        });

      }

      const item = Math.floor(Math.random() * this.slidePubliAqua.length);
      if (this.slidePubliAqua[item]) {
        console.log('Listado de anuncios -> ' + this.slidePubliAqua[item]);
        this.slideItem = this.slidePubliAqua[item];
      }
    }).catch(error => {
      console.log('App -> Error consultar anuncions: ' + error);
    });

  }

  async cerrarVentana() {
    this.closeModal();
  }

  closeModal() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss({ sistemasCerca: this.sistemaSeleccionado }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }


  llegarSistema() {
    if (this.sistemaSeleccionado.data.infoUbicacion?.ubicacion_lat && this.sistemaSeleccionado.data.infoUbicacion?.ubicacion_long) {

      console.log('Como llegar a sistema -> ', this.sistemaSeleccionado.data.infoUbicacion);
      this.ventanaComoLlegar(this.sistemaSeleccionado);

    } else {
      console.log('Seguimiento de errores -> ', this.sistemaSeleccionado);
    }
  }


  async ventanaComoLlegar(sistema: any) {

    if (sistema) {

      const registroSeleccionado = {
        tipo: 'sistema',
        registro: sistema
      };

      const modalAdd = await this.modalController.create({
        component: AppComollegarComponent,
        mode: 'ios',
        swipeToClose: true,
        componentProps: { registroSeleccionado }
      });
      await modalAdd.present();
      const { data } = await modalAdd.onWillDismiss();
      if (data) {
        console.log('Finalizar datos -> ' + data);
      } else {
        this.toast.msgAppBottom('¡Oops! Como llegar incorrecta.', 'danger');
      }

    } else {
      console.log('Seguimiento de errores -> ', sistema);
    }

  }

  async generarCodigo() {
    let codigoArray = {
      categoria: 'sistema_key',
      sistema_key: this.sistemaSeleccionado.data.sistema.public_key
    };
    this.escaner.generarQr(codigoArray).then(() => {

    }).catch((err) => { console.log('Error: ', err); });
  }


  compraApp() {
    console.log('Seguimiento -> Comprar Web');
    this.descargarAppAtwater();
  }

  compraWeb() {
    console.log('Seguimiento -> Comprar App');
    const enlaceWeb = this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/ragua?cods=' + this.sistemaSeleccionado.data.sistema.public_key;
    console.log('Enlace -> ', enlaceWeb);
    this.verPagina(enlaceWeb, 'Portal Web');
  }

  verPagina(pagina: string, titulo: string) {
    if (pagina) {
      console.log('Ver pagina: ' + pagina);
      this.aquabot.enlaceSugerido(pagina, titulo);
    }
  }

  async descargarAppAtwater() {
    console.log('Seguimiento -> Descargar App');
    if (this.platform.is('ios')) {
      this.aquabot.enlaceSugerido(this.redatwater.getEnlaceAppAtWaterIOS(), 'AT-WATER para iOS');
    } else { // Android
      this.aquabot.enlaceSugerido(this.redatwater.getEnlaceAppAtWaterAndroid(), 'AT-WATER para Android');
    }

  }


  async compartir(sistema: any) {
    console.log('Item Compartir -> ', sistema);
    
    if (sistema?.data) {

      const mensaje = `¡Red AT-WATER © Una nueva y refrescante forma de comprar! Código: ${sistema.data.sistema.public_key}, Modelo: ${sistema.data.sistema.modelo} Empresa: ${sistema.data.sistema.empresa} | Ciudad: ${sistema.data.infoUbicacion.ciudad_nombre}`;
      const asunto = 'Compartido desde ® Banco del Agua';
      const imagen = 'https://bancodelagua.com/app/assets/templates/homepage/image/red_sistemas.jpg'; // URL de la imagen a compartir
      const enlace = this.redatwater.getWebUrl(); // Enlace web

      const loading = await this.loadingCtrl.create({
        message: 'Cargando...',
        spinner: 'crescent',
        showBackdrop: true
      });
      loading.present();

      this.socialCompartir.share(mensaje, asunto, imagen, enlace)
        .then(() => {
          loading.dismiss();
          console.log('Compartido exitosamente: ', sistema);
        })
        .catch((error) => {
          loading.dismiss();
          console.error('Seguimiento de errores -> Error al compartir', error);
        });
    }
  }

}
