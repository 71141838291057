import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';

@Component({
  selector: 'app-detalle-notificacion',
  templateUrl: './detalle-notificacion.component.html',
  styleUrls: ['./detalle-notificacion.component.scss'],
})
export class DetalleNotificacionComponent implements OnInit, OnDestroy {

  @Input() listaItems: any[];

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_NOTIFICACION_DETALLE';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  constructor(
    private aquabot: AquabotService,
    private ventanaAlerta: AlertService
  ) {
    console.log('App: Detlle Notificación.');
    this.header.pagina = '/';
    this.header.titulo = 'NOTIFICACIÓN';
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    console.log('Lista de items: ', this.listaItems);

  }

  async procesarLista() {
    if (this.listaItems) {

      this.listaItems.forEach((item: any) => {
        console.log('Item -> ', item);
      });

    } else {
      console.log('Seguimiento de errores -> ', this.listaItems);
    }
  }


  goEnlace(item: any) {
    if (item) {

      console.log('Tipo de item: ', item.tipo);

      if (item.tipo === 'url') {

        this.aquabot.enlaceSugerido(item.data, 'PORTAL TRANSACCIONAL');

      } else {

        this.ventanaAlerta.msgAppCenter(item.titulo, '<span class="text-success">' + item.data + '</span>');

      }

    } else {
      console.log('Seguimiento de errores -> ', item);
    }
  }

}
