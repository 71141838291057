import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { AgregarTarjetaComponent } from './agregar-tarjeta/agregar-tarjeta.component';
import { InfoTarjetaComponent } from './info-tarjeta/info-tarjeta.component';

@Component({
  selector: 'app-cartera',
  templateUrl: './cartera.component.html',
  styleUrls: ['./cartera.component.scss'],
})
export class CarteraComponent implements OnInit {

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  formRecargarCuenta = {
    monto: null
  }

  viewTarjetaAquahabiente = false;
  viewTarjetasCredito = false;

  listaTarjetasCartera = new Array<any>();
  infoTarjetaAquahabiente: any;

  authUsuario: any;

  constructor(
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private modalController: ModalController,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
  ) {
    console.log('Ventana -> cartera');
    this.header.pagina = '/redatwater';
    this.header.titulo = 'Cartera';

  }

  ngOnInit() {
    // Consultar Auth
    this.auth.loadAuthToken().then((dataUsuario: any) => {
      if (dataUsuario) {
        //console.log('Auth -> Usuario logueado: ' + JSON.stringify(dataUsuario));
        this.authUsuario = dataUsuario;
        this.tarjetaAquahabienteInfo();
        this.carteraConsultarTarjetas();
      }
    }).catch(error => {
      console.log('Auth -> Error en usuaio logueado.');
      this.navCtrl?.navigateRoot(['/logout']);
    });
  }

  async ventanaAgregarTarjeta() { // Tarjeta agregar
    const modalAdd = await this.modalController.create({
      component: AgregarTarjetaComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
      this.carteraConsultarTarjetas();
    }
  }

  async carteraConsultarTarjetas() {
    console.log('Ventana -> Consultar tarjetas...');
    this.redatwater.carteraTarjetas().then((tarjetasData: any) => {
      //console.log('Cartera -> Tarjetas: ' + JSON.stringify(tarjetasData));
      this.viewTarjetasCredito = true;
      this.listaTarjetasCartera = tarjetasData;
    }).catch(error => {
      console.log('Ventana -> Error cartera consultar tarjetas. ' + error);
    });
  }

  async tarjetaAquahabienteInfo() {
    console.log('Ventana -> Consultar tarjeta Aquahabiente...');
    this.redatwater.infoTarjetaAquahabiente().then((tarjeta: any) => {
      //console.log('Cartera -> Tarjeta Aquahabiente: ' + JSON.stringify(tarjeta));
      this.viewTarjetaAquahabiente = true;
      this.infoTarjetaAquahabiente = tarjeta;
    }).catch(error => {
      console.log('Ventana -> Error consultar tarjeta Aquahabiente. ' + error);
    });
  }

  async eliminar(tarjeta: any) {
    if (tarjeta) {
      console.log('Ventana -> Eliminar tarjeta...');
      this.confirmarEliminar(tarjeta);
    }
  }

  async abrirTarjeta() {
    if (this.infoTarjetaAquahabiente) {
      console.log('Ventana -> Info de tarjeta Aquahabiente...');
      const tarjetaAquahabiente = this.infoTarjetaAquahabiente;
      const listaTarjetasCartera = this.listaTarjetasCartera;
      const modalAdd = await this.modalController.create({
        component: InfoTarjetaComponent,
        mode: 'ios',
        swipeToClose: true,
        componentProps: { tarjetaAquahabiente, listaTarjetasCartera }
      });
      await modalAdd.present();
      const { data } = await modalAdd.onWillDismiss();
      if (data) {
        console.log('Finalizar datos -> ' + data);
      }
    }
  }


  async confirmarEliminar(tarjeta: any) {
    console.log('Seguimiento -> Cantidad de productos: ', tarjeta);

    if (tarjeta?.id) {

      const alert = await this.alertCtrl.create({
        cssClass: 'my-custom-class',
        header: 'Eliminar tarjeta',
        message: '<span><span class="text-success">¿Esta seguro de eliminar?</span>  </span>',
        buttons: [
          {
            text: 'Cerrar ventana',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cerrar!');
            }
          }, {
            text: 'Si, eliminar',
            handler: () => {

              console.log('Seguimiento -> ', tarjeta);
              this.eliminarTarjeta(tarjeta);
            }
          }
        ]
      });

      await alert.present();
    } else {
      console.log('Seguimiento de errores -> ', tarjeta);
    }
  }

  async eliminarTarjeta(tarjeta: any) {
    if (tarjeta?.id) {
      console.log('Seguimiento -> Eliminando...');
      this.redatwater.eliminarTarjetaPasarela(tarjeta.id).then((getTarjeta: any) => {
        console.log('Tarjeta eliminada.');
        if (getTarjeta?.id) {
          console.log('Tarjeta Re eliminada.');
          this.carteraConsultarTarjetas();
        } else {
          console.log('Seguimiento de errores -> ', getTarjeta);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      })
    } else {
      console.log('Seguimiento de errores -> ', tarjeta);
    }
  }


}
