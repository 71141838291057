import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Renderer2, ElementRef, Inject, ViewChild, NgZone } from '@angular/core';
import { GooglemapsService } from './googlemaps.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast.service';
import { AlertService } from 'src/app/services/alert.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { EscanerService } from 'src/app/services/escaner.service';
import { UbicacionService } from 'src/app/services/ubicacion.service';

declare var google: any;

@Component({
  selector: 'app-googlemaps',
  templateUrl: './googlemaps.component.html',
  styleUrls: ['./googlemaps.component.scss'],
})
export class GooglemapsComponent implements OnInit {

  public apiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

  position = {
    direccion: null,
    lat: null,
    lng: null
  };

  label = {
    titulo: 'Ubicación',
    subtitulo: 'Mi ubicación'
  }

  map: any;
  marker: any;
  infowindow: any;
  positionSet: any;

  ubicacionText = '';

  ubicacionList = {
    origen: true
  }

  dataDirectionsService: any;
  googleAutocomplete: any;
  searchResults = new Array<any>();

  @ViewChild('map') divMap: ElementRef;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document,
    private googlemapsService: GooglemapsService,
    private ngZone: NgZone,
    private http: HttpClient,
    private alertController: AlertService,
    private modalController: ModalController,
    private escaner: EscanerService,
    private appUbicacion: UbicacionService,
    private alertCtr: AlertController,
    private loadingCtrl: LoadingController
  ) {
    console.log('Google maps');
  }

  ngOnInit(): void {
    this.init();
  }

  async init() {


    this.googlemapsService.init(this.renderer, this.document).then(() => {
      this.initMap();
    }).catch((err) => {
      console.log(err);
    });
  }

  async initMap() {

    const loading = await this.loadingCtrl.create({
      message: 'Cargando ubicación...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    this.appUbicacion.getUbicacionGPS().then((res) => {
      console.log('Ubicacion actual!');
      loading.dismiss();

      this.position = {
        direccion: res.direccion,
        lat: res.lat,
        lng: res.lng
      }

      this.googleAutocomplete = new google.maps.places.AutocompleteService();

      this.consultarUbicacionActual(this.position);

      let latLng = new google.maps.LatLng(this.position.lat, this.position.lng);

      let mapOptions = {
        center: latLng,
        zoom: 16,
        disableDefaultUI: true,
        clickableIcons: false,
      };

      this.map = new google.maps.Map(this.divMap.nativeElement, mapOptions);
      this.marker = new google.maps.Marker({
        map: this.map,
        animation: google.maps.Animation.DROP,
        icon: 'assets/img/marker.png',
        draggable: true
      });

      this.clickHandleEvent();
      this.infowindow = new google.maps.InfoWindow();
      if (this.label.titulo.length) {
        this.addMarker(this.position);
        //this.setInfoWindow(this.marker, this.label.titulo, this.label.subtitulo);
      }
    }).catch(err => {
      loading.dismiss();
      this.alertController.msgAppCenter('RED AT-WATER', 'Activa el GPS para establecer ubicación.');
    });
  }

  clickHandleEvent() {
    this.map.addListener('click', (event: any) => {
      const position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      this.addMarker(position);
    })
  }

  addMarker(position: any): void {
    let latLng = new google.maps.LatLng(position.lat, position.lng);
    this.marker.setPosition(latLng);
    this.map.panTo(position);
    this.positionSet = position;
  }

  setInfoWindow(marker: any, titulo: string, subtitulo: string) {
    const contentString = '<div id="contentInsideMap>' +
      '<div>' +
      '</div>' +
      '<p style="font-weight: bold; margin-bottom: 5px;"' +
      '<div id="bodyContent">' +
      '<p class="normal m-0">' + subtitulo + '</p>' +
      '</div>' +
      '</div>';
    this.infowindow.setContent(contentString);
    this.infowindow.open(this.map, marker);
  }

  async mylocation() {

    console.log('Ubicando...');
    const loading = await this.loadingCtrl.create({
      message: 'Cargando ubicación...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    this.appUbicacion.getUbicacionGPS().then((res) => {
      loading.dismiss();
      const position = {
        lat: res.lat,
        lng: res.lng
      }
      //this.addMarker(position);
      this.consultarUbicacionActual(this.position);
    }).catch((err) => {
      loading.dismiss();
      console.log('Algo salio mal: ' + err);
    });

  }

  aceptar() {
    console.log('Confirmar ubicacion ->', this.positionSet);
    this.alertController.msgAppCenter('RED AT-WATER', 'Ubicación confirmada: <span class="text-success">' + this.position.direccion + '</span>');
    this.modalController.dismiss({ pos: this.positionSet }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  cerrar() {
    console.log('Cerrar');
    this.modalController.dismiss().catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });

  }

  searchOrigen() {
    console.log('searchOrigen() ->', this.ubicacionText);
    if (this.ubicacionText !== null) {
      if (this.ubicacionText.trim().length) {
        this.ubicacionList.origen = false;
        this.googleAutocomplete.getPlacePredictions({ input: this.ubicacionText }, predictions => {
          console.log(predictions);
          this.ngZone.run(() => {
            this.searchResults = predictions;
          });
        });
      }
    }
  }

  async saveOrigen(origen: any) {

    console.log('Origen ->', origen);
    const loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    this.ubicacionText = origen.description;
    this.position.direccion = this.ubicacionText;
    const apiPath = this.apiUrl + '?key=' + environment.ApiKeyGoogleMaps + '&address=' + origen.description;
    this.http.get(apiPath).subscribe((data: any) => {
      console.log('Peticion coordenadas() ->', data);
      this.ubicacionList.origen = true;
      if (data.status === 'OK') {
        loading.dismiss();
        const coordenadas = data.results[0].geometry.location;
        console.log('Coordenadas de origen ->', coordenadas);
        this.position.direccion = this.ubicacionText;
        this.position.lat = coordenadas.lat;
        this.position.lng = coordenadas.lng;
        this.addMarker(this.position);
        //this.directionsDisplay.setMap(null);
      } else {
        loading.dismiss();
        this.alertController.msgAppCenter('Notificación', '¡Intente nuevamente!');
      }
    });

  }

  async consultarUbicacionActual(ubicacion: any) {

    console.log('Ubicacion ->', ubicacion);
    const loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    // this.ubicacionText.origen = ubicacion.description;
    //this.ubicacion.direccionB = this.ubicacionText.destino;
    const apiPath = this.apiUrl + '?key=' + environment.ApiKeyGoogleMaps + '&latlng=' + ubicacion.lat + ',' + ubicacion.lng;
    this.http.get(apiPath).subscribe((data: any) => {
      console.log('Peticion coordenadas() ->', data);
      this.ubicacionList.origen = true;
      if (data.status === 'OK') {
        loading.dismiss();
        const coordenadas = data.results[0].geometry.location;
        this.ubicacionText = data.results[0].formatted_address;
        this.position.direccion = this.ubicacionText;
        this.addMarker(this.position);
        console.log('Ubicacion de origen ->', this.ubicacionText);
      } else {

        this.alertController.msgAppCenter('Oops!', 'Lugar incorrecto.');
        loading.dismiss();
      }
    });

  }

  async escanearUbicacionInicio() {
    let metodosPermitidos = [];

    metodosPermitidos.push({
      label: 'PRINCIPAL',
      type: 'radio',
      name: 'camara_metodo',
      value: false,
    });

    metodosPermitidos.push({
      label: 'FRONTAL',
      type: 'radio',
      name: 'camara_metodo',
      value: true,
    });

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: 'CAMARA DE ESCANER',
      inputs: metodosPermitidos,
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cerrar!');
          }
        }, {
          text: 'Aceptar',
          handler: (data: any) => {
            console.log('Ok!');

            if (data === true) {
              this.leerQrUbicacion(data);
            } else if (data === false) {
              this.leerQrUbicacion(data);
            } else {
              this.escanearUbicacionInicio();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async leerQrUbicacion(camara: any) {
    this.escaner.leerQr(camara).then((data: any) => {
      console.log('Respuesta escaner -> ' + data);
      let parametrosEscaneados: any = data;

      if (parametrosEscaneados?.categoria &&
        parametrosEscaneados?.ubicacion &&
        parametrosEscaneados?.lat &&
        parametrosEscaneados?.lng) {

        if (parametrosEscaneados.categoria === 'ubicacion') {
          this.ubicacionText = parametrosEscaneados.ubicacion;
        } else {
          this.alertController.msgAppCenter('Tutorial', 'Categoria incorrecta.');
        }

      } else {
        this.alertController.msgAppCenter('Tutorial', 'Ingrese nuevamente la ubicación.');
      }
    }).catch((err) => {
      console.log('Error escanear ->', err);
    });
  }

}
