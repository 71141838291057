import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { EnviarMensajeComponent } from './enviar-mensaje/enviar-mensaje.component';

@Component({
  selector: 'app-app-mensajes',
  templateUrl: './app-mensajes.component.html',
  styleUrls: ['./app-mensajes.component.scss'],
})
export class AppMensajesComponent implements OnInit, OnDestroy {

  authUsuario: any = null;
  cuentaUsuario: any = null;
  listaMensajes: any[] = [];
  infoMensaje: any[] = [];
  cantTotalRegistros:number = 0;


  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_MENSAJES';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }


  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private utilitario: UtilitariosService,
    private aquabot: AquabotService,
    private ventanaAlerta: AlertService,
    private alertCtr: AlertController,
    private modalController: ModalController,

  ) {
    console.log('App: Mensajes.');
    this.header.pagina = '/';
    this.header.titulo = 'MENSAJES';
  }

  ngOnInit() {
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {

              this.authUsuario = getAuth.data;
              this.consultarRegistros();


            } else {
              console.log('Seguimiento de errores -> ', getAuth);
              this.cerrarVentana();
              
            }
          })


        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }

  consultarRegistros() {
    this.redatwater.consultarFullCuentaUsuario().then((getData: any) => {
      if (getData) {

        console.log('Full data cuenta usuario -> ', getData);
        this.cuentaUsuario = getData;
        this.listaMensajes = this.cuentaUsuario.mensajes;
        if (this.listaMensajes) {
          this.cantTotalRegistros = this.listaMensajes.length;
        }
      

      } else {
        console.log('Seguimiento de errores -> ', getData);
        this.cerrarVentana();
        //this.navCtrl?.navigateRoot(['/logout']);
      }

    }).catch((err: any) => {
      this.cerrarVentana();
      console.log('Seguimiento de errores -> ', err);
    });
  }


  getInfoRegistro(registro: any) {
    this.infoMensaje = [];
    if (registro?.infoRegistro) {
      //console.log('Seguimiento -> Info ', registro);
      registro.infoRegistro.forEach((info: any) => {
        this.infoMensaje.push({
          data: info.data,
          titulo: info.titulo
        });
      });

      this.procesarLista();

    } else {
      this.infoMensaje = [];
    }
  }

  seleccionarIdioma(idioma) {
    if (idioma) {
      //console.log('Seleccionar idioma -> ', idioma);
      this.selectedLanguage = idioma;
      //console.log('Idioma seleccionado: ' + this.selectedLanguage);
      this.languageChanged(this.selectedLanguage);
      this.idiomaSeleccionado = idioma;

      this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
        this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
          this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
          this.itemsIdioma = this.translateConfigService.getItemsIdioma();
          this.translateConfigService.getItemsIdioma();
          if (this.itemsIdiomaApp && this.itemsIdioma) {
            //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
            //console.log('Parametros de idioma -> ', this.itemsIdioma);
            //console.log('Test 2 -> ', this.itemsIdiomaApp.app_loading);

          } else {
            this.translateConfigService.resetIdioma(this.idiomaPagina);
          }
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }
  }

  languageChanged(idioma: string) {
    this.translateConfigService.setLanguage(idioma);
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss();
  }

  async procesarLista() {
    if (this.infoMensaje) {

      this.infoMensaje.forEach((item: any) => {
        console.log('Item -> ', item);
      });

    } else {
      console.log('Seguimiento de errores -> ', this.infoMensaje);
    }
  }
  
  goEnlace(item: any) {
    if (item) {

      console.log('Tipo de item: ', item.tipo);

      if (item.tipo === 'url') {

        this.aquabot.enlaceSugerido(item.data, 'PORTAL TRANSACCIONAL');

      } else {

        this.ventanaAlerta.msgAppCenter(item.titulo, '<span class="text-success">' + item.data + '</span>');

      }

    } else {
      console.log('Seguimiento de errores -> ', item);
    }
  }

  okMensaje(mensaje: any) {
    console.log('Seguimiento -> Ok Mensaje');
    this.redatwater.gestionarMensajes(mensaje.id, 'visto', mensaje.tipo_mensaje).then((getData: any) => {
      if (getData) {

        console.log('Mensaje -> Editada', getData);
        this.consultarRegistros();

      } else {
        console.log('Seguimiento de errores -> ', getData);
        this.cerrarVentana();
        //this.navCtrl?.navigateRoot(['/logout']);
      }

    }).catch((err: any) => {
      this.cerrarVentana();
      console.log('Seguimiento de errores -> ', err);
    });
  }

  nuevoMensaje() {
    console.log('Nuevo Mensaje -> Ventana.');
    this.ventanaMensaje();
  }


  async ventanaMensaje() { // Sistemas cercanos
    const modalAdd = await this.modalController.create({
      component: EnviarMensajeComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    
    if (data) {
      console.log('Finalizar datos -> ' + data.direccion);

    }
  }
}
