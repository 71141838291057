import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, AlertController, NavController, ModalController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';

@Component({
  selector: 'app-app-guiarapida',
  templateUrl: './app-guiarapida.component.html',
  styleUrls: ['./app-guiarapida.component.scss'],
})
export class AppGuiarapidaComponent implements OnInit, OnDestroy {

  @Input() usuario: any;
  @Input() tipo: any;

  @Input() grupo_usuario: any;

  authUsuario: any = null;
  cuentaUsuario: any = null;


  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_GUIA_RAPIDA';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private utilitario: UtilitariosService,
    private aquabot: AquabotService,
    private loadingCtrl: LoadingController,
    private ventanaAlerta: AlertService,
    private alertCtr: AlertController,
    private navCtrl: NavController,
    private modalController: ModalController,
  ) {
    console.log('App: Guia rapida.');
    this.header.pagina = '/';
    this.header.titulo = 'GUIA RAPIDA';
   }

   ngOnInit() {

    console.log('Parametros -> Guia ', this.usuario, this.tipo, this.grupo_usuario);

    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data.id) {
              this.authUsuario = getAuth.data;
              console.log('Consultando!');
              
            } else {
              this.navCtrl?.navigateRoot(['/logout']);
            }

          });

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }

  

}
