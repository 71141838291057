import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavController, IonInfiniteScroll } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';

@Component({
  selector: 'app-resultados-similar',
  templateUrl: './resultados-similar.component.html',
  styleUrls: ['./resultados-similar.component.scss'],
})
export class ResultadosSimilarComponent implements OnInit {

  @Input() respuestasSimilares: any;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'RESULTADOS_SIMILAR_AQUABOT';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }


  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private utilitario: UtilitariosService,
    private aquabot: AquabotService,
    private ventanaAlerta: AlertService,
    private alertCtr: AlertController,
    private navCtrl: NavController,
    private modalController: ModalController,
  ) { 
    console.log('App: Resultados similares.');
    this.header.pagina = '/';
    this.header.titulo = 'RESULTADOS SIMILARES';

  }

  ngOnInit() {

    console.log('Respuestas Similares ->', this.respuestasSimilares);

    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

        
        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

}
