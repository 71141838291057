import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AgregarTarjetaComponent } from '../agregar-tarjeta/agregar-tarjeta.component';
import { RedatwaterService } from 'src/app/services/redatwater.service';

@Component({
  selector: 'app-seleccionar-tarjeta',
  templateUrl: './seleccionar-tarjeta.component.html',
  styleUrls: ['./seleccionar-tarjeta.component.scss'],
})
export class SeleccionarTarjetaComponent implements OnInit {

  @Input() listaTarjetas: any[];

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  viewTarjetasCredito = true;

  listaTarjetasCartera = new Array<any>();
  infoTarjetaAquahabiente: any;

  constructor(
    private navCtrl: NavController,
    private redatwater: RedatwaterService,
    private alertController: AlertService,
    private modalController: ModalController,
    private alertCtrl: AlertController,
  ) {
    console.log('Ventana -> metodo de pago');
    this.header.pagina = '/redatwater';
    this.header.titulo = 'Método de pago';

  }

  ngOnInit() {
    
    if (this.listaTarjetas?.length) {
      console.log('Lista de tarjetas -> ' + JSON.stringify(this.listaTarjetas));
    } else {
      console.log('Consultando tarjetas!');
      this.carteraConsultarTarjetas();
    }
  }

  async seleccionar(tarjeta: any) {
    if (tarjeta) {
      if (tarjeta.tarjeta.token_tarjeta) {
        console.log('Ventana -> Seleccionar tarjeta...');
        this.modalController.dismiss(tarjeta);
      } else {
        console.log('Ventana -> Error tarjeta token: ' + JSON.stringify(tarjeta.tarjeta.token_tarjeta));
      }
    } else {
      console.log('Ventana -> Error tarjeta: ' + JSON.stringify(tarjeta));
    }
  }


  agregarTarjeta() {
    console.log('Cartera -> Agregar tarjeta.');
    this.ventanaAgregarTarjeta();
  }

  async ventanaAgregarTarjeta() { // Tarjeta agregar
    const modalAdd = await this.modalController.create({
      component: AgregarTarjetaComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
      this.carteraConsultarTarjetas();
    }
  }

   
  async carteraConsultarTarjetas() {
    console.log('Ventana -> Consultar tarjetas...');
    this.redatwater.carteraTarjetas().then((tarjetasData: any) => {
      //console.log('Cartera -> Tarjetas: ' + JSON.stringify(tarjetasData));
      this.viewTarjetasCredito = true;
      this.listaTarjetasCartera = tarjetasData;
      this.listaTarjetas = this.listaTarjetasCartera;
    }).catch(error => {
      console.log('Ventana -> Error cartera consultar tarjetas. ' + error);
    });
  }


}
