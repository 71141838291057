import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavController, IonInfiniteScroll, LoadingController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AppPubliaquaYoutubeComponent } from '../../app-publiaqua-youtube/app-publiaqua-youtube.component';

@Component({
  selector: 'app-app-interes',
  templateUrl: './app-interes.component.html',
  styleUrls: ['./app-interes.component.scss'],
})
export class AppInteresComponent implements OnInit {

  selectedCategory: string = 'todas'; // Inicialmente muestra todas las categorías
  noticias: any[] = [];
  allNoticias: any[] = [];
  cantTotalRegistros: number = 0;
  limitItems = 6;
  showInfiniteScroll = true;

  authUsuario: any = null;
  cuentaUsuario: any = null;


  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_TEMAS_INTERES';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  inputBuscarTema: String = '';

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private utilitario: UtilitariosService,
    private aquabot: AquabotService,
    private loadingCtrl: LoadingController,
    private ventanaAlerta: AlertService,
    private alertCtr: AlertController,
    private socialCompartir: SocialSharing,
    private navCtrl: NavController,
    private modalController: ModalController,
  ) {

    console.log('App: Temas de interes.');
    this.header.pagina = '/';
    this.header.titulo = 'TEMAS DE INTERES';

  }

  ngOnInit() {

    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data.id) {
              this.authUsuario = getAuth.data;
              console.log('Consultando todos los temas de interes!');
              this.redatwater.consultarTemasInteresFull(this.inputBuscarTema).then((getData: any) => {
                console.log('Seguimiento de interes -> ', getData);
                if (getData) {

                  this.allNoticias = getData;
                  this.cantTotalRegistros = this.allNoticias.length;
                  this.cargarDatos();

                } else {

                  console.log('Seguimiento de errores -> ', getData);
                  this.ventanaAlerta.msgAppCenter('Error', 'No se pudieron cargar los temas de interés. Inténtalo de nuevo.');

                }
              }).catch((err: any) => {
                console.log('Seguimiento de errores -> ', err);
                this.ventanaAlerta.msgAppCenter('Error de conexión', 'No se pudo conectar al Portal Transaccional. Por favor, verifica tu conexión a internet.');
              });

            } else {
              this.navCtrl?.navigateRoot(['/logout']);
            }

          });

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }

  filterByCategory(event: any) {
    if (this.selectedCategory) {
      console.log('Seguimiento -> Categoria seleccionada: ', this.selectedCategory);
      this.cargarDatos();
    } else {
      console.log('Seguimiento de errores -> ', this.selectedCategory);
      this.ventanaAlerta.msgAppCenter('Selección no válida', 'Por favor, selecciona una categoría válida.');
    }
  }

  buscarTema(event: any) {
    console.log('Seguimiento -> Seguimiento de buscado: ', this.inputBuscarTema);

    if (this.inputBuscarTema.length >= 3) {
      console.log('Buscando -> ', this.inputBuscarTema);

      this.redatwater.consultarTemasInteresFull(this.inputBuscarTema).then((getData: any) => {
        console.log('Seguimiento de interes -> ', getData);
        if (getData) {
          this.noticias = getData;
          this.cantTotalRegistros = this.noticias.length;
        } else {
          console.log('Seguimiento de errores -> ', getData);
          this.ventanaAlerta.msgAppCenter('Sin resultados', 'No se encontraron resultados para tu búsqueda.');
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
        this.ventanaAlerta.msgAppCenter('Error de búsqueda', 'No se pudo realizar la búsqueda. Inténtalo de nuevo.');
      });
    } else {
      console.log('Seguimiento -> ', this.inputBuscarTema);
      if (this.inputBuscarTema.length <= 0) {
        this.ventanaAlerta.msgAppCenter('Búsqueda vacía', '<span class="text-success">¿Que estas buscando?</span> introduce un término de búsqueda.');
        this.cargarDatos();
      }
    }
  }

  verDetalle(noticiaId: number) {
    if (noticiaId) {
      const paginaUrl = this.redatwater.getWebUrl() + '/es/homepage/viewblog?bid=' + noticiaId;
      this.verPagina(paginaUrl);
    } else {
      console.log('Seguimiento de errores -> No hay noticia', noticiaId);
      this.ventanaAlerta.msgAppCenter('Error', 'No se pudo cargar los detalles de la noticia. Inténtalo de nuevo.');
    }
  }

  verBlog() {
    console.log('Ver blog');
    const paginaUrl = this.redatwater.getWebUrl() + '/es/homepage/blog';
    this.verPagina(paginaUrl);
  }

  verPagina(pagina: string) {
    if (pagina) {
      console.log('Continuar leyendo : ' + pagina);
      this.aquabot.enlaceSugerido(pagina, 'Tema de interes');
    } else {
      console.log('Seguimiento de errores -> No se pudo cargar la página. Por favor, inténtalo de nuevo. ', pagina);
      this.ventanaAlerta.msgAppCenter('Error', 'No se pudo cargar la página. Inténtalo de nuevo.');
    }
  }

  cargarDatos() {
    this.noticias = this.allNoticias.slice(0, this.limitItems);
    if (this.limitItems >= this.allNoticias.length) {
      this.showInfiniteScroll = false;
    }
  }

  loadData(event) {
    setTimeout(() => {
      this.limitItems += 10;
      this.cargarDatos();
      event.target.complete();
      if (this.limitItems >= this.allNoticias.length) {
        event.target.disabled = true;
      }
    }, 300);
  }

  portalTransaccional() {
    console.log('Portal transaccional -> Iniciar sesion. ', this.authUsuario.codigo);
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/auth?us_co=' + this.authUsuario.codigo, 'portal transaccional');
  }

  // INICAR SESION => PORTAL TRANSACCIONAL
  iniciarPortalTransaccional() {
    console.log('Portal transaccional -> Auto Iniciar sesion. ', this.authUsuario.codigo, this.authUsuario.app_token);
    if (this.authUsuario.codigo && this.authUsuario.app_token) {
      this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/auth/loginApp?numero=' + this.authUsuario.codigo + '&auth_app=' + this.authUsuario.app_token, 'portal transaccional');
    } else {
      this.portalTransaccional();
    }
  }


  async compartir(tema: any) {
    const mensaje = `¡Mira este tema interesante! ${tema.titulo}`;
    const asunto = 'Compartido desde ® Banco del Agua';
    const imagen = tema.img; // URL de la imagen a compartir
    const enlace = this.redatwater.getWebUrl() + '/es/homepage/viewblog?bid=' + tema.id; // Enlace web

    const loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      spinner: 'crescent',
      showBackdrop: true
  });
  loading.present();

    this.socialCompartir.share(mensaje, asunto, imagen, enlace)
      .then(() => {
        loading.dismiss();
        console.log('Compartido exitosamente: ', tema);
      })
      .catch((error) => {
        loading.dismiss();
        console.error('Seguimiento de errores -> Error al compartir', error);
      });
  }


  viewYoutubePubliaqua() {
    console.log('Anuncios Youtube');
    this.viewPubliAquaYoutube();
  }

  async viewPubliAquaYoutube() { // Publi Aqua Youtube
    const modalAdd = await this.modalController.create({
      component: AppPubliaquaYoutubeComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: {}
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }
  
}
