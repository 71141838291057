import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-enviar-mensaje',
  templateUrl: './enviar-mensaje.component.html',
  styleUrls: ['./enviar-mensaje.component.scss'],
})
export class EnviarMensajeComponent implements OnInit {

  asunto: string = '';
  categoria: string = '';
  mensaje: string = '';

  authUsuario: any = null;
  cuentaUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_MENSAJES_ENVIAR';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  fileImage: File;
  fileImagePreview: any;

  imagenAdjuntaVistaPrevia: any;
  imagenAdjunta: File;

  constructor(
    private translateConfigService: TranslateConfigService,
    private toast: ToastService,
    private loadingCtrl: LoadingController,
    private modalController: ModalController,
    private auth: AuthService,
    private redatwater: RedatwaterService,
    private aquabot: AquabotService,
    private ventanaAlerta: AlertService
  ) {
    console.log('App: Enviar Mensaje.');
    this.header.pagina = '/';
    this.header.titulo = 'CONVERSA';
  }

  ngOnInit() {
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {

              this.authUsuario = getAuth.data;


            } else {
              console.log('Seguimiento de errores -> ', getAuth);
              this.cerrarVentana();

            }
          })


        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  enviarMensaje() {

    if (this.asunto && this.categoria && this.mensaje) {

      let dataPrametros = {
        asunto: this.asunto,
        categoria: this.categoria,
        mensaje: this.mensaje,
        file_imagen: this.fileImage,
      }

      console.log('Parametros de Mensaje: ', dataPrametros);

      this.redatwater.enviarMensajeContacto(dataPrametros, this.idiomaSeleccionado).then((getData: any) => {

        if (getData) {

          console.log('Seguimiento -> ', getData);
          console.log('Mensaje enviado');

          this.asunto = '';
          this.categoria = '';
          this.mensaje = '';

          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-success">Mensaje enviado con éxito.</span>');

          this.cerrarVentana();

        } else {

          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '¡Oops! Un Pequeño Percance <span class="text-success">Por favor, escríbenos a contacto@bancodelagua.com</span>');
          console.log('Seguimiento de errores -> ', getData);

        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
      });

    } else {
      console.log('¡Oops! Mensaje de contacto.');
      this.ventanaAlerta.msgAppCenter('¡Oops!', 'No se pudo enviar el mensaje. Campos obligatorios.');
    }
  }

  onFileImageChange(event: any) {
    this.fileImage = event.target.files[0];
    this.previewImage(this.fileImage, 'file_adjunto');
  }

  previewImage(image: File, type: string) {
    const reader = new FileReader();
    reader.onload = () => {
      //const base64String = reader.result.toString();
      if (type === 'file_adjunto') {
        this.fileImagePreview = reader.result;
      }
    };
    reader.readAsDataURL(image);
  }



  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss({ registro: true });
  }


}
