import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class AppUsabilidadService {

  public fontSize: number = 1;
  public baseFont = 12;

  // SINCRONIZADO EL DEFECTO ENTRE BACK Y CSS
  public baseTitulo = 18;
  public baseTituloSegundario = 16;
  public baseTituloMenu = 18;
  public baseSubTitulo = 15;
  public baseSlogan = 14;
  public baseBotones = 14;
  public baseBotonesIconos = 8;
  public baseDescripciones = 13;
  public baseContenido = 14;


  private appUsabilidadSubject: any;
  appUsabilidad: any;
  baseFontApp: any;

  private baseFontSizeSubject: BehaviorSubject<any> = new BehaviorSubject({}); // Tamaño de fuente base inicial


  public soundEnabled: boolean = true;
  public conversationEnabled: boolean = false;

  constructor(
    private ventanaAlerta: AlertService
  ) {
    console.log('Servicio -> App Usabilidad.');
    this.appUsabilidadSubject = new BehaviorSubject(this.appUsabilidad);
    this.loadApp();
  }

  setFontSize(fontSize: number) {
    this.fontSize = fontSize;
  }

  increaseFontSize() {
    this.fontSize++;
    this.setBaseFontSize('' + this.fontSize);
  }

  decreaseFontSize() {
    if (this.fontSize <= 0) {

      this.fontSize = this.baseFont;

    } else {

      this.fontSize--;

    }
    this.setBaseFontSize('' + this.fontSize);
  }

  getSoundEnabled() {
    console.log('Servicio Audio -> ', this.soundEnabled);
    return this.soundEnabled;
  }


  getconversationEnabled() {
    console.log('Servicio Voz -> ', this.conversationEnabled);
    return this.conversationEnabled;
  }

  async saveAppUsabilidad(parametros?: any) {

    let parametrosData: any = {
      fontsize: this.fontSize,
      help_audio_text: this.soundEnabled ? 'on' : 'off',
      help_voz_text: this.conversationEnabled ? 'on' : 'off'
    }

    if (parametros?.fontsize) {
      parametrosData = {
        fontsize: parametros.fontsize,
        help_audio_text: parametros.help_audio,
        help_voz_text: parametros.help_voz
      }
    }


    this.setAppUsabilidad(parametrosData);
    this.setBaseFontSize('' + this.fontSize);

  }


  async loadApp() {
    this.getAppUsabilidad().then((getData: any) => {
      console.log('Parametros Usabilidad -> ', getData.value);
      if (getData.value) {
        this.appUsabilidad = JSON.parse(getData.value);
        console.log('Test App Usabilidad -> ', this.appUsabilidad);
      } else {
        console.log('Seguimiento de errores -> ', getData);
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  async setAppUsabilidad(app: any) {
    if (app) {
      Preferences.set({
        key: 'app_usabilidad',
        value: JSON.stringify(app),
      });
      console.log('App Usabilidad -> Session almacenada.', app);

      this.ventanaAlerta.msgAppCenter('AJUSTES', '¡Exito! <span class="text-success">Cambios de usabilidad.</span>');

    }
  }

  async getAppUsabilidad() {
    const appUsabilidad: any = Preferences.get({ key: 'app_usabilidad' });
    if (appUsabilidad) {
      console.log('App Usabilidad -> ', appUsabilidad);
      if (appUsabilidad.value) {
        this.setAppUsabilidad(JSON.parse(appUsabilidad.value));

      }
      return appUsabilidad;
    }
  }


  getBaseFontApp() {
    return this.baseFontApp;
  }

  // Método para obtener el tamaño de fuente base
  getBaseFontSize() {
    return this.baseFontSizeSubject.asObservable();
  }

  // Método para actualizar el tamaño de fuente base
  setBaseFontSize(fontSize: string) {
    this.fontSize = parseInt(fontSize);
    let parametrosSize = {
      titulo: (this.baseTitulo + parseInt(fontSize)),
      titulosegundario: (this.baseTituloSegundario + parseInt(fontSize)),
      titulomenu: (this.baseTituloMenu + parseInt(fontSize)),
      subtitulo: (this.baseSubTitulo + parseInt(fontSize)),
      slogan: (this.baseSlogan + parseInt(fontSize)),
      botones: (this.baseBotones + parseInt(fontSize)),
      botones_iconos: (this.baseBotonesIconos + parseInt(fontSize)),
      descripciones: (this.baseDescripciones + parseInt(fontSize)),
      contenido: (this.baseContenido + parseInt(fontSize)),
    }
    console.log('Actualizando Font Size = ', parametrosSize);
    this.setBaseFontApp(parametrosSize); 
    this.baseFontSizeSubject.next(parametrosSize);

  }

  setBaseFontApp(baseFont: any) {
    if (baseFont) {
      this.baseFontApp = baseFont;
    }
  }

  establecerFontSize() {
    this.getBaseFontSize().subscribe((baseFontSize: any) => {
      document.documentElement.style.setProperty('--base-font-app-titulo', baseFontSize.titulo + 'px');
      document.documentElement.style.setProperty('--base-font-app-titulo-segundario', baseFontSize.titulosegundario + 'px');
      document.documentElement.style.setProperty('--base-font-app-titulo-menu', baseFontSize.titulomenu + 'px');
      document.documentElement.style.setProperty('--base-font-app-subtitulo', baseFontSize.subtitulo + 'px');
      document.documentElement.style.setProperty('--base-font-app-slogan', baseFontSize.slogan + 'px');
      document.documentElement.style.setProperty('--base-font-app-botones', baseFontSize.botones + 'px');
      document.documentElement.style.setProperty('--base-font-app-botones_iconos', baseFontSize.botones_iconos + 'px');
      document.documentElement.style.setProperty('--base-font-app-descripcion', baseFontSize.descripciones + 'px');
      document.documentElement.style.setProperty('--base-font-app-contenido', baseFontSize.contenido + 'px');
    });
  }

}
