import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { interval, Subscription } from 'rxjs';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { EscanerService } from 'src/app/services/escaner.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TextSpeechService } from 'src/app/services/text-speech.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-pay-metodos',
  templateUrl: './pay-metodos.component.html',
  styleUrls: ['./pay-metodos.component.scss'],
})
export class PayMetodosComponent implements OnInit, OnDestroy {

  @Input() totalPago: any; // Valor a pagar
  @Input() categoriaPago: any; // DIGITAL_PAY, FULL_PAY, REDAT_PAY
  @Input() autoMetodo: any;

  private subscriptionProceso: Subscription = new Subscription();

  header: Header = {
    nivel: 2,
    titulo: '',
    pagina: ''
  }

  authDispositivo: any;

  aquaBot = {
    msg: '',
    estado: ''
  };

  metodoSeleccionado = {
    metodo: null,
    encriptado: null,
    aquahabiente: {
      cuenta: null,
      clave: null,
      dataCuenta: null
    },
    ticket: {
      codigo: null,
      clave: null,
      dataTicket: null
    },
    nequi: {
      celular: null,
      celular_array: null,
      celular_string: null
    },
  }

  cuentaAquahabiente: any;
  ticketAgua: any;
  paisSeleccionado:any = null;

  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_PAY';

  btnConfirmar: boolean = false;
  btnConsultarTicket: boolean = false;
  btnConsultarAquahabiente: boolean = false;

  constructor(
    private translateConfigService: TranslateConfigService,
    private ventanaAlerta: AlertService,
    private escaner: EscanerService,
    private modalController: ModalController,
    private alertCtr: AlertController,
    private auth: AuthService,
    private redatwater: RedatwaterService,
    private navCtrl: NavController,
    private textSpeech: TextSpeechService,
    private aquabot: AquabotService,
  ) {
    console.log('¡Seleccionar metodo de pago!');

    this.header.pagina = '/redatwater';
    this.header.titulo = 'Tu elección';

    this.metodoSeleccionado.encriptado = 1;
    this.aquaBot = this.aquabot.appMsgAquaBot({
      msg: '',
      estado: ''
    });
  }

  ngOnInit() {
    console.log('Saldo total a pagar -> ', this.totalPago);
    console.log('Parametros Categoria metodo -> ', this.categoriaPago);
    console.log('Parametros Auto metodo -> ', this.autoMetodo);
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();

        if (this.itemsIdiomaApp && this.itemsIdioma) {
          //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
          //console.log('Parametros de idioma -> ', this.itemsIdioma);
          //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);
          this.textSpeech.hablar(this.itemsIdioma.hablar_pay_seleccionar);
          let pais = this.redatwater.getPaisSeleccionado();
          console.log('Pais seleccionado ->', pais);
          if (pais) { // PAIS 
            this.paisSeleccionado = pais;
          }

          if (this.autoMetodo) {
            if (this.autoMetodo === 'aquahabiente' ||
              this.autoMetodo === 'ticket' ||
              this.autoMetodo === 'nequi'
            ) {
              this.seleccionarMetodo(this.autoMetodo);
            } else {
              console.log('Seguimiento de errores -> Auto metodo no autorizado.');
            }
          }
        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {
    if (this.subscriptionProceso) {
      this.subscriptionProceso.unsubscribe();
    }
  }

  async seleccionarMetodo(metodo: string) {
    if (metodo) {
      this.metodoSeleccionado.encriptado = 1;
      this.metodoSeleccionado.metodo = metodo;
      
      if (this.metodoSeleccionado.metodo === 'aquahabiente') {
        this.aquaBot = this.aquabot.appMsgAquaBot({
          msg: this.itemsIdioma.msg_aquahabiente_cuenta,
          estado: 'text-success'
        });
        this.textSpeech.hablar(this.itemsIdioma.hablar_pay_aquahabiente);
        this.btnConfirmar = false;
        this.btnConsultarAquahabiente = true;
        this.btnConsultarTicket = false;
      } else if (this.metodoSeleccionado.metodo === 'ticket') {
        this.aquaBot = this.aquabot.appMsgAquaBot({
          msg: this.itemsIdioma.msg_ticket_cuenta,
          estado: 'text-success'
        });
        this.textSpeech.hablar(this.itemsIdioma.hablar_pay_ticket);
        this.btnConfirmar = false;
        this.btnConsultarAquahabiente = false;
        this.btnConsultarTicket = true;
      } else if (this.metodoSeleccionado.metodo === 'nequi') {
        this.metodoSeleccionado.nequi.celular_string = '';
        this.aquaBot = this.aquabot.appMsgAquaBot({
          msg: this.itemsIdioma.msg_nequi_datos,
          estado: 'text-success'
        });
        this.textSpeech.hablar(this.itemsIdioma.hablar_pay_nequi);
        this.btnConfirmar = true;
        this.btnConsultarAquahabiente = false;
        this.btnConsultarTicket = false;
      }
    } else {
      console.log('Seguimiento de errores -> ', metodo);
    }
  }

  async consultarAquahabiente() {
    console.log('Seguimiento -> Consultar cuenta aquahabiente!');
    if (this.metodoSeleccionado.aquahabiente.cuenta && this.metodoSeleccionado.aquahabiente.clave) {

      this.redatwater.getCuentaAquahabiente(this.metodoSeleccionado.aquahabiente.cuenta, this.metodoSeleccionado.aquahabiente.clave, this.metodoSeleccionado.encriptado).then((getData: any) => {
        if (getData) {
          console.log('Seguimiento -> Cuenta Aquahabiente: ', getData);
          if (getData.channel_app[0]?.aquahabiente) {

            this.cuentaAquahabiente = getData.channel_app[0].aquahabiente;

            if (this.cuentaAquahabiente.usuario &&
              this.cuentaAquahabiente.nombre &&
              this.cuentaAquahabiente.codigo &&
              this.cuentaAquahabiente.saldo) {

              console.log('Seguimiento -> Cuenta Aquahabiente: ', this.cuentaAquahabiente);

              if (parseInt(this.cuentaAquahabiente.saldo) >= this.totalPago) {

                this.aquaBot = this.aquabot.appMsgAquaBot({
                  msg: this.itemsIdioma.msg_pay_hola + ', ' + this.cuentaAquahabiente.nombre + ' ' + this.itemsIdioma.msg_aquahabiente_finalizar,
                  estado: 'text-success'
                });

                this.textSpeech.hablar(this.itemsIdioma.hablar_pay_hola + ' ' + this.cuentaAquahabiente.nombre + ',  ' + this.itemsIdioma.hablar_pay_aquahabiente_finalizar);

                this.btnConfirmar = true;
                this.btnConsultarAquahabiente = false;
                this.btnConsultarTicket = false;

              } else {
                this.aquaBot = this.aquabot.appMsgAquaBot({
                  msg: this.itemsIdioma.msg_pay_hola + ', ' + this.cuentaAquahabiente.nombre + ' ' + this.itemsIdioma.msg_aquahabiente_saldoinsuficiente,
                  estado: 'text-danger'
                });
                this.textSpeech.hablar(this.itemsIdioma.hablar_pay_hola + ' ' + this.cuentaAquahabiente.nombre + ',  ' + this.itemsIdioma.hablar_pay_aquahabiente_recargarsaldo);
              }

            } else {
              this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_aquahabiente_parametros);
              console.log('Seguimiento de errores -> Parametros de cuenta Aquahabiente incompletos: ', this.cuentaAquahabiente);
            }

          } else {
            this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_aquahabiente_parametros_app);
            console.log('Seguimiento de errores -> Parametros de cuenta Aquahabiente incompletos: ', this.cuentaAquahabiente);
          }
        } else {
          this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_aquahabiente_info_cuenta);
          console.log('Seguimiento de errores -> ', getData);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });

    } else {
      this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_aquahabiente_campos_cuenta);
      console.log('Seguimiento de errores -> ', this.metodoSeleccionado.aquahabiente);
    }
  }

  async escanearAquahabiente() { // Escanear cuenta aquahabiente
    console.log('Seguimiento -> Escanear codigos');
    let metodosPermitidos = [];

    metodosPermitidos.push({
      label: this.itemsIdioma.escancer_opt_camara_frontal,
      type: 'radio',
      name: 'camara_metodo',
      value: false,
    });

    metodosPermitidos.push({
      label: this.itemsIdioma.escancer_opt_camara_back,
      type: 'radio',
      name: 'camara_metodo',
      value: true,
    });

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: this.itemsIdioma.escaner_titulo_camara,
      inputs: metodosPermitidos,
      buttons: [
        {
          text: this.itemsIdioma.modal_btn_cerrar,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cerrar!');
          }
        }, {
          text: this.itemsIdioma.modal_btn_aceptar,
          handler: (data: any) => {
            console.log('Ok!');

            if (data === true) {
              this.leerQrCuenta(data);
            } else if (data === false) {
              this.leerQrCuenta(data);
            } else {
              this.escanearAquahabiente();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async leerQrCuenta(camara: any) {

    this.escaner.leerQr(camara).then((data: any) => {
      console.log('Seguimiento escaner -> ' + data);
      let parametrosEscaneados: any = data;

      if (parametrosEscaneados?.token &&
        parametrosEscaneados?.categoria &&
        parametrosEscaneados?.cuenta &&
        parametrosEscaneados?.clave) {

        if (this.escaner.verificarToken(parametrosEscaneados.token)) {
          if (parametrosEscaneados.categoria === 'cuenta_aquahabiente') {
            this.metodoSeleccionado.aquahabiente.cuenta = parametrosEscaneados?.cuenta;
            this.metodoSeleccionado.aquahabiente.clave = parametrosEscaneados?.clave;
            this.metodoSeleccionado.encriptado = 2;
            this.textSpeech.hablar(this.itemsIdioma.hablar_pay_qr);
            this.consultarAquahabiente(); // CONSULTAR INFO
          } else {
            this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_aquahabiente_qr_caterogoria);
            console.log('Seguimiento de errores -> ¡Oops! No es la categoria');
          }
        } else {
          console.log('Seguimiento de errores -> ¡Oops! Token de lector QR denegado.');
        }

      } else {
        this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_aquahabiente_qr_parametros);
        console.log('Seguimiento de errores -> ¡Oops! Parametros escaneados ', parametrosEscaneados);
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores ->', err);
    });
  }

  async consultarTicket() {
    console.log('Seguimiento -> Consultar ticket de agua!');
    if (this.metodoSeleccionado.ticket.codigo && this.metodoSeleccionado.ticket.clave) {

      this.redatwater.getTicketAgua(this.metodoSeleccionado.ticket.codigo, this.metodoSeleccionado.ticket.clave, this.metodoSeleccionado.encriptado).then((getData: any) => {
        if (getData) {
          console.log('Seguimiento -> Ticket de agua: ', getData);
          if (getData.channel_app[0]?.ticket) {

            this.ticketAgua = getData.channel_app[0].ticket;

            if (this.ticketAgua.propiedad_email &&
              this.ticketAgua.propiedad_id &&
              this.ticketAgua.propiedad_nombre &&
              this.ticketAgua.ticket_codigo &&
              this.ticketAgua.ticket_finalizado &&
              this.ticketAgua.ticket_id &&
              this.ticketAgua.ticket_lote_costo_unidad &&
              this.ticketAgua.ticket_lote_destacado &&
              this.ticketAgua.ticket_lote_hasta_fecha &&
              this.ticketAgua.ticket_lote_litros_unid_agua &&
              this.ticketAgua.ticket_lote_tipo_venta) {

              console.log('Seguimiento -> Ticket de agua: ', this.ticketAgua);
              this.aquaBot = this.aquabot.appMsgAquaBot({
                msg: this.itemsIdioma.msg_pay_hola + ', ' + this.ticketAgua.propiedad_nombre + ' ' + this.itemsIdioma.msg_ticket_finalizar,
                estado: 'text-success'
              });

              if (this.ticketAgua.ticket_cant_actual_recargas === '0') {
                this.textSpeech.hablar(this.itemsIdioma.hablar_pay_hola + ' ' + this.ticketAgua.propiedad_nombre + ', ' + this.itemsIdioma.hablar_pay_ticket_sinrecarga);
              } else {
                let totalAgua = (this.ticketAgua.ticket_lote_litros_unid_agua * this.ticketAgua.ticket_cant_actual_recargas);
                this.textSpeech.hablar(this.itemsIdioma.hablar_pay_hola + ' ' + this.ticketAgua.propiedad_nombre + ', ' + this.itemsIdioma.hablar_pay_ticket_sinrecarga_msg + ' ' + totalAgua + ' ' + this.itemsIdioma.hablar_pay_ticket_sinrecarga_msg_dos + ': ' + this.ticketAgua.ticket_lote_hasta_fecha);
              }

              this.btnConfirmar = true;
              this.btnConsultarAquahabiente = false;
              this.btnConsultarTicket = false;

            } else {
              this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ticket_parametros);
              console.log('Seguimiento de errores -> Parametros de Ticket de agua incompletos: ', this.cuentaAquahabiente);
            }

          } else {
            this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ticket_info);
            console.log('Seguimiento de errores -> Parametros de Ticket de agua incompletos: ', this.cuentaAquahabiente);
          }
        } else {
          this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ticket_campos);
          console.log('Seguimiento de errores -> ', getData);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });


    } else {
      console.log('Seguimiento de errores -> ', this.metodoSeleccionado.ticket);
    }
  }

  async escanearTicket() { // Escanear ticket de agua

    console.log('Seguimiento -> Escanear codigos');
    let metodosPermitidos = [];

    metodosPermitidos.push({
      label: this.itemsIdioma.escancer_opt_camara_frontal,
      type: 'radio',
      name: 'camara_metodo',
      value: false,
    });

    metodosPermitidos.push({
      label: this.itemsIdioma.escancer_opt_camara_back,
      type: 'radio',
      name: 'camara_metodo',
      value: true,
    });

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: this.itemsIdioma.escaner_titulo_camara,
      inputs: metodosPermitidos,
      buttons: [
        {
          text: this.itemsIdioma.modal_btn_cerrar,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cerrar!');
          }
        }, {
          text: this.itemsIdioma.modal_btn_aceptar,
          handler: (data: any) => {
            console.log('Ok!');

            if (data === true) {
              this.leerQrTicket(data);
            } else if (data === false) {
              this.leerQrTicket(data);
            } else {
              this.escanearTicket();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async leerQrTicket(camara: any) {
    this.escaner.leerQr(camara).then((data: any) => {
      console.log('Seguimiento escaner -> ' + data);
      let parametrosEscaneados: any = data;

      if (parametrosEscaneados?.token &&
        parametrosEscaneados?.categoria &&
        parametrosEscaneados?.codigo &&
        parametrosEscaneados?.clave) {

        if (this.escaner.verificarToken(parametrosEscaneados.token)) {
          if (parametrosEscaneados.categoria === 'ticket_agua') {
            this.metodoSeleccionado.ticket.codigo = parametrosEscaneados?.codigo;
            this.metodoSeleccionado.ticket.clave = parametrosEscaneados?.clave;
            this.metodoSeleccionado.encriptado = 2;
            this.textSpeech.hablar(this.itemsIdioma.hablar_pay_ticket_qr);
            this.consultarTicket(); // CONSULTAR INFO
          } else {
            this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ticket_qr_categoria);
            console.log('Seguimiento de errores -> ¡Oops! No es la categoria');
          }
        } else {
          console.log('Seguimiento de errores -> ¡Oops! Token de lector QR denegado.');
        }

      } else {
        this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ticket_qr_parametros);
        console.log('Seguimiento de errores -> ¡Oops! Parametros escaneados ', parametrosEscaneados);
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores ->', err);
    });
  }
  
  async verificarNequi(event) {
    console.log('Seguimiento -> Confirmar Nequi!', event);
    if (this.metodoSeleccionado.nequi.celular) {
      console.log('Seguimiento -> Numero de NEQUI ¡OK!', this.metodoSeleccionado.nequi.celular);

      const string = '' + this.metodoSeleccionado.nequi.celular;
      this.metodoSeleccionado.nequi.celular_array = string.split('');
      console.log('Celular vector -> ' + JSON.stringify(this.metodoSeleccionado.nequi.celular_array));

    } else {
      console.log('Seguimiento de errores -> ', this.metodoSeleccionado.nequi);
    }
  }

  cancelarSeleccion() {
    console.log('Seguimiento -> Cancelar seleccion: ', this.metodoSeleccionado.metodo);
    if (this.subscriptionProceso) {
      this.subscriptionProceso.unsubscribe();
    }
   
    this.textSpeech.hablar(this.itemsIdioma.hablar_pay_metodos);
    this.metodoSeleccionado = {
      metodo: null,
      encriptado: null,
      aquahabiente: {
        cuenta: null,
        clave: null,
        dataCuenta: null
      },
      ticket: {
        codigo: null,
        clave: null,
        dataTicket: null
      },
      nequi: {
        celular: null,
        celular_array: null,
        celular_string: null
      }
    }
    this.metodoSeleccionado.encriptado = 1;
    this.btnConfirmar = false;
    this.btnConsultarAquahabiente = false;
    this.btnConsultarTicket = false;
  }

  async confirmarSeleccionar() {
    console.log('Seguimiento -> Confirmar seleccion');
    if (this.metodoSeleccionado.metodo === 'efectivo') {
      console.log('Seleccion de pago: Efectivo');
    } else if (this.metodoSeleccionado.metodo === 'aquahabiente') {
      console.log('Seleccion de pago: Cuenta Aquahabiente');

    } else if (this.metodoSeleccionado.metodo === 'ticket') {
      console.log('Seleccion de pago: Ticket de Agua');
    } else if (this.metodoSeleccionado.metodo === 'nequi') {
      console.log('Seleccion de pago: NEQUI');
    } else {
      console.log('Seguimiento de errores -> ¡Oops! Algo salio mal al confirmar seleccion.');
    }
  }

  async confirmarMetodo() {
    console.log('Seguimiento -> Metodo confirmado: ', this.metodoSeleccionado);
    if (this.metodoSeleccionado.metodo === 'aquahabiente') {
      console.log('Seguimiento -> Metodo confirmado: ', this.metodoSeleccionado.aquahabiente.cuenta);

      if (this.cuentaAquahabiente?.usuario &&
        this.cuentaAquahabiente?.nombre &&
        this.cuentaAquahabiente?.codigo &&
        this.cuentaAquahabiente?.saldo) {

        if (parseInt(this.cuentaAquahabiente.saldo) >= this.totalPago) {

          this.metodoSeleccionado.aquahabiente.dataCuenta = this.cuentaAquahabiente;
          this.modalController.dismiss({ metodo: this.metodoSeleccionado }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });

        } else {
          this.aquaBot = this.aquabot.appMsgAquaBot({
            msg: this.itemsIdioma.msg_pay_hola + ', ' + this.cuentaAquahabiente.nombre + ' ' + this.itemsIdioma.msg_pay_aquahabiente,
            estado: 'text-danger'
          });
        }

      } else {
        this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_aquahabiente_cuenta_info);
      }

    } else if (this.metodoSeleccionado.metodo === 'ticket') {
      console.log('Seguimiento -> Metodo confirmado: ', this.metodoSeleccionado.ticket.codigo);


      if (this.ticketAgua?.propiedad_email &&
        this.ticketAgua?.propiedad_id &&
        this.ticketAgua?.propiedad_nombre &&
        this.ticketAgua?.ticket_codigo &&
        this.ticketAgua?.ticket_finalizado &&
        this.ticketAgua?.ticket_id &&
        this.ticketAgua?.ticket_lote_costo_unidad &&
        this.ticketAgua?.ticket_lote_destacado &&
        this.ticketAgua?.ticket_lote_hasta_fecha &&
        this.ticketAgua?.ticket_lote_litros_unid_agua &&
        this.ticketAgua?.ticket_lote_tipo_venta) {

        this.metodoSeleccionado.ticket.dataTicket = this.ticketAgua;
        this.modalController.dismiss({ metodo: this.metodoSeleccionado }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });

      } else {
        this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ticket_cuenta_info);
      }

    } else if (this.metodoSeleccionado.metodo === 'nequi') {
      console.log('Seguimiento -> Metodo confirmado: ', this.metodoSeleccionado.nequi.celular);

      if (this.metodoSeleccionado.nequi.celular) {

        this.metodoSeleccionado.nequi.celular_array.forEach((digito: any) => {
          if (digito) {
            this.metodoSeleccionado.nequi.celular_string += digito + ' ';
          }
        });
        console.log('Numero de celular String: ' + this.metodoSeleccionado.nequi.celular_string);
        this.modalController.dismiss({ metodo: this.metodoSeleccionado }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });

      } else {
        this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_nequi_cuenta_info);
      }

    } else {
      this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_pay_cuenta_info);
      console.log('Seguimiento de errores -> ¡Oops! Metodo de pago: ', this.metodoSeleccionado);
    }
  }

}
