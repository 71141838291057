import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Subscription, interval } from 'rxjs';
import { Header } from 'src/app/interfaces/book.interface';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TextSpeechService } from 'src/app/services/text-speech.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { ResultadosSimilarComponent } from './resultados-similar/resultados-similar.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit, OnDestroy {

  private subscriptionProcesoAnimacion: Subscription = new Subscription();
  procesosAnimacion: any;
  animacionRepeticiones: number = 0;
  animacionLimiteRepeticiones: number = 2;
  tiempoRecargaProcesosAnimacion = 80;

  authUsuario: any = null;
  cuentaUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_PERFIL';

   // Define los escenarios disponibles
   escenarios = [
    { nombre: 'Día', valor: 'entorno_dia' },
    { nombre: 'Noche', valor: 'entorno_noche' },
    { nombre: 'Agua', valor: 'entorno_agua' },
    { nombre: 'Oficina', valor: 'entorno_oficina' }
  ];

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: '',
    estado_animado: '',
    objeto_animado: {
      cabeza: '',
      ojos: 'eye-casual',
      ojos_pupila: 'pupil-casual',
      boca: 'mouth-casual',
      cuerpo: 'body-casual',
      nucleo: 'energy',
      brazos: {
        der: 'limb-left-casual',
        izq: 'limb-right-casual'
      }
    },
    estado_entorno: '',
    entorno_animado: null
  };

  viewPensando: boolean = false;
  btnEnviar: boolean = true;
  messageAquabot: string = '';

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  opciones_nav = 'inicio';

  navOpcionesConversacion: any = null;
  resultadosSimilares: any = null;

  navOpciones: any = {
    enlace_1: {
      titulo: 'TICKETS DE AGUA',
      option_1: {
        titulo: 'COMPRAR TICKET DE AGUA',
        link: '',
      },
      option_2: {
        titulo: 'OBTENER PIN DE UNICO',
        link: '',
      },
      option_3: {
        titulo: 'VERIFICAR TICKET DE AGUA',
        link: '',
      },
    },
    enlace_2: {
      titulo: 'RESOLUCIONES Y DOCUMENTOS OFICIALES',
      option_1: {
        titulo: 'DOCUMENTOS',
        link: '',
      },
      option_2: {
        titulo: 'PREGUNTAS FRECUENTES',
        link: '',
      },
      option_3: {
        titulo: 'VERIFICAR CUENTA DE USUARIO',
        link: '',
      },
    },
    enlace_3: {
      titulo: 'CAJERO DE AGUA',
      option_1: {
        titulo: 'COMO COMPRAR AGUA!?',
        link: '',
      },
      option_2: {
        titulo: 'COMO PURIFICAMOS EL AGUA!?',
        link: '',
      },
      option_3: {
        titulo: 'SOLICITAR UN CAJERO',
        link: '',
      },
    },
    enlace_4: {
      titulo: 'SERVICIOS',
      option_1: {
        titulo: 'SUMINISTRO DE AGUA',
        link: '',
      },
      option_2: {
        titulo: 'PORTAL TRANSACCIONAL',
        link: '',
      },
      option_3: {
        titulo: 'TIENDAS DE AGUA',
        link: '',
      },
    },
  }

  hourHandTransform: string;
  minuteHandTransform: string;
  secondHandTransform: string;

  limitItems = 6; // Define el límite inicial de elementos a mostrar
  mostrarTodos = false; // Variable de control para mostrar u ocultar todos los registros

  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private utilitario: UtilitariosService,
    private aquabot: AquabotService,
    private textSpeech: TextSpeechService,
    private alertCtr: AlertController,
    private modalController: ModalController,
  ) {
    console.log('App: Aquabot -> Help');
    this.header.pagina = '/';
    this.header.titulo = '';
    
    this.aquaBot = this.aquabot.appMsgAquaBot({
      msg: '¡Hola! Bienvenido a Banco del Agua, donde cada gota cuenta.',
      estado: 'text-primary',
      estado_animado: 'casual',
      objeto_animado: {
        cabeza: '',
        ojos: 'eye-casual',
        ojos_pupila: 'pupil-casual',
        boca: 'mouth-casual',
        cuerpo: 'body-casual',
        nucleo: 'energy',
        brazos: {
          der: 'limb-left-casual',
          izq: 'limb-right-casual'
        }
      }
    });
    
  }

  updateClock() {
    console.log('Actualizando hora!');
    const now = new Date();
    const hours = now.getHours() % 12;
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    this.hourHandTransform = `rotateZ(${hours * 30 + minutes / 2}deg)`;
    this.minuteHandTransform = `rotateZ(${minutes * 6}deg)`;
    this.secondHandTransform = `rotateZ(${seconds * 6}deg)`;
  }

  ngOnInit() {

    console.log('Mensaje actual: Red AT-WATER ->', this.redatwater.getChannelMessage());
    console.log('Historial: Red AT-WATER ->', this.redatwater.getFullMensajes());
    //this.messageAquabot = '¿Banco del Agua?';
    //this.sendMessageAquabot();

    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {

              this.authUsuario = getAuth.data;
              this.infoCuenta();
              
            } else {
              console.log('Seguimiento de errores -> ', getAuth);
              this.cerrarVentana();
            }
          })


        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

    if (this.subscriptionProcesoAnimacion) {
      this.subscriptionProcesoAnimacion.unsubscribe();
    }
    clearInterval(this.procesosAnimacion);

  }

   // Función para elegir un escenario al azar y llamar a setEscenario
   chooseRandomScenario() {
    const randomIndex = Math.floor(Math.random() * this.escenarios.length);
    const randomScenario = this.escenarios[randomIndex];
    this.setEscenario(randomScenario.nombre, randomScenario.valor);
  }


  async infoCuenta() {
    this.redatwater.consultarFullCuentaUsuario().then((getData: any) => {
      if (getData) {

        console.log('Full data cuenta usuario -> ', getData);

        if (getData?.infoUsuario) {

          this.cuentaUsuario = getData.infoUsuario;
          this.aquaBot = this.aquabot.appMsgAquaBot({
            msg: '¡Hola ' + this.cuentaUsuario.nombre + ' ! Bienvenido a Banco del Agua, donde cada gota cuenta.',
            estado: 'text-success',
            estado_animado: 'casual',
            objeto_animado: {
              cabeza: '',
              ojos: 'eye-casual',
              ojos_pupila: 'pupil-casual',
              boca: 'mouth',
              cuerpo: 'body-casual',
              nucleo: 'energy',
              brazos: {
                der: 'limb-left-casual',
                izq: 'limb-right-casual'
              }
            }
          });

          
          let simularEntorno = this.chooseRandomScenario();

          this.animacionRepeticiones = 0;
          this.animacionLimiteRepeticiones = ((this.aquaBot.msg).length) - 3;
          console.log('Total de caracteres: ', this.animacionLimiteRepeticiones);
          this.aquabotProcesosAnimacion('hablando');
          this.textSpeech.hablar(this.aquaBot.msg);

        } else {
          console.log('Seguimiento de errores -> ', getData);
        }

      } else {
        console.log('Seguimiento de errores -> ', getData);
        this.cerrarVentana();
        //this.navCtrl?.navigateRoot(['/logout']);
      }

    }).catch((err: any) => {
      this.cerrarVentana();
      console.log('Seguimiento de errores -> ', err);
    });
  }

  seleccionarIdioma(idioma) {
    if (idioma) {
      //console.log('Seleccionar idioma -> ', idioma);
      this.selectedLanguage = idioma;
      //console.log('Idioma seleccionado: ' + this.selectedLanguage);
      this.languageChanged(this.selectedLanguage);
      this.idiomaSeleccionado = idioma;

      this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
        this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
          this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
          this.itemsIdioma = this.translateConfigService.getItemsIdioma();
          this.translateConfigService.getItemsIdioma();
          if (this.itemsIdiomaApp && this.itemsIdioma) {
            //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
            //console.log('Parametros de idioma -> ', this.itemsIdioma);
            //console.log('Test 2 -> ', this.itemsIdiomaApp.app_loading);

          } else {
            this.translateConfigService.resetIdioma(this.idiomaPagina);
          }
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }
  }

  languageChanged(idioma: string) {
    this.translateConfigService.setLanguage(idioma);
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss();
  }


  getTRecargaProcesosAnimacion() {
    return this.tiempoRecargaProcesosAnimacion;
  }

  async aquabotProcesosAnimacion(tipo: string) { // ANIMACION 
    if (tipo) {
      this.procesosAnimacion = interval(this.getTRecargaProcesosAnimacion());
      if (this.subscriptionProcesoAnimacion) {
        this.subscriptionProcesoAnimacion.add(
          this.procesosAnimacion.subscribe(() => {
            console.log('Aquabot -> Animancion procesando...');

            if (this.animacionRepeticiones >= this.animacionLimiteRepeticiones) {

              if (this.subscriptionProcesoAnimacion) {
                this.subscriptionProcesoAnimacion.unsubscribe();
              }
              this.subscriptionProcesoAnimacion = null;
              clearInterval(this.procesosAnimacion);

              this.aquaBot.estado_animado = 'casual_2';
              this.aquaBot.objeto_animado = this.aquabot.getEstadosAnimacion('casual_2');

            } else {
              this.aquaBot.estado_animado = tipo;
              this.aquaBot.objeto_animado = this.aquabot.getEstadosAnimacion(tipo);
            }
            this.animacionRepeticiones++;
          })

        );
      }
    }
  }

  seleccionarMensaje(option: any) {
    if (option) {
      this.messageAquabot = option;
      this.sendMessageAquabot();
    }
  }

  async sendMessageAquabot() { // CONVERSAR CON AQUABOT
    if (this.messageAquabot) {
      this.btnEnviar = false;
      this.viewPensando = true;
      this.redatwater.aquabotAsistente('mensaje', this.messageAquabot).then((data: any) => {
        if (data) {

          console.log('Respuesta de canal de comunicacion -> ', data);

          if (data?.aquabot) {

            if (data.aquabot.respuesta_actual) {

              this.aquaBot = this.aquabot.appMsgAquaBot({
                msg: this.utilitario.convertirHtmlATexto(data.aquabot.respuesta_actual.salida),
                estado: 'text-success',
                estado_animado: 'casual',
                objeto_animado: {
                  cabeza: '',
                  ojos: 'eye-casual',
                  ojos_pupila: 'pupil-casual',
                  boca: 'mouth',
                  cuerpo: 'body-casual',
                  nucleo: 'energy',
                  brazos: {
                    der: 'limb-left-casual',
                    izq: 'limb-right-casual'
                  }
                }
              });

              let escenario = 'entorno_dia';
              let simularEntorno = this.setEscenario('dia', escenario);

              this.animacionRepeticiones = 0;
              this.animacionLimiteRepeticiones = ((this.aquaBot.msg).length) - 3;
              console.log('Total de caracteres: ', this.animacionLimiteRepeticiones);
              this.subscriptionProcesoAnimacion = new Subscription();
              this.aquabotProcesosAnimacion('hablando');
              this.textSpeech.hablar(this.aquaBot.msg);

              if (data.aquabot?.menu_principal) {
                this.opciones_nav = 'conversacion';
                console.log('Cambiando a menu de conversacion -> ', data.aquabot.menu_principal);
                this.navOpcionesConversacion = data.aquabot.menu_principal;
              }

              if (data.aquabot?.respuestas_similares) {
                this.resultadosSimilares = data.aquabot.respuestas_similares;
                console.log('Respuestas similares -> ', this.resultadosSimilares);
              }


            } else {

              this.aquaBot = this.aquabot.appMsgAquaBot({
                msg: 'Lo siento si no logré captar el significado de tu pregunta',
                estado: 'text-primary',
                estado_animado: 'casual_error',
                objeto_animado: {
                  cabeza: '',
                  ojos: 'eye-casual',
                  ojos_pupila: 'pupil-casual',
                  boca: 'mouth-casual',
                  cuerpo: 'body-casual',
                  nucleo: 'energy',
                  brazos: {
                    der: 'limb-left-casual',
                    izq: 'limb-right-casual'
                  }
                }
              });

              let escenario = 'entorno_dia';
              let simularEntorno = this.setEscenario('dia', escenario);

              this.aquabotProcesosAnimacion('error_casual');

              this.opciones_nav = 'conversacion';


              this.animacionRepeticiones = 0;
              this.animacionLimiteRepeticiones = (this.aquaBot.msg).length;
              console.log('Total de caracteres: ', this.animacionLimiteRepeticiones);
              this.subscriptionProcesoAnimacion = new Subscription();
              this.aquabotProcesosAnimacion('error_hablando');
              this.textSpeech.hablar(this.aquaBot.msg);


            }

          } else {

            this.opciones_nav = 'inicio';

            this.aquaBot = this.aquabot.appMsgAquaBot({
              msg: '¡Ups! Parece que hubo un malentendido en mi procesamiento de tu consulta',
              estado: 'text-danger',
              estado_animado: 'casual_error',
              objeto_animado: {
                cabeza: '',
                ojos: 'eye-casual',
                ojos_pupila: 'pupil-casual',
                boca: 'mouth-casual',
                cuerpo: 'body-casual',
                nucleo: 'energy',
                brazos: {
                  der: 'limb-left-casual',
                  izq: 'limb-right-casual'
                }
              }
            });

            let escenario = 'entorno_dia';
            let simularEntorno = this.setEscenario('dia', escenario);

            this.animacionRepeticiones = 0;
            this.animacionLimiteRepeticiones = (this.aquaBot.msg).length;
            console.log('Total de caracteres: ', this.animacionLimiteRepeticiones);
            this.subscriptionProcesoAnimacion = new Subscription();
            this.aquabotProcesosAnimacion('error_hablando');
            this.textSpeech.hablar(this.aquaBot.msg);

          }

          this.btnEnviar = true;
          this.messageAquabot = '';


        } else {
          this.btnEnviar = true;
          console.log('Seguimiento de errores - > ', data);
        }
        this.viewPensando = false;
      }).catch((err: any) => {
        this.btnEnviar = true;
        console.log('Seguimiento de errores -> ', err);
        this.viewPensando = false;
      });
    } else {
      this.viewPensando = false;
      this.btnEnviar = true;
      console.log('Seguimiento de errores -> ', this.messageAquabot);
    }
  }

  async verRespuestas() {
    console.log('Respuestas similares -> ', this.resultadosSimilares);
    this.ventanaRespuestas();
  }

  async ventanaRespuestas() { // Ok PEDIDO
    console.log('Ventana -> Resultados similares: ', this.resultadosSimilares);
    const respuestasSimilares = { respuestas: this.resultadosSimilares };
    const modalAdd = await this.modalController.create({
      component: ResultadosSimilarComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { respuestasSimilares }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();

    if (data) {
      console.log('Finalizar datos -> ' + data);
    }
  }

  verificarMessage(message) {
    if (message.detail.value) {
      console.log('Message: ', message.detail.value);
      this.aquaBot.estado_animado = 'casual';
      this.aquaBot.objeto_animado = this.aquabot.getEstadosAnimacion('casual_1');
    } else {
      // DEFAULT
      this.viewPensando = false;
      this.aquaBot.estado_animado = 'casual';
      this.aquaBot.objeto_animado = this.aquabot.getEstadosAnimacion('casual_2');
    }
    console.log('Aquabot -> ', this.aquaBot);
  }


  time: string = 'morning';
  autoMode: boolean = false;

  ionViewDidEnter() {
    this.generateStars();
  }

  generateStars() {
    const starsContainer = document.getElementById('stars-container');
    if (starsContainer) {
      const numStars = 100;

      for (let i = 0; i < numStars; i++) {
        const star = document.createElement('div');
        star.classList.add('star');
        star.style.top = `${this.getRandomNumber(0, 100)}%`;
        star.style.left = `${this.getRandomNumber(0, 100)}%`;
        star.style.animationDelay = `${this.getRandomNumber(0, 5)}s`;
        starsContainer?.appendChild(star);
      }
      console.log("Estrellas generadas!");
    } else {
      console.log('No se encontró el contenedor de las estrellas');
    }
  }

  getRandomNumber(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  setEscenario(timeOfDay: string, escenario: string) {

    if (timeOfDay && escenario) {

      let entornoAsistente = this.aquabot.getEntoronoAnimacion(escenario);
      this.aquaBot.entorno_animado = entornoAsistente;
      this.aquaBot.estado_entorno = escenario;

      console.log('Estableciendo escenario -> ', this.aquaBot.estado_entorno);
      console.log('Escenario -> ', this.aquaBot.entorno_animado);

      if (escenario === 'entorno_dia' || escenario === 'entorno_tarde' || escenario === 'entorno_noche') { // AMBIENTE

        if (this.aquaBot.entorno_animado?.objetos.sol && this.aquaBot.entorno_animado?.objetos.luna) {

          const header = document.querySelector('.' + this.aquaBot.entorno_animado.objetos.cielo);
          const sun = document.querySelector('.' + this.aquaBot.entorno_animado.objetos.sol);
          const moon = document.querySelector('.' + this.aquaBot.entorno_animado.objetos.luna);
          const stars = document.querySelectorAll('.' + this.aquaBot.entorno_animado.objetos.estrellas);
          const clouds = document.querySelectorAll('.' + this.aquaBot.entorno_animado.objetos.nubes);
          const section = document.querySelector('.' + this.aquaBot.entorno_animado.objetos.contenido);
          const arbol = document.querySelector('.' + this.aquaBot.entorno_animado.objetos.arbol);
          const titulo = document.querySelector('.tituloHelp'); // Titulo Pagina
          const skyElement = document.querySelector('.sky');

          sun?.setAttribute('style', 'display: none');
          moon?.setAttribute('style', 'display: none');


          if (timeOfDay === 'dia') {

            const headerGradient = 'linear-gradient(to top, #dff3fa, #dcf2fb, #e2f4fa, #eef8f7)';
            const sectionGradient = 'linear-gradient(to bottom, #def3fb, #def3fb, #f0fafe, #eef8f7, #eef8f7)';
            const tituloColorGradient = '#000';

            header?.setAttribute('style', `background: ${headerGradient}; border-bottom: none;`);
            section?.setAttribute('style', `background: ${sectionGradient}; border-bottom: none;`);
            titulo?.setAttribute('style', `color: ${tituloColorGradient}; `);

            sun?.setAttribute('style', 'display: block');
            moon?.setAttribute('style', 'display: none');

            clouds.forEach((cloud: any) => {
              cloud.style.animation = 'moveClouds 23s linear infinite';
            });

            stars.forEach((star: any) => {
              star.style.display = 'none';
            });

            skyElement?.setAttribute('style', 'background: linear-gradient(to bottom, #eef8f7, #e1e1e1, #b1b5b8, #8d8e93, #494750)');

            console.log("Entorno Día!");

          } else if (timeOfDay === 'noche') {

            const headerGradient = 'linear-gradient(to top, rgba(0, 38, 77, 0.9), #2a4460, #64778a, #bfc9cd, #eef8f7)';
            const sectionGradient = 'linear-gradient(to bottom, rgba(0, 38, 77, 0.9), #2a4460, #758693, #dce4e3, #eef8f7)';
            const tituloColorGradient = '#fff';

            header?.setAttribute('style', `background: ${headerGradient}; border-bottom: none;`);
            section?.setAttribute('style', `background: ${sectionGradient}; border-bottom: none;`);
            titulo?.setAttribute('style', `color: ${tituloColorGradient}; `);

            sun?.setAttribute('style', 'display: none');
            moon?.setAttribute('style', 'display: block');

            clouds.forEach((cloud: any) => {
              cloud.style.animation = 'moveClouds 50s linear infinite';
            });

            stars.forEach((star: any) => {
              star.style.display = 'block';
            });

            skyElement?.setAttribute('style', 'background: linear-gradient(to bottom, #eef8f7, #e1e1e1, #b1b5b8, #8d8e93, #494750)');

            console.log("Entorno Noche!");
          }

        } else {
          console.log('Seguimiento de errores -> ', this.aquaBot);
        }



      } else if (escenario === 'entorno_agua') { // ENTORNO EN EL AGUA

        if (timeOfDay === 'agua') {

          const header = document.querySelector('.' + this.aquaBot.entorno_animado.objetos.cielo);
          const section = document.querySelector('.' + this.aquaBot.entorno_animado.objetos.contenido);
          const titulo = document.querySelector('.tituloHelp'); // Titulo Pagina

          const headerGradient = 'linear-gradient(to bottom, rgb(238 248 247), rgb(153 178 212), rgb(97 132 189), rgb(74 114 179), rgb(61 103 174), rgb(53 98 170))';
          const sectionGradient = 'linear-gradient(to top, #eef8f7, #eff8ff, #d6e6f7, rgb(98 136 185), rgb(51 94 168))';
          const tituloColorGradient = '#fff';

          header?.setAttribute('style', `background: ${headerGradient}; border-bottom: none;`);
          section?.setAttribute('style', `background: ${sectionGradient}; border-bottom: none;`);
          titulo?.setAttribute('style', `color: ${tituloColorGradient}; `);

          console.log('Entorno agua');
        } else {
          console.log('Seguimiento de errores -> ', timeOfDay);
        }

      } else if (escenario === 'entorno_oficina') { // ENTORNO EN OFICINA

        if (timeOfDay === 'oficina') {

          const header = document.querySelector('.' + this.aquaBot.entorno_animado.objetos.cielo);
          const section = document.querySelector('.' + this.aquaBot.entorno_animado.objetos.contenido);
          const titulo = document.querySelector('.tituloHelp'); // Titulo Pagina

          this.updateClock();
          setInterval(() => {
            this.updateClock();
          }, 1000);

          const headerGradient = 'linear-gradient(to bottom, #eef8f7,#f5f5f5, #eef8f7)';
          const sectionGradient = ' linear-gradient(to top, #eef8f7, #eff8ff, #f5f6e8, rgb(249, 249, 227), rgb(238, 248, 247))';
          const tituloColorGradient = '#000';

          header?.setAttribute('style', `background: ${headerGradient}; border-bottom: none;`);
          section?.setAttribute('style', `background: ${sectionGradient}; border-bottom: none;`);
          titulo?.setAttribute('style', `color: ${tituloColorGradient}; `);

          console.log('Entorno oficina');

        } else {
          console.log('Seguimiento de errores -> ', timeOfDay);
        }

      }

    }
  }

  setRandomTime(escenario: string) {
    const randomTime = Math.random() < 0.5 ? 'morning' : 'night';
    this.setEscenario(randomTime, escenario);
  }

  // Método para cargar todos los elementos o mostrar los primeros 6 registros
  toggleMostrarTodos() {

    this.mostrarTodos = !this.mostrarTodos;

    console.log('Mostrar opciones -> ', this.mostrarTodos);
  }



}
