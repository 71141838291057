import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GooglemapsComponent } from './googlemaps.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser'

@NgModule({
  declarations: [
    GooglemapsComponent,
   ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FontAwesomeModule, // Fonts
  ],
  exports: [
    GooglemapsComponent,
  ]
})
export class GooglemapsModule { }
