import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { Geolocation as GeolocationGPS } from '@ionic-native/geolocation/ngx';
import { LoadingService } from './loading.service';
import { AlertService } from './alert.service';


@Injectable({
  providedIn: 'root'
})
export class UbicacionService {

  ubicacionApp: any = {
    lat: null,
    lng: null,
    direccion: null
  };

  ubicacionGPS: any = {
    lat: null,
    lng: null,
    direccion: null
  };

  constructor(
    private geolocation: GeolocationGPS,
    private alertController: AlertService
  ) {
    console.log('Services -> Ubicacion!');
  }

  async ubicacionCapacitor(): Promise<any> {

    return new Promise((resolve, reject) => {
      Geolocation.getCurrentPosition().then((res) => {
        console.log('Ubicacion App -> ', res);
        if (res) {
          this.ubicacionApp.lat = res.coords.latitude;
          this.ubicacionApp.lng = res.coords.longitude;
          this.ubicacionApp.direccion = 'Ubicación actual';
        }
        //console.log('Ubicacion con APP: ', this.ubicacionApp);

        resolve(this.ubicacionApp);

      }).catch((err: any) => {
        console.log('Error ubicacion App', err);
        resolve(false);
      });
    });
  }

  async ubicacionNativa(): Promise<any> {

    // this.splashLoadingService.loadingApp(true, 'Posición actual...', 'assets/img/favicon.png');

    return new Promise((resolve, reject) => {
      this.geolocation.getCurrentPosition().then((res) => {
        if (res) {
          this.ubicacionGPS.lat = res.coords.latitude;
          this.ubicacionGPS.lng = res.coords.longitude;
          this.ubicacionGPS.direccion = 'Ubicación actual';
        }
        //console.log('Ubicacion con GPS: ', this.ubicacionGPS);

        resolve(this.ubicacionGPS);
      }).catch((err) => {
        console.log('Error ubicacion GPS', err);
        this.alertController.msgAppCenter('RED AT-WATER', '¡Tu experiencia es nuestra prioridad! <span class="text-success">Verifica y activa tu GPS otorgando permisos a la App.</span>');

        resolve(false);
      });
    });
  }

  async getUbicacionActual() { // Establecer actual
    return this.ubicacionGPS();
  }

  async getUbicacionApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ubicacionCapacitor().then((data: any) => {
        //console.log('Resultado ubicacion -> ', data);
        if (data) {
          resolve(data);
        } else {
          resolve(false);
        }
      }).catch((err) => {

        console.log('Error metodo ubicacion App.', err);
        this.alertController.msgAppCenter('RED AT-WATER', '¡Tu experiencia es nuestra prioridad! <span class="text-primary">Verifica y activa tu GPS otorgando permisos a la App.</span>');
        resolve(false);
      });
    });
  }

  async getUbicacionGPS(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ubicacionNativa().then((data: any) => {
        console.log('Resultado ubicacion -> ', data);
        if (data) {
          resolve(data);
        } else {
          resolve(false);
        }
      }).catch((err) => {
        resolve(false);
        console.log('Error metodo ubicacion GPS.', err);
      });
    });
  }


}
