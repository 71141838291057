import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  time: number = 3000;

  constructor(
    public toastController: ToastController
  ) { }

  /* Top */
  async msgAppTop(msg: string, state:string, tiempo?: number) {    
    const toast = await this.toastController.create({
      message: msg,
      color: state,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }

  /* Center */
  async msgAppCenter(msg: string, state:string, tiempo?: number) {    
    const toast = await this.toastController.create({
      message: msg,
      color: state,
      position: 'middle',
      duration: 2000
    });
    toast.present();
  }

  /* Bottom */
  async msgAppBottom(msg: string, state:string, tiempo?: number) {    
    const toast = await this.toastController.create({
      message: msg,
      color: state,
      position: 'bottom',
      duration: 2000
    });
    toast.present();
  }

   /* Multiple */
   async msgAppMultiple(messages:any, position: any) {

    console.log('Posicion -> ' + position);

    if (messages) {
      
      if (messages.length > 1) {
        this.time = this.time + 3000;
      }

      messages.forEach((msg : any) => {

        console.log('Mensaje -> ' + msg.msg);
        
        if (position === 'top') {
          this.msgAppTop(msg.msg, msg.estado, this.time);
        } else if (position === 'center') {
          this.msgAppCenter(msg.msg, msg.estado, this.time);
        } else if (position === 'bottom') {
          this.msgAppBottom(msg.msg, msg.estado, this.time);
        }
  
        this.time = this.time - 600;
        
      })
    };
    
  }
}
