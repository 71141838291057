import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import { Header, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TextSpeechService } from 'src/app/services/text-speech.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-pedidos-abiertos-domiciliario',
  templateUrl: './pedidos-abiertos-domiciliario.component.html',
  styleUrls: ['./pedidos-abiertos-domiciliario.component.scss'],
})
export class PedidosAbiertosDomiciliarioComponent implements OnInit, OnDestroy {


  authUsuario: any = null;
  pedidosTiendas: any = [];
  listaPedidosAsociados = new Array<any>();

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_USUARIOS_PEDIDOS_DISPONIBLES_DOMICILIARIO';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 1,
    pagina: '',
    titulo: ''
  }

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Establecer ubicación.',
    coordenadas: {
      lat: null,
      lng: null
    }
  };

  totalRows: number;
  currentPage: number = 1;
  limit: number = 8;

  vistaRegistros: string = 'tiendas'; // Valor inicial del segmento (puedes cambiarlo según tus necesidades)

  ordenarBusqueda: String = 'todos';

  cantidadPedidosCajeros: number = 0;
  cantidadPedidosTiendas: number = 0;

  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private navCtrl: NavController,
    private modalController: ModalController,
    private alertCtr: AlertController,
    private ventanaAlerta: AlertService,
    private aquabot: AquabotService,
    private geolocalizacion: GeolocalizacionService,
    private textSpeech: TextSpeechService
  ) {
    console.log('App -> Pedios abiertos para domiciliario!');
    this.header.pagina = '/redatwater-usuario';
    this.header.titulo = 'PEDIDOS LIBRES';

  }

  ngOnInit() {

    this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getUsuario: any) => {
      console.log('Usuario Auth ->', getUsuario);
      if (getUsuario?.data) {
        this.authUsuario = getUsuario.data;
        console.log('Seguimiento -> Auth dispositivo: ', this.authUsuario);
        this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
          this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
            this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
            this.itemsIdioma = this.translateConfigService.getItemsIdioma();

            if (this.itemsIdiomaApp && this.itemsIdioma) {
              //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
              //console.log('Parametros de idioma -> ', this.itemsIdioma);
              //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);

              this.aquaBot = this.aquabot.appMsgAquaBot({
                msg: this.itemsIdiomaApp.aquabot_msg_cajeros,
                estado: 'text-primary'
              });

              this.loadUbicacionActual();


            } else {
              this.translateConfigService.resetIdioma(this.idiomaPagina);
            }
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      } else {
        this.navCtrl?.navigateRoot(['/logout']);
      }
    });

  }

  ngOnDestroy() {

  }

  async loadUbicacionActual() {
    this.geolocalizacion.loadUbicacionActual().then((ubicacion: any) => {
      console.log('App Ubicacion actual -> ' + JSON.stringify(ubicacion));

      this.direccionActual = ubicacion;

      this.cambiarTipoBusqueda();
      
    }).catch(error => {
      console.log('App -> Error: ' + error);
    });
  }


  nextPage() {
    if (this.currentPage < Math.ceil(this.totalRows / this.limit)) {
      this.currentPage++;

    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;

    }
  }

  isLastPage(): boolean {
    return this.currentPage === this.getLastPage();
  }

  getLastPage(): number {
    return Math.ceil(this.totalRows / this.limit);
  }

  isBothButtonsDisabled(): boolean {
    return this.currentPage === 1 && this.isLastPage();
  }


  pedidosDisponiblesTiendas(page: number, limit: number) {
    console.log('Seguimiento -> Pedidos libres / Tiendas');
    const pagination = {
      page: page,
      limit: limit
    }

    this.listaPedidosAsociados = new Array<any>();
    this.pedidosTiendas = [];

    this.redatwater.pedidosDisponiblesTiendasDomiciliarios(pagination, this.direccionActual.coordenadas).then((pedidos: any) => {

      console.log('Seguimiento -> ', pedidos);

      if (pedidos) {



        this.pedidosTiendas = pedidos;
        this.totalRows = pedidos.total_registros;
        this.cantidadPedidosTiendas = this.totalRows;
        this.procesarPedidosDisponiblesTienda();

      } else {
        console.log('Seguimiento de errores -> ', pedidos);
      }

    }).catch((error: any) => {
      console.log('Seguimiento de errores -> ', error);
    });
  }

  procesarPedidosDisponiblesTienda() {

    if (this.pedidosTiendas?.pedidos) {

      this.pedidosTiendas.pedidos.forEach((pedido: any) => {

        if (pedido?.id) {

          console.log('Pedido -> ', pedido);
          this.listaPedidosAsociados.push(pedido);

        } else {
          console.log('Seguimiento de errores -> ', pedido);
        }

      });

    } else {
      console.log('Seguimiento de errores -> ', this.pedidosTiendas);
    }


  }

  pedidosDisponiblesCajeros(page: number, limit: number) {
    console.log('Seguimiento -> Pedidos libres / Cajeros de agua');
    const pagination = {
      page: page,
      limit: limit
    }

    this.listaPedidosAsociados = new Array<any>();
    this.pedidosTiendas = [];

    this.redatwater.pedidosDisponiblesCajerosDomiciliarios(pagination, this.direccionActual.coordenadas).then((pedidos: any) => {

      console.log('Seguimiento -> ', pedidos);

      if (pedidos) {

        this.pedidosTiendas = pedidos;
        this.totalRows = pedidos.total_registros;
        this.cantidadPedidosCajeros = this.totalRows;
        this.procesarPedidosDisponiblesCajero();

      } else {
        console.log('Seguimiento de errores -> ', pedidos);
      }

    }).catch((error: any) => {
      console.log('Seguimiento de errores -> ', error);
    });
  }

  procesarPedidosDisponiblesCajero() {

    if (this.pedidosTiendas?.pedidos) {

      this.pedidosTiendas.pedidos.forEach((pedido: any) => {

        if (pedido?.id) {

          console.log('Pedido -> ', pedido);
          this.listaPedidosAsociados.push(pedido);

        } else {
          console.log('Seguimiento de errores -> ', pedido);
        }

      });

    } else {
      console.log('Seguimiento de errores -> ', this.pedidosTiendas);
    }


  }


  cambiarTipoBusqueda() {
    // Lógica para cambiar la selección y mostrar los registros adecuados según el tipo de búsqueda seleccionado
    // Aquí puedes agregar tu lógica para actualizar los registros en función del valor de "vistaRegistros"
    console.log('Tipo de búsqueda seleccionado:', this.vistaRegistros);

    this.listaPedidosAsociados = new Array<any>();

    if (this.vistaRegistros === 'cajeros') {

      this.pedidosDisponiblesCajeros(this.currentPage, this.limit);

    } else if (this.vistaRegistros === 'tiendas') {

      this.pedidosDisponiblesTiendas(this.currentPage, this.limit);

    } else {

      console.log('Seguimiento de errores -> ', this.vistaRegistros);

    }
  }

  async confirmarAceptarPedido(tipo: any, pedido: any) {
    console.log('Entregar Pedido al Cliente: ', tipo, pedido);

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: '¡Aceptar Pedido!',
      message: '¿Estás seguro de que deseas <span class="text-success">aceptar el pedido</span> para su entrega?',
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Pedido cancelada');
          }
        },
        {
          text: 'Aceptar pedido',
          handler: (data: any) => {
            console.log('Entrega confirmada!');
            // Aquí puedes agregar la lógica para marcar el pedido como entregado al cliente y realizar cualquier otra acción necesaria.
            this.aceptarPedidoLibre(tipo, pedido);
          }
        }
      ]
    });

    await alert.present();
  }

  aceptarPedidoLibre(tipo: any, pedido: any) {
    console.log('Pedido ->', pedido);
    if (pedido) {

      this.redatwater.confirmarAceptarPedidoLibre(tipo, pedido).then((getData: any) => {

        console.log('Seguimiento -> ', pedido);

        if (getData) {

          this.listaPedidosAsociados = new Array<any>();
          this.ventanaAlerta.msgAppCenter('PEDIDO ACEPTADO', '<span class="text-success">¡Pedido confirmado!</span> Me encargaré de entregarlo lo antes posible.');
          this.cambiarTipoBusqueda();

        } else {
          console.log('Seguimiento de errores -> ', getData);
        }

      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });

    } else {
      console.log('Seguimiento de errores ->', pedido);
    }
  }

}
