import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()

export class AuthrolGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private navCtrl: NavController
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const expecteModulo = route.data.modulo;
    console.log('Modulo ->', expecteModulo);
    const expecteRol = route.data.rol;
    console.log('Roles ->', expecteRol);

    let tienePermiso = false;
    

    return new Promise(async (resolve, reject) => {
      this.auth.loadAuthToken().then(async (getUsuario: any) => {
        //console.log('Dispositivo Auth ->', getUsuario);
        if (getUsuario) {

          this.auth.setUpdateAuth(getUsuario);
          
          if (expecteModulo === 'all') { // TODOS LOS ROLES

            if (expecteRol) {
              expecteRol.forEach(rolElement => {
                if (rolElement) {

                  console.log('Verificando rol -> ', rolElement);

                  if (rolElement === 'root' ||
                    rolElement === 'empresa' ||
                    rolElement === 'usuario' ||
                    rolElement === 'cliente' ||
                    rolElement === 'referido'
                  ) {

                    tienePermiso = true;

                  }
                }
              });

              // VERIFICAR PERMISO
              if (tienePermiso) {

                console.log('Seguridad -> Tiene permiso.');
                resolve(true);

              } else {

                this.navCtrl?.navigateRoot(['/logout']);
                console.log('Seguridad -> No Tiene permiso.');
                resolve(false);

              }
            }

          } else {
            this.navCtrl?.navigateRoot(['/logout']);
            resolve(false);
          }

        } else {
          this.navCtrl?.navigateRoot(['/logout']);
          resolve(false);
        }

      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
        resolve(false);
      });
    });
  }

}
