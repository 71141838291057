import { AfterViewInit, Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { AuthService } from './auth.service';
import { FirebaseService } from './firebase.service';
import { Notificacion } from '../interfaces/book.interface';
import { LocalNotificationActionPerformed, LocalNotifications } from '@capacitor/local-notifications';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { VibrarService } from './vibrar.service';
import { AudioService } from './audio.service';
import { RedatwaterService } from './redatwater.service';


@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  notificacion_id = '';

  authCuenta: any = null;

  tipoDispositivo: any = '';

  constructor(
    private platform: Platform,
    private auth: AuthService,
    private vibrar: VibrarService,
    private redatwater: RedatwaterService,
    private firebase: FirebaseService,
    private navCtrl: NavController,
    private audio: AudioService
  ) {
    this.consultarUsuarioAuth();
  }

  async consultarUsuarioAuth() {

    this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
      console.log('Usuario Auth ->', getAuth);
      if (getAuth?.data.id) {
        this.authCuenta = getAuth.data;
        this.inicializar();
      }
    });

  }

  async inicializar() {

    if (this.platform.is('capacitor')) {

      if (this.platform.is('android')) {
        this.tipoDispositivo = 'android';
      } else if (this.platform.is('ios')) {
        this.tipoDispositivo = 'ios';
      } 

      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          console.log('Registrando...');
          PushNotifications.register();
        } else {
          console.log('No tiene Permisos concedidos.');
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          //console.log('Push registration success, token: ' + token.value);

          this.guardarToken(token.value);
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          alert('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('Push received: ' + JSON.stringify(notification));

          this.vibrar.playVibracion('loop'); // Vibrar

          this.audio.preload('appSonido', 'assets/sounds/notificacion.mp3');

          this.audio.play('appSonido');

          LocalNotifications.schedule({
            notifications: [
              {
                title: notification.title,
                body: notification.body,
                id: Date.now(),
                schedule: { at: new Date(Date.now() + 500) },
                extra: {
                  data: notification.data
                }
              }
            ]
          });

        }
      );


      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(notification));
          // Accion
          this.navCtrl?.navigateRoot(notification.notification.data.enlace);
        }
      );

      /*
      LocalNotifications.addListener('localNotificationReceived', (notification: LocalNotification) => {
        console.log('Push received: ' + JSON.stringify(notification));
        this.vibrar.playVibracion('loop'); // Vibrar
        LocalNotifications.schedule({
          notifications: [
            {
              title: notification.title,
              body: notification.body,
              id: 1,
              extra: {
                data: notification.extra
              }
            }
          ]
        });
      });
      */

      LocalNotifications.addListener('localNotificationActionPerformed', (notification: LocalNotificationActionPerformed) => {
        console.log('Local action performed: ' + JSON.stringify(notification));
        // Accion
        this.navCtrl?.navigateRoot(notification.notification.extra.enlace);
      });


    } else {
      this.tipoDispositivo = 'web';
      console.log('PushNotificacionts.requestPermission() -> no es movil');
    }
  }

  async localNotificacion(data: any) {

    this.vibrar.playVibracion('loop'); // Vibrar

    this.audio.preload('appSonido', 'assets/sounds/notificacion.mp3');

    this.audio.play('appSonido');

    await LocalNotifications.schedule({
      notifications: [
        {
          title: data.title,
          body: data.body,
          id: data.id,
          schedule: { at: new Date(Date.now() + 500) },
          extra: {
            data: data.extra
          },
          iconColor: data.color
        }
      ]
    });
  }

  async guardarToken(token: any) {
    //console.log('Almacenar Token -> ', token, this.tipoDispositivo, this.authCuenta);
    this.redatwater.guardarTokenNotificacion(token, this.tipoDispositivo, this.authCuenta).then((getData: any) => {
      console.log('Seguimiento -> ', getData);

    }).catch((err: any) => {
      console.log('Seguimiento de errores ->', err);
    });
  }

  newNotification(notificacion: Notificacion) {
    if (notificacion !== null && notificacion !== undefined) {

    }
  }


}
