
import { Injectable } from '@angular/core';
import { SpeechRecognition } from '@capacitor-community/speech-recognition';
import { TextToSpeech } from '@capacitor-community/text-to-speech';
import { Platform } from '@ionic/angular';
import { AppUsabilidadService } from './app-usabilidad.service';


@Injectable({
  providedIn: 'root'
})
export class TextSpeechService {

  textHabla: String = "";
  onServicio: boolean = false;
  iniciarGrabacion: boolean = true;
  idioma = 'es-ES';

  constructor(
    private platform: Platform,
    private appUsabilidad: AppUsabilidadService
  ) {
    console.log('Servicio Text To Speech!');
    if (this.platform.is('capacitor')) { // ¿Movile?
      console.log('Servicio Text to Speech iniciado.');
      this.onServicio = true;
    } else {
      console.log('Servicio Text to Speech disponible solo para dispositivos.');
    }

    if (this.onServicio) {
      //SpeechRecognition.requestPermission();
    }

  }


  async startRecognition() {
    if (this.onServicio) {

      let appHabilitado = this.appUsabilidad.getconversationEnabled();

      if (appHabilitado) {
        const { available } = await SpeechRecognition.available();
        if (available) {
          this.iniciarGrabacion = true;
          SpeechRecognition.start({
            popup: false,
            partialResults: true,
            language: 'es-ES'
          });

          SpeechRecognition.addListener('partialResults', (data: any) => {

            if (data.matches && data.matches.length > 0) {
              console.log('Seguimiento -> Escuchando: ', data.matches);
              this.textHabla = data.matches[0];

            }

            if (data.value && data.value.length > 0) {
              console.log('Seguimiento -> Escuchando: ', data.value);
              this.textHabla = data.value[0];
              // this.changeDetectorRef.detectChanges();
            }
          });
        }
      }
    }
  }

  async stopRecognition() {
    if (this.onServicio) {

      let appHabilitado = this.appUsabilidad.getconversationEnabled();

      if (appHabilitado) {
        this.iniciarGrabacion = false;
        await SpeechRecognition.stop();
      }
    }
  }

  async supportedLanguagues() {
    if (this.onServicio) {

      let appHabilitado = this.appUsabilidad.getSoundEnabled();

      if (appHabilitado) {
        TextToSpeech.getSupportedLanguages().then((getData: any) => {
          console.log('Seguimiento -> Voces languajes: ' + JSON.stringify(getData), getData);
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> Voces languajes: ', err);
        });
      }

    }
  }

  async getSupportedVoices() {
    if (this.onServicio) {

      let appHabilitado = this.appUsabilidad.getSoundEnabled();

      if (appHabilitado) {
        TextToSpeech.getSupportedVoices().then((getData: any) => {
          console.log('Seguimiento -> Voces: ' + JSON.stringify(getData), getData);
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> Voces: ', err);
        });
      }

    }
  }

  isLanguageSupported(lang: string) {
    if (this.onServicio) {
      let appHabilitado = this.appUsabilidad.getSoundEnabled();
      if (appHabilitado) {
        TextToSpeech.isLanguageSupported({ lang });
      }

    }
  }

  async hablar(text: string) {
    console.log('Seguimiento -> Habla: ' + text);
    if (this.onServicio) {
      let appHabilitado = this.appUsabilidad.getSoundEnabled();
  
      if (appHabilitado) {
        if ((text !== null && text !== undefined) && (text !== 'null' && text !== 'undefined')) {
          // Dispositivo móvil, utiliza TextToSpeech nativo
          TextToSpeech.speak({
            text: text,
            category: "ambient",
            lang: this.idioma,
            rate: 1,
            pitch: 1,
            volume: 1,
          }).then((voce: any) => {
            console.log('Seguimiento -> Speech: ' + JSON.stringify(voce), voce);
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });
        } else {
          console.log('Seguimiento de errores -> ', text);
        }
      }
    } else {
      // Navegador web, utiliza la API de SpeechSynthesis
      const message = new SpeechSynthesisUtterance(text);
      // Ajusta la voz si es necesario
      // message.voice = speechSynthesis.getVoices()[index];
      message.pitch = 1;
      message.rate = 0.8;
      message.volume = 1;
      speechSynthesis.speak(message);
    }
  }

}
