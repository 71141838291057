import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { OkPedidoClienteComponent } from './ok-pedido-cliente/ok-pedido-cliente.component';
import { OkPedidoCajeroComponent } from './ok-pedido-cajero/ok-pedido-cajero.component';

@Component({
  selector: 'app-pedidos-cliente',
  templateUrl: './pedidos-cliente.component.html',
  styleUrls: ['./pedidos-cliente.component.scss'],
})
export class PedidosClienteComponent implements OnInit {

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  totalRows: number;
  currentPage: number = 1;
  limit: number = 8;

  listaPedidos: Array<any> = Array();
  pedidos: any = null;

  authUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_CLIENTE_HISTORIAL_PEDIDOS';

  defaultImage = 'assets/img/default.png';

  vistaRegistros: string = 'cajeros'; // Valor inicial del segmento (puedes cambiarlo según tus necesidades)

  limiteTiendas = 6;
  limiteCajeros = 6;

  cantidadPedidosCajeros: number = 0;
  cantidadPedidosTiendas: number = 0;

  constructor(
    private redatwater: RedatwaterService,
    private navCtrl: NavController,
    private toast: ToastService,
    private alertCtr: AlertController,
    private modalController: ModalController,

    private auth: AuthService,
    private translateConfigService: TranslateConfigService
  ) {
    console.log('Ventana -> historial de pedidos');
    this.header.pagina = '/redatwater-cliente';
    this.header.titulo = 'Historial de Pedidos';
  }

  ngOnInit() {

    this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getUsuario: any) => {
      console.log('Usuario Auth ->', getUsuario);
      if (getUsuario?.data) {
        this.authUsuario = getUsuario.data;
        console.log('Seguimiento -> Auth dispositivo: ', this.authUsuario);
        this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
          this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
            this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
            this.itemsIdioma = this.translateConfigService.getItemsIdioma();

            if (this.itemsIdiomaApp && this.itemsIdioma) {
              //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
              //console.log('Parametros de idioma -> ', this.itemsIdioma);
              //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);
 
              this.cambiarTipoBusqueda();
              
            } else {
              this.translateConfigService.resetIdioma(this.idiomaPagina);
            }
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      } else {
        this.navCtrl?.navigateRoot(['/logout']);
      }
    });

  }


  pedidosTienda(page: number, limit: number) {
    console.log('Seguimiento -> Pedidos tienda');

    const pagination = {
      page: page,
      limit: limit
    }

    this.redatwater.consultarMisPedidosTienda(pagination).then((getData: any) => {

      if (getData) {

        console.log('Lista de pedidos tienda -> ', getData);
        this.pedidos = getData.pedidos;
        this.totalRows = getData.total_registros;
        this.cantidadPedidosTiendas = this.totalRows;
        this.procesarPedidoTienda();

      } else {
        console.log('Seguimiento de errores -> ', getData);

      }

    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);

    });

  }

  async procesarPedidoTienda() {

    this.listaPedidos = Array();

    if (this.pedidos?.length) {

      console.log('Seguimiento -> ', this.pedidos);

      this.pedidos.forEach((pedido: any) => {
        if (pedido.id) {

          this.listaPedidos.push(pedido);

        }
      });

    } else {
      console.log('Seguimiento de errores -> ', this.pedidos);
    }
  }

  nextPage() {
    if (this.currentPage < Math.ceil(this.totalRows / this.limit)) {
      this.currentPage++;
      this.pedidosTienda(this.currentPage, this.limit);
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.pedidosTienda(this.currentPage, this.limit);
    }
  }

  isLastPage(): boolean {
    return this.currentPage === this.getLastPage();
  }

  getLastPage(): number {
    return Math.ceil(this.totalRows / this.limit);
  }

  isBothButtonsDisabled(): boolean {
    return this.currentPage === 1 && this.isLastPage();
  }

  okPedido(pedido: any) {
    this.ventanaOkPedido(pedido);
  }

  async ventanaOkPedido(dataPedido: any) { // Ok PEDIDO

    const pedido = { pedido: dataPedido };

    const modalAdd = await this.modalController.create({
      component: OkPedidoClienteComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { pedido }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    this.cambiarTipoBusqueda();
    if (data) {
      
      console.log('Finalizar datos -> ' + data);

    }
  }

  okPedidoCajero(pedido: any) {
    this.ventanaOkPedidoCajero(pedido);
  }

  async ventanaOkPedidoCajero(dataPedido: any) { // Ok PEDIDO

    const pedido = { pedido: dataPedido };

    const modalAdd = await this.modalController.create({
      component: OkPedidoCajeroComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { pedido }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    this.cambiarTipoBusqueda();
    if (data) {
      
      console.log('Finalizar datos -> ' + data);

    } 
  }


  async buscarPedidosTiendas() {

    console.log('Buscar ->  Pedidos tienda');

    this.pedidos = null;

    this.pedidosTienda(this.currentPage, this.limit);
  }


  async buscarPedidosCajeros() {

    console.log('Buscar ->  Pedidos cajero de agua');

    this.pedidos = null;

    this.pedidosCajeros(this.currentPage, this.limit);
  }

  pedidosCajeros(page: number, limit: number) {
    console.log('Seguimiento -> Pedidos cajero');

    const pagination = {
      page: page,
      limit: limit
    }

    this.redatwater.consultarMisPedidosCajero(pagination).then((getData: any) => {

      if (getData) {

        console.log('Lista de pedidos cajero -> ', getData);
        this.pedidos = getData.pedidos;
        this.totalRows = getData.total_registros;
        this.cantidadPedidosCajeros = this.totalRows;

        this.procesarPedidoCajero();

      } else {
        console.log('Seguimiento de errores -> ', getData);

      }

    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });

  }

  procesarPedidoCajero() {

    console.log('Seguimiento -> Procesar pedidos por cajero');
    this.listaPedidos = Array();
    
    if (this.pedidos?.length) {

      console.log('Seguimiento -> ', this.pedidos);

      this.pedidos.forEach((pedido: any) => {
        if (pedido.id) {

          this.listaPedidos.push(pedido);

        }
      });

    } else {
      console.log('Seguimiento de errores -> ', this.pedidos);
    }

  }

  cambiarTipoBusqueda() {
    // Lógica para cambiar la selección y mostrar los registros adecuados según el tipo de búsqueda seleccionado
    // Aquí puedes agregar tu lógica para actualizar los registros en función del valor de "vistaRegistros"
    console.log('Tipo de búsqueda seleccionado:', this.vistaRegistros);
    this.listaPedidos = Array();
    
    if (this.vistaRegistros === 'cajeros') {

      this.buscarPedidosCajeros();

    } else if (this.vistaRegistros === 'tiendas') {

      this.buscarPedidosTiendas();

    } else {

      console.log('Seguimiento de errores -> ', this.vistaRegistros);

    }
  }

  estadoPedido(estado: any) {
    if (estado) {

      let estadoEntrega = '';

      switch (estado) {

        case '1':
          estadoEntrega = 'solicitado';
          break;

        case '2':
          estadoEntrega = 'procesado';
          break;

        case '3':
          estadoEntrega = 'enviado';
          break;

        case '4':
          estadoEntrega = 'entregado';
          break;

        default:
          estadoEntrega = '';
          break;

      }

      return estadoEntrega;

    } else {
      console.log('Seguimiento de errores -> ', estado);
    }
  }
}
