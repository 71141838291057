import { Injectable } from '@angular/core';
import {
  BarcodeScannerOptions,
  BarcodeScanner
} from "@ionic-native/barcode-scanner/ngx";
import { Platform } from '@ionic/angular';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class EscanerService {

  barcodeScannerOptions: BarcodeScannerOptions;

  tokenApp = 'UmVkIGdsb2JhbCBkZSBkaXN0cmlidWNjacOzbiBkZSBhZ3VhLCBCYW5jbyBkZWwgYWd1YSBTQVM=';

  constructor(
    private platform: Platform,
    private alertController: AlertService,
    private barcodeScanner: BarcodeScanner,
  ) {
    console.log('App componente: Escanner');
    this.barcodeScannerOptions = {
      showTorchButton: true,
      showFlipCameraButton: true
    };
  }

  leerQr(camaraFrontal:boolean): Promise<any> {
    console.log('Escanear ubicacion...');
    return new Promise((resolve, reject) => {
      if (this.platform.is('capacitor')) { // ¿Movile?
        this.barcodeScanner.scan({preferFrontCamera: camaraFrontal, showTorchButton: true}).then(barcodeData => {
          resolve(JSON.parse(barcodeData.text));
        }).catch(err => {
          console.log('Error', err);
          this.alertController.msgAppCenter('RED AT-WATER', 'Imposible escanear.');
          reject(false);
        });
      } else {
        this.alertController.msgAppCenter('RED AT-WATER', 'Valido para <span class="text-success">dispositivo móvil.</span>');
        reject(false);
      }
    });
  }


  leerQrRecycla(camaraFrontal:boolean): Promise<any> {
    console.log('Escanear ubicacion...');
    return new Promise((resolve, reject) => {
      if (this.platform.is('capacitor')) { // ¿Movile?
        this.barcodeScanner.scan({preferFrontCamera: camaraFrontal, showTorchButton: true}).then(barcodeData => {
          resolve(JSON.parse(barcodeData.text));
        }).catch(err => {
          console.log('Error', err);
          this.alertController.msgAppCenter('RED AT-WATER', 'Imposible escanear.');
          reject(false);
        });
      } else {
        this.alertController.msgAppCenter('RED AT-WATER', 'Valido para <span class="text-success">dispositivo móvil.</span>');
        reject(false);
      }
    });
  }

  getTokenApp() {
    return this.tokenApp;
  }


  generarQr(data:any): Promise<any> {
    console.log('Generar QR...');
    return new Promise((resolve, reject) => {
      if (this.platform.is('capacitor')) { // ¿Movile?
        this.barcodeScanner
          .encode(this.barcodeScanner.Encode.TEXT_TYPE, JSON.stringify(data))
          .then(
            encodedData => {
              console.log(encodedData);
              resolve(encodedData);
            },
            err => {
              console.log("Error occured : " + err);
              reject(false);
            }
          );
      } else {
        this.alertController.msgAppCenter('RED AT-WATER', 'Valido para <span class="text-success">dispositivo móvil.</span>');
        reject(false);
      }
    });
  }

  async verificarToken(token:string) {
    if (token === this.tokenApp) {
      return true;
    } else {
      return false;
    }
  }

}
