import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { MetodoPago, Usuario } from '../interfaces/book.interface';

@Injectable({
  providedIn: 'root'
})
export class UtilitariosService implements OnDestroy {

  fechaActual: any;
  fechaActualObj: any;
  fechaDia: any;
  fechaMes: any;
  fechaYear: any;
  fechaHora: any;
  fechaMinuto: any;

  metodos_pago: MetodoPago[];

  constructor(
    public datepipe: DatePipe
  ) {
    console.log('Herramientas utilitarias');
    this.establecerFecha(); // Fecha Actual
  }

  ngOnDestroy() {

  }

  
  /****************************************/
  /****** Metodos Utilitarios ******/
  /****************************************/

  getFechaActualObj() {
    return this.fechaActualObj;
  }

  getFechaActual() {
    return this.fechaActual;
  }

  nameDia(index) {
    var dia = new Array(7);
    dia[0] = "Domingo";
    dia[1] = "Lunes";
    dia[2] = "Martes";
    dia[3] = "Miércoles";
    dia[4] = "Jueves";
    dia[5] = "Viernes";
    dia[6] = "Sábado";
    return dia[index];
  }

  convertirHtmlATexto(html: string): string {
    const codigoHtmlMap = {
      "&aacute;": "á",
      "&eacute;": "é",
      "&iacute;": "í",
      "&oacute;": "ó",
      "&uacute;": "ú",
      "&ntilde;": "ñ",
      "&uuml;": "ü",
      "&Aacute;": "Á",
      "&Eacute;": "É",
      "&Iacute;": "Í",
      "&Oacute;": "Ó",
      "&Uacute;": "Ú",
      "&Ntilde;": "Ñ",
      "&Uuml;": "Ü",
      "&ldquo;": "“",
      "&rdquo;": "”",
      "&lsquo;": "‘",
      "&rsquo;": "’",
      "&nbsp;": " ",
      "&copy;": "©",
      "&reg;": "®",
      "&trade;": "™",
      "&ndash;": "–",
      "&mdash;": "—",
      "&laquo;": "«",
      "&raquo;": "»",
      "&lt;": "<",
      "&gt;": ">",
      "&amp;": "&",
      "&cent;": "¢",
      "&pound;": "£",
      "&euro;": "€",
      "&yen;": "¥",
      "&sect;": "§",
      "&para;": "¶",
      "&ordm;": "º",
      "&ordf;": "ª",
      "&iexcl;": "¡",
      "&iquest;": "¿",
    };
  
    return html.replace(/&[^;]+;/g, match => {
      const codigoHtml = match.toLowerCase();
      return codigoHtmlMap[codigoHtml] || match;
    });
  }

  nameMes(index) {
    var mes = new Array(12);
    mes[0] = "Enero";
    mes[1] = "Febrero";
    mes[2] = "Marzo";
    mes[3] = "Abril";
    mes[4] = "Mayo";
    mes[5] = "Junio";
    mes[6] = "Julio";
    mes[7] = "Agosto";
    mes[8] = "Septiembre";
    mes[9] = "Octubre";
    mes[10] = "Noviembre";
    mes[11] = "Diciembre";
    return mes[index];
  }

  getFechaDia() {
    return this.fechaDia;
  }

  getFechaMes() {
    return this.fechaMes;
  }

  getFechaYear() {
    return this.fechaYear;
  }

  getFechaHora() {
    return this.fechaHora;
  }

  getFechaMinuto() {
    return this.fechaMinuto;
  }

  establecerFecha() {
    this.fechaActual = new Date();
    this.fechaActualObj = this.datepipe.transform(this.fechaActual, 'd/MM/y, h:mm:ss');

    this.fechaDia = this.datepipe.transform(this.fechaActual, 'd'); // # Dia
    this.fechaMes = this.datepipe.transform(this.fechaActual, 'MM');
    this.fechaYear = this.datepipe.transform(this.fechaActual, 'y');
    this.fechaHora = this.datepipe.transform(this.fechaActual, 'H');
    this.fechaMinuto = this.datepipe.transform(this.fechaActual, 'mm');

    console.log('Fecha: string ->',
      this.nameDia(this.fechaActual.getDay()) + ' ' +
      this.fechaDia + ' de ' +
      this.nameMes(parseInt(this.fechaMes) - 1) + '  ' +
      this.fechaMes + ' de ' +
      this.fechaYear
    );

    console.log('Fecha ->', this.fechaActual);
    console.log('Fecha: objeto ->', this.fechaActualObj);
  }

  generarIdUnico(): string {
    let a = new Date();
    let b = a.toISOString();
    let c = b.replace(/[-T:.Z]/g, "");
    console.log("id único: :" + c);
    return c.toString();
  }

  filtroClavesRedATWATER(password: string) {
    
    if (password !== '1234'
      && password !== '4321'
      && password !== '1111'
      && password !== '0123'
      && password !== '3210'
      && password !== '0000'
      && password !== '1233'
      && password !== '0987'
      && password !== '9999'
      && password !== '8888'
      && password !== '7777'
      && password !== '6666'
      && password !== '5555'
      && password !== '4444'
      && password !== '3333'
      && password !== '2222'
    ) {
      if (password.length === 4) {
        console.log('Seguimiento de errores -> Clave aceptada.', password);
        return password;
      }
    } else {
      console.log('Seguimiento de errores -> Filtro de clave 4 numeros.', password);
      return false;
    }
  }

  removeHtmlTags(input: string): string {
    const div = document.createElement('div');
    div.innerHTML = input;
    return div.textContent || div.innerText || '';
  }


  setMetodosPago() {

    // Efectivo
    const metodoEfectivo: MetodoPago = {
      id: 1,
      metodo: 'efectivo'
    }
    this.metodos_pago.push(metodoEfectivo);

    // Tarjeta Aquahabiente
    const metodoTarjetaAquahabiente: MetodoPago = {
      id: 2,
      metodo: 'Tarjeta Aquahabiente'
    }
    this.metodos_pago.push(metodoTarjetaAquahabiente);

    // Ticket de agua
    const metodoTicketAgua: MetodoPago = {
      id: 3,
      metodo: 'Ticket de Agua'
    }
    this.metodos_pago.push(metodoTicketAgua);

    // Transaccion a otra cuenta
    const metodoTransaccionCuenta: MetodoPago = {
      id: 4,
      metodo: 'Transferencia a Cuenta'
    }
    this.metodos_pago.push(metodoTransaccionCuenta);

    // Cartera
    const metodoCartera: MetodoPago = {
      id: 5,
      metodo: 'Cartera'
    }
    this.metodos_pago.push(metodoCartera);

    console.log("Utilitarios -> Metodos de pago establecidos.");

  }

  getMetodosPago() {
    if (this.metodos_pago) {
      return this.metodos_pago;
    }
  }


  /*
   * Funcion de opciones de select en formularios.
   * Inp = []
   * Out = []
   */

  async selectoptiones(parametros: any) {
    if (parametros && parametros.tipodeselect) {
      let opciones = [];
      switch (parametros.tipodeselect) {
        case 'tiposdedocumento':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': 'cedula de ciudadania'
            },
            {
              'id': 2,
              'option': 'documento de identidad',
            },
            {
              'id': 3,
              'option': 'documento extranjero',
            },
            {
              'id': 4,
              'option': 'nit',
            },
            {
              'id': 5,
              'option': 'número de celular',
            }
          ];
          break;
        case 'modulosUsuarios':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': 'homepage',
            },
            {
              'id': 2,
              'option': 'empresas',
            },
            {
              'id': 3,
              'option': 'usuarios',
            },
            {
              'id': 4,
              'option': 'clientes',
            },
          ];
          break;
        case 'porcentajes_transferencia':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': '0%',
              'porcentaje': 0
            },
            {
              'id': 2,
              'option': '5%',
              'porcentaje': 0.05
            },
            {
              'id': 3,
              'option': '10%',
              'porcentaje': 0.1
            },
            {
              'id': 4,
              'option': '15%',
              'porcentaje': 0.15
            },
            {
              'id': 5,
              'option': '20%',
              'porcentaje': 0.2
            },
            {
              'id': 6,
              'option': '25%',
              'porcentaje': 0.25
            },
            {
              'id': 7,
              'option': '30%',
              'porcentaje': 0.3
            },
            {
              'id': 8,
              'option': '35%',
              'porcentaje': 0.35
            },
            {
              'id': 9,
              'option': '40%',
              'porcentaje': 0.4
            },
            {
              'id': 10,
              'option': '45%',
              'porcentaje': 0.45
            },
            {
              'id': 11,
              'option': '50%',
              'porcentaje': 0.5
            },
            {
              'id': 12,
              'option': '55%',
              'porcentaje': 0.55
            },
            {
              'id': 13,
              'option': '60%',
              'porcentaje': 0.6
            },
            {
              'id': 14,
              'option': '65%',
              'porcentaje': 0.65
            },
            {
              'id': 15,
              'option': '70%',
              'porcentaje': 0.7
            },
            {
              'id': 16,
              'option': '75%',
              'porcentaje': 0.7
            },
            {
              'id': 17,
              'option': '80%',
              'porcentaje': 0.8
            },
            {
              'id': 18,
              'option': '85%',
              'porcentaje': 0.85
            },
            {
              'id': 19,
              'option': '90%',
              'porcentaje': 0.9
            },
            {
              'id': 20,
              'option': '95%',
              'porcentaje': 0.95
            },

          ];
          break;
        // Descuento
        case 'porcentajes_descuento':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': '0%',
              'porcentaje': 0
            },
            {
              'id': 2,
              'option': '5%',
              'porcentaje': 0.05
            },
            {
              'id': 3,
              'option': '10%',
              'porcentaje': 0.1
            },
            {
              'id': 4,
              'option': '15%',
              'porcentaje': 0.15
            },
            {
              'id': 5,
              'option': '20%',
              'porcentaje': 0.2
            },
            {
              'id': 6,
              'option': '25%',
              'porcentaje': 0.25
            },
            {
              'id': 7,
              'option': '30%',
              'porcentaje': 0.3
            },
            {
              'id': 8,
              'option': '35%',
              'porcentaje': 0.35
            },
            {
              'id': 9,
              'option': '40%',
              'porcentaje': 0.4
            },
            {
              'id': 10,
              'option': '45%',
              'porcentaje': 0.45
            },
            {
              'id': 11,
              'option': '50%',
              'porcentaje': 0.5
            },
            {
              'id': 12,
              'option': '55%',
              'porcentaje': 0.55
            },
            {
              'id': 13,
              'option': '60%',
              'porcentaje': 0.6
            },
            {
              'id': 14,
              'option': '65%',
              'porcentaje': 0.65
            },
            {
              'id': 15,
              'option': '70%',
              'porcentaje': 0.7
            },
            {
              'id': 16,
              'option': '75%',
              'porcentaje': 0.7
            },
            {
              'id': 17,
              'option': '80%',
              'porcentaje': 0.8
            },
            {
              'id': 18,
              'option': '85%',
              'porcentaje': 0.85
            },
            {
              'id': 19,
              'option': '90%',
              'porcentaje': 0.9
            },
            {
              'id': 20,
              'option': '95%',
              'porcentaje': 0.95
            },

          ];
          break;
        // Iva
        case 'listadoiva':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': '0%',
              'iva': 0
            },
            {
              'id': 2,
              'option': '19%',
              'iva': 0.19
            },
            {
              'id': 3,
              'option': '18%',
              'iva': 0.18
            },
            {
              'id': 4,
              'option': '20%',
              'iva': 0.2
            },
          ];
          break;

        case 'categorias_gastos':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': 'transporte',
            },
            {
              'id': 2,
              'option': 'viaticos',
            },
            {
              'id': 3,
              'option': 'herramientas y/o accesorios',
            },
            {
              'id': 4,
              'option': 'Plan & Accesorios para Celular',
            },
            {
              'id': 5,
              'option': 'Cafetería o Gourmet',
            },
            {
              'id': 6,
              'option': 'Aseo',
            },
            {
              'id': 7,
              'option': 'Publicidad o Marketing',
            },
            {
              'id': 8,
              'option': 'Botellones plásticos',
            },
            {
              'id': 9,
              'option': 'Garrafones plásticos',
            },
            {
              'id': 10,
              'option': 'Tapas para botellones',
            },
            {
              'id': 11,
              'option': 'Prensitas para botellones',
            },
            {
              'id': 12,
              'option': 'Arrendamiento',
            },
            {
              'id': 13,
              'option': 'Servicio de agua',
            },
            {
              'id': 14,
              'option': 'Servicio de luz',
            },
            {
              'id': 15,
              'option': 'Servicio de internet',
            },
            {
              'id': 16,
              'option': 'Papelería',
            },
          ];
          break;

        case 'sexo':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': 'masculino',
            },
            {
              'id': 2,
              'option': 'femenino',
            },
            {
              'id': 3,
              'option': 'empresa',
            },
          ];
          break;

        // Estados del sistema
        case 'estados':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': 'activado',
            },
            {
              'id': 2,
              'option': 'desactivado',
            },
            {
              'id': 3,
              'option': 'suspendido',
            },
            {
              'id': 4,
              'option': 'bloqueado',
            },
            {
              'id': 5,
              'option': 'verificado',
            },
            {
              'id': 6,
              'option': 'visto',
            },
            {
              'id': 7,
              'option': 'validado',
            },
            {
              'id': 8,
              'option': 'enviado',
            },
            {
              'id': 9,
              'option': 'recibido',
            },
            {
              'id': 10,
              'option': 'cancelado',
            },
            {
              'id': 11,
              'option': 'aprobado',
            },
            {
              'id': 12,
              'option': 'procesando',
            },
            {
              'id': 13,
              'option': 'solicitado',
            },
            {
              'id': 14,
              'option': 'autorizado',
            },
            {
              'id': 15,
              'option': 'entregado',
            },
            {
              'id': 16,
              'option': 'pagado',
            },
            {
              'id': 17,
              'option': 'sin pagar',
            },
            {
              'id': 18,
              'option': 'pendiente',
            },
            {
              'id': 19,
              'option': 'finalizado',
            },
            {
              'id': 20,
              'option': 'agotado',
            },
            {
              'id': 21,
              'option': 'disponible',
            },
            {
              'id': 22,
              'option': 'empresa asociada',
            },
            {
              'id': 23,
              'option': 'usuario asociado',
            },
            {
              'id': 24,
              'option': 'usuario asociado : distribuidor',
            },

          ];
          break;

        // Tipos de proveedores
        case 'tiposdeproveedor':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': 'Artículos de ferretería, construcción, pinturas y productos de vidrio',
            },
            {
              'id': 2,
              'option': 'Almacén de eléctricos',
            },
            {
              'id': 3,
              'option': 'Materiales eléctricos e industriales',
            },
            {
              'id': 4,
              'option': 'Comercio de computadores, equipos periféricos, programas de informática y equipos de telecomunicaciones',
            },
            {
              'id': 5,
              'option': 'Forja, prensado, estampado y laminado de metal',
            },
            {
              'id': 6,
              'option': 'Fabricación de productos metálicos para uso estructural'
            },
            {
              'id': 7,
              'option': 'Tornillos y herramientas'
            },
            {
              'id': 8,
              'option': 'Giros, pagos de facturas, recargas, consignaciones, pagos de servicios publicos'
            },
            {
              'id': 9,
              'option': 'Almacenes de cadena, Supermercados'
            },
            {
              'id': 10,
              'option': 'Transportadora de mercancía'
            },
            {
              'id': 11,
              'option': 'Publicidad'
            },
            {
              'id': 12,
              'option': 'Captación, tratamiento y distribución de agua'
            },
            {
              'id': 13,
              'option': 'Evacuación y tratamiento de aguas residuales'
            },
            {
              'id': 14,
              'option': 'Otros tipos de comercio al por menor'
            },
            {
              'id': 15,
              'option': 'Fabricación de papel y cartón ondulado. Fabricación de envases, empaques y de embalajes de papel y cartón.'
            },

            {
              'id': 16,
              'option': 'peluquería y otros tratamientos de belleza.'
            },
            {
              'id': 17,
              'option': 'otras actividades de servicio de apoyo a las empresas n.c.p.'
            },

          ];
          break;

        // Actividades economicas
        case 'actividades_economicas':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': 'peluquería y otros tratamientos de belleza',
              'ciiu': 9602
            },
            {
              'id': 2,
              'option': 'comercio al por mayor de productos farmacéuticos, medicinales, cosméticos y de tocador',
              'ciiu': 4645
            },
            {
              'id': 3,
              'option': 'comercio al por menor de productos farmacéuticos y medicinales, cosméticos y artículos de tocador en establecimientos especializados',
              'ciiu': 4773
            },
            {
              'id': 4,
              'option': 'fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico',
              'ciiu': 2100
            },
            {
              'id': 5,
              'option': 'comercio al por mayor de materiales de construcción, artículos de ferretería, pinturas, productos de vidrio, equipo y materiales de fontanería y calefacción',
              'ciiu': 4663
            },
            {
              'id': 6,
              'option': 'comercio al por menor de artículos de ferretería, pinturas y productos de vidrio en establecimientos especializados',
              'ciiu': 4752
            },
            {
              'id': 7,
              'option': 'expendio de comidas preparadas en cafeterías',
              'ciiu': 5613
            },
            {
              'id': 8,
              'option': 'elaboración de productos de panadería',
              'ciiu': 1081
            },
            {
              'id': 9,
              'option': 'comercio al por mayor de combustibles sólidos, líquidos, gaseosos y productos conexos',
              'ciiu': 4661
            },
            {
              'id': 10,
              'option': 'comercio al por mayor de metales y productos metalíferos',
              'ciiu': 4662
            },
            {
              'id': 11,
              'option': 'comercio al por menor en establecimientos no especializados, con surtido compuesto principalmente por productos diferentes de alimentos (víveres en general}, bebidas y tabaco',
              'ciiu': 4719
            },
            {
              'id': 12,
              'option': 'comercio al por menor de productos agrícolas para el consumo en establecimientos especializados',
              'ciiu': 4721
            },
            {
              'id': 13,
              'option': 'comercio al por menor de leche, productos lácteos y huevos, en establecimientos especializados',
              'ciiu': 4722
            },
            {
              'id': 14,
              'option': 'lavado y limpieza, incluso la limpieza en seco, de productos textiles y de piel',
              'ciiu': 9601
            },
            {
              'id': 15,
              'option': 'elaboración de bebidas no alcohólicas, producción de aguas minerales y de otras aguas embotelladas',
              'ciiu': 1104
            },
            {
              'id': 16,
              'option': 'captación, tratamiento y distribución de agua',
              'ciiu': 3600
            },
            {
              'id': 17,
              'option': 'evacuación y tratamiento de aguas residuales',
              'ciiu': 3700
            },
            {
              'id': 18,
              'option': 'actividades de producción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión',
              'ciiu': 5911
            },
            {
              'id': 19,
              'option': 'actividades de posproducción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión',
              'ciiu': 5912
            },
            {
              'id': 20,
              'option': 'bancos comerciales',
              'ciiu': 6412
            },
            {
              'id': 21,
              'option': 'organización de convenciones y eventos comerciales',
              'ciiu': 8230
            },
            {
              'id': 22,
              'option': 'expendio de bebidas alcohólicas para el consumo dentro del establecimiento',
              'ciiu': 5630
            },
            {
              'id': 23,
              'option': 'establecimientos que combinan diferentes niveles de educación',
              'ciiu': 8530
            },
            {
              'id': 24,
              'option': 'actividades de juegos de azar y apuestas',
              'ciiu': 9200
            },
            {
              'id': 25,
              'option': 'servicios de seguros sociales de riesgos profesionales',
              'ciiu': 6522
            },
            {
              'id': 26,
              'option': 'servicios de apoyo a la silvicultura',
              'ciiu': '0240'
            },
            {
              'id': 27,
              'option': 'actividades de servicios relacionados con la impresión',
              'ciiu': 1812
            },
            {
              'id': 28,
              'option': 'actividades de saneamiento ambiental y otros servicios de gestión de desechos',
              'ciiu': 3900
            },
            {
              'id': 29,
              'option': 'servicio por horas',
              'ciiu': 5530
            },
            {
              'id': 30,
              'option': 'actividades de otros servicios de comidas',
              'ciiu': 5629
            },
            {
              'id': 31,
              'option': 'otras actividades de servicio de apoyo a las empresas n.c.p.',
              'ciiu': 8299
            },
            {
              'id': 32,
              'option': 'fabricación de sustancias y productos químicos básicos',
              'ciiu': 2011
            },
            {
              'id': 33,
              'option': 'fabricación de otros productos químicos n.c.p.',
              'ciiu': 2029
            },
            {
              'id': 34,
              'option': 'Alojamiento en centros vacacionales',
              'ciiu': 5513
            },
            {
              'id': 35,
              'option': 'Comercio al por mayor de productos químicos básicos, cauchos y plásticos en formas primarias y productos químicos de uso agropecuario',
              'ciiu': 4664
            },
            {
              'id': 36,
              'option': 'Actividades de centros de llamadas (Call center)',
              'ciiu': 8220
            },

          ];
          break;

        /*
         * Tienda virtual 
         * Tipos de descripcion para un producto
         */
        case 'tiendavirtualtiposdescripcion':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': 'sistema de filtración',
            },
            {
              'id': 2,
              'option': 'dimensión',
            },
            {
              'id': 3,
              'option': 'precio',
            },
            {
              'id': 4,
              'option': 'cantidad de productos',
            },
            {
              'id': 5,
              'option': 'precio costo',
            },
          ];
          break;

        // Metodos de pago
        case 'metodosdepago':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': 'Bancolombia : Cuenta de Ahorros',
              'codigo': '428-000005-13'
            },
            {
              'id': 2,
              'option': 'Efectivo',
              'codigo': '$'
            },
            {
              'id': 3,
              'option': 'Pago Online',
              'codigo': 'Wompi'
            },
            {
              'id': 4,
              'option': 'BBVA : Cuenta Corriente',
              'codigo': '00130130 000100 022361'
            },
          ];
          break;


        // Metodos de envio
        case 'metodosdeenvio':
          opciones['opciones'] = [
            {
              'id': 1,
              'option': 'servientrega',
            },
            {
              'id': 2,
              'option': 'envia',
            },
          ];
          break;



      }

      if ((parametros.id)) {
        if ((opciones['opciones'][parametros.id - 1])) {
          return opciones['opciones'][parametros.id - 1];
        }
      } else {

        if ((parametros['condiciones']) && (parametros['condiciones'])) {
          let opcioneTMP = [];
          opciones['opciones'].forEach((option: any) => {

            if (parametros['condiciones'].indexOf(option.id) === -1) {
              opcioneTMP.push(option);
            }

          });

          opciones['opciones'] = opcioneTMP;
        }

        return opciones;  
      }

    }
  }


  /*
     Calcular porcentaje
  */

  async calcularporcentaje(parametros: any) {
    if (parametros.porcentaje && parametros.numeroInput) {

      //var_dump(2000*0.05);

      let returnPorcentaje = [];

      const parametrosFuncion = {
        'tipodeselect': 'porcentajes_descuento',
        'id': parametros.porcentaje
      };

      this.selectoptiones(parametrosFuncion).then((porcentaje: any) => {
        returnPorcentaje['numeroInput'] = parametros['numeroInput'];

        returnPorcentaje['porcentaje'] = porcentaje;

        returnPorcentaje['porcentaje_numero'] = (parametros['numeroInput'] * porcentaje.porcentaje);

        returnPorcentaje['total_porcentaje'] = (parametros['numeroInput'] + returnPorcentaje['porcentaje_numero']);

        return returnPorcentaje;
      });

    }
  }


}
