import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { EscanerService } from 'src/app/services/escaner.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-asociar-suministro-agua',
  templateUrl: './asociar-suministro-agua.component.html',
  styleUrls: ['./asociar-suministro-agua.component.scss'],
})
export class AsociarSuministroAguaComponent implements OnInit {


  formUsuario = { // Usuario
    codigo: '',
    email: '',
    clave: '',
    encriptado: 1,
    terminos: false,
  }

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: ''
  }

  idiomaSeleccionado: string = null;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'AUTH_REGISTRO_TICKET';

  keyboardLayout: string[][] = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['', '0', '']
  ];

  constructor(
    private translateConfigService: TranslateConfigService,
    private modalController: ModalController,
    private aquabot: AquabotService,
    private redatwater: RedatwaterService,
    private alertCtr: AlertController,
    private escaner: EscanerService,
    private toast: ToastService,
    private ventanaAlerta: AlertService
  ) {
    console.log('Ventana -> asociar suministro de agua');
    this.header.pagina = '/registro';
    this.header.titulo = 'Asociar Suministro';
  }

  ngOnInit() {
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        if (this.itemsIdiomaApp && this.itemsIdioma) {
          //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
          console.log('Parametros de idioma -> ', this.itemsIdioma);
          //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }

  async finalizarSuministro() {
    if (this.formUsuario.codigo &&
      this.formUsuario.clave &&
      this.formUsuario.email &&
      this.formUsuario.terminos) {

      let parametrosData = {
        codigo: this.formUsuario.codigo,
        clave: this.formUsuario.clave,
        correo: this.formUsuario.email
      }
      this.redatwater.asociarSuministro(parametrosData).then((getData: any) => {

        if (getData) {
          console.log('Seguimiento -> ', getData);

          this.modalController.dismiss();

        } else {
          console.log('Seguimiento de errores -> ', getData);
          this.toast.msgAppBottom('¡Oops! Asociar Suministro de Agua.', 'danger');
        }

      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });

    } else {
      this.toast.msgAppBottom('Asegúrate de llenar todos los campos.', 'danger');
    }
  }

  async saltarVentana() {
    this.modalController.dismiss();
  }

  terminos() {
    console.log('Ventana -> Terminos ');
    this.terminos_web();
  }

  async terminos_web() {
    console.log('Navegador -> Portal Transaccional: Terminos y condiciones');
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/terminosycondiciones', 'portal transaccional');
  }

  aceptarTerminos() {
    console.log('Aceptar terminos:' + this.formUsuario.terminos);
    if (this.formUsuario.terminos) {
      //this.toast.msgAppCenter('A través de www.bancodelagua.com encuentra información y acceso de tus productos y servicios.', 'success');
    } else {
      this.toast.msgAppCenter('Para nosotros es muy importante el derecho a la privacidad.', 'danger');
    }
  }

  appendToPassword(key: string) {
    if (this.formUsuario.clave.length < 20) {
      this.formUsuario.clave += key;
    }
    // Aquí puedes agregar la lógica para verificar y procesar la contraseña.
    // Por ejemplo, puedes llamar a una función para hacerlo.
    this.verifyAndProcessPassword(this.formUsuario.clave);
  }

  verifyAndProcessPassword(password: string) {
    // Aquí puedes realizar la lógica para verificar y procesar la contraseña.
    // Puedes realizar una llamada a una API, almacenarla localmente, etc.
    // Por ahora, solo mostraremos un mensaje en la consola.
    console.log('Contraseña ingresada: ' + password);
  }



  async escanearTicket() { // Escanear ticket de agua

    console.log('Seguimiento -> Escanear qr ticket de agua');
    this.leerQr(false);
  }

  async leerQr(camara: any) {
    this.escaner.leerQr(camara).then((data: any) => {
      console.log('Seguimiento escaner -> ' + data);
      let parametrosEscaneados: any = data;

      if (parametrosEscaneados?.token &&
        parametrosEscaneados?.categoria &&
        parametrosEscaneados?.codigo &&
        parametrosEscaneados?.clave) {

        if (this.escaner.verificarToken(parametrosEscaneados.token)) {
          if (parametrosEscaneados.categoria === 'ticket_agua') {
            this.formUsuario.codigo = parametrosEscaneados?.codigo;
            this.formUsuario.clave = parametrosEscaneados?.clave;
            this.formUsuario.encriptado = 2;
            // this.textSpeech.hablar(this.itemsIdioma.hablar_pay_ticket_qr);

          } else {
            this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ticket_qr_categoria);
            console.log('Seguimiento de errores -> ¡Oops! No es la categoria');
          }
        } else {
          console.log('Seguimiento de errores -> ¡Oops! Token de lector QR denegado.');
        }

      } else {
        this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ticket_qr_parametros);
        console.log('Seguimiento de errores -> ¡Oops! Parametros escaneados ', parametrosEscaneados);
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores ->', err);
    });
  }

}
