import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import { AppComollegarComponent } from 'src/app/components/app-comollegar/app-comollegar.component';
import { Header, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { EscanerService } from 'src/app/services/escaner.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TextSpeechService } from 'src/app/services/text-speech.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-ok-pedido-cajero-domiciliario',
  templateUrl: './ok-pedido-cajero-domiciliario.component.html',
  styleUrls: ['./ok-pedido-cajero-domiciliario.component.scss'],
})
export class OkPedidoCajeroDomiciliarioComponent implements OnInit, OnDestroy {


  @Input() pedido: any;

  authUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_CLIENTE_OK_PEDIDO_CAJERO_DOMICILIARIO';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 1,
    pagina: '',
    titulo: ''
  }

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Establecer ubicación.',
    coordenadas: {
      lat: null,
      lng: null
    }
  };

  defaultImage = 'assets/img/default.png';
  imagenCajero = '';

  imgSuministroAgua = '';
  domiciliario = '';
  intercambiarEnvase = '';
  estadoPedidoEntrega = '';
  calificacionDomiciliario = '';
  calificacionCliente = '';

  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private navCtrl: NavController,
    private ventanaAlerta: AlertService,
    private modalController: ModalController,
    private alertCtr: AlertController,
    private escaner: EscanerService,
    private aquabot: AquabotService,
    private textSpeech: TextSpeechService
  ) {
    console.log('App -> Detalle de pedido');
    this.header.pagina = '/redatwater-cliente';
    this.header.titulo = 'DETALLE PEDIDO';

  }

  ngOnInit() {

    console.log('Seguimiento -> Pedido: ', this.pedido);
    this.imagenCajero = 'assets/img/sistemas/' + this.pedido.pedido.sistema_modelo_id + '.png';
    console.log('Seguimiento IMG Cajero -> ', this.imagenCajero);

    this.detallePedido();

    this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getUsuario: any) => {
      console.log('Usuario Auth ->', getUsuario);
      if (getUsuario?.data) {
        this.authUsuario = getUsuario.data;
        console.log('Seguimiento -> Auth dispositivo: ', this.authUsuario);
        this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
          this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
            this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
            this.itemsIdioma = this.translateConfigService.getItemsIdioma();

            if (this.itemsIdiomaApp && this.itemsIdioma) {
              //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
              //console.log('Parametros de idioma -> ', this.itemsIdioma);
              //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);

              this.aquaBot = this.aquabot.appMsgAquaBot({
                msg: this.itemsIdiomaApp.aquabot_msg_cajeros,
                estado: 'text-primary'
              });

            } else {
              this.translateConfigService.resetIdioma(this.idiomaPagina);
            }
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      } else {
        this.navCtrl?.navigateRoot(['/logout']);
      }
    });

  }

  detallePedido() {

    if (this.pedido.pedido?.pedido_agua_domiciliario_id) {
      this.domiciliario = this.pedido.pedido.pedido_agua_domiciliario_id;
    } else {
      this.domiciliario = '';
    }
    
    if (this.pedido.pedido?.pedido_agua_intercambiar === '2') {
      this.intercambiarEnvase = 'Si';
    } else {
      this.intercambiarEnvase = 'No';
    }

    if (this.pedido.pedido?.pedido_agua_calificacion_cliente_domiciliario) {
      this.calificacionDomiciliario = this.pedido.pedido.pedido_agua_calificacion_cliente_domiciliario;
    } else {
      this.calificacionDomiciliario = '';
    }

    if (this.pedido.pedido?.pedido_agua_calificacion_usuario_cliente) {
      this.calificacionCliente = this.pedido.pedido.pedido_agua_calificacion_usuario_cliente;
    } else {
      this.calificacionCliente = '';
    }


    switch (this.pedido.pedido?.pedido_agua_entregado) {

      case '1':
        this.estadoPedidoEntrega = 'solicitado';
        break;

      case '2':
        this.estadoPedidoEntrega = 'procesado';
        break;

      case '3':
        this.estadoPedidoEntrega = 'enviado';
        break;

      case '4':
        this.estadoPedidoEntrega = 'entregado';
        break;

      default:
        this.estadoPedidoEntrega = '';
        break;

    }

  }


  ngOnDestroy() {

  }

  establecerImagenAgua(presentacion: any) {
    this.imgSuministroAgua = '';
    if (parseInt(presentacion) >= 1) {
      console.log('Seguimiento -> Presentacion ID', presentacion);
      switch (presentacion) {

        case '1':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/1litro.png';
          break;

        case '5':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/5litros.png';
          break;

        case '10':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/10litros.png';
          break;

        case '19':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/19litros.png';
          break;

        default:
          this.imgSuministroAgua = '';
          break;

      }

      console.log('Seguimiento IMG -> ', this.imgSuministroAgua);

    } else {
      console.log('Seguimiento de errores -> ', presentacion);
    }
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    if (this.modalController) {
      this.modalController.dismiss({ carrito: true });
    }
  }

  solicitarCodigoRecarga() {
    console.log('Solicitar codigo de recarga -> ', this.pedido);
    this.generarCodigo();
  }

  async generarCodigo() {
    if (this.pedido.pedido.sistema_public_key) {
      console.log('Seguimiento -> Generar QR de cajero');
      let codigoArray = {
        token: this.escaner.getTokenApp(),
        categoria: 'pedido_domiciliario',
        sistema_key: this.pedido.pedido.sistema_public_key,
        pedido_id: this.pedido.pedido.id
      };
      this.escaner.generarQr(codigoArray).then(() => { }).catch((err) => { console.log('Error: ', err); });
    } else {
      console.log('Seguimiento de errores -> ', this.pedido);
    }
  }

  entregarPedidoCliente() {
    console.log('Entregar pedido Cliente -> ', this.pedido);
    this.confirmarEntregaPedidoCliente();
  }

  async confirmarEntregaPedidoCliente() {
    console.log('Entregar Pedido al Cliente: ', this.pedido);

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: '¡Confirmar Entrega!',
      message: '¿Estás seguro que deseas <span class="text-success">entregar el pedido</span> al cliente?',
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Entrega cancelada');
          }
        },
        {
          text: 'Confirmar Entrega',
          handler: (data: any) => {
            console.log('Entrega confirmada!');
            // Aquí puedes agregar la lógica para marcar el pedido como entregado al cliente y realizar cualquier otra acción necesaria.
            this.confirmarEntregaClientePedido();
          }
        }
      ]
    });

    await alert.present();
  }

  async confirmarEntregaClientePedido() {
    console.log('Confirmar Cliente Entregade pedido');
    if (this.pedido.pedido?.id) {
      
      this.redatwater.confirmarEntregaClientePedidoCajero(this.pedido.pedido.id).then((pedido: any) => {

        if (pedido?.pedido) {

          console.log('Seguimiento pedido -> ', pedido);
          this.pedido = pedido;

          this.detallePedido();

        } else {
          console.log('Seguimiento de errores -> ', pedido);
          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Información incorrecta, Portal Transaccional.');
        }

      }).catch((error: any) => {
        console.log('Seguimiento de errores -> ', error);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
      });
      
    } else {
      console.log('Seguimiento de errores ->', this.pedido);
    }
  }

  async comoLlegar(direccion: any, proceso:any) {
    if (direccion) {

      console.log('Llegar a pedido', this.pedido);
      this.ventanaComoLlegar(direccion, proceso);

    } else {
      console.log('Seguimiento de errores ->', direccion);
    }
  }

  async ventanaComoLlegar(direccion: any, proceso:any) {

    const registroSeleccionado = {
      tipo: 'domicilio_cajero',
      registro: this.pedido,
      direccion: direccion,
      proceso: proceso
    };

    const modalAdd = await this.modalController.create({
      component: AppComollegarComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { registroSeleccionado }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    } else {

    }

  }

  calificarCliente() {
    console.log('Calificar domiciliario');
    this.ventanaCalificarCliente();
  }


  async ventanaCalificarCliente() {
    console.log('Calificar al domiciliario');

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: 'Califica tu experiencia',
      message: 'Por favor, selecciona una opción para <span class="text-success">calificar al cliente:</span>',
      inputs: [
        {
          type: 'radio',
          label: 'Excelente',
          value: 3,
          checked: false
        },
        {
          type: 'radio',
          label: 'Bueno',
          value: 2,
          checked: false
        },
        {
          type: 'radio',
          label: 'Malo',
          value: 1,
          checked: false
        }
      ],
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Calificación cancelada');
          }
        },
        {
          text: 'Enviar Calificación',
          handler: (data: any) => {
            if (data === 1 || data === 2 || data === 3) {

              const seleccionado = data;
              console.log('Calificación enviada:', seleccionado);
              this.confirmarCalificacionCliente(seleccionado);

            } else {
              console.log('Seguimiento de errores -> ', data);
              this.ventanaCalificarCliente();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async confirmarCalificacionCliente(calificacion: any) {
    console.log('Confirmar Calificacion de pedido');
    if (this.pedido.pedido?.id) {

      this.redatwater.confirmarCalificacionClienteCajero(this.pedido.pedido.id, calificacion).then((pedido: any) => {

        if (pedido?.pedido) {

          this.pedido = pedido;
          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-success">¡Gracias por tu calificación! </span> tu opinión es importante para nosotros.');
          this.detallePedido();

        } else {
          console.log('Seguimiento de errores -> ', pedido);
          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Información incorrecta, Portal Transaccional.');
        }

      }).catch((error: any) => {
        console.log('Seguimiento de errores -> ', error);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
      });

    } else {
      console.log('Seguimiento de errores ->', this.pedido);
    }
  }

}
