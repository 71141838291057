import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthrolUsuariosGuard implements CanActivate {


  constructor(
    private auth: AuthService,
    private navCtrl: NavController
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let expecteModulo = route.data.modulo;
    console.log('Modulo ->', expecteModulo);
    let expecteRol = route.data.rol;
    console.log('Roles ->', expecteRol);
    let expecteRolId = route.data.rol_id;
    console.log('Roles ID ->', expecteRolId);

    let tienePermiso = false;

    return new Promise(async (resolve, reject) => {
      this.auth.loadAuthToken().then(async (getUsuario: any) => {
        //console.log('Dispositivo Auth ->', getUsuario);
        if (getUsuario) {

          this.auth.setUpdateAuth(getUsuario);

          if (expecteModulo === 'usuarios') { // ROLES TIPO CLIENTES

            if (expecteRol?.length) {

              expecteRol.forEach((rolElement: any) => {

                if (rolElement) {

                  console.log('Verificando rol -> ', rolElement);

                  if (rolElement === 'usuario'
                  ) {

                    expecteRolId.forEach(rolId => {

                      if (rolId === getUsuario.grupo_id) {

                        tienePermiso = true;

                      }

                    });

                  }
                }
              });

              // VERIFICAR PERMISO
              if (tienePermiso) {

                console.log('Seguridad -> Tiene permiso.');
                resolve(true);

              } else {

                this.navCtrl?.navigateRoot(['/home']);
                console.log('Seguridad -> No Tiene permiso.');
                resolve(false);

              }

            } else {
              console.log('Seguimiento de errores -> ', expecteRol);
              this.navCtrl?.navigateRoot(['/logout']);
              resolve(false);
            }

          } else {
            this.navCtrl?.navigateRoot(['/logout']);
            resolve(false);
          }

        } else {
          this.navCtrl?.navigateRoot(['/logout']);
          resolve(false);
        }

      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
        resolve(false);
      });
    });
  }

}
