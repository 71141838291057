import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    public alertCtrl: AlertController
  ) { }

  /* Center */
  async msgAppCenter(titulo: string, msg: string) {    
    const alert = await this.alertCtrl.create({
        cssClass: 'my-custom-class',
        header: titulo,
        message: msg,
        buttons: ['Cerrar ventana']
      });
  
      await alert.present();
  }

}
