import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import { Header, ITiendaCercana, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { DetalleProductoComponent } from '../../app-clientes/tiendas/detalle-producto/detalle-producto.component';
import { DetalleTiendaComponent } from '../../app-clientes/tiendas/detalle-tienda/detalle-tienda.component';
import { DetalleTiendaUsuarioComponent } from './detalle-tienda-usuario/detalle-tienda-usuario.component';
import { DetalleProductoTiendaUsuarioComponent } from './detalle-producto-tienda-usuario/detalle-producto-tienda-usuario.component';

@Component({
  selector: 'app-tiendas-usuario',
  templateUrl: './tiendas-usuario.component.html',
  styleUrls: ['./tiendas-usuario.component.scss'],
})
export class TiendasUsuarioComponent implements OnInit, OnDestroy {


  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  authUsuario: any = null;
  defaultImage = 'assets/img/default.png';

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'TIENDAS_AGUA_CERCA';

  listaTiendas: any[] = [];
  listaTiendasCercanas = new Array<any>();

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Establecer ubicación.',
    coordenadas: {
      lat: null,
      lng: null
    }
  };

  aquaBot = {
    msg: '',
    estado: ''
  };

  buscador: String = '';
  finalBuscador: String = 'all';
  ordenarBusqueda: String = 'distancia';

  totalRows: number;
  currentPage: number = 1;
  limit: number = 3;
  limiteTiendas = 3;

  slideOptions = {
    slidesPerView: 4,
    spaceBetween: 10,
    centeredSlides: false,
    slidesOffsetBefore: 10,
    slidesOffsetAfter: 10,
  };

  tipoBusqueda: String = 'tiendas';

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private toast: ToastService,
    private redatwater: RedatwaterService,
    private translateConfigService: TranslateConfigService,
    private aquabot: AquabotService,
    private geolocalizacion: GeolocalizacionService,
    private auth: AuthService,
    private alertCtr: AlertController,
    private utilitario: UtilitariosService
  ) {
    console.log('Ventana -> busqueda de tiendas');
    this.header.pagina = '/redatwater-usuario';
    this.header.titulo = 'Tiendas cercanas';

    this.aquaBot = this.aquabot.appMsgAquaBot({
      msg: 'Ahorra con estilo, cuida el agua con Banco del Agua.',
      estado: 'text-success'
    });
  }

  ngOnInit() {

    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {

              this.authUsuario = getAuth.data;
              this.loadUbicacionActual();

            } else {

              this.navCtrl?.navigateRoot(['/logout']);

            }
          });

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }

  async loadUbicacionActual() {
    this.geolocalizacion.loadUbicacionActual().then((ubicacion: any) => {
      console.log('App Ubicacion actual -> ' + JSON.stringify(ubicacion));

      this.direccionActual = ubicacion;
      this.busquedaTiendas(this.currentPage, this.limit);

    }).catch(error => {
      console.log('App -> Error: ' + error);
    });
  }

  async buscarTiendas() {

    console.log('Buscar -> ', this.buscador);
    if (this.buscador === '' || this.buscador === undefined || this.buscador === '0') {
      this.finalBuscador = 'all';
      this.ordenarBusqueda = 'distancia';
    } else {
      this.finalBuscador = this.buscador;
      this.ordenarBusqueda = 'relevancia';
    }


    this.listaTiendas = [];
    this.listaTiendasCercanas = new Array<any>();

    this.busquedaTiendas(this.currentPage, this.limit);
  }


  async busquedaTiendas(page: number, limit: number) {

    console.log('Seguimiento -> Busqueda de tiendas');
    this.listaTiendas = [];
    this.listaTiendasCercanas = new Array<any>();

    if (this.tipoBusqueda === 'tiendas') {

      const pagination = {
        page: page,
        limit: limit
      }
      this.redatwater.getTiendasBusqueda(this.finalBuscador, this.direccionActual.coordenadas, pagination).then((getData: any) => {

        if (getData) {

          console.log('Lista de tiendas cerca -> ', getData);
          this.listaTiendas = getData.tiendas;

          this.totalRows = getData.total_registros;

          this.ordenarTiendas();

        } else {

          console.log('Seguimiento de errores -> ', getData);

        }

      }).catch((err: any) => {

        console.log('Seguimiento de errores -> ', err);
      });


    }

  }


  async ordenarTiendas() {

    const tiendasAgua = this.listaTiendas;

    if (tiendasAgua) {

      tiendasAgua.forEach((itemTienda) => {

        //console.log('Tienda -> ' + JSON.stringify(itemTienda));

        const dataTienda: ITiendaCercana = {
          id: itemTienda.id,
          tipo: 'tiendas',
          categoria: itemTienda.categoria,
          categoria_id: itemTienda.categoria_id,
          descripcion: itemTienda.descripcion,
          estado: itemTienda.estado,
          estado_id: itemTienda.estado_id,
          celular: itemTienda.celular,
          email: itemTienda.email,
          empresa: itemTienda.empresa,
          empresa_id: itemTienda.empresa_id,
          titulo: itemTienda.titulo,

          ubicacion_ciudad: itemTienda.ubicacion_ciudad,
          ubicacion_ciudad_id: itemTienda.ubicacion_ciudad_id,
          ubicacion_lat: itemTienda.ubicacion_lat,
          ubicacion_long: itemTienda.ubicacion_long,
          direccion: itemTienda.direccion,
          imagen: itemTienda.img,

          horario_inicio: itemTienda.horario_inicio,
          horario_fin: itemTienda.horario_fin,
          horario_inicio_indicativo: itemTienda.horario_inicio_indicativo,
          horario_fin_indicativo: itemTienda.horario_fin_indicativo,
          envio_gratis: itemTienda.envio_gratis,
          indicativo_movil: itemTienda.indicativo_movil,
          tienda_virtual: itemTienda.tienda_virtual,
          destacado: itemTienda.destacado,

          empresa_logo: itemTienda.empresa_logo,
          empresa_pagina_web: itemTienda.empresa_pagina_web,

          fechaRegistro: itemTienda.created_at,
          fechaModificacion: itemTienda.updated_at,

          geolocalizacion: {
            distancia: itemTienda.geolocalizacion.distancia,
            distancia_text: itemTienda.geolocalizacion.distancia_text,
            unidad: itemTienda.geolocalizacion.unidad,
          },

          lista_productos: itemTienda.lista_productos

        }

        //console.log('Tiendas cercanas -> ' + JSON.stringify(dataTienda));
        this.listaTiendasCercanas.push(dataTienda);


      });

      this.listaTiendasCercanas.sort((a, b) => {
        if (a.geolocalizacion.distancia == b.geolocalizacion.distancia) {
          return 0;
        }

        if (a.geolocalizacion.distancia < b.geolocalizacion.distancia) {
          return -1;
        }

        return 1;
      });
    }

  }

  async ventanaDetalleTienda(tienda: any) { // Detalle producto
    const modalAdd = await this.modalController.create({
      component: DetalleTiendaUsuarioComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { tienda }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    } else {

    }
  }

  async detalleTienda(tienda: any) {
    if (tienda) {
      console.log('Seguimiento -> Detalle de tienda ', tienda);
      this.ventanaDetalleTienda(tienda);
    } else {
      console.log('Seguimiento de errores -> ', tienda);
    }
  }

  async ventanaDetalleProducto(tienda: any, producto: any) { // Detalle producto

    const modalAdd = await this.modalController.create({
      component: DetalleProductoTiendaUsuarioComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { tienda, producto }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    } else {

    }
  }

  async detalleProducto(tienda: any, producto: any) {
    if (tienda && producto) {
      console.log('Seguimiento -> Detalle de producto ', producto);
      this.ventanaDetalleProducto(tienda, producto);
    } else {
      console.log('Seguimiento de errores -> ', producto);
    }
  }


  cambiarTipoBusqueda() {
    console.log('Seleccionar tipo de busqueda');
    this.seleccionarTipoBusqueda();
  }

  async seleccionarTipoBusqueda() {

    console.log('Seleccionar otro tipo de busqueda: ');

    let metodosPermitidos = [];

    metodosPermitidos.push({
      label: 'Tiendas de agua',
      type: 'radio',
      name: 'tipo_busqueda',
      value: 1,
    });

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: '¡Busqueda personalizada!',
      //message: '',
      inputs: metodosPermitidos,
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cerrar!');
          }
        }, {
          text: 'Aceptar',
          handler: (data: any) => {
            console.log('Ok!');

            if (data === 1) { // TIENDAS DE AGUA

              this.listaTiendas = [];
              this.listaTiendasCercanas = new Array<any>();
              this.currentPage = 1;
              this.limit = this.limiteTiendas;
              this.tipoBusqueda = 'tiendas';
              this.buscarTiendas();

            } else {
              console.log('Seguimiento de errores -> seleccionar tipo de busqueda.');
              this.seleccionarTipoBusqueda();
            }
          }
        }
      ]
    });

    await alert.present();

  }

  nextPage() {
    if (this.currentPage < Math.ceil(this.totalRows / this.limit)) {
      this.currentPage++;
      this.busquedaTiendas(this.currentPage, this.limit);
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.busquedaTiendas(this.currentPage, this.limit);
    }
  }

  isLastPage(): boolean {
    return this.currentPage === this.getLastPage();
  }

  getLastPage(): number {
    return Math.ceil(this.totalRows / this.limit);
  }

  isBothButtonsDisabled(): boolean {
    return this.currentPage === 1 && this.isLastPage();
  }


}
