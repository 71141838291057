import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './session/auth.guard';
import { AuthFalseGuard } from './session/authfalse.guard';
import { AuthrolGuard } from './session/authrol.guard';
import { AuthrolClientesGuard } from './session/authrol-clientes.guard';
import { AuthrolUsuariosGuard } from './session/authrol-usuarios.guard';

const routes: Routes = [
  /*  
  ***********************
  ****** BANCO DEL AGUA 
  ***********************
  */
  {
    path: 'home', // APP
    loadChildren: () => import('./views/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthFalseGuard],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'logout',
    loadChildren: () => import('./views/auth/logout/logout.module').then(m => m.LogoutPageModule)
  },
  // SESSION
  {
    path: 'registro',
    loadChildren: () => import('./views/auth/registro/registro.module').then(m => m.RegistroPageModule),
    canActivate: [AuthFalseGuard],
  },
  {
    path: 'redatwater',
    loadChildren: () => import('./views/app/redatwater/redatwater.module').then(m => m.RedatwaterPageModule),
    canActivate: [AuthrolGuard],
    data: {
      modulo: 'all', // TODOS LOS ROLES
      rol: ['root', 'empresa'],
    }
  },

  /*  
  ***********************
  ****** CLIENTES: 
  CLIENTES (PERSONA, EMPRESA) & REFERIDOS
  ***********************
  */
  {
    path: 'redatwater-cliente',
    loadChildren: () => import('./views/app-clientes/redatwater-cliente/redatwater-cliente.module').then(m => m.RedatwaterClientePageModule),
    canActivate: [AuthrolClientesGuard],
    data: {
      modulo: 'clientes',
      rol: ['cliente', 'referido'],
    }
  },
  {
    path: 'tiendas',
    loadChildren: () => import('./views/app-clientes/tiendas-cliente/tiendas-cliente.module').then(m => m.TiendasPageModule),
    canActivate: [AuthrolClientesGuard],
    data: {
      modulo: 'clientes',
      rol: ['cliente', 'referido'],
    }
  },

  /*  
  ***********************
  ****** USUARIOS:
  ADMIN, DISTRIBUIDOR, PRE VENDEDOR, DOMICILIARIO
  ***********************
  */
  {
    path: 'redatwater-usuario',
    loadChildren: () => import('./views/app-usuarios/redatwater-usuario/redatwater-usuario.module').then(m => m.RedatwaterUsuarioPageModule),
    canActivate: [AuthrolUsuariosGuard],
    data: {
      modulo: 'usuarios',
      rol: ['usuario'],
      rol_id: ['1', '2', '4', '7'] // ADMIN, DISTRIBUIDOR, DOMICILIARIO, PRE VENDEDOR
    }
  }, 
  {
    path: 'tiendas-usuario',
    loadChildren: () => import('./views/app-usuarios/tiendas-usuario/tiendas-usuario.module').then( m => m.TiendasUsuarioPageModule),
    data: {
      modulo: 'usuarios',
      rol: ['usuario'],
      rol_id: ['1', '2', '4', '7'] // ADMIN, DISTRIBUIDOR, DOMICILIARIO, PRE VENDEDOR
    }
  },
  {
    path: 'gps-ubicacion',
    loadChildren: () => import('./views/auth/gps-ubicacion/gps-ubicacion.module').then( m => m.GpsUbicacionPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
