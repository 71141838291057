import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavController, ModalController, LoadingController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TextSpeechService } from 'src/app/services/text-speech.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UbicacionService } from 'src/app/services/ubicacion.service';
import { GooglemapsService } from 'src/app/utilitarios/googlemaps/googlemaps.service';
import { environment } from 'src/environments/environment';
import { Browser } from '@capacitor/browser';

declare var google: any;

@Component({
  selector: 'app-app-comollegar',
  templateUrl: './app-comollegar.component.html',
  styleUrls: ['./app-comollegar.component.scss'],
})
export class AppComollegarComponent implements OnInit {

  @Input() registroSeleccionado: any;

  public apiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
  private subscription: Subscription = new Subscription();

  authDispositivo: any = null;
  channelSistema: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_COMOLLEGAR';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: ''
  }

  ubicacionText = {
    origen: null,
    destino: null
  }

  ubicacionList = {
    origen: true,
    destino: true
  }

  ubicacion = {
    direccionA: null,
    direccionB: null,

    positionOrigen: {
      lat: null,
      lng: null
    },
    positionDestino: {
      lat: null,
      lng: null
    },
    dataRuta: null
  }

  directionsService: any;
  directionsDisplay: any;
  dataDirectionsService: any;
  googleAutocomplete: any;
  searchResults = new Array<any>();

  label = {
    titulo: 'Ubicación',
    subtitulo: 'Mi ubicación'
  }

  map: any;
  marker: any;
  infowindow: any;
  positionSet: any;

  imagenDomicilioComollegar: any = '';

  @ViewChild('map') divMap: ElementRef;

  constructor(
    private translateConfigService: TranslateConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document,
    private http: HttpClient,
    private googlemapsService: GooglemapsService,
    private ventanaAlerta: AlertService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private geolocalizacion: GeolocalizacionService,
    private navCtrl: NavController,
    private modalController: ModalController,
    private appUbicacion: UbicacionService,
    private loadingCtrl: LoadingController,
    private aquabot: AquabotService,
    private textSpeech: TextSpeechService
  ) {
    console.log('App -> ¿Como llegar?');
  }

  ngOnInit() {
    console.log('Seguimiento -> Registro seleccionado: ', this.registroSeleccionado);

    this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getDispositivo: any) => {
      console.log('Auth Usuario ->', getDispositivo);
      if (getDispositivo?.data) {
        this.authDispositivo = getDispositivo.data;
        console.log('Seguimiento -> Auth dispositivo: ', this.authDispositivo);

        this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
          this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
            this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
            this.itemsIdioma = this.translateConfigService.getItemsIdioma();

            if (this.itemsIdiomaApp && this.itemsIdioma) {
              //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
              //console.log('Parametros de idioma -> ', this.itemsIdioma);
              //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);

              this.aquaBot = this.aquabot.appMsgAquaBot({
                msg: this.itemsIdioma.aquabot_msg_ubicacion,
                estado: 'text-primary'
              });

              if (this.registroSeleccionado.tipo === 'tienda') {

                console.log('Seguimiento -> ', this.registroSeleccionado);

                if (this.registroSeleccionado.registro.ubicacion_lat && this.registroSeleccionado.registro.ubicacion_long) {
                  this.ubicacion.positionDestino.lat = parseFloat(this.registroSeleccionado.registro.ubicacion_lat);
                  this.ubicacion.positionDestino.lng = parseFloat(this.registroSeleccionado.registro.ubicacion_long);
                  console.log('Iniciando Mapa!');
                  this.iniciarMapa();

                } else {
                  console.log('Seguimiento de errores -> ', this.registroSeleccionado.registro);
                }

              } else if (this.registroSeleccionado.tipo === 'sistema') {

                console.log('Seguimiento -> ', this.registroSeleccionado);

                if (this.registroSeleccionado.registro.data?.infoUbicacion.ubicacion_lat && this.registroSeleccionado.registro.data?.infoUbicacion.ubicacion_long) {
                  this.ubicacion.positionDestino.lat = parseFloat(this.registroSeleccionado.registro.data.infoUbicacion.ubicacion_lat);
                  this.ubicacion.positionDestino.lng = parseFloat(this.registroSeleccionado.registro.data.infoUbicacion.ubicacion_long);
                  console.log('Iniciando Mapa!');
                  this.iniciarMapa();

                } else {
                  console.log('Seguimiento de errores -> ', this.registroSeleccionado.registro);
                }

              } else if (this.registroSeleccionado.tipo === 'domicilio_tienda') {
                console.log('Seguimiento -> ', this.registroSeleccionado);

                if (this.registroSeleccionado.proceso === 'inicio') {

                  if (this.registroSeleccionado.registro?.pedido.tienda.ubicacion_lat && this.registroSeleccionado.registro?.pedido.tienda.ubicacion_long) {

                    this.ubicacion.positionDestino.lat = parseFloat(this.registroSeleccionado.registro.pedido.tienda.ubicacion_lat);
                    this.ubicacion.positionDestino.lng = parseFloat(this.registroSeleccionado.registro.pedido.tienda.ubicacion_long);

                    this.imagenDomicilioComollegar = 'assets/img/redatwater/tiendas.png';

                    console.log('Ubicacion de inicio -> ', this.ubicacion);
                    this.iniciarMapa();

                  } else {
                    console.log('Seguimiento de errores -> ', this.registroSeleccionado.registro);
                  }

                } else if (this.registroSeleccionado.proceso === 'fin') {

                  if (this.registroSeleccionado.direccion) {

                    const direccionTexto = {
                      description: this.registroSeleccionado.direccion
                    };

                    this.geolocalizacion.consultarDireccionTexto(direccionTexto).then((getUbicacion: any) => {

                      if (getUbicacion) {

                        console.log('Seguimiento -> ', getUbicacion);

                        this.ubicacion.positionDestino.lat = getUbicacion.geometry.location.lat;
                        this.ubicacion.positionDestino.lng = getUbicacion.geometry.location.lng;

                        this.imagenDomicilioComollegar = 'assets/img/app_perfil.png';

                        console.log('Ubicacion de fin -> ', this.ubicacion);
                        this.iniciarMapa();


                      } else {
                        console.log('Seguimiento de errores -> ', getUbicacion);
                      }

                    }).catch((err: any) => {
                      console.log('Seguimiento de errores -> ', err);
                    });

                  } else {
                    console.log('Seguimiento de errores -> ', this.registroSeleccionado.direccion);
                  }

                } else {

                  console.log('Seguimiento de errores -> ', this.registroSeleccionado);

                }


              } else if (this.registroSeleccionado.tipo === 'domicilio_cajero') {
                console.log('Seguimiento -> ', this.registroSeleccionado);

                if (this.registroSeleccionado.proceso === 'inicio') {

                  if (this.registroSeleccionado.registro?.pedido.sistema_info_contacto.ubicacion_lat && this.registroSeleccionado.registro?.pedido.sistema_info_contacto.ubicacion_long) {

                    this.ubicacion.positionDestino.lat = parseFloat(this.registroSeleccionado.registro.pedido.sistema_info_contacto.ubicacion_lat);
                    this.ubicacion.positionDestino.lng = parseFloat(this.registroSeleccionado.registro.pedido.sistema_info_contacto.ubicacion_long);

                    this.imagenDomicilioComollegar = 'assets/img/sistemas/' + this.registroSeleccionado.registro.pedido.sistema_modelo_id + '.png';

                    console.log('Ubicacion de inicio -> ', this.ubicacion);
                    this.iniciarMapa();

                  } else {
                    console.log('Seguimiento de errores -> ', this.registroSeleccionado.registro);
                  }

                } else if (this.registroSeleccionado.proceso === 'fin') {

                  if (this.registroSeleccionado.direccion) {

                    const direccionTexto = {
                      description: this.registroSeleccionado.direccion
                    };

                    this.geolocalizacion.consultarDireccionTexto(direccionTexto).then((getUbicacion: any) => {

                      if (getUbicacion) {

                        console.log('Seguimiento -> ', getUbicacion);

                        this.ubicacion.positionDestino.lat = getUbicacion.geometry.location.lat;
                        this.ubicacion.positionDestino.lng = getUbicacion.geometry.location.lng;

                        this.imagenDomicilioComollegar = 'assets/img/app_perfil.png';

                        console.log('Ubicacion de fin -> ', this.ubicacion);
                        this.iniciarMapa();


                      } else {
                        console.log('Seguimiento de errores -> ', getUbicacion);
                      }

                    }).catch((err: any) => {
                      console.log('Seguimiento de errores -> ', err);
                    });

                  } else {
                    console.log('Seguimiento de errores -> ', this.registroSeleccionado.direccion);
                  }

                } else {

                  console.log('Seguimiento de errores -> ', this.registroSeleccionado);

                }

              }

            } else {
              this.translateConfigService.resetIdioma(this.idiomaPagina);
            }
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      } else {
        this.navCtrl?.navigateRoot(['/logout']);
      }
    });
  }

  async iniciarMapa() {
    const loading = await this.loadingCtrl.create({
      message: 'Cargando ubicación...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    this.googlemapsService.init(this.renderer, this.document).then(() => {
      this.appUbicacion.getUbicacionGPS().then((res) => {
        console.log('Get Localizacion ->', res);
        this.ubicacion.positionOrigen = {
          lat: res.lat,
          lng: res.lng
        }

        this.initMap();
        this.aquaBot = this.aquabot.appMsgAquaBot({
          msg: this.itemsIdioma.aquabot_msg_ubicacion_consultando,
          estado: 'text-primary'
        });
        this.consultarUbicacionActual(this.ubicacion.positionOrigen);
        this.consultarUbicacionDestino(this.ubicacion.positionDestino);

        loading.dismiss();
      }).catch((err) => {
        loading.dismiss();
        console.log('Algo salio mal: ' + err);
      });
    }).catch((err) => {
      loading.dismiss();
      this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ubicacion_activaubicacion);
      console.log(err);
    });
  }

  initMap() {

    console.log('Mapa Default ->', google);

    this.googleAutocomplete = new google.maps.places.AutocompleteService();
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer();

    let latLng = new google.maps.LatLng(this.ubicacion.positionOrigen.lat, this.ubicacion.positionOrigen.lng);

    let mapOptions = {
      center: latLng,
      zoom: 15,
      disableDefaultUI: false,
      clickableIcons: false,
    };

    this.map = new google.maps.Map(this.divMap.nativeElement, mapOptions);
    this.marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      icon: 'assets/img/marker.png',
      draggable: true
    });

    //this.clickHandleEvent();

    this.infowindow = new google.maps.InfoWindow();
    if (this.label.titulo.length) {
      this.addMarker(this.ubicacion.positionOrigen);
    }


  }


  clickHandleEvent() {
    this.map.addListener('click', (event: any) => {
      const position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      console.log('Click -> ', position);
      this.addMarker(position);
      //this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ubicacion_marker);
    })
  }

  async addMarker(position: any) {
    const loading = await this.loadingCtrl.create({
      message: 'Cargando ubicación...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    let latLng = new google.maps.LatLng(position.lat, position.lng);
    this.marker.setPosition(latLng);
    this.map.panTo(position);
    console.log('Posicion Inicial', position);
    this.ubicacion.positionOrigen = { // Ubicacion de origen
      lat: position.lat,
      lng: position.lng
    }
    if (this.ubicacion.positionDestino.lat !== null && this.ubicacion.positionOrigen.lng !== null) {
      this.calculateRoute();
      this.directionsDisplay.setMap(this.map); // Asociar Mapa   
    }
    loading.dismiss();

  }

  setInfoWindow(marker: any, titulo: string, subtitulo: string) {
    const contentString = '<div id="contentInsideMap>' +
      '<div>' +
      '</div>' +
      '<p style="font-weight: bold; margin-bottom: 5px;"' +
      '<div id="bodyContent">' +
      '<p class="normal m-0">' + subtitulo + '</p>' +
      '</div>' +
      '</div>';
    this.infowindow.setContent(contentString);
    this.infowindow.open(this.map, marker);
  }

  async consultarUbicacionActual(ubicacion: any) {

    console.log('Ubicacion ->', ubicacion);
    const loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    // this.ubicacionText.origen = ubicacion.description;
    //this.ubicacion.direccionB = this.ubicacionText.destino;
    const apiPath = this.apiUrl + '?key=' + environment.ApiKeyGoogleMaps + '&latlng=' + ubicacion.lat + ',' + ubicacion.lng;
    this.http.get(apiPath).subscribe((data: any) => {
      console.log('Peticion coordenadas() ->', data);
      this.ubicacionList.origen = true;
      this.ubicacionList.destino = true;
      if (data.status === 'OK') {
        loading.dismiss();
        const coordenadas = data.results[0].geometry.location;
        this.ubicacionText.origen = data.results[0].formatted_address;
        this.ubicacion.direccionA = this.ubicacionText.origen;
        console.log('Ubicacion de origen ->', this.ubicacionText.origen);
      } else {
        console.log('Seguimiento de errores -> ', data);
        this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdiomaApp.alertas_ubicacion_consultarubicacion);
        loading.dismiss();
      }
    }, (err: any) => {
      console.log('Seguimiento de errores -> HTTP: ', err);
    });

  }

  async consultarUbicacionDestino(ubicacion: any) {

    console.log('Ubicacion ->', ubicacion);
    const loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    // this.ubicacionText.destino = ubicacion.description;
    //this.ubicacion.direccionB = this.ubicacionText.destino;
    const apiPath = this.apiUrl + '?key=' + environment.ApiKeyGoogleMaps + '&latlng=' + ubicacion.lat + ',' + ubicacion.lng;
    this.http.get(apiPath).subscribe((data: any) => {
      console.log('Peticion coordenadas() ->', data);
      this.ubicacionList.origen = true;
      this.ubicacionList.destino = true;
      if (data.status === 'OK') {
        loading.dismiss();
        const coordenadas = data.results[0].geometry.location;
        this.ubicacionText.destino = data.results[0].formatted_address;
        this.ubicacion.direccionA = this.ubicacionText.destino;
        console.log('Ubicacion de destino ->', this.ubicacionText.destino);
      } else {
        console.log('Seguimiento de errores -> ', data);
        this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ubicacion_consultarubicacion_destino);
        loading.dismiss();
      }
    }, (err: any) => {
      console.log('Seguimiento de errores -> HTTP: ', err);
    });

  }

  private calculateRoute() {

    console.log('Seguimiento -> Calcular ruta: ', this.ubicacion);
    this.aquaBot = this.aquabot.appMsgAquaBot({
      msg: this.itemsIdioma.aquabot_msg_ubicacion_calcularruta,
      estado: 'text-primary'
    });

    this.directionsService.route({
      origin: this.ubicacion.positionOrigen,
      destination: this.ubicacion.positionDestino,
      //travelMode: google.maps.TravelMode.WALKING,
      travelMode: google.maps.TravelMode.DRIVING,
      provideRouteAlternatives: false,
      avoidHighways: true,
      region: 'co',
      /*
      drivingOptions: {
        trafficModel: 'bestguess'
      }
      */
    }, (response, status) => {
      console.log("Seguimiento -> ", response);
      this.dataDirectionsService = response;
      this.ubicacion.dataRuta = this.dataDirectionsService;
      if (this.dataDirectionsService.routes[0]?.legs[0]) {
        console.log('Verificar distancia -> ' + this.dataDirectionsService.routes[0].legs[0].distance.value);
        if (status === google.maps.DirectionsStatus.OK) {
          this.directionsDisplay.setDirections(response);
          let latLng = new google.maps.LatLng(this.ubicacion.positionOrigen.lat, this.ubicacion.positionOrigen.lng);
          this.marker.setPosition(latLng);
          //this.map.panTo(this.ubicacion.positionOrigen);
          console.log('Posicion Inicial', this.ubicacion.positionOrigen);
          this.aquaBot = this.aquabot.appMsgAquaBot({
            msg: this.itemsIdioma.aquabot_msg_ubicacion_rutacalculada,
            estado: 'text-success'
          });
        } else {
          alert('Could not display directions due to: ' + status);
        }
      } else {
        this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdioma.alertas_ubicacion_error);

      }
    });
  }


  seleccionarIdioma(idioma) {
    if (idioma) {
      //console.log('Seleccionar idioma -> ', idioma);
      this.selectedLanguage = idioma;
      //console.log('Idioma seleccionado: ' + this.selectedLanguage);
      this.languageChanged(this.selectedLanguage);
      this.idiomaSeleccionado = idioma;

      this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
        this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
          this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
          this.itemsIdioma = this.translateConfigService.getItemsIdioma();
          this.translateConfigService.getItemsIdioma();
          if (this.itemsIdiomaApp && this.itemsIdioma) {
            //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
            //console.log('Parametros de idioma -> ', this.itemsIdioma);
            //console.log('Test 2 -> ', this.itemsIdiomaApp.app_loading);

            this.textSpeech.hablar(this.itemsIdioma.hablar_app_intrucciones);
          } else {
            this.translateConfigService.resetIdioma(this.idiomaPagina);
          }
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }
  }


  languageChanged(idioma: string) {
    this.translateConfigService.setLanguage(idioma);
  }

  ngOnDestroy() {

  }

  async instruccionesApp(texto: String) {
    this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, ("<b><u>" + this.itemsIdioma.app_intrucciones + ":</u></b> <span class='text-success'>" + texto + "</span>"));
  }

  async cerrarVentana() {
    this.closeModal();
  }

  closeModal() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss({ comoLlegar: this.registroSeleccionado }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  async abrirGoogleMaps() {
    if (this.platform.is('capacitor')) {
      // Abrir en la aplicación de Google Maps si está instalada
      await Browser.open({ url: 'https://maps.google.com/maps?q=' + this.ubicacion.positionDestino.lat + ',' + this.ubicacion.positionDestino.lng });
    } else {
      // Abrir en el navegador si no está instalada la aplicación de Google Maps
      window.open('https://maps.google.com/maps?q=' + this.ubicacion.positionDestino.lat + ',' + this.ubicacion.positionDestino.lng, '_system');
    }
  }

  async abrirWaze() {
    if (this.platform.is('capacitor')) {
      // Abrir en la aplicación de Waze si está instalada
      await Browser.open({ url: 'https://waze.com/ul?ll=' + this.ubicacion.positionDestino.lat + ',' + this.ubicacion.positionDestino.lng + '&navigate=yes' });
    } else {
      // Abrir en el navegador si no está instalada la aplicación de Waze
      window.open('https://waze.com/ul?ll=' + this.ubicacion.positionDestino.lat + ',' + this.ubicacion.positionDestino.lng + '&navigate=yes', '_system');
    }
  }


}
