import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';

@Component({
  selector: 'app-datos-facturacion',
  templateUrl: './datos-facturacion.component.html',
  styleUrls: ['./datos-facturacion.component.scss'],
})
export class DatosFacturacionComponent implements OnInit, OnDestroy {

  authUsuario: any = null;
  cuentaUsuario: any = null;
  carteraFacturacion: any = null;
  carteraBanco: any = null;

  tipoView: any;

  facturacion = {
    tipo: 'factura',
    tipo_documento: 4,
    nombre: null,
    documento: null,
    celular: null,
    correo: null,
    direccion: null
  }

  banco = {
    banco_tipo: 'nacional',
    tipo_documento: 4,
    nombre: null,
    nombre_banco: null,
    documento: null,
    nro_banco: null,
    tipo_cuenta_banco: null
  }

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_CARTERA_FACTURACION';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  tipoNombre = 'RAZÓN SOCIAL';
  tipoDocumento = 'NIT';

  tipoNombreBanco = 'RAZÓN SOCIAL';
  tipoDocumentoBanco = 'NIT';

  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private utilitario: UtilitariosService,
    private alertCtr: AlertController,
    private modalController: ModalController,
  ) {
    console.log('App: Cartera Datos de facturación');
    this.header.pagina = '/';
    this.header.titulo = 'CARTERA';
    this.tipoView = 'facturacion';
  }

  ngOnInit() {
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {

              this.authUsuario = getAuth.data;
              this.infoCuenta();

            } else {
              console.log('Seguimiento de errores -> ', getAuth);
              this.cerrarVentana();
            }
          })


        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }


  seleccionarIdioma(idioma) {
    if (idioma) {
      //console.log('Seleccionar idioma -> ', idioma);
      this.selectedLanguage = idioma;
      //console.log('Idioma seleccionado: ' + this.selectedLanguage);
      this.languageChanged(this.selectedLanguage);
      this.idiomaSeleccionado = idioma;

      this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
        this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
          this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
          this.itemsIdioma = this.translateConfigService.getItemsIdioma();
          this.translateConfigService.getItemsIdioma();
          if (this.itemsIdiomaApp && this.itemsIdioma) {
            //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
            //console.log('Parametros de idioma -> ', this.itemsIdioma);
            //console.log('Test 2 -> ', this.itemsIdiomaApp.app_loading);

          } else {
            this.translateConfigService.resetIdioma(this.idiomaPagina);
          }
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }
  }

  languageChanged(idioma: string) {
    this.translateConfigService.setLanguage(idioma);
  }

  tipoViewChanged() {
    // Esta función se ejecutará cuando cambie el segmento
    if (this.tipoView === 'facturacion') {
      // Lógica para mostrar la vista de Datos de Facturación
      console.log('Vista de Datos de Facturación');
    } else if (this.tipoView === 'banco') {
      // Lógica para mostrar la vista de Datos de Banco
      console.log('Vista de Datos de Banco');
    }
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss();
  }

  async asociarFactura() {
    if (this.facturacion.tipo &&
      this.facturacion.tipo_documento &&
      this.facturacion.nombre &&
      this.facturacion.documento) {

      console.log('Asociar factura');
      const parametrosFacturacion = {
        tipo: this.facturacion.tipo,
        documento_tipo: this.facturacion.tipo_documento,
        nombre: this.facturacion.nombre,
        direccion: this.facturacion.direccion,
        celular: this.facturacion.celular,
        correo: this.facturacion.correo,
        documento: this.facturacion.documento
      }
      this.redatwater.addFacturacionCartera(parametrosFacturacion).then((getData: any) => {

        if (getData) {

          console.log('Seguimiento -> ', getData);
          this.infoCuenta();

        } else {
          console.log('Seguimiento de errores -> ', getData);
        }

      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });

    } else {
      console.log('Seguimiento de errores -> Parametros obligatorios para asociar factura');
    }
  }

  async asociarBanco() {
    if (this.banco.banco_tipo &&
      this.banco.tipo_documento &&
      this.banco.nombre &&
      this.banco.nro_banco &&
      this.banco.nombre_banco &&
      this.banco.tipo_cuenta_banco &&
      this.banco.documento) {

      console.log('Asociar banco', this.banco);
      const parametrosBanco = {
        banco_tipo: this.banco.banco_tipo,
        documento_tipo: this.banco.tipo_documento,
        nombre: this.banco.nombre,
        nombre_banco: this.banco.nombre_banco,
        documento: this.banco.documento,
        banco_nro_cuenta: this.banco.nro_banco,
        banco_tipo_cuenta: this.banco.tipo_cuenta_banco,
      }
      this.redatwater.addBancoCartera(parametrosBanco).then((getData: any) => {

        if (getData) {

          console.log('Seguimiento -> ', getData);
          this.infoCuenta();

        } else {
          console.log('Seguimiento de errores -> ', getData);
        }

      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });

    } else {
      console.log('Seguimiento de errores -> Parametros obligatorios para asociar banco');
    }
  }


  verificarDocumentoTipo(event) {
    if (event.detail.value) {

      if (event.detail.value === '4') {

        this.tipoNombre = 'Razón social';
        this.tipoDocumento = 'NIT';

      } else if (event.detail.value === '1') {
        this.tipoNombre = 'Nombre';
        this.tipoDocumento = 'Número de documento';
      } else {
        console.log('Seguimiento -> ', event);
        this.tipoNombre = 'Razón social';
        this.tipoDocumento = 'NIT';
      }

      console.log('Seguimiento Factura ->', this.tipoNombre, this.tipoDocumento);

    } else {
      console.log('Seguimiento de errores -> ', event);
    }
  }

  verificarDocumentoTipoBanco(event) {
    if (event.detail.value) {


      if (event.detail.value === '4') {

        this.tipoNombreBanco = 'Razón social';
        this.tipoDocumentoBanco = 'NIT';

      } else if (event.detail.value === '1') {

        this.tipoNombreBanco = 'Nombre';
        this.tipoDocumentoBanco = 'Número de documento';
      } else {
        console.log('Seguimiento -> ', event);
        this.tipoNombreBanco = 'Razón social';
        this.tipoDocumentoBanco = 'NIT';
      }

      console.log('Seguimiento Banco ->', this.tipoNombreBanco, this.tipoDocumentoBanco);

    } else {
      console.log('Seguimiento de errores -> ', event);
    }
  }

  async infoCuenta() {

    // LIMPIAR DATOS
    this.facturacion = {
      tipo: 'factura',
      tipo_documento: 4,
      nombre: null,
      documento: null,
      celular: null,
      correo: null,
      direccion: null
    }
  
    this.banco = {
      banco_tipo: 'nacional',
      tipo_documento: 4,
      nombre: null,
      nombre_banco: null,
      documento: null,
      nro_banco: null,
      tipo_cuenta_banco: null
    }

    this.redatwater.consultarFullCuentaUsuario().then((getData: any) => {
      if (getData) {

        console.log('Full data cuenta usuario -> ', getData);

        if (getData?.infoUsuario) {

          this.cuentaUsuario = getData.infoUsuario;

          if (getData?.cartera) {

            console.log('Seguimiento -> Cartera ', getData.infoUsuario.cartera);

            if (getData.cartera?.facturacion) {

              this.carteraFacturacion = getData.cartera.facturacion;

              this.facturacion.tipo = this.carteraFacturacion.tipo;
              this.facturacion.tipo_documento = this.carteraFacturacion.tipo_documento;
              this.facturacion.documento = this.carteraFacturacion.documento;
              this.facturacion.direccion = this.carteraFacturacion.direccion;
              this.facturacion.correo = this.carteraFacturacion.correo;
              this.facturacion.celular = this.carteraFacturacion.celular;
              this.facturacion.nombre = this.carteraFacturacion.nombre;

            } else {
              console.log('Seguimiento de errores -> ', getData);
            }

            if (getData.cartera?.banco) {

              this.carteraBanco = getData.cartera.banco;

              this.banco.banco_tipo = this.carteraBanco.tipo_banco;
              this.banco.tipo_documento = this.carteraBanco.tipo_documento;
              this.banco.documento = this.carteraBanco.nro_documento;
              this.banco.nombre = this.carteraBanco.nombre;
              this.banco.nombre_banco = this.carteraBanco.nombre_banco;
              this.banco.tipo_cuenta_banco = this.carteraBanco.banco_tipo_cuenta;
              this.banco.nro_banco = this.carteraBanco.banco_nro_cuenta;

            } else {
              console.log('Seguimiento de errores -> ', getData);
            }

          } else {
            console.log('Seguimiento de errores -> ', getData);
          }

        } else {
          console.log('Seguimiento de errores -> ', getData);
        }

      } else {
        console.log('Seguimiento de errores -> ', getData);
        this.cerrarVentana();
        //this.navCtrl?.navigateRoot(['/logout']);
      }

    }).catch((err: any) => {
      this.cerrarVentana();
      console.log('Seguimiento de errores -> ', err);
    });
  }

}
