import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { AgregarPedidoVendedorComponent } from '../pedidos-vendedores/agregar-pedido-vendedor/agregar-pedido-vendedor.component';
import { RecargarTarjetaClienteComponent } from './recargar-tarjeta-cliente/recargar-tarjeta-cliente.component';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-seleccionar-cliente',
  templateUrl: './seleccionar-cliente.component.html',
  styleUrls: ['./seleccionar-cliente.component.scss'],
})
export class SeleccionarClienteComponent implements OnInit, OnDestroy {

  authUsuario: any = null;

  cuentaUsuario: any = null;
  viewCuenta: boolean = false;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'SELECCIONAR_CLIENTE_USUARIO';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  defaultImage = 'assets/img/default.png';

  tipoDocumento = '';

  buscarCuenta: any = {
    tipo_documento: '',
    documento: ''
  }

  geolocalizacionUbicacionActual: any;


  viewTarjetaAquahabiente = false;
  infoTarjetaAquahabiente: any;
  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private ventanaAlerta: AlertService,
    private socialCompartir: SocialSharing,
    private loadingCtrl: LoadingController,
    private utilitario: UtilitariosService,
    private geolocalizacion: GeolocalizacionService,
    private alertCtr: AlertController,
    private alertController: AlertService,
    private modalController: ModalController,
  ) {
    console.log('App: Seleccionar cliente');
    this.header.pagina = '/redatwater-usuario';
    this.header.titulo = 'Buscar cliente';
  }

  ngOnInit() {

    this.buscarCuenta.tipo_documento = 'aquahabiente';
    this.tipoDocumento = 'Cuenta AQUAHABIENTE';

    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.loadAuthToken().then(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);

            if (getAuth) {

              this.authUsuario = getAuth;
              this.ubicacionActual()

            } else {
              console.log('Seguimiento de errores -> ', getAuth);
              this.cerrarVentana();
            }
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });


        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }


  seleccionarIdioma(idioma) {
    if (idioma) {
      //console.log('Seleccionar idioma -> ', idioma);
      this.selectedLanguage = idioma;
      //console.log('Idioma seleccionado: ' + this.selectedLanguage);
      this.languageChanged(this.selectedLanguage);
      this.idiomaSeleccionado = idioma;

      this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
        this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
          this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
          this.itemsIdioma = this.translateConfigService.getItemsIdioma();
          this.translateConfigService.getItemsIdioma();
          if (this.itemsIdiomaApp && this.itemsIdioma) {
            //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
            //console.log('Parametros de idioma -> ', this.itemsIdioma);
            //console.log('Test 2 -> ', this.itemsIdiomaApp.app_loading);

          } else {
            this.translateConfigService.resetIdioma(this.idiomaPagina);
          }
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }
  }

  languageChanged(idioma: string) {
    this.translateConfigService.setLanguage(idioma);
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss({ cliente: this.cuentaUsuario });
  }


  verificarDocumentoTipo(event) {
    if (event.detail.value) {

      if (event.detail.value === 'aquahabiente') {
        this.tipoDocumento = 'Cuenta AQUAHABIENTE';
      } else if (event.detail.value === '1') {
        this.tipoDocumento = 'Nro. ciudadania';
      } else if (event.detail.value === '2') {
        this.tipoDocumento = 'Nro. identidad';
      } else if (event.detail.value === '3') {
        this.tipoDocumento = 'Nro. extranjero';
      } else if (event.detail.value === '4') {
        this.tipoDocumento = 'Número de celular';
      } else if (event.detail.value === '5') {
        this.tipoDocumento = 'Nro. Pasaporte';
      } else {
        console.log('Seguimiento -> ', event);
        this.tipoDocumento = '';
        this.buscarCuenta.tipo_documento = '';
      }


    } else {
      console.log('Seguimiento de errores -> ', event);
    }
  }

  consultarCuenta() {
    if (this.buscarCuenta.tipo_documento, this.buscarCuenta.documento) {

      console.log('Consultar cuenta -> ', this.buscarCuenta);
      this.infoCuenta();

    } else {
      console.log('Seguimiento de errores ->', this.buscarCuenta);
    }
  }

  async infoCuenta() {

    if (this.buscarCuenta.tipo_documento && this.buscarCuenta.documento) {

      this.redatwater.consultarCuentaCliente(this.buscarCuenta).then((getData: any) => {
        if (getData) {

          console.log('Cuenta usuario -> ', getData);

          if (getData?.tipo) {

            this.cuentaUsuario = getData;
            this.viewCuenta = true;

          } else {
            console.log('Seguimiento de errores -> ', getData);
            this.viewCuenta = false;
          }

        } else {
          console.log('Seguimiento de errores -> ', getData);
          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Información incorrecta, Portal Transaccional.');
          this.viewCuenta = false;
        }

      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
        this.viewCuenta = false;
      });

    } else {
      console.log('Seguimiento de errores -> ', this.buscarCuenta);
      this.viewCuenta = false;
    }
  }


  async ubicacionActual() {
    this.geolocalizacion.loadUbicacionActual().then((ubicacionData: any) => {
      if (ubicacionData) {

        console.log('Ventana -> Ubicacion actual: ' + JSON.stringify(ubicacionData));

        this.geolocalizacionUbicacionActual = ubicacionData;

      }
    }).catch(error => {
      console.log('Ventana -> Error establecer ubicacion. ' + error);

    });
  }


  terminos() {
    console.log('Ventana -> Terminos ');

  }

  async tarjetaAquahabienteInfo() {
    console.log('Ventana -> Consultar tarjeta Aquahabiente...');
    this.redatwater.infoTarjetaAquahabiente().then((tarjeta: any) => {
      console.log('Cartera -> Tarjeta Aquahabiente: ' + JSON.stringify(tarjeta));
      this.viewTarjetaAquahabiente = true;
      this.infoTarjetaAquahabiente = tarjeta;
    }).catch(error => {
      console.log('Ventana -> Error consultar tarjeta Aquahabiente. ' + error);
      this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
    });
  }

  nuevaConsulta() {
    console.log('Nueva consulta');
    this.buscarCuenta.tipo_documento = '';
    this.buscarCuenta.documento = '';
    this.viewCuenta = false;
    // DEFAULT
    this.buscarCuenta.tipo_documento = 'aquahabiente';
    this.tipoDocumento = 'Cuenta AQUAHABIENTE';
  }

  recargarTarjetaCliente() {
    console.log('Seguimiento -> Ventana recarga tarjeta');
    this.viewRecargarTarjeta();
  }


  async viewRecargarTarjeta() {
    const cliente = this.cuentaUsuario;
    const modalAdd = await this.modalController.create({
      component: RecargarTarjetaClienteComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { cliente }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Seguimiento -> Data recargar tarjeta: ', data);
      this.consultarCuenta();
    }
  }


  registrarVentaCliente() {
    console.log('Seguimiento -> Ventana registrar venta');
    this.viewRegistrarVenta();
  }

  async viewRegistrarVenta() {
    if (this.geolocalizacionUbicacionActual?.coordenadas.lat) {
      const cliente = this.cuentaUsuario;
      const modalAdd = await this.modalController.create({
        component: AgregarPedidoVendedorComponent,
        mode: 'ios',
        swipeToClose: true,
        componentProps: { cliente }
      });
      await modalAdd.present();
      const { data } = await modalAdd.onWillDismiss();
      if (data) {
        console.log('Seguimiento -> Data registrar venta: ', data);
        this.consultarCuenta();
      }
    } else {
      console.log('Seguimiento de errores -> ', this.geolocalizacionUbicacionActual);
    }
  }

  conexionClientes(usuario: string, tipo: string) {
    console.log('Referir cuenta a web');
    /*
    const mensaje = ;
    const asunto = ;
    const imagen = ''; // URL de la imagen a compartir // referidos/auth/registrarse?c=10347990
    const enlace = ; // Enlace web
*/

    let tituloMensaje = '';
    let imagenMensaje = '';
    let asuntoMensaje = '';
    let enlaceMensaje = '';
    console.log('Seguimiento -> Tipo: ', usuario);

    tituloMensaje = `La hidratación es clave. Evita quedarte sin agua fresca. ¡Es hora de renovar tu suministro y mantener la frescura a tu alcance! Pide con  ${this.authUsuario.nombre} y disfruta de calidad ¡RED AT-WATER © Código de Red: ${this.authUsuario.codigo}! Haz tu pedido o compra ahora. Aquahabiente ${this.cuentaUsuario.cuenta.nombres}, Nro. Cuenta:  ${this.buscarCuenta.documento} `;
    imagenMensaje = 'https://bancodelagua.com/app/assets/templates/homepage/image/red_app_domicilio_personalizado_aquahabiente.jpg';
    asuntoMensaje = 'Compartido desde ® Banco del Agua';
    enlaceMensaje = 'https://bancodelagua.com/app/es/homepage/descargarAppPlaystore?tipo_app=bancodelagua';

    let parametrosData = {
      tituloMensaje: tituloMensaje,
      imagenMensaje: imagenMensaje,
      asuntoMensaje: asuntoMensaje,
      enlaceMensaje: enlaceMensaje
    };

    console.log('Parametros -> Compartir ', parametrosData);
    this.compartirHazConexiones(parametrosData);
  }

  async compartirHazConexiones(parametros: any) {
    if (parametros) {
      const loading = await this.loadingCtrl.create({
        message: 'Cargando...',
        spinner: 'crescent',
        showBackdrop: true
      });
      loading.present();

      this.socialCompartir.share(parametros.tituloMensaje, parametros.asuntoMensaje, parametros.imagenMensaje, parametros.enlaceMensaje)
        .then(() => {
          loading.dismiss();
          console.log('Compartido exitosamente: ', this.authUsuario);
        })
        .catch((error) => {
          loading.dismiss();
          console.error('Seguimiento de errores -> Error al compartir', error);
        });
    } else {
      console.log('Seguimiento de errores -> ', parametros);
    }

  }

}
