import { Injectable, OnInit } from '@angular/core';
import { Ciudad, Cupon, IAuth, Pais, Referido, Tarjeta, Ticket, Usuario } from '../interfaces/book.interface';
import { LoadingController, NavController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { ToastService } from './toast.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AlertService } from './alert.service';
import { Subscription } from 'rxjs';
import { GeolocalizacionService } from './geolocalizacion.service';
import { NotificacionesService } from './notificaciones.service';

@Injectable({
    providedIn: 'root'
})
export class RedatwaterService {

    private subscriptionHttp: Subscription = new Subscription();

    public webUrl = 'https://bancodelagua.com/app';
    public apiUrl = 'https://bancodelagua.com/channel'; // Canal de comunicacion

    public enlaceAppAtWaterAndroid = 'https://play.google.com/store/apps/details?id=com.bancodelagua.atwater';
    public enlaceAppAtWaterIOS = '';

    private key_empresa = 'aEU1b2Q1R3FwWTl1ZmtQS3JOM0ZTdz09';
    private httpHeaders: any;

    idRoot = '5bb6974925db51ea863ee0bbdd034323'; // App Banco del Agua

    paisSeleccionado: any = {
        id: 1,
        pais: 'colombia',
        divisa: 'COP',
        idioma: 'es',
        continente: '',
        precio_litro_agua: '',
        img: 'assets/img/paises/1.png'
    };

    peticionDatosAPI = '';

    ciudades: any;
    paises: any;

    precioLitroAgua = 0; // Precio del Agua Global

    domicilioGratis = {
        distancia: 2.0
    }

    tarifasDomicilio = {
        tarifa_base_tienda: 3000,
        tarifa_base_km: 1000,
        tarifa_base_km_mt3: 10000,
        tarifa_base_suministro_agua: 1000,
        tarifa_base_suministro_agua_mt3: 100000
    }

    /*
    tarifa_base_tienda: 3000,
    tarifa_base_km: 1000,
    tarifa_base_km_mt3: 10000,
    tarifa_base_suministro_agua: 1000,
    tarifa_base_suministro_agua_mt3: 100000
    */

    authUsuario: IAuth = null;
    usuarioData: any = null;

    channelMessage: any = [];
    channelFullMessages: any = [];

    cajerosCercanos: any = [];

    limiteCacheMessages: number = 1000;

    constructor(
        private http: HttpClient,
        private loadingCtrl: LoadingController,
        private alertController: AlertService,
        private auth: AuthService,
        private toast: ToastService,
        private geolocalizacion: GeolocalizacionService,
        private navCtrl: NavController
    ) {

        console.log('Canal de comunicacion...');
        this.verificarTokenApp();

        let paisActual = this.auth.loadAppConfig().then((appData: any) => {
            if (appData?.pais) {
                console.log('Sesion de App Config -> ', appData);
                this.setPaisSeleccionado(appData.pais);
            } else {
                console.log('Seguimiento de errores -> ', appData);
            }
        }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
        });

    }

    async verificarTokenApp() {
        this.peticionDatosAPI = '?pass=' + this.idRoot + '&empresa=' + this.key_empresa + '&pais=' + this.paisSeleccionado.id;
        // Consultar Auth

        // Suscribirse al BehaviorSubject para obtener las actualizaciones del usuario autenticado
        this.auth.authUsuario$.subscribe((getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {
                this.setAuthUsuario(getAuth.data);
                //this.auth.setUpdateAuth(dataUsuario);
                this.authUsuario = this.getAuthUsuario();

                this.authTokenApp(this.authUsuario).then((data: any) => {

                    console.log('Auth token App -> ', data);
                    this.usuarioData = data.info;

                }).catch(error => {
                    console.log('Api -> Error usuario info. ' + error);
                });
            } else {
                console.log('Seguimiento de errores -> Auth Usuario ', getAuth);
                this.auth.loadAuthToken().then(async (getUsuario: any) => {
                    //console.log('Dispositivo Auth ->', getUsuario);
                    if (getUsuario) {

                        this.auth.setUpdateAuth(getUsuario);
                        this.setAuthUsuario(getUsuario);

                    } else {
                        console.log('Seguimiento de errores -> total auth ', getUsuario);
                    }
                });
            }
        });

        // Llamar al método para obtener el usuario autenticado cuando la aplicación se inicia
        this.auth.getAuthUsuarioObservable('app_auth').subscribe();

    }

    getEnlaceAppAtWaterAndroid() {
        return this.enlaceAppAtWaterAndroid;
    }

    getEnlaceAppAtWaterIOS() {
        return this.enlaceAppAtWaterIOS;
    }

    setAuthUsuario(auth: any) {
        if (auth?.id) {
            this.authUsuario = auth;
        }
    }

    getAuthUsuario() {
        return this.authUsuario;
    }

    getTarifasDomicilio() {
        return this.tarifasDomicilio;
    }

    setChannelMessages(msg: any) {
        if (msg) {

            this.channelMessage = msg;
            this.channelMessage.tipo = 'redatwater';
            this.channelMessage.fecha = Date.now();

            if (this.channelFullMessages.length >= this.limiteCacheMessages) {
                this.channelFullMessages = [];
                console.log('Seguimiento -> Limpiando memoria cache de mensajes...');
            }
            this.channelFullMessages.push(this.channelMessage);
        }
    }

    getChannelMessage() {
        return this.channelMessage;
    }

    getFullMensajes() {
        return this.channelFullMessages;
    }

    getWebUrl() {
        return this.webUrl;
    }

    getPrecioLitroAgua() {
        return this.precioLitroAgua;
    }

    setPrecioLitroAgua(precio: any) {
        if (precio > 0) {
            console.log('Estableciendo precio de agua -> ', precio);
            this.precioLitroAgua = precio;
        }
    }

    getDomicilioDistanciaGratis() {
        return this.domicilioGratis.distancia;
    }

    setPaisSeleccionado(pais: any) {
        console.log('Seguimiento -> Establecer pais. ', pais);
        if (pais.id >= 1) {
            this.paisSeleccionado = pais;
        }
    }

    getPaisSeleccionado() {
        return this.paisSeleccionado;
    }

    getIdRoot() {
        return this.idRoot;
    }

    async listaPaises(): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando paises...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {
            /** 
             * En este caso, obtengo el resultado de otra función para usarlo
             * dentro del cuerpo de mi promesa
             */

            const apiPath = this.apiUrl + '/bancodelagua/getListaPaises' + this.peticionDatosAPI;
            this.paises = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    if (data) {

                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            this.toast.msgAppMultiple(messages, 'bottom');
                        }

                        console.log('Peticion -> lista de paises: ', data);
                        // Lista de paises 
                        this.paises = data.respuesta.data.lista;
                        //console.log('Listado de pais -> ' + this.paises);

                        if (this.paises) {
                            resolve(this.paises);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );


        });

    }

    async aquabotAsistente(tipo: string, message: string) {
        const loading = await this.loadingCtrl.create({
            message: 'Aquabot...',
            spinner: 'crescent',
            showBackdrop: true
        });
        //loading.present();

        return new Promise((resolve, reject) => {

            const apiPath = this.apiUrl + '/aquabot/asistenteAquabot' + this.peticionDatosAPI + '&onAquabot=2&aquabot_tipo=' + tipo + '&aquabot_message=' + message;
            this.paises = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    if (data) {
                        /*
                                                if (data.respuesta.data.msg) {
                                                    const messages = data.respuesta.data.msg;
                                                    this.setChannelMessages(messages);
                                                    this.toast.msgAppMultiple(messages, 'bottom');
                                                }
                        */
                        console.log('Peticion -> AQUABOT: ', data);
                        if (data?.aquabot) {
                            if (data.aquabot) {
                                resolve(data);
                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(false);
                        }

                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );


        });
    }

    async listaCiudades(pais: any): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando ciudades...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {
            /** 
             * En este caso, obtengo el resultado de otra función para usarlo
             * dentro del cuerpo de mi promesa
             */



            const parametrosPeticion = {
                pais: pais
            }

            const apiPath = this.apiUrl + '/bancodelagua/getListaCiudades' + this.peticionDatosAPI;
            //this.httpHeaders = this.httpHeaders.append('pais', parametrosPeticion.pais);
            this.ciudades = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    if (data) {

                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            this.toast.msgAppMultiple(messages, 'bottom');
                        }

                        console.log('Peticion -> lista de ciudades: ', data);
                        // Lista de ciudades 
                        this.ciudades = data.respuesta.data.lista;
                        //console.log('Listado de ciudad -> ' + this.ciudades);

                        if (this.ciudades) {

                            resolve(this.ciudades);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );


        });

    }

    async loadAuthUsuario(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.authUsuario) {
                resolve(this.authUsuario);
            } else {
                resolve(false);
            }
        });
    }

    async loadInfoUsuario(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.usuarioData) {
                resolve(this.usuarioData);
            } else {
                resolve(false);
            }
        });
    }

    // Verificar Token App
    async authTokenApp(auth: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (auth.id) {
                this.geolocalizacion.loadUbicacionActual().then((ubicacionData: any) => {
                    if (ubicacionData) {
                        console.log('Auth Token App -> Ubicacion actual: ' + JSON.stringify(ubicacionData));
                        if (ubicacionData?.coordenadas) {

                            this.checkTokenApp(auth, ubicacionData.coordenadas).then((tokenApp: any) => {
                                if (tokenApp) {

                                    //console.log('Token App -> Verificado! ' + JSON.stringify(tokenApp));
                                    resolve(tokenApp);
                                } else {
                                    console.log('Auth -> Cerrando sesion... ');
                                    this.navCtrl?.navigateRoot(['/logout']);
                                    //resolve(false);

                                }
                            }).catch(error => {
                                console.log('Auth -> Error verificar token App. ' + error);
                                reject(error);
                            });
                        } else {
                            console.log('Seguimiento de errores -> Establecer ubicacion.');
                        }
                    }
                }).catch(error => {
                    console.log('Seguimiento -> Error establecer ubicacion. ' + error);
                    resolve(false);
                });
            }
        });
    }

    // Verificar documento disponible
    async guardarTokenNotificacion(token: any, dispositivo: any, auth: any): Promise<any> {

        return new Promise((resolve, reject) => {
            const apiPath = this.apiUrl + '/bancodelagua/tokenNotificacionApp' + this.peticionDatosAPI + '&token=' + JSON.stringify(token) + '&us=' + auth.usuario + '&ui=' + auth.id + '&movil=' + dispositivo;
            //console.log('Peticion notificacion, ', apiPath);
            const checkToken = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {

                    if (data) {

                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            this.toast.msgAppMultiple(messages, 'bottom');
                        }

                        if (data.respuesta.data.cuenta) {

                            resolve(data.respuesta.data.cuenta);

                        } else {
                            resolve(false);
                        }
                    }


                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                })
            );


        });

    }

    // Verificar documento disponible
    async checkDocumento(tipo: number, documento: any, referido: boolean): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando documento...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {
            const apiPath = this.apiUrl + '/bancodelagua/getCheckDocumento' + this.peticionDatosAPI + '&do=' + documento + '&ti=' + tipo + '&re=' + referido;
            const checkDocumento = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    //console.log('Peticion -> Check Documento: ', data);

                    if (data) {

                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            this.toast.msgAppMultiple(messages, 'bottom');
                        }

                        if (data.respuesta.data.existe) {

                            resolve(data.respuesta.data.existe);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );


        });

    }

    // Registro de usuario
    async getRegistroUsuario(usuario: Usuario, tarjeta: Tarjeta, ticket?: Ticket, cupon?: Cupon, referido?: Referido): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Procesando registro...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        //console.log('Usuario ->' + usuario);
        //console.log('Tarjeta ->' + tarjeta);
        // EXTERNO
        //console.log('Referido ->' + referido);
        //console.log('Cupon ->' + cupon);
        //console.log('Ticket ->' + ticket);

        const apiPath = this.apiUrl + '/bancodelagua/registroUsuario' + this.peticionDatosAPI;
        const parametrosPeticion = {
            us: {
                perfil_tipo_cuenta: usuario.tipo_cuenta,
                perfil_nombres: usuario.nombres,
                perfil_apellidos: usuario.apellidos,
                perfil_email: usuario.email,
                perfil_tipo_documento_id: usuario.tipo_documento_id,
                perfil_numero_documento: usuario.numero_documento,
                //perfil_fecha_nacimiento: usuario.fecha_nacimiento,
                perfil_ciudad: usuario.ciudad,
                perfil_empresa: usuario.empresa,
                perfil_direccion: usuario.direccion,
                perfil_sexo: usuario.sexo,
                perfil_celular: usuario.celular
            },
            ta: {
                clave: tarjeta.clave,
            },
            bo: {
                codigo: 0,
                clave: 0,
                email: ''
            },
            cu: {
                codigo: 0,
                clave: 0,
                email: ''
            },
            re: {
                codigo: 0
            }
        }

        if (ticket) {
            if (ticket.codigo > 0 &&
                ticket.clave > 0 &&
                ticket.email) { // Check Data Ticket
                parametrosPeticion.bo.codigo = ticket.codigo;
                parametrosPeticion.bo.clave = ticket.clave;
                parametrosPeticion.bo.email = ticket.email;
            }
        }

        if (cupon) {
            if (cupon.codigo > 0 &&
                cupon.clave > 0 &&
                cupon.email) { // Check Data Cupon
                parametrosPeticion.cu.codigo = cupon.codigo;
                parametrosPeticion.cu.clave = cupon.clave;
                parametrosPeticion.cu.email = cupon.email;
            }
        }

        if (referido) {
            if (referido.codigo > 0) { // Check Data Referido
                parametrosPeticion.re.codigo = referido.codigo;
            }
        }

        console.log('Data -> ' + parametrosPeticion);

        return new Promise((resolve, reject) => {

            this.httpHeaders = new HttpParams();
            this.httpHeaders = this.httpHeaders.append('perfil_tipo_cuenta', parametrosPeticion.us.perfil_tipo_cuenta);
            this.httpHeaders = this.httpHeaders.append('perfil_nombres', parametrosPeticion.us.perfil_nombres);
            this.httpHeaders = this.httpHeaders.append('perfil_apellidos', parametrosPeticion.us.perfil_apellidos);
            this.httpHeaders = this.httpHeaders.append('perfil_email', parametrosPeticion.us.perfil_email);
            this.httpHeaders = this.httpHeaders.append('perfil_tipo_documento', parametrosPeticion.us.perfil_tipo_documento_id);
            this.httpHeaders = this.httpHeaders.append('perfil_numero_documento', parametrosPeticion.us.perfil_numero_documento);
            this.httpHeaders = this.httpHeaders.append('perfil_sexo', parametrosPeticion.us.perfil_sexo);
            this.httpHeaders = this.httpHeaders.append('perfil_ciudad', parametrosPeticion.us.perfil_ciudad);
            this.httpHeaders = this.httpHeaders.append('perfil_celular', parametrosPeticion.us.perfil_celular);
            this.httpHeaders = this.httpHeaders.append('perfil_direccion', parametrosPeticion.us.perfil_direccion);
            this.httpHeaders = this.httpHeaders.append('perfil_empresa', parametrosPeticion.us.perfil_empresa);
            //this.httpHeaders = this.httpHeaders.append('perfil_fecha_nacimiento', parametrosPeticion.us.perfil_fecha_nacimiento);

            this.httpHeaders = this.httpHeaders.append('tarjeta_clave', parametrosPeticion.ta.clave);

            if (ticket) {
                if (parametrosPeticion.bo.codigo > 0 &&
                    parametrosPeticion.bo.clave > 0 &&
                    parametrosPeticion.bo.email) { // Check Data Ticket
                    this.httpHeaders = this.httpHeaders.append('ticket_codigo', parametrosPeticion.bo.codigo);
                    this.httpHeaders = this.httpHeaders.append('ticket_clave', parametrosPeticion.bo.clave);
                    this.httpHeaders = this.httpHeaders.append('ticket_email', parametrosPeticion.bo.email);
                }
            }

            if (cupon) {
                if (parametrosPeticion.cu.codigo > 0 &&
                    parametrosPeticion.cu.clave > 0 &&
                    parametrosPeticion.cu.email) { // Check Data Cupon
                    this.httpHeaders = this.httpHeaders.append('cupon_codigo', parametrosPeticion.cu.codigo);
                    this.httpHeaders = this.httpHeaders.append('cupon_clave', parametrosPeticion.cu.clave);
                    this.httpHeaders = this.httpHeaders.append('cupon_email', parametrosPeticion.cu.email);
                }
            }

            if (referido) {
                if (parametrosPeticion.re.codigo > 0) { // Check Data 
                    this.httpHeaders = this.httpHeaders.append('rerido_codigo', parametrosPeticion.re.codigo);
                }
            }

            const addUsuario = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {

                    //console.log('Peticion -> Registro de usuario: ', data);
                    if (data) {
                        if (data.respuesta) {
                            if (data.respuesta.data) {
                                if (data.respuesta.data.msg) {
                                    const messages = data.respuesta.data.msg;
                                    this.setChannelMessages(messages);
                                    this.toast.msgAppMultiple(messages, 'bottom');
                                }
                                resolve(data.respuesta.data);
                            } else {
                                resolve(false);
                            }
                        }
                    }

                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });

    }

    async authToken(codigo: any, clave: any) {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando token ...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        //console.log('Usuario ->' + usuario);
        //console.log('Tarjeta ->' + tarjeta);
        // EXTERNO
        //console.log('Referido ->' + referido);
        //console.log('Cupon ->' + cupon);
        //console.log('Ticket ->' + ticket);

        const apiPath = this.apiUrl + '/bancodelagua/getAuthToken' + this.peticionDatosAPI;
        const parametrosPeticion = {
            codigo: codigo,
            clave: clave,
        }


        console.log('Data -> ' + parametrosPeticion);

        return new Promise((resolve, reject) => {

            this.httpHeaders = new HttpParams();
            this.httpHeaders = this.httpHeaders.append('co', parametrosPeticion.codigo);
            this.httpHeaders = this.httpHeaders.append('cl', parametrosPeticion.clave);

            const addUsuario = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {

                    //console.log('Peticion -> Auth token: ', data);
                    if (data) {
                        if (data.respuesta) {
                            if (data.respuesta.data) {

                                if (data.respuesta.data.msg) {
                                    const messages = data.respuesta.data.msg;
                                    this.setChannelMessages(messages);
                                    this.toast.msgAppMultiple(messages, 'bottom');
                                }

                                resolve(data.respuesta.data.auth);

                            } else {
                                resolve(false);
                            }
                        }
                    }

                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });
    }

    // Verificar codigo cuenta
    async checkCodigoCuenta(codigo: number): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando código...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {
            const apiPath = this.apiUrl + '/bancodelagua/getCheckCodigo' + this.peticionDatosAPI + '&co=' + codigo;
            const checkCodigo = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    //console.log('Peticion -> Check Codigo Cuenta: ', data);

                    if (data) {

                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            this.toast.msgAppMultiple(messages, 'bottom');
                        }

                        if (data.respuesta.data.existe) {

                            resolve(data.respuesta.data.existe);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });

    }

    getCajerosCercanos() {
        return this.cajerosCercanos;
    }

    setCajerosCercanos(cajeros: any) {
        if (cajeros) {
            this.cajerosCercanos = cajeros;
        } else {
            console.log('Seguimiento de errores -> ', cajeros);
        }
    }

    // Verificar Token App
    async checkTokenApp(auth: IAuth, ubicacion: any): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando Token de seguridad...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        if (ubicacion.lat && ubicacion.lng) {

            return new Promise((resolve, reject) => {

                const apiPath = this.apiUrl + '/bancodelagua/getCheckToken' + this.peticionDatosAPI + '&to=' + auth.app_token + '&us=' + auth.usuario + '&ui=' + auth.usuario_id + '&lat=' + ubicacion.lat + '&lng=' + ubicacion.lng;
                const checkTokenApp = this.subscriptionHttp.add(
                    this.http.get(apiPath).subscribe((data: any) => {
                        //console.log('Peticion -> Check Token App: ', data);

                        if (data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data?.lista_cobertura_cajeros_atwater) {
                                this.setCajerosCercanos(data.respuesta.data.lista_cobertura_cajeros_atwater);
                            }

                            if (data.respuesta.data.auth?.auth) {

                                if (data.respuesta.data.auth.info?.ciudad_precio_litro_agua) {
                                    this.setPrecioLitroAgua(parseInt(data.respuesta.data.auth.info.ciudad_precio_litro_agua));
                                }

                                resolve(data.respuesta.data.auth);
                            } else {
                                resolve(false);
                                this.navCtrl?.navigateRoot(['/logout']);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', ubicacion);
            loading.dismiss();
        }

    }


    // Verificar información full de usuario
    //https://bancodelagua.com/channel/bancodelagua/getFullCuentaUsuario?pass=&empresa=&us=cliente&u_id=
    async consultarFullCuentaUsuario(): Promise<any> {
        if (this.authUsuario?.usuario) {


            return new Promise((resolve, reject) => {
                const apiPath = this.apiUrl + '/bancodelagua/getFullCuentaUsuario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const checkCodigo = this.subscriptionHttp.add(
                    this.http.get(apiPath).subscribe((data: any) => {
                        //console.log('Peticion -> Direcciones: ', data);
                        if (data.respuesta?.data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.cuenta) {

                                resolve(data.respuesta.data.cuenta);
                            } else {
                                resolve(false);
                            }
                        }
                        // loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        // loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }

    }


    // Gestionar notificaciones de usuario
    //https://bancodelagua.com/channel/bancodelagua/getEditNotificaciones?pass=&empresa=&us=cliente&u_id=
    async gestionarNotificaciones(reg_id: any, tipo: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Verificando información...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('reg_id', reg_id);
                this.httpHeaders = this.httpHeaders.append('tipo', tipo);

                const apiPath = this.apiUrl + '/bancodelagua/getEditNotificaciones' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const checkCodigo = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        console.log('Peticion -> Notificaciones: ', data);

                        if (data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.app) {

                                resolve(data.respuesta.data.app);

                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(false);
                        }

                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores ->', this.authUsuario);
        }
    }

    // Gestionar mensajes de usuario
    //https://bancodelagua.com/channel/bancodelagua/getEditMensajes?pass=&empresa=&us=cliente&u_id=
    async gestionarMensajes(reg_id: any, tipo: any, tipo_mensaje: any): Promise<any> {
        if (this.authUsuario?.usuario) {

            const loading = await this.loadingCtrl.create({
                message: 'Verificando información...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('reg_id', reg_id);
                this.httpHeaders = this.httpHeaders.append('tipo', tipo);
                this.httpHeaders = this.httpHeaders.append('tipo_mensaje', tipo_mensaje);

                const apiPath = this.apiUrl + '/bancodelagua/getEditMensajes' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const checkCodigo = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        console.log('Peticion -> Mensajes: ', data);

                        if (data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.app) {

                                resolve(data.respuesta.data.app);

                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(false);
                        }

                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }

    }

    // Verificar direcciones de usuario
    //https://bancodelagua.com/channel/bancodelagua/getDirecciones?pass=&empresa=&us=cliente&u_id=
    async consultarDireccionesUsuario(): Promise<any> {

        if (this.authUsuario?.usuario) {

            const loading = await this.loadingCtrl.create({
                message: 'Verificando direcciones...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {
                const apiPath = this.apiUrl + '/bancodelagua/getDirecciones' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id;
                const checkCodigo = this.subscriptionHttp.add(
                    this.http.get(apiPath).subscribe((data: any) => {
                        //console.log('Peticion -> Direcciones: ', data);
                        if (data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.app) {

                                resolve(data.respuesta.data.app);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // Guardar direccion
    async guardarDireccion(parametros: any) {
        if (parametros) {
            const loading = await this.loadingCtrl.create({
                message: 'Agregando a favoritos...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            if (parametros.lat && parametros.lng) {

                //console.log('Parametros ->' + parametros);

                const apiPath = this.apiUrl + '/bancodelagua/addDireccion' + this.peticionDatosAPI;
                const parametrosPeticion = {
                    us: this.authUsuario.usuario,
                    u_id: this.authUsuario.usuario_id,
                    ti: parametros.titulo,
                    di: parametros.direccion,
                    lat: parametros.lat,
                    long: parametros.lng,
                }

                console.log('Data -> ' + parametrosPeticion);

                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();
                    this.httpHeaders = this.httpHeaders.append('us', parametrosPeticion.us);
                    this.httpHeaders = this.httpHeaders.append('u_id', parametrosPeticion.u_id);
                    this.httpHeaders = this.httpHeaders.append('ti', parametrosPeticion.ti);
                    this.httpHeaders = this.httpHeaders.append('di', parametrosPeticion.di);
                    this.httpHeaders = this.httpHeaders.append('lat', parametrosPeticion.lat);
                    this.httpHeaders = this.httpHeaders.append('long', parametrosPeticion.long);

                    const addUsuario = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {

                            //console.log('Peticion -> Agregar direccion: ', data);
                            if (data) {
                                if (data.respuesta) {
                                    if (data.respuesta.data) {

                                        if (data.respuesta.data.msg) {
                                            const messages = data.respuesta.data.msg;
                                            this.setChannelMessages(messages);
                                            this.toast.msgAppMultiple(messages, 'bottom');
                                        }

                                        resolve(data.respuesta.data.app);

                                    } else {
                                        resolve(false);
                                    }
                                }
                            }

                            loading.dismiss();

                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);
                            loading.dismiss();
                        })
                    );

                });

            } else {
                console.log('Seguimiento de errores -> ', parametros);
                loading.dismiss();
            }
        }
    }


    // PubliAqua Full Anuncios
    //https://bancodelagua.com/channel/bancodelagua/getPubliaquaFullPublicaciones?pass=&empresa=&pais=
    async consultarAnunciosFull(): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Cargando...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {

            const apiPath = this.apiUrl + '/bancodelagua/getPubliaquaFullPublicaciones' + this.peticionDatosAPI;
            const listaAnuncios = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    //console.log('Peticion -> Full Anuncios PubliAqua: ', data);

                    if (data) {
                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }
                        if (data.respuesta.data.publiaqua) {

                            resolve(data.respuesta.data.publiaqua);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });
    }

    // PubliAqua Videos Youtube Full Anuncios
    //https://bancodelagua.com/channel/bancodelagua/getPubliaquaFullPublicaciones?pass=&empresa=&pais=
    async consultarAnunciosFullYoutube(): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Cargando...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {

            const apiPath = this.apiUrl + '/bancodelagua/getPubliaquaFullYoutubePublicaciones' + this.peticionDatosAPI;
            const listaAnuncios = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    //console.log('Peticion -> Full Anuncios PubliAqua: ', data);

                    if (data) {
                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }
                        if (data.respuesta.data.publiaqua) {

                            resolve(data.respuesta.data.publiaqua);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });
    }

    // Temas de interes Full / Blog
    //https://bancodelagua.com/channel/bancodelagua/getInteresFullPublicaciones?pass=&empresa=&pais=
    async consultarTemasInteresFull(busqueda?: any): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Cargando...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {

            const apiPath = this.apiUrl + '/bancodelagua/getInteresFullPublicaciones' + this.peticionDatosAPI;
            this.httpHeaders = new HttpParams();
            this.httpHeaders = this.httpHeaders.append('bus', busqueda);

            const listaTemas = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                    //console.log('Peticion -> Full Temas de Interes: ', data);

                    if (data) {
                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }
                        if (data.respuesta.data?.interes) {

                            resolve(data.respuesta.data.interes);

                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });
    }

    // Temas de interes Destacados / Blog
    //https://bancodelagua.com/channel/bancodelagua/getInteresFullPublicaciones?pass=&empresa=&pais=
    async consultarTemasInteresDestacados(cantidad: number): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Cargando...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {

            const apiPath = this.apiUrl + '/bancodelagua/getInteresDestacadosPublicaciones' + this.peticionDatosAPI;
            this.httpHeaders = new HttpParams();
            this.httpHeaders = this.httpHeaders.append('cant', cantidad);

            const listaTemas = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                    //console.log('Peticion -> Destacados Temas de Interes: ', data);

                    if (data) {
                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }
                        if (data.respuesta.data?.interes) {

                            resolve(data.respuesta.data.interes);

                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });
    }

    // Productos de Tienda virtual oficial destacados
    //https://bancodelagua.com/channel/bancodelagua/getProdutosDestacados?pass=&empresa=
    async productosDestacados(): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Productos destacados...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {
            const apiPath = this.apiUrl + '/bancodelagua/getProdutosDestacados' + this.peticionDatosAPI;
            const listaProductos = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    //console.log('Peticion ->  Productos destacados: ', data);
                    if (data) {
                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }
                        if (data.respuesta.data.tienda_virtual) {

                            resolve(data.respuesta.data.tienda_virtual);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });

    }


    // Productos de Tienda virtual oficial de sachets
    //https://bancodelagua.com/channel/bancodelagua/getProductosSachets?pass=&empresa=
    async productosSachets(): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Cargando...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {

            const apiPath = this.apiUrl + '/bancodelagua/getProductosSachets' + this.peticionDatosAPI;
            const listaProductos = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    //console.log('Peticion ->  Productos sachets: ', data);
                    if (data) {
                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }
                        if (data.respuesta.data.tienda_virtual) {

                            resolve(data.respuesta.data.tienda_virtual);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });

    }

    // Productos de Tienda virtual oficial de tapas
    //https://bancodelagua.com/channel/bancodelagua/getProductosTapas?pass=&empresa=
    async productosTapas(): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Cargando...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {
            const apiPath = this.apiUrl + '/bancodelagua/getProductosTapas' + this.peticionDatosAPI;
            const listaProductos = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    //console.log('Peticion ->  Productos tapas: ', data);
                    if (data) {
                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }
                        if (data.respuesta.data.tienda_virtual) {
                            resolve(data.respuesta.data.tienda_virtual);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });

    }

    // DISPONIBILIDAD DE USUARIO / APP
    //https://bancodelagua.com/channel/bancodelagua_usuario/getConfirmarDisponibilidad?pass=&empresa=&us=cliente&u_id=
    async confirmarDisponibilidadUsuario(): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getConfirmarDisponibilidad' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const dataUsuario = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Confirmar disponibilidad usuario: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.cuenta_disponible) {

                                resolve(data.respuesta.data.cuenta_disponible);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // Tiendas de agua cercanas
    //https://bancodelagua.com/channel/bancodelagua/getTiendasCercanas?pass=&empresa=&pais=1&lat=&lng=
    async tiendasAguaCercanas(geolocalizacion: any, limit: any): Promise<any> {
        if (geolocalizacion?.latitud && geolocalizacion?.longitud) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando tiendas...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {
                const apiPath = this.apiUrl + '/bancodelagua/getTiendasAguaCercanas' + this.peticionDatosAPI + '&limit=' + limit + '&lat=' + geolocalizacion.latitud + '&lng=' + geolocalizacion.longitud;
                const listaProductos = this.subscriptionHttp.add(
                    this.http.get(apiPath).subscribe((data: any) => {
                        console.log('Peticion ->  Tiendas cercanas: ', data);
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.tiendas) {
                                resolve(data.respuesta.data.tiendas);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        }
    }

    async saveTarjetaWompi(token: any, nombre: any) {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando token ...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        //console.log('Usuario ->' + usuario);
        //console.log('Tarjeta ->' + tarjeta);

        const apiPath = this.apiUrl + '/bancodelagua/getSaveTarjeta' + this.peticionDatosAPI;
        const parametrosPeticion = {
            usuario: this.authUsuario.usuario,
            usuario_id: this.authUsuario.usuario_id,
            token_app: this.authUsuario.app_token,
            token: token,
            pasarela: 'wompi',
            nombre: nombre,
        }

        //console.log('Data -> ' + parametrosPeticion);

        return new Promise((resolve, reject) => {

            this.httpHeaders = new HttpParams();
            this.httpHeaders = this.httpHeaders.append('to', parametrosPeticion.token);
            this.httpHeaders = this.httpHeaders.append('no', parametrosPeticion.nombre);
            this.httpHeaders = this.httpHeaders.append('us', parametrosPeticion.usuario);
            this.httpHeaders = this.httpHeaders.append('u_id', parametrosPeticion.usuario_id);
            this.httpHeaders = this.httpHeaders.append('pas', parametrosPeticion.pasarela);
            this.httpHeaders = this.httpHeaders.append('t_app', parametrosPeticion.token_app);

            const addUsuario = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {

                    //console.log('Peticion -> Guardar tarjeta: ', data);
                    if (data) {
                        if (data.respuesta) {
                            if (data.respuesta.data) {

                                if (data.respuesta.data.msg) {
                                    const messages = data.respuesta.data.msg;
                                    this.setChannelMessages(messages);
                                    this.toast.msgAppMultiple(messages, 'bottom');
                                }

                                resolve(data.respuesta.data.cartera);

                            } else {
                                resolve(false);
                            }
                        }
                    }

                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });
    }


    // Cartera => Tarjetas
    //https://bancodelagua.com/channel/bancodelagua/getCarteraTarjetas?pass=&empresa=&pais=1&us=cliente&u_id=&t_app=
    async carteraTarjetas(): Promise<any> {
        if (this.authUsuario?.usuario) {

            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {
                const apiPath = this.apiUrl + '/bancodelagua/getCarteraTarjetas' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath).subscribe((data: any) => {
                        //console.log('Peticion ->  Cartera tarjetas: ', JSON.stringify(data));
                        if (data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.cartera) {

                                resolve(data.respuesta.data.cartera);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // App => Tarjeta aquahabiente
    //https://bancodelagua.com/channel/bancodelagua/getTarjetaAquahabiente?pass=&empresa=&pais=1&us=cliente&u_id=&t_app=
    async infoTarjetaAquahabiente(): Promise<any> {
        if (this.authUsuario?.usuario) {

            return new Promise((resolve, reject) => {
                const apiPath = this.apiUrl + '/bancodelagua/getTarjetaAquahabiente' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath).subscribe((data: any) => {
                        //console.log('Peticion ->  Tarjeta Aquahabiente: ', JSON.stringify(data));
                        if (data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.app) {

                                resolve(data.respuesta.data.app);
                            } else {
                                resolve(false);
                            }
                        }

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // Guardar compra online
    async almacenarCompraOnlineWompi(parametros: any) {

        if (this.authUsuario?.usuario) {

            if (parametros) {

                const loading = await this.loadingCtrl.create({
                    message: 'Almacenando tu transacción...',
                    spinner: 'crescent',
                    showBackdrop: true
                });
                loading.present();

                console.log('Parametros ->' + JSON.stringify(parametros));

                /* http://bancodelagua.com/channel/bancodelagua/getPagoOnline?pass=&empresa=&us=cliente&u_id=2&t_app=
                &pay_tipo=
                &pay_status=
                &pay_nombre=
                &pay_total=
                &pay_divisa=
                &pay_pasarela=
                &pay_transaccion_id=
                &pay_referencia=
                */
                const apiPath = this.apiUrl + '/bancodelagua/getPagoOnline' + this.peticionDatosAPI;
                const parametrosPeticion = {
                    us: this.authUsuario.usuario,
                    u_id: this.authUsuario.usuario_id,
                    t_app: this.authUsuario.app_token,
                    pay_tipo: parametros.tipo,
                    pay_status: parametros.pay.status,
                    pay_nombre: parametros.pay.payment_method.extra.name,
                    pay_total: parametros.total_pagar,
                    pay_divisa: parametros.pay.payment_method.extra.brand,
                    pay_pasarela: 'wompi',
                    pay_transaccion_id: parametros.pay.id,
                    pay_referencia: 'TARJETA-' + parametros.pay.reference,
                }

                console.log('Data -> ' + parametrosPeticion);

                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();
                    this.httpHeaders = this.httpHeaders.append('us', parametrosPeticion.us);
                    this.httpHeaders = this.httpHeaders.append('u_id', parametrosPeticion.u_id);
                    this.httpHeaders = this.httpHeaders.append('t_app', parametrosPeticion.t_app);
                    this.httpHeaders = this.httpHeaders.append('pay_tipo', parametrosPeticion.pay_tipo);
                    this.httpHeaders = this.httpHeaders.append('pay_status', parametrosPeticion.pay_status);
                    this.httpHeaders = this.httpHeaders.append('pay_nombre', parametrosPeticion.pay_nombre);
                    this.httpHeaders = this.httpHeaders.append('pay_total', parametrosPeticion.pay_total);
                    this.httpHeaders = this.httpHeaders.append('pay_divisa', parametrosPeticion.pay_divisa);
                    this.httpHeaders = this.httpHeaders.append('pay_pasarela', parametrosPeticion.pay_pasarela);
                    this.httpHeaders = this.httpHeaders.append('pay_transaccion_id', parametrosPeticion.pay_transaccion_id);
                    this.httpHeaders = this.httpHeaders.append('pay_referencia', parametrosPeticion.pay_referencia);

                    const addUsuario = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {

                            //console.log('Peticion -> Guardar pago con wompi: ', data);
                            if (data) {
                                if (data.respuesta) {
                                    if (data.respuesta.data) {

                                        if (data.respuesta.data.msg) {
                                            const messages = data.respuesta.data.msg;
                                            this.setChannelMessages(messages);
                                            this.toast.msgAppMultiple(messages, 'bottom');
                                        }

                                        resolve(data.respuesta.data);

                                    } else {
                                        console.log('Seguimiento de errores -> ', data);
                                        resolve(false);
                                    }
                                } else {
                                    resolve(false);
                                    console.log('Seguimiento de errores -> ', data);
                                }
                            } else {
                                resolve(false);
                                console.log('Seguimiento de errores -> ', data);
                            }

                            loading.dismiss();

                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);
                            resolve(false);
                            loading.dismiss();
                        })
                    );

                    loading.dismiss();

                });

            }

        } else {
            console.log('Seguimiento de errores ->', this.authUsuario);
        }
    }


    // Guardar compra online NEQUI
    async almacenarCompraOnlineWompiNEQUI(parametros: any) {
        if (this.authUsuario?.usuario) {
            if (parametros) {
                const loading = await this.loadingCtrl.create({
                    message: 'Almacenando tu transacción...',
                    spinner: 'crescent',
                    showBackdrop: true
                });
                loading.present();

                //console.log('Parametros ->' + parametros);
                /* http://bancodelagua.com/channel/bancodelagua/getPagoOnline?pass=&empresa=&us=cliente&u_id=2&t_app=
                &pay_tipo=
                &pay_status=
                &pay_nombre=
                &pay_total=
                &pay_divisa=
                &pay_pasarela=
                &pay_transaccion_id=
                &pay_referencia=
                */
                const apiPath = this.apiUrl + '/bancodelagua/getPagoOnline' + this.peticionDatosAPI;
                const parametrosPeticion = {
                    us: this.authUsuario.usuario,
                    u_id: this.authUsuario.usuario_id,
                    t_app: this.authUsuario.app_token,
                    pay_status: parametros.status,
                    pay_nombre: parametros.name,
                    pay_tipo: parametros.tipo,
                    pay_total: parametros.total_pagar,
                    pay_pasarela: 'wompi',
                    pay_divisa: parametros.divisa,
                    pay_transaccion_id: Date.now(),
                    pay_referencia: 'NEQUI-' + Date.now(),
                }

                //console.log('Data -> ' + parametrosPeticion);

                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();
                    this.httpHeaders = this.httpHeaders.append('us', parametrosPeticion.us);
                    this.httpHeaders = this.httpHeaders.append('u_id', parametrosPeticion.u_id);
                    this.httpHeaders = this.httpHeaders.append('t_app', parametrosPeticion.t_app);
                    this.httpHeaders = this.httpHeaders.append('pay_tipo', parametrosPeticion.pay_tipo);
                    this.httpHeaders = this.httpHeaders.append('pay_nombre', parametrosPeticion.pay_nombre);
                    this.httpHeaders = this.httpHeaders.append('pay_status', parametrosPeticion.pay_status);
                    this.httpHeaders = this.httpHeaders.append('pay_total', parametrosPeticion.pay_total);
                    this.httpHeaders = this.httpHeaders.append('pay_pasarela', parametrosPeticion.pay_pasarela);
                    this.httpHeaders = this.httpHeaders.append('pay_divisa', parametrosPeticion.pay_divisa);
                    this.httpHeaders = this.httpHeaders.append('pay_transaccion_id', parametrosPeticion.pay_transaccion_id);
                    this.httpHeaders = this.httpHeaders.append('pay_referencia', parametrosPeticion.pay_referencia);

                    const addUsuario = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {

                            //console.log('Peticion -> Guardar pago con wompi: ', data);
                            if (data) {
                                if (data.respuesta) {
                                    if (data.respuesta.data) {

                                        if (data.respuesta.data.msg) {
                                            const messages = data.respuesta.data.msg;
                                            this.setChannelMessages(messages);
                                            this.toast.msgAppMultiple(messages, 'bottom');
                                        }

                                        resolve(data.respuesta.data);

                                    } else {
                                        console.log('Seguimiento de errores -> ', data);
                                        resolve(false);
                                    }
                                } else {
                                    resolve(false);
                                    console.log('Seguimiento de errores -> ', data);
                                }
                            } else {
                                resolve(false);
                                console.log('Seguimiento de errores -> ', data);
                            }

                            loading.dismiss();

                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);
                            resolve(false);
                            loading.dismiss();
                        })
                    );

                    loading.dismiss();

                });
            }
        } else {
            console.log('Seguimiento de errores ->', this.authUsuario);
        }
    }


    // Tickets de agua
    //https://bancodelagua.com/channel/bancodelagua/getTicketsAgua?pass=&empresa=&us=cliente&u_id=
    async consultarTicketsAgua(): Promise<any> {
        if (this.authUsuario?.usuario) {

            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {
                const apiPath = this.apiUrl + '/bancodelagua/getTicketsAgua' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath).subscribe((data: any) => {
                        //console.log('Peticion ->  Tickets de Agua: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.tickets) {

                                resolve(data.respuesta.data.tickets);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }

    }

    // App => Cupon de descuento
    //https://bancodelagua.com/channel/bancodelagua/getCuponDescuento?pass=&empresa=&us=cliente&u_id=
    async infoCuponDescuento(): Promise<any> {
        if (this.authUsuario?.usuario) {

            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {
                const apiPath = this.apiUrl + '/bancodelagua/getCuponDescuento' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath).subscribe((data: any) => {
                        //console.log('Peticion -> Cupon de descuento: ', JSON.stringify(data));
                        if (data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.app) {

                                resolve(data.respuesta.data.app);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }

    }

    // Guardar pedido de recarga de agua
    async hacerPedidoAgua(parametrosPedido: any) {
        if (this.authUsuario?.usuario) {

            if (parametrosPedido) {

                const loading = await this.loadingCtrl.create({
                    message: 'Verificando pedido ...',
                    spinner: 'crescent',
                    showBackdrop: true
                });
                loading.present();

                console.log('Verificar data -> ' + JSON.stringify(parametrosPedido));

                let tipoDomicilio; // Tipo de domicilio
                if (parametrosPedido.pedidoParametros.parametrosPedido.tipo_domicilio) {
                    if (parametrosPedido.pedidoParametros.parametrosPedido.tipo_domicilio.entrega === true) {
                        tipoDomicilio = 'entrega';
                    } else if (parametrosPedido.pedidoParametros.parametrosPedido.tipo_domicilio.recoger === true) {
                        tipoDomicilio = 'recoger';
                    }
                }

                let tipoPresentacion = '';
                if (parametrosPedido.pedidoParametros.parametrosPedido?.tipoPresentacion) {
                    tipoPresentacion = parametrosPedido.pedidoParametros.parametrosPedido.tipoPresentacion;
                }

                let recargaLugar = ''; // En lugar
                if (parametrosPedido.pedidoParametros.parametrosPedido.recargaLugar) {
                    if (parametrosPedido.pedidoParametros.parametrosPedido.recargaLugar === 'tienda') {

                    }

                    if (parametrosPedido.pedidoParametros.parametrosPedido.recargaLugar === 'cajero') { // CAJERO

                    }

                    recargaLugar = parametrosPedido.pedidoParametros.parametrosPedido.recargaLugar;
                }


                let productoSachetId = 0; // Producto Sachet
                if (parametrosPedido.pedidoParametros.parametrosPedido.sachet) {
                    if (parametrosPedido.pedidoParametros.parametrosPedido.sachet.id) {
                        productoSachetId = parametrosPedido.pedidoParametros.parametrosPedido.sachet.id;
                    }
                }

                let productoTapaId = 0; // Producto Tapa
                if (parametrosPedido.pedidoParametros.parametrosPedido.tapa) {
                    if (parametrosPedido.pedidoParametros.parametrosPedido.tapa.id) {
                        productoTapaId = parametrosPedido.pedidoParametros.parametrosPedido.tapa.id;
                    }
                }

                let conGas = 1; // Con Gas
                if (parametrosPedido.pedidoParametros.parametrosPedido.congas) {
                    conGas = 2;
                }

                let intercambiarEnvase = 1;
                if (parametrosPedido.pedidoParametros.parametrosPedido.intercambiar) {
                    intercambiarEnvase = 2;
                }

                let intercambiarEnvaseEspecificacion = '';
                if (parametrosPedido.pedidoParametros.parametrosPedido.especificacionEnvase) {
                    intercambiarEnvaseEspecificacion = parametrosPedido.pedidoParametros.parametrosPedido.especificacionEnvase;
                } else {
                    intercambiarEnvaseEspecificacion = 'genérico';
                }

                let tiendaSeleccionada: any = { // Tienda seleccionada
                    id: null,
                    tipo: null
                };
                if (parametrosPedido.pedidoParametros.parametrosPedido.tiendaSeleccionada) {
                    if (parametrosPedido.pedidoParametros.parametrosPedido.tiendaSeleccionada.id) {
                        tiendaSeleccionada = {
                            id: parametrosPedido.pedidoParametros.parametrosPedido.tiendaSeleccionada.id,
                            tipo: parametrosPedido.pedidoParametros.parametrosPedido.tiendaSeleccionada.tipo
                        }
                    }
                }

                let cajeroSeleccionado: any = {
                    id: null,
                    modelo_id: null
                }

                if (parametrosPedido.pedidoParametros.parametrosPedido.cajeroSeleccionado?.cajero) {
                    if (parametrosPedido.pedidoParametros.parametrosPedido.cajeroSeleccionado.cajero?.id) {

                        cajeroSeleccionado = {
                            id: parametrosPedido.pedidoParametros.parametrosPedido.cajeroSeleccionado.cajero.id,
                            modelo_id: parametrosPedido.pedidoParametros.parametrosPedido.cajeroSeleccionado.cajero.modelo_id
                        };

                    }
                }

                let metodoPagoId = 0; // Metodo de pago
                let transaccionId = null;
                let carteraId = null;

                if (parametrosPedido.payParametros.metodo_pago.tarjeta) {

                    metodoPagoId = 3;
                    transaccionId = parametrosPedido.payParametros.transaccionId;
                    carteraId = parametrosPedido.payParametros.carteraId;

                } else if (parametrosPedido.payParametros.metodo_pago.aquahabiente) {
                    metodoPagoId = 5;
                }

                let direccionData: any = {
                    direccion_id: 0,
                    extra_place_id: '',
                    extra_direccion: '',
                    extra_direccion_lat: '',
                    extra_direccion_lng: ''
                };
                if (parametrosPedido.pedidoParametros.parametrosUbicacion) {
                    if (parametrosPedido.pedidoParametros.parametrosUbicacion.place_id) {
                        direccionData.direccion_id = null;
                        direccionData.extra_place_id = direccionData.extra_direccion = parametrosPedido.pedidoParametros.parametrosUbicacion.place_id;
                        direccionData.extra_direccion = parametrosPedido.pedidoParametros.parametrosUbicacion.direccion;
                        direccionData.extra_direccion_lat = parametrosPedido.pedidoParametros.parametrosUbicacion.coordenadas.lat;
                        direccionData.extra_direccion_lng = parametrosPedido.pedidoParametros.parametrosUbicacion.coordenadas.lng;
                    }
                }

                let cuponData: any = { // Cupon de descuento
                    id: 0,
                    lote_id: 0,
                    firma: 0
                };
                if (parametrosPedido.payParametros.cupon) {
                    if (parametrosPedido.payParametros.cupon.id) { }
                    cuponData.id = parametrosPedido.payParametros.cupon.id;
                }

                let ticketData: any = {
                    id: 0,
                    ticket_agua_id: 0
                };
                if (parametrosPedido.payParametros.ticket_agua.id) {
                    if (parametrosPedido.payParametros.ticket_agua) {
                        ticketData.ticket_agua_id = parametrosPedido.payParametros.ticket_agua.id;
                    }
                }

                let cantidad = 0;
                if (parametrosPedido.pedidoParametros.parametrosPedido.cantidad > 0) {
                    cantidad = parametrosPedido.pedidoParametros.parametrosPedido.cantidad;
                }

                let cantMt3 = 0;
                if (parametrosPedido.pedidoParametros.parametrosPedido?.cantidadMT3) {
                    cantMt3 = parametrosPedido.pedidoParametros.parametrosPedido.cantidadMT3;
                }


                let comentario = 'Pedido realizado / App Banco del Agua.';
                if (parametrosPedido.pedidoParametros.parametrosPedido.comentario) {
                    comentario = parametrosPedido.pedidoParametros.parametrosPedido.comentario;
                }

                const parametrosPeticion = {
                    usuario: this.authUsuario.usuario,
                    usuario_id: this.authUsuario.usuario_id,
                    token_app: this.authUsuario.app_token,

                    // Basico
                    tipo_domicilio: tipoDomicilio,
                    tipo_presentacion: tipoPresentacion,
                    presentacion_id: parametrosPedido.pedidoParametros.parametrosPedido.presentacion,
                    sachet_id: productoSachetId,
                    tapa_id: productoTapaId,
                    con_gas: conGas,
                    cantidad: cantidad,
                    comentario: comentario,
                    propina: parametrosPedido.payParametros.propina,
                    hora_entrega: parametrosPedido.payParametros.horaEntrega,
                    fecha_entrega: parametrosPedido.payParametros.fechaEntrega,
                    domicilio: parametrosPedido.totalParametros.totalEnvio,
                    intercambiar_envase: intercambiarEnvase,
                    intercambiar_especificacion: intercambiarEnvaseEspecificacion,

                    // MT3
                    cantidad_mt3: cantMt3,

                    // En lugar
                    recarga_lugar: recargaLugar,

                    // Cartera
                    cartera_id: parametrosPedido.payParametros.carteraId,
                    transaccion_id: parametrosPedido.payParametros.transaccionId,
                    metodo_pago: metodoPagoId,
                    total_pago: parametrosPedido.totalParametros.totalPayFull,
                    total_litros_pagar: parametrosPedido.totalParametros.totalLitrosPagar,
                    total_litros_agua: parametrosPedido.totalParametros.totalAguaFull,
                    // Ticket de agua
                    ticketagua_id: ticketData.ticket_agua_id,

                    // Cupon de descuento
                    cupondescuento_id: cuponData.id,

                    // Ubicacion
                    direccion_id: direccionData.direccion_id,
                    extra_direccion_place_id: direccionData.extra_place_id,
                    extra_direccion: direccionData.extra_direccion,
                    extra_direccion_lat: direccionData.extra_direccion_lat,
                    extra_direccion_lng: direccionData.extra_direccion_lng,

                    // Tienda de Agua
                    tipo_tienda: tiendaSeleccionada.tipo,
                    tienda_agua_id: tiendaSeleccionada.id,

                    // Cajero seleccionado
                    cajero_agua_id: cajeroSeleccionado.id
                }


                console.log('Data -> ' + parametrosPeticion);

                const apiPath = this.apiUrl + '/bancodelagua_cliente/getHacerPedidoAgua' + this.peticionDatosAPI;

                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();
                    this.httpHeaders = this.httpHeaders.append('us', parametrosPeticion.usuario);
                    this.httpHeaders = this.httpHeaders.append('u_id', parametrosPeticion.usuario_id);
                    this.httpHeaders = this.httpHeaders.append('t_app', parametrosPeticion.token_app);
                    // Solicitar agua
                    this.httpHeaders = this.httpHeaders.append('tipo_domicilio', parametrosPeticion.tipo_domicilio);
                    this.httpHeaders = this.httpHeaders.append('tipo_presentacion', parametrosPeticion.tipo_presentacion);
                    this.httpHeaders = this.httpHeaders.append('recarga_lugar', parametrosPeticion.recarga_lugar);


                    this.httpHeaders = this.httpHeaders.append('presentacion_id', parametrosPeticion.presentacion_id);
                    this.httpHeaders = this.httpHeaders.append('sachet_id', parametrosPeticion.sachet_id);
                    this.httpHeaders = this.httpHeaders.append('tapa_id', parametrosPeticion.tapa_id);
                    this.httpHeaders = this.httpHeaders.append('congas', parametrosPeticion.con_gas);
                    this.httpHeaders = this.httpHeaders.append('hora_entrega', parametrosPeticion.hora_entrega);
                    this.httpHeaders = this.httpHeaders.append('fecha_entrega', parametrosPeticion.fecha_entrega);
                    this.httpHeaders = this.httpHeaders.append('cantidad', parametrosPeticion.cantidad);
                    this.httpHeaders = this.httpHeaders.append('cantidad_mt3', parametrosPeticion.cantidad_mt3);
                    this.httpHeaders = this.httpHeaders.append('comentario', parametrosPeticion.comentario);
                    this.httpHeaders = this.httpHeaders.append('intercambiar_envase', parametrosPeticion.intercambiar_envase);
                    this.httpHeaders = this.httpHeaders.append('intercambiar_envase_especificacion', parametrosPeticion.intercambiar_especificacion);
                    this.httpHeaders = this.httpHeaders.append('propina', parametrosPeticion.propina);
                    this.httpHeaders = this.httpHeaders.append('domicilio', parametrosPeticion.domicilio);
                    // Cartera
                    this.httpHeaders = this.httpHeaders.append('cartera_id', parametrosPeticion.cartera_id);
                    this.httpHeaders = this.httpHeaders.append('transaccion_id', parametrosPeticion.transaccion_id);
                    this.httpHeaders = this.httpHeaders.append('metodo_pago', parametrosPeticion.metodo_pago);
                    this.httpHeaders = this.httpHeaders.append('total_pago', parametrosPeticion.total_pago);
                    this.httpHeaders = this.httpHeaders.append('total_litros_pagar', parametrosPeticion.total_litros_pagar);
                    this.httpHeaders = this.httpHeaders.append('total_litros_agua', parametrosPeticion.total_litros_agua);
                    // Ticket
                    this.httpHeaders = this.httpHeaders.append('ticket_agua_id', parametrosPeticion.ticketagua_id);
                    // Cupon
                    this.httpHeaders = this.httpHeaders.append('cupon_descuento_id', parametrosPeticion.cupondescuento_id);
                    // Geolocalizacion
                    this.httpHeaders = this.httpHeaders.append('direccion_id', parametrosPeticion.direccion_id);
                    this.httpHeaders = this.httpHeaders.append('extra_direccion_place_id', parametrosPeticion.extra_direccion_place_id);
                    this.httpHeaders = this.httpHeaders.append('extra_direccion', parametrosPeticion.extra_direccion);
                    this.httpHeaders = this.httpHeaders.append('extra_direccion_lat', parametrosPeticion.extra_direccion_lat);
                    this.httpHeaders = this.httpHeaders.append('extra_direccion_lng', parametrosPeticion.extra_direccion_lng);
                    // Tienda
                    this.httpHeaders = this.httpHeaders.append('tipo_tienda', parametrosPeticion.tipo_tienda);
                    this.httpHeaders = this.httpHeaders.append('tienda_id', parametrosPeticion.tienda_agua_id);
                    // Cajero
                    this.httpHeaders = this.httpHeaders.append('cajero_id', parametrosPeticion.cajero_agua_id);

                    const addPedido = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {

                            console.log('Peticion -> Hacer pedido de agua: ', data);
                            if (data) {
                                if (data.respuesta) {
                                    if (data.respuesta.data) {

                                        if (data.respuesta.data.msg) {
                                            const messages = data.respuesta.data.msg;
                                            this.setChannelMessages(messages);
                                            this.toast.msgAppMultiple(messages, 'bottom');
                                        }

                                        if (data.respuesta.data?.pedido_agua) {

                                            resolve(data.respuesta.data.pedido_agua);

                                        } else {

                                            resolve(false);

                                        }

                                    } else {
                                        resolve(false);
                                    }
                                }
                            }

                            loading.dismiss();

                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);
                            loading.dismiss();
                        })
                    );

                });

            }
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario?.usuario);
        }
    }

    // Tienda virtual tiendas cercanas
    //https://bancodelagua.com/channel/bancodelagua/getTiendasCercanas?pass=&empresa=&pais=1&lat=&lng=
    async tiendaTiendasCercanas(geolocalizacion: any): Promise<any> {
        if (geolocalizacion?.latitud && geolocalizacion?.longitud) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando tiendas...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {
                const apiPath = this.apiUrl + '/bancodelagua/getTiendaComercioCerca' + this.peticionDatosAPI + '&lat=' + geolocalizacion.latitud + '&lng=' + geolocalizacion.longitud;
                const listaTiendas = this.subscriptionHttp.add(
                    this.http.get(apiPath).subscribe((data: any) => {
                        console.log('Peticion ->  Tiendas cercanas: ', data);
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.tiendas) {
                                resolve(data.respuesta.data.tiendas);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        }

    }

    // Empresas Red AT-WATER
    //https://bancodelagua.com/channel/bancodelagua/getTiendasCercanas?pass=&empresa=&pais=1&lat=&lng=
    async empresasRedATWATER(): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Red AT-WATER...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {
            const apiPath = this.apiUrl + '/bancodelagua/getEmpresas' + this.peticionDatosAPI;
            const listaEmpresas = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    console.log('Peticion ->  Empresas: ', data);
                    if (data) {
                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }
                        if (data.respuesta.data.app) {

                            resolve(data.respuesta.data.app);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });
    }


    // Tienda virtual buscar productos
    //https://bancodelagua.com/channel/bancodelagua/getTiendasBusquedaProductos?pass=&empresa=&b=t&pais=1&lat=&lng=
    async buscarProductos(busqueda: any, geolocalizacion: any, pagination: any): Promise<any> {
        if (geolocalizacion?.latitud && geolocalizacion?.longitud) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando productos...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua/getTiendasBusquedaProductos' + this.peticionDatosAPI + '&lat=' + geolocalizacion.latitud
                    + '&lng=' + geolocalizacion.longitud + '&b=' + busqueda;
                const listaBusqueda = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        console.log('Peticion ->  Busqueda de productos: ', data);
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.busqueda) {


                                resolve(data.respuesta.data.busqueda);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        }

    }


    // CONSULTAR CUENTA AQUAHABIENTE POR CUENTA Y CLAVE
    async getCuentaAquahabiente(cuenta: string, clave: string, encriptado: number) {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando cuenta ...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();
        const apiPath = this.apiUrl + '/redatwater/getCuentaAquahabiente' + this.peticionDatosAPI;

        const parametrosPeticion = {
            encriptado: encriptado,
            cuenta: cuenta,
            clave: clave
        }

        console.log('Data -> ' + parametrosPeticion);

        return new Promise((resolve, reject) => {

            this.httpHeaders = new HttpParams();
            this.httpHeaders = this.httpHeaders.append('encriptado', parametrosPeticion.encriptado);
            this.httpHeaders = this.httpHeaders.append('cuenta', parametrosPeticion.cuenta);
            this.httpHeaders = this.httpHeaders.append('clave', parametrosPeticion.clave);

            const peticionAuth = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((getData: any) => {

                    console.log('Peticion -> Aquahabiente, Datos de cuenta: ', getData);

                    if (getData) {
                        if (getData?.respuesta.data.msg) {
                            this.setChannelMessages(getData?.respuesta.data.msg);
                        }
                        if (getData?.channel.conexion_token) {
                            if (getData?.channel_app[0]) {
                                resolve(getData);
                            } else {
                                resolve(false);
                                console.log('Seguimiento de errores -> ');
                            }
                        } else {
                            resolve(false);
                        }
                        console.log('Seguimiento -> ', getData.messages);
                    } else {
                        resolve(false);
                    }

                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                    resolve(false);
                })
            );

        });
    }

    // CONSULTAR CUENTA AQUAHABIENTE POR CUENTA
    async getCuentaAquahabienteCodigo(cuenta: string) {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando cuenta ...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();
        const apiPath = this.apiUrl + '/redatwater/getCuentaAquahabienteCodigo' + this.peticionDatosAPI;

        const parametrosPeticion = {
            cuenta: cuenta
        }

        console.log('Data -> ' + parametrosPeticion);

        return new Promise((resolve, reject) => {

            this.httpHeaders = new HttpParams();
            this.httpHeaders = this.httpHeaders.append('cuenta', parametrosPeticion.cuenta);

            const peticionAuth = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((getData: any) => {

                    console.log('Peticion -> Aquahabiente, Datos de cuenta: ', getData);

                    if (getData) {
                        if (getData?.messages) {
                            this.setChannelMessages(getData?.messages);
                        }
                        if (getData?.channel.conexion_token) {
                            if (getData?.channel_app) {

                                resolve(getData);
                            } else {

                                resolve(false);
                                console.log('Seguimiento de errores -> ');
                            }
                        } else {
                            resolve(false);
                        }
                        console.log('Seguimiento -> ', getData.messages);
                    } else {
                        resolve(false);
                    }

                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                    resolve(false);
                })
            );

        });
    }

    // CONSULTAR TICKET POR CODIGO Y CLAVE
    async getTicketAgua(codigo: string, clave: string, encriptado: number) {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando ticket ...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        const apiPath = this.apiUrl + '/redatwater/getTicketAgua' + this.peticionDatosAPI;
        const parametrosPeticion = {
            encriptado: encriptado,
            codigo: codigo,
            clave: clave
        }

        console.log('Data -> ' + parametrosPeticion);

        return new Promise((resolve, reject) => {

            this.httpHeaders = new HttpParams();
            this.httpHeaders = this.httpHeaders.append('encriptado', parametrosPeticion.encriptado);
            this.httpHeaders = this.httpHeaders.append('codigo', parametrosPeticion.codigo);
            this.httpHeaders = this.httpHeaders.append('clave', parametrosPeticion.clave);

            const peticionAuth = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((getData: any) => {

                    console.log('Peticion -> Ticket de agua, Datos de ticket: ', getData);

                    if (getData) {
                        if (getData?.messages) {
                            this.setChannelMessages(getData?.messages);
                        }
                        if (getData?.channel.conexion_token) {
                            if (getData?.channel_app[0]) {

                                resolve(getData);
                            } else {

                                resolve(false);
                                console.log('Seguimiento de errores -> ');
                            }
                        } else {
                            resolve(false);
                        }
                        console.log('Seguimiento -> ', getData.messages);
                    } else {
                        resolve(false);
                    }

                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                    resolve(false);
                })
            );

        });
    }

    // CONSULTAR TICKET POR CODIGO 
    async getTicketAguaCodigo(codigo: string) {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando ticket ...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        const apiPath = this.apiUrl + '/redatwater/getTicketAguaCodigo' + this.peticionDatosAPI;
        const parametrosPeticion = {
            codigo: codigo
        }

        console.log('Data -> ' + parametrosPeticion);

        return new Promise((resolve, reject) => {

            this.httpHeaders = new HttpParams();
            this.httpHeaders = this.httpHeaders.append('codigo', parametrosPeticion.codigo);

            const peticionAuth = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((getData: any) => {

                    console.log('Peticion -> Ticket de agua, Datos de ticket: ', getData);

                    if (getData) {
                        if (getData?.messages) {
                            this.setChannelMessages(getData?.messages);
                        }
                        if (getData?.channel.conexion_token) {
                            if (getData?.channel_app[0]) {

                                resolve(getData);
                            } else {

                                resolve(false);
                                console.log('Seguimiento de errores -> ');
                            }
                        } else {
                            resolve(false);
                        }
                        console.log('Seguimiento -> ', getData.messages);
                    } else {
                        resolve(false);
                    }

                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                    resolve(false);
                })
            );

        });
    }


    // Listado de bonificaciones
    //https://bancodelagua.com/channel/bancodelagua/getBonificaciones?pass=&empresa=&us=cliente&u_id=
    async consultarBonificaciones(): Promise<any> {
        if (this.authUsuario?.usuario) {

            const loading = await this.loadingCtrl.create({
                message: 'Verificando información...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {
                const apiPath = this.apiUrl + '/bancodelagua/getBonificaciones' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const checkCodigo = this.subscriptionHttp.add(
                    this.http.get(apiPath).subscribe((data: any) => {
                        //console.log('Peticion -> Bonificaciones: ', data);
                        if (data.respuesta?.data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }


                            if (data.respuesta.data?.app) {

                                if (data.respuesta.data.app?.bonificaciones) {

                                    resolve(data.respuesta.data.app.bonificaciones);

                                } else {
                                    resolve(false);
                                }

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }

    }

    // Asociar facturacion a cartera
    //https://bancodelagua.com/channel/bancodelagua/getFacturacionCartera?pass=&empresa=&us=cliente&u_id=
    async addFacturacionCartera(parametros: any): Promise<any> {

        if (this.authUsuario?.usuario) {

            const loading = await this.loadingCtrl.create({
                message: 'Verificando información...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('factura_tipo', parametros.tipo);
                this.httpHeaders = this.httpHeaders.append('factura_documento_tipo', parametros.documento_tipo);
                this.httpHeaders = this.httpHeaders.append('factura_nombre', parametros.nombre);
                this.httpHeaders = this.httpHeaders.append('factura_documento', parametros.documento);
                this.httpHeaders = this.httpHeaders.append('factura_direccion', parametros.direccion);
                this.httpHeaders = this.httpHeaders.append('factura_celular', parametros.celular);
                this.httpHeaders = this.httpHeaders.append('factura_correo', parametros.correo);

                const apiPath = this.apiUrl + '/bancodelagua/getFacturacionCartera' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const checkCodigo = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        console.log('Peticion -> Cartera Facturación: ', data);
                        if (data.respuesta?.data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.cartera) {

                                resolve(data.respuesta.data.cartera);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }

    }

    // Asociar banco a cartera
    //https://bancodelagua.com/channel/bancodelagua/getBancosCartera?pass=&empresa=&us=cliente&u_id=
    async addBancoCartera(parametros: any): Promise<any> {

        if (this.authUsuario?.usuario) {

            const loading = await this.loadingCtrl.create({
                message: 'Verificando información...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('banco_tipo', parametros.banco_tipo);
                this.httpHeaders = this.httpHeaders.append('banco_documento_tipo', parametros.documento_tipo);
                this.httpHeaders = this.httpHeaders.append('banco_nombre', parametros.nombre);
                this.httpHeaders = this.httpHeaders.append('banco_nombre_banco', parametros.nombre_banco);
                this.httpHeaders = this.httpHeaders.append('banco_documento', parametros.documento);
                this.httpHeaders = this.httpHeaders.append('banco_nro_cuenta', parametros.banco_nro_cuenta);
                this.httpHeaders = this.httpHeaders.append('banco_tipo_cuenta', parametros.banco_tipo_cuenta);

                const apiPath = this.apiUrl + '/bancodelagua/getBancosCartera' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const checkCodigo = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        console.log('Peticion -> Cartera Banco: ', data);
                        if (data.respuesta?.data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.cartera) {

                                resolve(data.respuesta.data.cartera);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }

    }


    // Consultar tiendas destacadas
    //https://bancodelagua.com/channel/bancodelagua/getTiendasDestacadas?pass=&empresa=
    async tiendasDestacadas(): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando información...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {

            this.httpHeaders = new HttpParams();

            const apiPath = this.apiUrl + '/bancodelagua/getTiendasDestacadas' + this.peticionDatosAPI;
            const tiendasDestacadas = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                    console.log('Peticion -> Tiendas destacadas: ', data);
                    if (data.respuesta?.data) {

                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }

                        if (data.respuesta.data.tiendas) {
                            resolve(data.respuesta.data.tiendas);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });

    }


    // Buscar tiendas 
    //https://bancodelagua.com/channel/bancodelagua/getTiendasBusqueda?pass=&empresa=
    async getTiendasBusqueda(busqueda: any, geolocalizacion: any, pagination: any): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando información...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        if (geolocalizacion?.lat && geolocalizacion.lng) {

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua/getTiendasBusqueda' + this.peticionDatosAPI + '&b=' + busqueda + '&lat=' +
                    geolocalizacion.lat + '&lng=' + geolocalizacion.lng;
                const buscarTiendas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        console.log('Peticion -> Busqueda de tiendas: ', data);
                        if (data.respuesta?.data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.busqueda) {
                                resolve(data.respuesta.data.busqueda);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', geolocalizacion);
            loading.dismiss();
        }

    }


    // Consultar tiendas destacadas
    //https://bancodelagua.com/channel/bancodelagua/getSistemasCercanos?pass=&empresa=
    async sistemasCercanos(geolocalizacion: any, pagination: any): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando información...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        if (geolocalizacion?.lat && geolocalizacion?.lng) {


            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua/getSistemasCercanos' + this.peticionDatosAPI + '&lat=' +
                    geolocalizacion.lat + '&lng=' + geolocalizacion.lng;
                const tiendasDestacados = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        console.log('Peticion -> Sistemas destacados: ', data);
                        if (data.respuesta?.data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.sistemas) {
                                resolve(data.respuesta.data.sistemas);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', geolocalizacion);
            loading.dismiss();
        }

    }


    // Buscar sistemas 
    //https://bancodelagua.com/channel/bancodelagua/getSistemasBusqueda?pass=&empresa=
    async getSistemasBusqueda(tipoBusqueda: any, busqueda: any, geolocalizacion: any, pagination: any): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando información...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        if (geolocalizacion?.lat && geolocalizacion?.lng) {


            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua/getSistemasBusqueda' + this.peticionDatosAPI + '&tipo=' + tipoBusqueda + '&b=' + busqueda + '&lat=' +
                    geolocalizacion.lat + '&lng=' + geolocalizacion.lng;
                const buscarSistemas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        console.log('Peticion -> Busqueda de tiendas: ', data);
                        if (data.respuesta?.data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.busqueda) {
                                resolve(data.respuesta.data.busqueda);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> : ', geolocalizacion);
            loading.dismiss();
        }

    }

    // CONSULTAR MODELOS DE SISTEMAS
    async listaModelosSistemas(): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando información...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {

            this.httpHeaders = new HttpParams();

            const apiPath = this.apiUrl + '/bancodelagua/getListaModelosSistemas' + this.peticionDatosAPI;
            const modelosSistemas = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                    console.log('Peticion -> Modelos de sistemas: ', data);
                    if (data.respuesta?.data) {

                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }

                        if (data.respuesta.data.app) {
                            resolve(data.respuesta.data.app);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });

    }



    // ELIMINAR TARJETA
    async eliminarTarjetaPasarela(tarjeta_id: any): Promise<any> {
        if (this.authUsuario?.usuario) {

            const loading = await this.loadingCtrl.create({
                message: 'Verificando información...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                this.httpHeaders = this.httpHeaders.append('tarjeta_id', tarjeta_id);

                const apiPath = this.apiUrl + '/bancodelagua/delTarjetaPasarela' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const modelosSistemas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        console.log('Peticion -> Eliminar tarjeta: ', data);
                        if (data.respuesta?.data) {

                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }

                            if (data.respuesta.data.app) {
                                resolve(data.respuesta.data.app);
                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }

    }

    // Mis Tareas
    //https://bancodelagua.com/channel/bancodelagua/getListaTareas?pass=&empresa=&us=cliente&u_id=
    async consultarMisTareas(pagination: any): Promise<any> {
        if (this.authUsuario?.usuario) {

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua/getListaTareas' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Mis tareas: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.mis_tareas) {

                                resolve(data.respuesta.data.mis_tareas);
                            } else {
                                resolve(false);
                            }
                        }


                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);

                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // USUARIOS ADMIN =>  TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getUsuariosTiendasAsociadas?pass=&empresa=&pais=1&lat=&lng=
    async consultarTiendasAsociadas(geolocalizacion: any, pagination: any): Promise<any> {
        if (this.authUsuario?.usuario) {

            if (geolocalizacion?.lat && geolocalizacion.lng) {
                const loading = await this.loadingCtrl.create({
                    message: 'Cargando tiendas...',
                    spinner: 'crescent',
                    showBackdrop: true
                });
                loading.present();

                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();

                    if (pagination) {
                        this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                        this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                    }

                    const apiPath = this.apiUrl + '/bancodelagua_usuario/getUsuariosTiendasAsociadas' + this.peticionDatosAPI + '&lat=' + geolocalizacion.lat
                        + '&lng=' + geolocalizacion.lng + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                    const listaBusqueda = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                            console.log('Peticion ->  Tiendas asociadas: ', data);
                            if (data) {
                                if (data.respuesta.data.msg) {
                                    const messages = data.respuesta.data.msg;
                                    this.setChannelMessages(messages);
                                    //this.toast.msgAppMultiple(messages, 'bottom');
                                }

                                if (data.respuesta.data.tiendas_asociadas) {

                                    resolve(data.respuesta.data.tiendas_asociadas);

                                } else {
                                    resolve(false);
                                }
                            }
                            loading.dismiss();

                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);
                            loading.dismiss();
                        })
                    );

                });
            }

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // Verificar información cuenta de usuario
    //https://bancodelagua.com/channel/bancodelagua_usuario/getConsultarCuentaClientes?pass=&empresa=
    async consultarCuentaCliente(cuenta: any): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando información...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {
            const apiPath = this.apiUrl + '/bancodelagua_usuario/getConsultarCuentaClientes' + this.peticionDatosAPI + '&td=' + cuenta.tipo_documento + '&documento=' + cuenta.documento;
            const checkCodigo = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    //console.log('Peticion -> Consultar cuenta usuario: ', data);
                    if (data.respuesta?.data) {

                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }

                        if (data.respuesta.data.cuenta) {

                            resolve(data.respuesta.data.cuenta);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });

    }

    // USUARIOS VENDEDORES =>  TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getVendedoresTiendasAsociadas?pass=&empresa=&pais=1&lat=&lng=
    async consultarTiendasAsociadasVendedores(geolocalizacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {

            if (geolocalizacion?.lat && geolocalizacion?.lng) {
                const loading = await this.loadingCtrl.create({
                    message: 'Cargando tiendas...',
                    spinner: 'crescent',
                    showBackdrop: true
                });
                loading.present();

                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();

                    const apiPath = this.apiUrl + '/bancodelagua_usuario/getVendedoresTiendasAsociadas' + this.peticionDatosAPI + '&lat=' + geolocalizacion.lat
                        + '&lng=' + geolocalizacion.lng + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                    const listaBusqueda = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                            console.log('Peticion ->  Tiendas asociadas vendedor: ', data);
                            if (data) {
                                if (data.respuesta.data.msg) {
                                    const messages = data.respuesta.data.msg;
                                    this.setChannelMessages(messages);
                                    //this.toast.msgAppMultiple(messages, 'bottom');
                                }

                                if (data.respuesta.data.tiendas_asociadas) {

                                    resolve(data.respuesta.data.tiendas_asociadas);

                                } else {
                                    resolve(false);
                                }
                            }
                            loading.dismiss();

                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);
                            loading.dismiss();
                        })
                    );

                });
            } else {
                console.log('Seguimiento de errores -> ', geolocalizacion);

            }

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // Mi Actividad
    //https://bancodelagua.com/channel/bancodelagua/getListaMiActividad?pass=&empresa=&us=cliente&u_id=
    async consultarMiActividad(): Promise<any> {
        if (this.authUsuario?.usuario) {

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                const apiPath = this.apiUrl + '/bancodelagua/getListaMiActividad' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Mis actividad: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.mi_actividad) {

                                resolve(data.respuesta.data.mi_actividad);
                            } else {
                                resolve(false);
                            }
                        }


                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);

                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // Mis Pedidos Clientes / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_cliente/getListaMisPedidosTienda?pass=&empresa=&us=cliente&u_id=
    async consultarMisPedidosTienda(pagination: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua_cliente/getListaMisPedidosTienda' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Mis tareas: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.mis_pedidos) {

                                resolve(data.respuesta.data.mis_pedidos);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // Mis Pediso Clientes / Cajero
    //https://bancodelagua.com/channel/bancodelagua_cliente/getListaMisPedidosCajero?pass=&empresa=&us=cliente&u_id=
    async consultarMisPedidosCajero(pagination: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua_cliente/getListaMisPedidosCajero' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Mis tareas: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.mis_pedidos) {

                                resolve(data.respuesta.data.mis_pedidos);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }




    // PEDIDOS ASOCIADOS / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getListaPedidosTienda?pass=&empresa=&us=cliente&u_id=
    async consultarPedidosAsociadosTiendas(): Promise<any> {
        if (this.authUsuario?.usuario) {


            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();


                const apiPath = this.apiUrl + '/bancodelagua_usuario/getListaPedidosTienda' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Pedidos asociados tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedidos_asociados) {

                                resolve(data.respuesta.data.pedidos_asociados);

                            } else {
                                resolve(false);
                            }
                        }


                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);

                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // ACEPTAR PEDIDOS ASOCIADOS / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getAceptarPedido?pass=&empresa=&us=cliente&u_id=
    async confirmarPedidoAsociadoTiendas(pedido: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getAceptarPedido' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Pedido asociado tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // PEDIDOS ASOCIADOS / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getPedidosTienda?pass=&empresa=&us=cliente&u_id=
    async pedidosAsociadosTienda(tienda: any, pagination: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('tienda_id', tienda);

                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getPedidosTienda' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Pedidos asociados tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedidos_tienda) {

                                resolve(data.respuesta.data.pedidos_tienda);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // LIBERAR DOMICILIO PEDIDO ASOCIADO / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getLiberarPedidoTienda?pass=&empresa=&us=cliente&u_id=
    async confirmarLiberarPedido(pedido: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getLiberarPedidoTienda' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Pedido asociado tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // LIBERAR DOMICILIO PEDIDO ASOCIADO / TIENDAS DE AGUA / DOMICILIARIO
    //https://bancodelagua.com/channel/bancodelagua_usuario/getLiberarPedidoTiendaDomiciliario?pass=&empresa=&us=cliente&u_id=
    async confirmarLiberarPedidoDomiciliario(pedido: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getLiberarPedidoTiendaDomiciliario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Pedido liberado, asociado tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // DOMICILIARIOS / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getDomiciliariosTiendas?pass=&empresa=&us=cliente&u_id=
    async consultarDomiciliariosTiendas(pedido: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getDomiciliariosTiendas' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Domiciliario tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.domiciliarios_tienda) {

                                resolve(data.respuesta.data.domiciliarios_tienda);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // ASIGNAR DOMICILIARIO / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getAsignarDomiciliarioTienda?pass=&empresa=&us=cliente&u_id=
    async asignarDomicilioPedido(domiciliario: any, pedido: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('domiciliario_id', domiciliario);
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getAsignarDomiciliarioTienda' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Asignar Domiciliario tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.domiciliario_tienda) {

                                resolve(data.respuesta.data.domiciliario_tienda);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // ACEPTAR ENVIO PEDIDOS ASOCIADOS / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getAceptarEnvioPedido?pass=&empresa=&us=cliente&u_id=
    async confirmarEnvioPedidoTiendas(pedido: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getAceptarEnvioPedido' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Envio de Pedido asociado tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // CALIFICAR DOMICILIO / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getCalificarDomiciliarioPedido?pass=&empresa=&us=cliente&u_id=
    async confirmarCalificacionDomiciliario(pedido: any, calificacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);
                this.httpHeaders = this.httpHeaders.append('calificacion_usuario_domiciliario', calificacion);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getCalificarDomiciliarioPedido' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Calificar domiciliario tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // CALIFICAR DOMICILIO / CLIENTE
    //https://bancodelagua.com/channel/bancodelagua_cliente/getCalificarDomiciliarioPedido?pass=&empresa=&us=cliente&u_id=
    async confirmarCalificacionClienteDomiciliario(pedido: any, calificacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);
                this.httpHeaders = this.httpHeaders.append('calificacion_cliente_domiciliario', calificacion);

                const apiPath = this.apiUrl + '/bancodelagua_cliente/getCalificarDomiciliarioPedido' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Calificar domiciliario tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // CALIFICAR DOMICILIO / CLIENTE
    //https://bancodelagua.com/channel/bancodelagua_cliente/getCalificarDomiciliarioPedidoCajero?pass=&empresa=&us=cliente&u_id=
    async confirmarCalificacionClienteDomiciliarioCajero(pedido: any, calificacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);
                this.httpHeaders = this.httpHeaders.append('calificacion_cliente_domiciliario', calificacion);

                const apiPath = this.apiUrl + '/bancodelagua_cliente/getCalificarDomiciliarioPedidoCajero' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Calificar domiciliario tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // CALIFICAR TIENDA DE AGUA / CLIENTE
    //https://bancodelagua.com/channel/bancodelagua_cliente/getCalificarTiendaPedido?pass=&empresa=&us=cliente&u_id=
    async confirmarCalificacionTienda(pedido: any, calificacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);
                this.httpHeaders = this.httpHeaders.append('calificacion_cliente_tienda', calificacion);

                const apiPath = this.apiUrl + '/bancodelagua_cliente/getCalificarTiendaPedido' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Calificar domiciliario tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // CALIFICAR CLIENTE / DOMICILIARIO
    //https://bancodelagua.com/channel/bancodelagua_usuario/getCalificarClientePedidoCajero?pass=&empresa=&us=cliente&u_id=
    async confirmarCalificacionClienteCajero(pedido: any, calificacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);
                this.httpHeaders = this.httpHeaders.append('calificacion_usuario_cliente', calificacion);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getCalificarClientePedidoCajero' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Calificar domiciliario tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // ACEPTAR ENTREGA CLIENTE PEDIDOS ASOCIADOS / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getAceptarEntregaClientePedido?pass=&empresa=&us=cliente&u_id=
    async confirmarEntregaClientePedidoTiendas(pedido: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getAceptarEntregaClientePedido' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Entrega de Pedido Cliente, asociado tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // ACEPTAR ENTREGA CLIENTE PEDIDOS ASOCIADOS / DOMICILIARIO / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getAceptarEntregaClientePedidoDomiciliario?pass=&empresa=&us=cliente&u_id=
    async confirmarEntregaClientePedidoDomiciliarioTiendas(pedido: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getAceptarEntregaClientePedidoDomiciliario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Entrega de Pedido Cliente, asociado tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // CALIFICAR CLIENTE / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getCalificarClientePedido?pass=&empresa=&us=cliente&u_id=
    async confirmarCalificacionCliente(pedido: any, calificacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);
                this.httpHeaders = this.httpHeaders.append('calificacion_usuario_cliente', calificacion);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getCalificarClientePedido' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Calificar domiciliario tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // Guardar compra carrito de compras
    async hacerCompraCarrito(parametrosPedido: any) {
        if (this.authUsuario?.usuario) {

            if (parametrosPedido) {

                const loading = await this.loadingCtrl.create({
                    message: 'Verificando pedido ...',
                    spinner: 'crescent',
                    showBackdrop: true
                });
                loading.present();

                console.log('Verificar data -> ' + JSON.stringify(parametrosPedido));

                const parametrosPeticion = {
                    usuario: this.authUsuario.usuario,
                    usuario_id: this.authUsuario.usuario_id,
                    token_app: this.authUsuario.app_token,

                    // Basico
                    tipo_domicilio: parametrosPedido.tipo_domicilio,
                    total_pago: parametrosPedido.total_pago,
                    propina: parametrosPedido.propina,
                    domicilio: parametrosPedido.domicilio,
                    metodo_pago_id: 3, // WOMPI
                    direccion: parametrosPedido.direccion,
                    comentario: 'Pedido realizado / App Banco del Agua.',
                    direccion_tipo_domicilio: parametrosPedido.direccion_tipo_domicilio,
                    direccion_dejar_puerta: parametrosPedido.direccion_dejar_puerta,
                    pedido_productos: JSON.stringify(parametrosPedido.pedido_productos),
                    transaccion_id: parametrosPedido.transaccion_id,
                    cartera_id: parametrosPedido.cartera_id
                }

                console.log('Data -> ' + parametrosPeticion);

                const apiPath = this.apiUrl + '/bancodelagua_cliente/getHacerPedidoProductos' + this.peticionDatosAPI;
                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();
                    this.httpHeaders = this.httpHeaders.append('us', parametrosPeticion.usuario);
                    this.httpHeaders = this.httpHeaders.append('u_id', parametrosPeticion.usuario_id);
                    this.httpHeaders = this.httpHeaders.append('t_app', parametrosPeticion.token_app);
                    // Solicitar compra carrito
                    this.httpHeaders = this.httpHeaders.append('total_pago', parametrosPeticion.total_pago);
                    this.httpHeaders = this.httpHeaders.append('comentario', parametrosPeticion.comentario);
                    this.httpHeaders = this.httpHeaders.append('propina', parametrosPeticion.propina);
                    this.httpHeaders = this.httpHeaders.append('domicilio', parametrosPeticion.domicilio);
                    this.httpHeaders = this.httpHeaders.append('tipo_domicilio', parametrosPeticion.tipo_domicilio);
                    this.httpHeaders = this.httpHeaders.append('metodo_pago_id', parametrosPeticion.metodo_pago_id);
                    this.httpHeaders = this.httpHeaders.append('direccion', parametrosPeticion.direccion);
                    this.httpHeaders = this.httpHeaders.append('direccion_tipo_domicilio', parametrosPeticion.direccion_tipo_domicilio);
                    this.httpHeaders = this.httpHeaders.append('direccion_dejar_puerta', parametrosPeticion.direccion_dejar_puerta);
                    this.httpHeaders = this.httpHeaders.append('pedido_productos', parametrosPeticion.pedido_productos);
                    // TRANSACCION 
                    this.httpHeaders = this.httpHeaders.append('transaccion_id', parametrosPeticion.transaccion_id);
                    this.httpHeaders = this.httpHeaders.append('cartera_id', parametrosPeticion.cartera_id);

                    const addPedido = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {

                            console.log('Peticion -> Hacer pedido carrito: ', data);
                            if (data) {
                                if (data.respuesta) {
                                    if (data.respuesta.data) {

                                        if (data.respuesta.data.msg) {
                                            const messages = data.respuesta.data.msg;
                                            this.setChannelMessages(messages);
                                            this.toast.msgAppMultiple(messages, 'bottom');
                                        }

                                        if (data.respuesta.data?.pedido_productos) {

                                            resolve(data.respuesta.data.pedido_productos);

                                        } else {

                                            resolve(false);

                                        }

                                    } else {
                                        resolve(false);
                                    }
                                }
                            }

                            loading.dismiss();

                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);
                            loading.dismiss();
                        })
                    );

                });
            }
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario?.usuario);
        }
    }

    // RECARGAR TARJETA DE CLIENTE / PRE VENDEDOR
    //https://bancodelagua.com/channel/bancodelagua_usuario/getRecargarTarjetaUsario?pass=&empresa=&us=cliente&u_id=
    async confirmarRecargaTarjetaCliente(cliente: any, saldo: number): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('cliente_usuario', cliente.tipo);
                this.httpHeaders = this.httpHeaders.append('cliente_id', cliente.cuenta.id);
                this.httpHeaders = this.httpHeaders.append('saldo', saldo);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getRecargarTarjetaUsario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Recargar tarjeta de cliente: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.tarjeta_recargada) {

                                resolve(data.respuesta.data.tarjeta_recargada);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // REGISTRAR PEDIDO PREVENDEDOR / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getRegistrarVentaPrevendedor?pass=&empresa=&us=cliente&u_id=
    async registrarVentaPrevendedor(datosPedido: any, cliente: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('cliente_usuario', cliente.tipo);
                this.httpHeaders = this.httpHeaders.append('cliente_id', cliente.cuenta.id);

                this.httpHeaders = this.httpHeaders.append('tienda_id', datosPedido.tiendaId);
                this.httpHeaders = this.httpHeaders.append('saldo_total', datosPedido.saldo_total);
                this.httpHeaders = this.httpHeaders.append('forma_pago', datosPedido.formaPago);
                this.httpHeaders = this.httpHeaders.append('comentario', datosPedido.comentario);
                this.httpHeaders = this.httpHeaders.append('tipo_productos', datosPedido.tipoProductos);
                this.httpHeaders = this.httpHeaders.append('productos', JSON.stringify(datosPedido.productos));


                const apiPath = this.apiUrl + '/bancodelagua_usuario/getRegistrarVentaPrevendedor' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const registroVenta = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Registrar venta de Prevendedor: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.venta_tienda) {

                                resolve(data.respuesta.data.venta_tienda);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // HISTORIAL DE VENTAS VENDEDORES / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getHistorialVentas?pass=&empresa=&us=cliente&u_id=
    async historialVentasVendedores(pagination: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();

                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getHistorialVentas' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaHistorial = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Pedidos asociados tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.historial_ventas) {

                                resolve(data.respuesta.data.historial_ventas);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // PEDIDOS ACTIVOS / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getListaPedidosActivosDomiciliario?pass=&empresa=&us=cliente&u_id=
    async consultarPedidosActivosDomiciliario(geolocalizacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {


            if (geolocalizacion?.lat && geolocalizacion?.lng) {

                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();


                    const apiPath = this.apiUrl + '/bancodelagua_usuario/getListaPedidosActivosDomiciliario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token + '&lat=' + geolocalizacion.lat + '&lng=' + geolocalizacion.lng;
                    const listaPedidos = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                            //console.log('Peticion ->  Pedidos asociados domiciliario: ', JSON.stringify(data));
                            if (data) {
                                if (data.respuesta.data.msg) {
                                    const messages = data.respuesta.data.msg;
                                    this.setChannelMessages(messages);
                                    //this.toast.msgAppMultiple(messages, 'bottom');
                                }
                                if (data.respuesta.data.pedidos_asociados) {

                                    resolve(data.respuesta.data.pedidos_asociados);

                                } else {
                                    resolve(false);
                                }
                            }


                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);

                        })
                    );

                });

            } else {
                console.log('Seguimiento de errores -> ', geolocalizacion);
            }

        } else {

            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // PEDIDOS ACTIVOS / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getListaHistorialPedidosDomiciliario?pass=&empresa=&us=cliente&u_id=
    async historialPedidosAsociadosDomiciliario(pagination: any): Promise<any> {
        if (this.authUsuario?.usuario) {


            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getListaHistorialPedidosDomiciliario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaPedidos = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Pedidos asociados domiciliario: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedidos_asociados) {

                                resolve(data.respuesta.data.pedidos_asociados);

                            } else {
                                resolve(false);
                            }
                        }


                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);

                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // PEDIDOS CAJEROS ACTIVOS / TIENDAS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getListaHistorialPedidosCajeroDomiciliario?pass=&empresa=&us=cliente&u_id=
    async consultarPedidosAsociadosCajeroDomiciliario(pagination: any): Promise<any> {
        if (this.authUsuario?.usuario) {


            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                if (pagination) {
                    this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                    this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                }

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getListaHistorialPedidosCajeroDomiciliario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaPedidos = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Pedidos cajeros asociados domiciliario: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedidos_asociados) {

                                resolve(data.respuesta.data.pedidos_asociados);

                            } else {
                                resolve(false);
                            }
                        }


                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);

                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // PEDIDOS ACTIVOS / CAJEROS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getListaPedidosCajerosActivosDomiciliario?pass=&empresa=&us=cliente&u_id=
    async consultarPedidosCajerosActivosDomiciliario(geolocalizacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {

            if (geolocalizacion?.lat && geolocalizacion?.lng) {

                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();


                    const apiPath = this.apiUrl + '/bancodelagua_usuario/getListaPedidosCajerosActivosDomiciliario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token + '&lat=' +
                        geolocalizacion.lat + '&lng=' + geolocalizacion.lng;
                    const listaPedidos = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                            //console.log('Peticion ->  Pedidos asociados domiciliario: ', JSON.stringify(data));
                            if (data) {
                                if (data.respuesta.data.msg) {
                                    const messages = data.respuesta.data.msg;
                                    this.setChannelMessages(messages);
                                    //this.toast.msgAppMultiple(messages, 'bottom');
                                }
                                if (data.respuesta.data.pedidos_asociados) {

                                    resolve(data.respuesta.data.pedidos_asociados);

                                } else {
                                    resolve(false);
                                }
                            }


                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);

                        })
                    );

                });

            } else {
                console.log('Seguimiento de errores -> ', geolocalizacion);
            }


        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // ACEPTAR ENTREGA CLIENTE PEDIDOS ASOCIADOS / CAJEROS DE AGUA
    //https://bancodelagua.com/channel/bancodelagua_usuario/getAceptarEntregaClientePedidoCajero?pass=&empresa=&us=cliente&u_id=
    async confirmarEntregaClientePedidoCajero(pedido: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getAceptarEntregaClientePedidoCajero' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaConfirmarPedido = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Entrega de Pedido Cliente, asociado tienda: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // PEDIDOS DISPONIBLES / TIENDA DE AGUA / USUARIO DOMICILIARIO
    //https://bancodelagua.com/channel/bancodelagua_usuario/getListaPedidosDisponiblesTiendasDomiciliario?pass=&empresa=&us=cliente&u_id=
    async pedidosDisponiblesTiendasDomiciliarios(pagination: any, geolocalizacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            if (geolocalizacion?.lat && geolocalizacion?.lng) {

                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();

                    if (pagination) {
                        this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                        this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                    }

                    const apiPath = this.apiUrl + '/bancodelagua_usuario/getListaPedidosDisponiblesTiendasDomiciliario' + this.peticionDatosAPI + '&lat=' + geolocalizacion.lat
                        + '&lng=' + geolocalizacion.lng + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                    const listaPedidos = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                            //console.log('Peticion -> Pedidos disponibles de tiendas de agua para domiciliarios: ', JSON.stringify(data));
                            if (data) {
                                if (data.respuesta.data.msg) {
                                    const messages = data.respuesta.data.msg;
                                    this.setChannelMessages(messages);
                                    //this.toast.msgAppMultiple(messages, 'bottom');
                                }
                                if (data.respuesta.data.pedidos_disponibles) {

                                    resolve(data.respuesta.data.pedidos_disponibles);

                                } else {
                                    resolve(false);
                                }
                            }
                            loading.dismiss();

                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);
                            loading.dismiss();
                        })
                    );

                });

            } else {
                console.log('Seguimiento de errores -> ', geolocalizacion);
                loading.dismiss();
            }
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }



    // PEDIDOS DISPONIBLES / CAJEROS DE AGUA / USUARIO DOMICILIARIO
    //https://bancodelagua.com/channel/bancodelagua_usuario/getListaPedidosDisponiblesCajerosDomiciliario?pass=&empresa=&us=cliente&u_id=
    async pedidosDisponiblesCajerosDomiciliarios(pagination: any, geolocalizacion: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();


            if (geolocalizacion.lat && geolocalizacion.lng) {

                return new Promise((resolve, reject) => {

                    this.httpHeaders = new HttpParams();

                    if (pagination) {
                        this.httpHeaders = this.httpHeaders.append('page', pagination.page);
                        this.httpHeaders = this.httpHeaders.append('limit', pagination.limit);
                    }

                    const apiPath = this.apiUrl + '/bancodelagua_usuario/getListaPedidosDisponiblesCajerosDomiciliario' + this.peticionDatosAPI + '&lat=' + geolocalizacion.lat
                        + '&lng=' + geolocalizacion.lng + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                    const listaPedidos = this.subscriptionHttp.add(
                        this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                            //console.log('Peticion -> Pedidos disponibles de cajeros de agua para domiciliarios: ', JSON.stringify(data));
                            if (data) {
                                if (data.respuesta.data.msg) {
                                    const messages = data.respuesta.data.msg;
                                    this.setChannelMessages(messages);
                                    //this.toast.msgAppMultiple(messages, 'bottom');
                                }
                                if (data.respuesta.data.pedidos_disponibles) {

                                    resolve(data.respuesta.data.pedidos_disponibles);

                                } else {
                                    resolve(false);
                                }
                            }
                            loading.dismiss();

                        }, (err: any) => {
                            console.log('Seguimiento de errores -> HTTP: ', err);
                            loading.dismiss();
                        })
                    );

                });

            } else {
                console.log('Seguimiento de errores -> ', geolocalizacion);
                loading.dismiss();
            }

        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // ACEPTAR PEDIDO / DOMICILIARIO LIBRE
    //https://bancodelagua.com/channel/bancodelagua_usuario/getAceptarPedidoLibreDomiciliario?pass=&empresa=&us=cliente&u_id=
    async confirmarAceptarPedidoLibre(tipo: any, pedido_id: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('pedido_id', pedido_id);
                this.httpHeaders = this.httpHeaders.append('tipo_pedido', tipo);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getAceptarPedidoLibreDomiciliario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaPedidos = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Aceptar pedido libre por domiciliario: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedidos_disponibles) {

                                resolve(data.respuesta.data.pedidos_disponibles);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // Verificar información cuenta de usuario
    //https://bancodelagua.com/channel/bancodelagua_usuario/getConsultarCuentaUsuarios?pass=&empresa=
    async consultarCuentaUsuario(cuenta: any): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando información...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {
            const apiPath = this.apiUrl + '/bancodelagua_usuario/getConsultarCuentaUsuarios' + this.peticionDatosAPI + '&cuenta=' + cuenta;
            const checkCodigo = this.subscriptionHttp.add(
                this.http.get(apiPath).subscribe((data: any) => {
                    //console.log('Peticion -> Consultar cuenta usuario: ', data);
                    if (data.respuesta?.data) {

                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }

                        if (data.respuesta.data.cuenta) {

                            resolve(data.respuesta.data.cuenta);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });

    }

    // DESCONTAR TRANSFERENCIA / USUARIO
    //https://bancodelagua.com/channel/bancodelagua_usuario/getDescontarSaldoUsuario?pass=&empresa=&us=&u_id=
    async confirmarDescontarTransferenciaUsuario(cuenta: any, saldo: number, motivo: string, tipo: string, cantSuminitro?: number, idSuminitro?: number): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('cuenta', cuenta);
                this.httpHeaders = this.httpHeaders.append('saldo', saldo);
                this.httpHeaders = this.httpHeaders.append('motivo', motivo);
                this.httpHeaders = this.httpHeaders.append('tipo', tipo);

                if (cantSuminitro) {
                    this.httpHeaders = this.httpHeaders.append('cantSuminitro', cantSuminitro);
                }

                if (idSuminitro) {
                    this.httpHeaders = this.httpHeaders.append('idSuminitro', idSuminitro);
                }

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getDescontarSaldoUsuario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Descontar Saldo Usuario: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.cuenta) {

                                resolve(data.respuesta.data.cuenta);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    // DESCONTAR TRANSFERENCIA / USUARIO / SUMINISTROS AGUA 
    //https://bancodelagua.com/channel/bancodelagua_usuario/getRedSuministrosDescuento?pass=&empresa=&us=&u_id=
    async consultarSuministrosDisponiblesDescontar(aquahabiente_id: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                this.httpHeaders = new HttpParams();
                this.httpHeaders = this.httpHeaders.append('aquahabiente_id', aquahabiente_id);

                const apiPath = this.apiUrl + '/bancodelagua_usuario/getRedSuministrosDescuento' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const listaTarjetas = this.subscriptionHttp.add(
                    this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                        //console.log('Peticion ->  Descontar, Lista de suministros: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.red_suministros) {

                                resolve(data.respuesta.data.red_suministros);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // CONFIRMAR REGISTRO VENDEDOR / APP 
    //https://bancodelagua.com/channel/bancodelagua_usuario/getConfirmarRegistroVendedor?pass=&empresa=&us=cliente&u_id=
    async confirmarRegistroVendedor(dataParametros: any, idioma: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {


                const httpOptions = {
                    headers: new HttpHeaders()
                };

                const formData = new FormData();
                formData.append('tipo_documento', dataParametros.tipo_documento);
                formData.append('numero_documento', dataParametros.numero_documento);
                formData.append('ciudad_documento', dataParametros.ciudad_documento);
                formData.append('fecha_documento', dataParametros.fecha_documento);
                formData.append('foto_perfil', dataParametros.foto_perfil, dataParametros.foto_perfil.name);
                formData.append('front_documento', dataParametros.front_documento, dataParametros.front_documento.name);
                formData.append('back_documento', dataParametros.back_documento, dataParametros.back_documento.name);

                const apiPath = this.webUrl + '/' + idioma + '/auth/getConfirmarRegistroVendedor' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const getRegistro = this.subscriptionHttp.add(
                    this.http.post(apiPath, formData, httpOptions).subscribe((data: any) => {
                        //console.log('Peticion ->  Confirmar registro vendedor: ', JSON.stringify(data));

                        if (data) {
                            if (data.data.msg) {
                                const messages = data.data.msg;
                                this.setChannelMessages(messages);
                                this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.data.registro_app) {

                                resolve(data.data.registro_app);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // CONFIRMAR REGISTRO DOMICILIARIO / APP 
    //https://bancodelagua.com/channel/bancodelagua_usuario/getConfirmarRegistroDomiciliario?pass=&empresa=&us=cliente&u_id=
    async confirmarRegistroDomiciliario(dataParametros: any, idioma: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {


                const httpOptions = {
                    headers: new HttpHeaders()
                };

                const formData = new FormData();
                formData.append('tipo_documento', dataParametros.tipo_documento);
                formData.append('numero_documento', dataParametros.numero_documento);
                formData.append('ciudad_documento', dataParametros.ciudad_documento);
                formData.append('fecha_documento', dataParametros.fecha_documento);
                formData.append('foto_perfil', dataParametros.foto_perfil, dataParametros.foto_perfil.name);
                formData.append('front_documento', dataParametros.front_documento, dataParametros.front_documento.name);
                formData.append('back_documento', dataParametros.back_documento, dataParametros.back_documento.name);

                const apiPath = this.webUrl + '/' + idioma + '/auth/getConfirmarRegistroDomiciliario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const getRegistro = this.subscriptionHttp.add(
                    this.http.post(apiPath, formData, httpOptions).subscribe((data: any) => {
                        //console.log('Peticion ->  Confirmar registro vendedor: ', JSON.stringify(data));

                        if (data) {
                            if (data.data.msg) {
                                const messages = data.data.msg;
                                this.setChannelMessages(messages);
                                this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.data.registro_app) {

                                resolve(data.data.registro_app);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }

    /*
    // CONFIRMAR FOTO DE PEDIDO / DOMICILIARIO
    //https://bancodelagua.com/channel/bancodelagua_usuario/getConfirmarFotoPedidoDomiciliario?pass=&empresa=&us=cliente&u_id=
    async confirmarFotoPedidoDomiciliario(dataParametros: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {

                const httpOptions = {
                    headers: new HttpHeaders()
                };

                const formData = new FormData();
                formData.append('pedido_id', dataParametros.pedido_id);
                formData.append('foto_pedido', dataParametros.foto_pedido);
             
                const apiPath = this.apiUrl + '/bancodelagua_usuario/getConfirmarFotoPedidoDomiciliario' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const getRegistro = this.subscriptionHttp.add(
                    this.http.post(apiPath, formData, httpOptions).subscribe((data: any) => {
                        console.log('Peticion ->  Confirmar foto pedido: ', JSON.stringify(data));
                        if (data) {
                            if (data.respuesta.data.msg) {
                                const messages = data.respuesta.data.msg;
                                this.setChannelMessages(messages);
                                //this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.respuesta.data.pedido_asociado) {

                                resolve(data.respuesta.data.pedido_asociado);

                            } else {
                                resolve(false);
                            }
                        }
                       
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }
    */


    // ENVIAR MENSAJE DE CONTACTO
    //https://bancodelagua.com/channel/bancodelagua/getMensajeContacto?pass=&empresa=&us=cliente&u_id=
    async enviarMensajeContacto(dataParametros: any, idioma: any): Promise<any> {
        if (this.authUsuario?.usuario) {
            const loading = await this.loadingCtrl.create({
                message: 'Cargando...',
                spinner: 'crescent',
                showBackdrop: true
            });
            loading.present();

            return new Promise((resolve, reject) => {


                const httpOptions = {
                    headers: new HttpHeaders()
                };

                const formData = new FormData();
                formData.append('asunto', dataParametros.asunto);
                formData.append('categoria', dataParametros.categoria);
                formData.append('mensaje', dataParametros.mensaje);

                if (dataParametros?.file_imagen) {
                    formData.append('file_imagen', dataParametros.file_imagen, dataParametros.file_imagen.name);
                }

                const apiPath = this.webUrl + '/' + idioma + '/auth/getEnviarmensaje' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
                const getRegistro = this.subscriptionHttp.add(
                    this.http.post(apiPath, formData, httpOptions).subscribe((data: any) => {
                        //console.log('Peticion ->  Enviar mensaje de contacto: ', JSON.stringify(data));

                        if (data) {
                            if (data.data.msg) {
                                const messages = data.data.msg;
                                this.setChannelMessages(messages);
                                this.toast.msgAppMultiple(messages, 'bottom');
                            }
                            if (data.data.mensaje_app) {

                                resolve(data.data.mensaje_app);

                            } else {
                                resolve(false);
                            }
                        }
                        loading.dismiss();

                    }, (err: any) => {
                        console.log('Seguimiento de errores -> HTTP: ', err);
                        loading.dismiss();
                    })
                );

            });
        } else {
            console.log('Seguimiento de errores -> ', this.authUsuario);
        }
    }


    // ASOCIAR SUMINISTRO DE AGUA
    //https://bancodelagua.com/channel/bancodelagua/getAsociarSuministro?pass=&empresa=
    async asociarSuministro(parametros: any): Promise<any> {
        const loading = await this.loadingCtrl.create({
            message: 'Verificando información...',
            spinner: 'crescent',
            showBackdrop: true
        });
        loading.present();

        return new Promise((resolve, reject) => {

            this.httpHeaders = new HttpParams();
            this.httpHeaders = this.httpHeaders.append('codigo', parametros.codigo);
            this.httpHeaders = this.httpHeaders.append('clave', parametros.clave);
            this.httpHeaders = this.httpHeaders.append('correo', parametros.correo);

            const apiPath = this.apiUrl + '/bancodelagua/getAsociarSuministro' + this.peticionDatosAPI + '&us=' + this.authUsuario.usuario + '&u_id=' + this.authUsuario.usuario_id + '&t_app=' + this.authUsuario.app_token;
            const getSuministro = this.subscriptionHttp.add(
                this.http.get(apiPath, { params: this.httpHeaders }).subscribe((data: any) => {
                    console.log('Peticion -> Asociar suministro: ', data);
                    if (data.respuesta?.data) {

                        if (data.respuesta.data.msg) {
                            const messages = data.respuesta.data.msg;
                            this.setChannelMessages(messages);
                            //this.toast.msgAppMultiple(messages, 'bottom');
                        }

                        if (data.respuesta.data.suministro_agua) {
                            resolve(data.respuesta.data.suministro_agua);
                        } else {
                            resolve(false);
                        }
                    }
                    loading.dismiss();

                }, (err: any) => {
                    console.log('Seguimiento de errores -> HTTP: ', err);
                    loading.dismiss();
                })
            );

        });

    }

}
