import { Injectable, OnDestroy } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService implements OnDestroy {

  private subscription: Subscription = new Subscription();
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  defaultLang = 'es';

  constructor(
    private translateService: TranslateService,
    private loadingCtrl: LoadingController,
  ) {
    console.log('App translate...');
    this.translateService.setDefaultLang(this.defaultLang);
    this.translateService.use(this.defaultLang);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getDefaultLanguage() {
    let language = this.translateService.getBrowserLang();
    this.translateService.setDefaultLang(language);
    return language;
  }

  setLanguage(setLang) {
    this.translateService.use(setLang);
  }

  async getItems(id: string, value?: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (id) {

        const loading = await this.loadingCtrl.create({
          message: 'Cargando idioma...',
          spinner: 'crescent',
          showBackdrop: true
        });
        loading.present();

        console.log('Lista de items de idioma');
        if (value) {
          this.subscription.add(
            this.translateService.get(id, { value: value }).subscribe((res: string) => {
              console.log('Item de idioma con condicion ' + value, res);

              if (res) {
                resolve(res);
              } else {
                resolve(false);
              }

              loading.dismiss();

            })
          );
        } else {
          this.subscription.add(
            this.translateService.get(id).subscribe((res: string) => {
              console.log('Item de idioma sin condicion.', res);

              if (res) {
                resolve(res);
              } else {
                resolve(false);
              }

              loading.dismiss();

            })
          );
        }

      }
    });
  }
  /*
    async getItem(id: string, value?: string) {
      if (id) {
  
        const loading = await this.loadingCtrl.create({
          message: 'Cargando idioma...',
          spinner: 'crescent',
          showBackdrop: true
        });
        loading.present();
  
        console.log('Get Item de idioma');
        if (value) {
          this.subscription.add(
            this.translateService.get(id, { value: 'world' }).subscribe((res: string) => {
              console.log('Item de idioma', res);
              loading.dismiss();
            })
          );
        } else {
          this.subscription.add(
            this.translateService.get(id).subscribe((res: string) => {
              console.log('Item de idioma', res);
              loading.dismiss();
            })
          );
        }
  
      }
    }
    */

  async initAppIdioma(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getItems('APP').then((items: any) => {
        console.log('App -> Lista de items: ', items);
        if (items) {
          this.itemsIdiomaApp = items; // IDIOMA APP
          this.setItemsIdiomaApp(this.itemsIdiomaApp);
          resolve(this.itemsIdiomaApp);
        } else {
          resolve(false);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
        resolve(false);
      });
    });
  }

  async itemsIdiomaPagina(pagina: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getItems(pagina).then((items: any) => {
        console.log('Pagina -> Lista de items: ', items);
        if (items) {
          this.itemsIdioma = items; // IDIOMA PAGINA
          this.setItemsIdioma(this.itemsIdioma);
          resolve(this.itemsIdioma);
        } else {
          resolve(false);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
        resolve(false);
      });
    });
  }

  getItemsIdiomaApp() {
    if (this.itemsIdiomaApp) {
      return this.itemsIdiomaApp;
    } else {
      return false;
    }
  }

  setItemsIdioma(data) {
    if (data) {
      this.itemsIdioma = data;
    }
  }

  setItemsIdiomaApp(data) {
    if (data) {
      this.itemsIdiomaApp = data;
    }
  }

  getItemsIdioma() {
    if (this.itemsIdioma) {
      return this.itemsIdioma;
    } else {
      return false;
    }
  }

  async resetIdioma(pagina: string) {
    console.log('Seguimiento de errores -> Re intentando idioma...');
  }

}
