import { Component, Input, OnInit } from '@angular/core';
import { DetalleProductoTiendaUsuarioComponent } from '../detalle-producto-tienda-usuario/detalle-producto-tienda-usuario.component';
import { NavController, ModalController, LoadingController } from '@ionic/angular';
import { AppComollegarComponent } from 'src/app/components/app-comollegar/app-comollegar.component';
import { Header } from 'src/app/interfaces/book.interface';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-detalle-tienda-usuario',
  templateUrl: './detalle-tienda-usuario.component.html',
  styleUrls: ['./detalle-tienda-usuario.component.scss'],
})
export class DetalleTiendaUsuarioComponent implements OnInit {

 
  @Input() tienda: any;

  pedido: any;

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  aquaBot = {
    msg: '',
    estado: ''
  };

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'TIENDAS_AGUA_USUARIO_DETALLE_TIENDA';

  authUsuario: any = null;
  defaultImage = 'assets/img/default.png';

  cantidadProducto: number = 0;
  cantidadInput = 0;
  productoCarrito: any;

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private toast: ToastService,
    private redatwater: RedatwaterService,
    private translateConfigService: TranslateConfigService,
    private aquabot: AquabotService,
    private geolocalizacion: GeolocalizacionService,
    private auth: AuthService,
    private carrito: CarritoService,
    private loadingCtrl: LoadingController,
    private socialCompartir: SocialSharing,
    private utilitario: UtilitariosService
  ) {
    console.log('Ventana -> detalle de tienda');
    this.header.pagina = '/redatwater-usuario';
    this.header.titulo = 'Detalle de tienda';

    this.aquaBot = this.aquabot.appMsgAquaBot({
      msg: 'Ahorra con estilo, cuida el agua con Banco del Agua.',
      estado: 'text-success'
    });

  }

  ngOnInit() {
    console.log('Detalle tienda -> ', this.tienda);
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {

              this.authUsuario = getAuth.data;

            } else {
              this.navCtrl?.navigateRoot(['/logout']);
            }
          });

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }

  async ventanaDetalleProducto(tienda: any, producto: any) { // Detalle producto

    const modalAdd = await this.modalController.create({
      component: DetalleProductoTiendaUsuarioComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { tienda, producto }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    } else {
      this.toast.msgAppBottom('¡Oops! Detalle de producto incorrecta.', 'danger');
    }
  }

  async detalleProducto(tienda: any, producto: any) {
    if (tienda && producto) {
      console.log('Seguimiento -> Detalle de producto ', producto);
      this.ventanaDetalleProducto(tienda, producto);
    } else {
      console.log('Seguimiento de errores -> ', producto);
    }
  }

  async llamarTienda(tienda: any) {
    if (tienda) {
      console.log('Llamar a tienda', tienda);
      //this.aquabot.enlaceContacto('tel:' + '' + tienda.celular, 'Número de contacto: ' + tienda.celular);
    } else {
      console.log('Seguimiento de errores -> llamar: ', tienda);
    }
  }

  async mailTienda(tienda: any) {
    if (tienda) {
      console.log('Correo a tienda', tienda);
      //this.aquabot.enlaceContacto('mailto:' + tienda.email, 'Correo electrónico');
    } else {
      console.log('Seguimiento de errores -> correo: ', tienda);
    }
  }

  async llegarTienda(tienda: any) {

    if (tienda) {
      console.log('Llegar a tienda', tienda);
      this.ventanaComoLlegar(tienda);
    } else {
      console.log('Seguimiento de errores -> como llegar: ', tienda);
    }

  }

  async ventanaComoLlegar(tienda: any) {

    if (tienda) {

      const registroSeleccionado = {
        tipo: 'tienda',
        registro: tienda
      };

      const modalAdd = await this.modalController.create({
        component: AppComollegarComponent,
        mode: 'ios',
        swipeToClose: true,
        componentProps: { registroSeleccionado }
      });
      await modalAdd.present();
      const { data } = await modalAdd.onWillDismiss();
      if (data) {
        console.log('Finalizar datos -> ' + data);
      } else {
        this.toast.msgAppBottom('¡Oops! Como llegar incorrecta.', 'danger');
      }

    } else {
      console.log('Seguimiento de errores -> ', tienda);
    }

  }

  async compartir(tienda: any) {
    const mensaje = `¡Red AT-WATER © Tienda de Agua: ${tienda.titulo}! Contacto: Tel ${tienda.celular}, Correo: ${tienda.email}, Dirección: ${tienda.direccion}`;
    const asunto = 'Compartido desde ® Banco del Agua';
    const imagen = tienda.imagen; // URL de la imagen a compartir
    const enlace = this.redatwater.getWebUrl(); // Enlace web

    const loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    this.socialCompartir.share(mensaje, asunto, imagen, enlace)
      .then(() => {
        loading.dismiss();
        console.log('Compartido exitosamente: ', tienda);
      })
      .catch((error) => {
        loading.dismiss();
        console.error('Seguimiento de errores -> Error al compartir', error);
      });
  }

  sitioTienda(tienda: any) {
    console.log('Sitio web tienda. ', tienda);
    if (tienda?.empresa_pagina_web) {
      this.aquabot.enlaceSugerido(tienda.empresa_pagina_web, 'Sitio web');
    }
  }

}
