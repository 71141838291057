import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import { Header, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TextSpeechService } from 'src/app/services/text-speech.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { AlertService } from 'src/app/services/alert.service';
import { AppComollegarComponent } from 'src/app/components/app-comollegar/app-comollegar.component';

@Component({
  selector: 'app-ok-pedido-domiciliario',
  templateUrl: './ok-pedido-domiciliario.component.html',
  styleUrls: ['./ok-pedido-domiciliario.component.scss'],
})
export class OkPedidoDomiciliarioComponent implements OnInit, OnDestroy {

  @Input() pedido: any;

  authUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_USUARIOS_OK_PEDIDO_DOMICILIARIO';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 1,
    pagina: '',
    titulo: ''
  }

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Establecer ubicación.',
    coordenadas: {
      lat: null,
      lng: null
    }
  };

  defaultImage = 'assets/img/default.png';

  imgFactura = 'assets/img/iconos/factura.png';
  productoSeleccionado = 0; // Inicialmente seleccionamos el producto 0

  imgSuministroAgua = '';
  calificacionCliente = '';
  estadoPedidoEntrega = '';
  intercambiarEnvase = '';
  viewOpcion: string = '';

  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private navCtrl: NavController,
    private modalController: ModalController,
    private alertCtr: AlertController,
    private ventanaAlerta: AlertService,
    private aquabot: AquabotService,
    private textSpeech: TextSpeechService
  ) {
    console.log('App -> Ok Pedido tienda!');
    this.header.pagina = '/redatwater-usuario';
    this.header.titulo = 'DETALLE PEDIDO';

  }

  ngOnInit() {

    console.log('Seguimiento -> Pedido ', this.pedido);

    this.detallePedido();

    this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getUsuario: any) => {
      console.log('Usuario Auth ->', getUsuario);
      if (getUsuario?.data) {
        this.authUsuario = getUsuario.data;
        console.log('Seguimiento -> Auth dispositivo: ', this.authUsuario);
        this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
          this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
            this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
            this.itemsIdioma = this.translateConfigService.getItemsIdioma();

            if (this.itemsIdiomaApp && this.itemsIdioma) {
              //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
              //console.log('Parametros de idioma -> ', this.itemsIdioma);
              //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);

              this.aquaBot = this.aquabot.appMsgAquaBot({
                msg: this.itemsIdiomaApp.aquabot_msg_cajeros,
                estado: 'text-primary'
              });

              this.viewOpcion = 'info_pedido';

            } else {
              this.translateConfigService.resetIdioma(this.idiomaPagina);
            }
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      } else {
        this.navCtrl?.navigateRoot(['/logout']);
      }
    });

  }

  detallePedido() {

    if (this.pedido.pedido?.calificacion_usuario_cliente) {
      this.calificacionCliente = this.pedido.pedido.calificacion_usuario_cliente;
    } else {
      this.calificacionCliente = '';
    }

    if (this.pedido.pedido.pedido_agua[0]?.intercambiar === '2') {
      this.intercambiarEnvase = 'Si';
    } else {
      this.intercambiarEnvase = 'No';
    }

    switch (this.pedido.pedido?.entregado) {

      case '1':
        this.estadoPedidoEntrega = 'solicitado';
        break;

      case '2':
        this.estadoPedidoEntrega = 'procesado';
        break;

      case '3':
        this.estadoPedidoEntrega = 'enviado';
        break;

      case '4':
        this.estadoPedidoEntrega = 'entregado';
        break;

      default:
        this.estadoPedidoEntrega = '';
        break;

    }
  }

  ngOnDestroy() {

  }

  seleccionarViewOpcion(view: any) {
    if (view) {
      console.log('Seleccionar view -> ', view);
      this.viewOpcion = view;
    }
  }


  establecerImagenAgua(presentacion: any) {
    this.imgSuministroAgua = '';
    if (parseInt(presentacion) >= 1) {
      console.log('Seguimiento -> Presentacion ID', presentacion);
      switch (presentacion) {

        case '1':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/1litro.png';
          break;

        case '5':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/5litros.png';
          break;

        case '10':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/10litros.png';
          break;

        case '19':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/19litros.png';
          break;

        default:
          this.imgSuministroAgua = '';
          break;

      }

    } else {
      console.log('Seguimiento de errores -> ', presentacion);
    }
  }

  entregarPedidoCliente() {
    console.log('Entregar pedido Cliente -> ', this.pedido);
    this.confirmarEntregaPedidoCliente();
  }

  async confirmarEntregaPedidoCliente() {
    console.log('Entregar Pedido al Cliente: ', this.pedido);

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: '¡Confirmar Entrega!',
      message: '¿Estás seguro que deseas <span class="text-success">entregar el pedido</span> al cliente?',
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Entrega cancelada');
          }
        },
        {
          text: 'Confirmar Entrega',
          handler: (data: any) => {
            console.log('Entrega confirmada!');
            // Aquí puedes agregar la lógica para marcar el pedido como entregado al cliente y realizar cualquier otra acción necesaria.
            this.confirmarEntregaClientePedido();
          }
        }
      ]
    });

    await alert.present();
  }


  async confirmarEntregaClientePedido() {
    console.log('Confirmar Cliente Entregade pedido');
    if (this.pedido.pedido?.id) {

      this.redatwater.confirmarEntregaClientePedidoDomiciliarioTiendas(this.pedido.pedido.id).then((pedido: any) => {

        if (pedido?.pedido) {

          this.pedido = pedido;

          this.detallePedido();

        } else {
          console.log('Seguimiento de errores -> ', pedido);
          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Información incorrecta, Portal Transaccional.');
        }

      }).catch((error: any) => {
        console.log('Seguimiento de errores -> ', error);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
      });

    } else {
      console.log('Seguimiento de errores ->', this.pedido);
    }
  }

  seleccionarProducto(index: number) {
    this.productoSeleccionado = index; // Cambiar la selección al índice del producto seleccionado
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    if (this.modalController) {
      this.modalController.dismiss({ pedido: this.pedido });
    }
  }

  async calificarCliente() {
    console.log('Calificar al cliente');

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: 'Califica tu experiencia',
      message: 'Por favor, selecciona una opción para <span class="text-success">calificar al cliente:</span>',
      inputs: [
        {
          type: 'radio',
          label: 'Excelente',
          value: 3,
          checked: false
        },
        {
          type: 'radio',
          label: 'Bueno',
          value: 2,
          checked: false
        },
        {
          type: 'radio',
          label: 'Malo',
          value: 1,
          checked: false
        }
      ],
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Calificación cancelada');
          }
        },
        {
          text: 'Enviar Calificación',
          handler: (data: any) => {
            if (data === 1 || data === 2 || data === 3) {

              const seleccionado = data;
              console.log('Calificación enviada:', seleccionado);
              this.confirmarCalificacionCliente(seleccionado);

            } else {
              console.log('Seguimiento de errores -> ', data);
              this.calificarCliente();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async confirmarCalificacionCliente(calificacion: any) {
    console.log('Confirmar Calificacion de pedido');
    if (this.pedido.pedido?.id) {

      this.redatwater.confirmarCalificacionCliente(this.pedido.pedido.id, calificacion).then((pedido: any) => {

        if (pedido?.pedido) {

          this.pedido = pedido;

          this.detallePedido();

        } else {
          console.log('Seguimiento de errores -> ', pedido);
          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Información incorrecta, Portal Transaccional.');
        }

      }).catch((error: any) => {
        console.log('Seguimiento de errores -> ', error);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
      });

    } else {
      console.log('Seguimiento de errores ->', this.pedido);
    }
  }

  async comoLlegar(direccion: any, proceso: any) {
    if (direccion) {

      console.log('Llegar a pedido', this.pedido);
      this.ventanaComoLlegar(direccion, proceso);

    } else {
      console.log('Seguimiento de errores ->', direccion);
    }
  }

  async ventanaComoLlegar(direccion: any, proceso: any) {

    const registroSeleccionado = {
      tipo: 'domicilio_tienda',
      registro: this.pedido,
      direccion: direccion,
      proceso: proceso
    };

    const modalAdd = await this.modalController.create({
      component: AppComollegarComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { registroSeleccionado }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      console.log('Finalizar datos -> ' + data);
    } else {

    }

  }


  liberarDomicilio() {
    console.log('Seguimiento -> Liberar domicilio');
    this.confirmarLiberarPedido();
  }

  async confirmarLiberarPedido() {

    console.log('Liberar pedido: ', this.pedido);

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: 'Confirmación de Liberación de Pedido',
      message: '<span class="text-success">Estás a punto de liberar este pedido.</span> Si no puedes realizar la entrega, otro domiciliario podrá aceptarla y encargarse de completarla.',
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cerrar!');
          }
        }, {
          text: 'Aceptar & Continuar',
          handler: (data: any) => {
            console.log('Ok!');
            this.okLiberarPedido();
          }
        }
      ]
    });

    await alert.present();

  }

  async okLiberarPedido() {

    console.log('Seguimiento -> Liberar pedido para domicilio', this.pedido);

    if (this.pedido.pedido?.id) {

      this.redatwater.confirmarLiberarPedidoDomiciliario(this.pedido.pedido.id).then((pedido: any) => {

        if (pedido?.pedido) {

          this.ventanaAlerta.msgAppCenter('RED AT-WATER', 'El pedido ha sido  <span class="text-success">liberado para entrega</span>');
          this.cerrarVentana();
          console.log('Pedido liberado -> ', pedido);

        } else {
          console.log('Seguimiento de errores -> ', pedido);
        }

      }).catch((error: any) => {
        console.log('Seguimiento de errores -> ', error);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
      })

    } else {
      console.log('Seguimiento de errores ->', this.pedido);
    }

  }
/*
  async seleccionarFotoEntrega() {
   
  }

  async subirFotoAlPedido(imagePath: string) {

    console.log('Imagen a subir -> ', imagePath);
    let parametrosData = {
      pedido_id: this.pedido.pedido.id,
      foto_pedido: imagePath
    }
    this.redatwater.confirmarFotoPedidoDomiciliario(parametrosData).then((getData: any) => {

      if (getData) {

        console.log('Seguimiento -> ', getData);
        // this.confirmarEntregaClientePedido();

      } else {

        console.log('Seguimiento de errores -> ', getData);

      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });

  }
  */

}
