import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController, ActionSheetController, MenuController, AlertController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { EscanerService } from 'src/app/services/escaner.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';

@Component({
  selector: 'app-recargar-tarjeta-cliente',
  templateUrl: './recargar-tarjeta-cliente.component.html',
  styleUrls: ['./recargar-tarjeta-cliente.component.scss'],
})
export class RecargarTarjetaClienteComponent implements OnInit {

  @Input() cliente: any;

  authUsuario: any = null;
  cuentaUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'RECARGAR_SALDO_CLIENTE_USUARIO';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  defaultImage = 'assets/img/default.png';

  buscarCuenta: any = {
    tipo_documento: 'aquahabiente',
    documento: ''
  }


  valorRecarga: number;
  btnViewRecargar: boolean = false;
  btnViewCerrar: boolean = false;

  constructor(
    private navCtrl: NavController,
    private geolocalizacion: GeolocalizacionService,
    private redatwater: RedatwaterService,
    private modalController: ModalController,
    private aquabot: AquabotService,
    private escaner: EscanerService,
    private auth: AuthService,
    private ventanaAlerta: AlertService,
    private actionSheetCtrl: ActionSheetController,
    private menu: MenuController,
    private alertCtr: AlertController,
    private translateConfigService: TranslateConfigService,
    private utilitario: UtilitariosService,
    private toast: ToastService,
  ) {
    console.log('App: Recargar saldo tarjeta');
    this.header.pagina = '/redatwater-usuario';
    this.header.titulo = 'Recargar Tarjeta';
  }

  ngOnInit() {

    console.log('Cliente -> ', this.cliente);
    this.buscarCuenta.documento = this.cliente.cuenta.codigo;
    
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data.id) {
              this.authUsuario = getAuth.data;

              this.consultarFullCuenta();

            } else {
              this.navCtrl?.navigateRoot(['/logout']);
            }

          });

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });

  }


  // INICAR SESION => PORTAL TRANSACCIONAL
  iniciarPortalTransaccional() {
    console.log('Portal transaccional -> Auto Iniciar sesion. ', this.authUsuario.codigo, this.authUsuario.app_token);
    if (this.authUsuario.codigo && this.authUsuario.app_token) {
      this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/auth/loginApp?numero=' + this.authUsuario.codigo + '&auth_app=' + this.authUsuario.app_token, 'portal transaccional');
    } else {
      this.portalTransaccional();
    }
  }

  portalTransaccional() {
    console.log('Portal transaccional -> Iniciar sesion. ', this.authUsuario.codigo);
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/auth?us_co=' + this.authUsuario.codigo, 'portal transaccional');
  }

  async consultarFullCuenta() {
    this.redatwater.consultarFullCuentaUsuario().then((getData: any) => {
      if (getData) {

        console.log('Full data cuenta usuario -> ', getData);
        this.cuentaUsuario = getData;

        if (parseInt(this.cuentaUsuario.infoUsuario.transferencia) > 0) {

          console.log('Saldo transferencia disponible -> ', this.cuentaUsuario.infoUsuario);
          this.btnViewRecargar = true;

        } else {

          console.log('Saldo transferencia -> ', this.cuentaUsuario.infoUsuario);
          this.btnViewRecargar = false;

        }

      } else {
        console.log('Seguimiento de errores -> ', getData);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Información incorrecta, Portal Transaccional.');
      }

    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
      this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
    });
  }

  recargarTarjeta() {
    console.log('Recargar tarjeta -> ', this.valorRecarga);
    if (this.valorRecarga > 0) {

      if (this.valorRecarga <= parseInt(this.cuentaUsuario.infoUsuario.transferencia)) {

        this.confimarRecargaTarjeta(this.cliente);

      } else {
        console.log('Seguimiento de errores -> ', this.valorRecarga);
        this.btnViewRecargar = true;
        this.btnViewCerrar = false;
      }

    } else {
      console.log('Seguimiento de errores -> ', this.valorRecarga);
      this.btnViewRecargar = true;
      this.btnViewCerrar = false;
    }
  }

  async confimarRecargaTarjeta(cliente: any) {

    this.redatwater.confirmarRecargaTarjetaCliente(cliente, this.valorRecarga).then((getData: any) => {
      if (getData) {

        console.log('Tarjeta recargada -> ', getData);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-success">¡Objetivo cumplido! </span> Tarjeta AQUAHABIENTE recargada con éxito.');
        this.infoCuenta();
        this.consultarFullCuenta();
        this.valorRecarga = 0;
        this.btnViewRecargar = false;
        this.btnViewCerrar = true;

      } else {
        console.log('Seguimiento de errores -> ', getData);
        this.btnViewRecargar = true;
        this.btnViewCerrar = false;
      }
    }).catch((error: any) => {
      console.log('Seguimiento de errores -> ', error);
      this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
      this.btnViewRecargar = true;
      this.btnViewCerrar = false;
    });

  }

  async infoCuenta() {

    if (this.buscarCuenta.tipo_documento && this.buscarCuenta.documento) {

      this.redatwater.consultarCuentaCliente(this.buscarCuenta).then((getData: any) => {
        if (getData) {

          console.log('Cuenta usuario -> ', getData);

          if (getData?.tipo) {

            this.cliente = getData;

          } else {
            console.log('Seguimiento de errores -> ', getData);

          }

        } else {
          console.log('Seguimiento de errores -> ', getData);

        }

      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);

      });

    } else {
      console.log('Seguimiento de errores -> ', this.buscarCuenta);

    }
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss({ cliente: this.cliente });
  }


}
