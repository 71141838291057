import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()

export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private navCtrl: NavController
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve, reject) => {
      this.auth.loadAuthToken().then(async (getUsuario: any) => {
        //console.log('Dispositivo Auth ->', getUsuario);
        if (getUsuario) {
          this.auth.setUpdateAuth(getUsuario);
          resolve(true);
        } else {
          this.navCtrl?.navigateRoot(['/home']);
          resolve(false);
        }
      }).catch((err:any) => {
        console.log('Seguimiento de errores -> ', err);
        resolve(true);
      });
    });
  }

}
