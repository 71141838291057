import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, OnInit, Renderer2 } from '@angular/core';
import { ModalController, LoadingController, NavController } from '@ionic/angular';
import { Header, IAuth, IDireccionesUsuario, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { GooglemapsService } from 'src/app/utilitarios/googlemaps/googlemaps.service';
import { environment } from 'src/environments/environment';
import { VerificarUbicacionComponent } from './verificar-ubicacion/verificar-ubicacion.component';

declare var google: any;

@Component({
  selector: 'app-agregar-direccion',
  templateUrl: './agregar-direccion.component.html',
  styleUrls: ['./agregar-direccion.component.scss'],
})
export class AgregarDireccionComponent implements OnInit {

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  inputBuscar: '';

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Consultando...',
    coordenadas: {
      lat: null,
      lng: null
    }
  };
  fullDirecciones: IDireccionesUsuario[];
  direccionUsuario: IDireccionesUsuario;
  listadoUbicacion = true;
  map: any;
  googleAutocomplete: any;
  resultadosBusqueda = new Array<any>();

  private authUsuario: IAuth;

  btnUbicacion:boolean = false;


  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document,
    private redatwater: RedatwaterService,
    private geolocalizacion: GeolocalizacionService,
    private googlemapsService: GooglemapsService,
    private auth: AuthService,
    private ngZone: NgZone,
    private utilitarios: UtilitariosService,
    private modalController: ModalController,
    public navCtrl: NavController,
    public alertCtrl: AlertService,
    public toast: ToastService
  ) {
    console.log('Ventana -> asociar dirección');
    this.header.pagina = '/redatwater';
    this.header.titulo = 'Agregar o escoge una dirección';
  }

  ngOnInit() {
    this.auth.loadAuthToken().then((dataUsuario: any) => {
      if (dataUsuario) {

        this.googlemapsService.init(this.renderer, this.document).then(() => {
          this.initGoogleMaps();
        }).catch((err) => {
          console.log(err);
        });


        //console.log('Auth -> Info Usuario: ' + JSON.stringify(dataUsuario));
        this.authUsuario = dataUsuario;
        this.geolocalizacion.establecerUbicacionActual('geolocalizacion').then((dataUbicacion) => {
          console.log('Ventana -> Establecer ubicacion. ' + dataUbicacion.direccion);
          if (dataUbicacion.direccion) {
            this.direccionActual = dataUbicacion;
            this.btnUbicacion = true;
          } else {
            console.log('Ventana -> Error informacion geolocalizacion.');
          }
          console.log();
        }).catch(error => {
          console.log('Ventana -> Error establecer ubicacion actual.' + error);
        });

        console.log('Ventana -> Consulta direcciones.');
        this.consultarDirecciones();

      } else {
        console.log('Auth -> Error! Información de usuario.');
        this.navCtrl?.navigateRoot(['/logout']);
      }
    });
  }

  ngOnDestroy() {

  }

  async finalizarUbicacion() {
    if (this.inputBuscar) {

    }
  }

  async consultarDirecciones() {
    //console.log(usuario_id);
    this.redatwater.consultarDireccionesUsuario().then((direcciones: any) => {
      //console.log('App -> Direcciones: ' + direcciones);
      this.fullDirecciones = direcciones;
    }).catch(error => {
      console.log('App -> Error en consultar Direcciones. ' + error);
    });
  }


  async seleccionarUbicacion(metodo: string, direccion?: any) {

    if (metodo === 'automatico') {

      const estado_id = 1;

      console.log('Ventana -> Seleccion automatico');
      this.direccionUsuario = {
        id: this.direccionActual.place_id,
        titulo: this.direccionActual.direccion,
        usuario: this.authUsuario.usuario,
        usuario_id: this.authUsuario.usuario_id,
        latitud: this.direccionActual.coordenadas.lat,
        longitud: this.direccionActual.coordenadas.lng,
        direccion: this.direccionActual.direccion,
        estado: '',
        estado_id: estado_id
      };
      //console.log('Id Direccion -> ' + this.direccionUsuario);
      this.modalController.dismiss(this.direccionUsuario);

    } else if (metodo === 'manual' && direccion) {

      console.log('Ventana -> Seleccion manual');
      //console.log('Id Direccion -> ' + direccion);

      this.direccionUsuario = direccion;
      this.modalController.dismiss(this.direccionUsuario);

    } else {
      console.log('Ventana -> Error seleccionar Ubicacion.');
    }

  }

  async guardarBusqueda(ubicacion: any) {

    this.geolocalizacion.consultarDireccionTexto(ubicacion).then((dataUbicacion: any) => {
      console.log('Geolocalizacion -> Guardar busqueda: ' + dataUbicacion.geometry.location);
      this.listadoUbicacion = true;


      console.log('Ventana -> Seleccion por busqueda');
      const estado_id = 1;
      this.direccionUsuario = {
        id: dataUbicacion.place_id,
        titulo: dataUbicacion.formatted_address,
        usuario: this.authUsuario.usuario,
        usuario_id: this.authUsuario.usuario_id,
        latitud: dataUbicacion.geometry.location.lat,
        longitud: dataUbicacion.geometry.location.lng,
        direccion: dataUbicacion.formatted_address,
        estado: '',
        estado_id: estado_id
      };
      //console.log('Id Direccion -> ' + this.direccionUsuario);
      this.verificarUbicacion();

    }).catch(error => {
      console.log('Geolocalizacion -> Error guardar busqueda: ' + error);
    });

  }

  async verificarUbicacion() {

    this.direccionActual = {
      place_id: this.direccionUsuario.id,
      direccion: this.direccionUsuario.direccion,
      coordenadas: {
        lat: this.direccionUsuario.latitud,
        lng: this.direccionUsuario.longitud
      }
    };

    const inputUbicacion = this.direccionActual;

    const modalAdd = await this.modalController.create({
      component: VerificarUbicacionComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { inputUbicacion }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data) {
      if (data.ubicacion.place_id) {
        console.log('Finalizar datos -> ' + data);
        const estado_id = 1;
        this.direccionActual = data.ubicacion;
        this.direccionUsuario = {
          id: this.direccionActual.place_id,
          titulo: data.titulo, // Alias
          usuario: this.authUsuario.usuario,
          usuario_id: this.authUsuario.usuario_id,
          latitud: this.direccionActual.coordenadas.lat,
          longitud: this.direccionActual.coordenadas.lng,
          direccion: this.direccionActual.direccion,
          estado: '',
          estado_id: estado_id
        };
        this.geolocalizacion.setUbicacionActualSession(this.direccionActual);
        if (data.ponerTitulo === true) {
          console.log('Ventana -> Guardar direccion.');
          const parametrosDireccion = {
            usuario: this.direccionUsuario.usuario,
            usuario_id: this.direccionUsuario.usuario_id,
            titulo: this.direccionUsuario.titulo,
            direccion: this.direccionUsuario.direccion,
            lat: this.direccionUsuario.latitud,
            lng: this.direccionUsuario.longitud,
          }
          this.redatwater.guardarDireccion(parametrosDireccion).then((dataRegistro: any) => {
            if (dataRegistro) {
              console.log('Guardar direccion -> ' + dataRegistro);
              this.consultarDirecciones();
            } else {
              console.log('Guardar direccion -> Error informacion.');
            }
          }).catch(error => {
            console.log('Ventana -> Error agregar direccion. ' + error);
          });
        } else {
          console.log('Ventana -> No guardar direccion.');
        }
      }
    }
  }

  buscarUbicacion() {
    console.log('Busqueda ubicacion ->', this.inputBuscar);
    if (this.inputBuscar !== null) {
      if (this.inputBuscar.trim().length) {
        this.listadoUbicacion = false;
        this.googleAutocomplete.getPlacePredictions({ input: this.inputBuscar }, predictions => {
          console.log(predictions);
          this.ngZone.run(() => {
            this.resultadosBusqueda = predictions;
          });
        });
      }
    }
  }


  initGoogleMaps() {

    console.log('Google Mapa -> ', google);

    this.googleAutocomplete = new google.maps.places.AutocompleteService();

  }


  async buscarMapa() {
    console.log('Ventana -> Buscasr en mapa.');
    this.geolocalizacion.establecerUbicacionActual('geolocalizacion').then((dataUbicacion) => {
      console.log('Ventana -> Establecer ubicacion. ' + dataUbicacion.direccion);
      if (dataUbicacion.direccion) {

        this.direccionActual = dataUbicacion;

        const estado_id = 1;
        this.direccionUsuario = {
          id: dataUbicacion.place_id,
          titulo: this.direccionActual.direccion,
          usuario: this.authUsuario.usuario,
          usuario_id: this.authUsuario.usuario_id,
          latitud: this.direccionActual.coordenadas.lat,
          longitud: this.direccionActual.coordenadas.lng,
          direccion: this.direccionActual.direccion,
          estado: '',
          estado_id: estado_id
        };

        this.verificarUbicacion();

      } else {
        console.log('Ventana -> Error informacion geolocalizacion.');
      }
      console.log();
    }).catch(error => {
      console.log('Ventana -> Error establecer ubicacion actual.' + error);
    });


  }

}
