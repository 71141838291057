import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController, LoadingController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Header } from 'src/app/interfaces/book.interface';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-detalle-producto-tienda-usuario',
  templateUrl: './detalle-producto-tienda-usuario.component.html',
  styleUrls: ['./detalle-producto-tienda-usuario.component.scss'],
})
export class DetalleProductoTiendaUsuarioComponent implements OnInit {

  
  @Input() tienda: any;
  @Input() producto: any;

  pedido: any;
  carritoSuscriber: Subscription;

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  aquaBot = {
    msg: '',
    estado: ''
  };

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'TIENDAS_AGUA_USUARIO_DETALLE_PRODUCTO';

  authUsuario: any = null;
  defaultImage = 'assets/img/default.png';

  cantidadProducto: number = 0;
  cantidadInput = 0;
  productoCarrito: any;

  slidePubliAqua = [];
  slideItem: any = {
    'titulo': '',
    'info': '',
    'boton': '',
    'boton_url': '',
    'imagen': 'assets/img/default.png'
  };
  
  anunciosPubliAqua: Array<any> = Array();
  anunciosLista: any = [];
  slideAnuncios: any[] = [];
  totalAnuncios = 0;

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private toast: ToastService,
    private socialCompartir: SocialSharing,
    private loadingCtrl: LoadingController,
    private redatwater: RedatwaterService,
    private translateConfigService: TranslateConfigService,
    private aquabot: AquabotService,
    private geolocalizacion: GeolocalizacionService,
    private auth: AuthService,
    private carrito: CarritoService,
    private utilitario: UtilitariosService
  ) {
    console.log('Ventana -> detalle de producto');
    this.header.pagina = '/redatwater-usuario';
    this.header.titulo = 'Detalle de producto';

    this.aquaBot = this.aquabot.appMsgAquaBot({
      msg: 'Ahorra con estilo, cuida el agua con Banco del Agua.',
      estado: 'text-success'
    });

  }

  ngOnInit() {
    console.log('Detalle tienda -> ', this.tienda);
    console.log('Detalle producto -> ', this.producto);
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {

              this.authUsuario = getAuth.data;
              this.publiAquaSlide();
              
            } else {
              this.navCtrl?.navigateRoot(['/logout']);
            }
          });

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }

  init() {
    this.producto = {
      id: null,
      comercio: null,
      comercio_ciudad: null,
      categoria: null,
      producto: null,
      descripcion: null,
      precio: null,
      foto: null,
      destacado: null,
      estado: null,
    };

  }

  dataProducto() {
    console.log('Info de Producto -> ');

  }

   async publiAquaSlide() {

    // Consultar Anuncios
    this.redatwater.consultarAnunciosFull().then((dataAnuncios: any) => {
      if (dataAnuncios) {
        //console.log("Full Anuncios -> " + JSON.stringify(dataAnuncios));
        dataAnuncios.forEach((anuncio: any) => {
          console.log('Anuncio -> ' + anuncio.id);
          this.slidePubliAqua.push({
            'titulo': anuncio.titulo,
            'info': this.utilitario.removeHtmlTags(anuncio.descripcion),
            'boton': 'Ver promocion ...',
            'boton_url': anuncio.link,
            'imagen': anuncio.file
          });
        });

        this.slidePubliAqua.push({
          'titulo': '¡10% de descuento!',
          'info': 'Disfruta en tiendas seleccionadas',
          'boton': 'Ver tiendas ...',
          'boton_url': '',
          'imagen': 'assets/img/publiaqua/descuento.png'
        });

      }

      const item = Math.floor(Math.random() * this.slidePubliAqua.length);
      if (this.slidePubliAqua[item]) {
        console.log('Listado de anuncios -> ' + this.slidePubliAqua[item]);
        this.slideItem = this.slidePubliAqua[item];
      }
    }).catch(error => {
      console.log('App -> Error consultar anuncions: ' + error);
    });

  }

  verPagina(pagina: string) {
    if (pagina) {
      console.log('Ver pagina: ' + pagina);
      this.aquabot.enlaceSugerido(pagina, 'PubliAqua');
    }
  }


  async compartir(producto: any) {
    console.log('Compartir producto -> ', producto);
    const mensaje = `¡Red AT-WATER © No te pierdas este producto: ${producto.titulo}! Precio: $ ${producto.precio}`;
    const asunto = 'Compartido desde ® Banco del Agua';
    const imagen = producto.img; // URL de la imagen a compartir
    const enlace = ''; // Enlace web

    const loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    this.socialCompartir.share(mensaje, asunto, imagen, enlace)
      .then(() => {
        loading.dismiss();
        console.log('Compartido exitosamente: ', producto);
      })
      .catch((error) => {
        loading.dismiss();
        console.error('Seguimiento de errores -> Error al compartir', error);
      });
  }

  

}
