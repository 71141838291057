import { Injectable } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class VibrarService {

  constructor(
    private platform: Platform,
    private vibration: Vibration,
  ) {
    console.log('Servicio vibrar');
  }

  playVibracion(metodo: any, loop?: number) {
    // '../assets/sounds/' + audio
    if (this.platform.is('capacitor')) { // ¿Movile?
      if (metodo === 'play') {
        if (loop >= 1) {
          for (let i = 0; i < loop; i++) {
            this.vibration.vibrate([1000, 500]);
          }
        }
      } else if (metodo === 'loop') {
        this.vibration.vibrate([1000, 500, 1000, 500, 1000, 500, 800, 1000, 500, 1000, 500, 800, 300, 800, 300, 800, 300, 800]);
      } else if (metodo === 'stop') {
        this.vibration.vibrate([0]);
      } else {
        this.vibration.vibrate([0]);
      }
    }

  }

}
