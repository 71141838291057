import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, LoadingController, ModalController } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Froms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Fonts
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FirebaseService } from './services/firebase.service';

// HTTP
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { firebaseConfig } from '../environments/firebaseconfig';

import { DatePipe } from '@angular/common';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { AudioManagement } from '@ionic-native/audio-management/ngx';
import { GooglemapsModule } from './utilitarios/googlemaps/googlemaps.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GlobalErrorHandlerService } from './core';
import { AuthGuard } from './session/auth.guard';
import { AuthFalseGuard } from './session/authfalse.guard';

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateConfigService } from './services/translate-config.service';

import { IonicSelectableModule } from 'ionic-selectable';
import { AuthrolGuard } from './session/authrol.guard';
import { AuthrolDashboardGuard } from './session/authrol-dashboard.guard';
import { AuthrolEmpresasGuard } from './session/authrol-empresas.guard';
import { AuthrolUsuariosGuard } from './session/authrol-usuarios.guard';
import { AuthrolClientesGuard } from './session/authrol-clientes.guard';
import { AuthrolReferidosGuard } from './session/authrol-referidos.guard';
import { AuthService } from './services/auth.service';
import { Preferences } from '@capacitor/preferences';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    
    // Fonts
    FontAwesomeModule,
    // Load Img
    LazyLoadImageModule,
    // Form
    FormsModule,
    ReactiveFormsModule,
    IonicSelectableModule,
    // Firebase
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule.enablePersistence(),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    //AngularFireDatabaseModule,   
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      //registrationStrategy: 'registerWhenStable:30000'
    }),
    
  ],
  providers: [
    FirebaseService,
    AuthGuard,
    AuthService,
   
    AuthrolGuard,
    AuthFalseGuard,
    AuthrolDashboardGuard,
    AuthrolEmpresasGuard,
    AuthrolUsuariosGuard,
    AuthrolClientesGuard,
    AuthrolReferidosGuard,
    TranslateConfigService,
    GooglemapsModule,
    DatePipe,
    Insomnia,
    SocialSharing,
    Vibration,
    BarcodeScanner,
    AudioManagement,
    Geolocation,
    NativeAudio,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
