import { Injectable } from '@angular/core';
import { Card, Cartera, Transaccion, Usuario } from '../interfaces/book.interface';
import { LoadingController, NavController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { FirebaseService } from './firebase.service';
import { ToastService } from './toast.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { UtilitariosService } from './utilitarios.service';

@Injectable({
  providedIn: 'root'
})
export class WompiService {

  //public apiUrl = 'https://sandbox.wompi.co/v1';
  public apiUrl = 'https://production.wompi.co/v1';
  private httpHeaders: any;

  private cartera: Cartera;
  private tokenPay: any;
  private transaccion: Transaccion;
  public montoMinimoRecargaTarjeta = 3000;

  private emailVentas = 'invoice@bancodelagua.com';

  constructor(
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    private alertController: AlertService,
    private utilitarios: UtilitariosService,
    private navCtrl: NavController
  ) {
    console.log('Pasarela de pago -> Wompi!');
    this.establecerTokerPay();
  }

  getmontoMinimoRecargaTarjeta() {
    return this.montoMinimoRecargaTarjeta;
  }

  establecerTokerPay() {
    this.obtenerToken().then((token: any) => {
      this.tokenPay = token;
    }).catch(error => {
      console.log('Wompi -> Error token: ' + error);
    });
  }

  async obtenerToken(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      /* Obtener Token */
      const apiPath = this.apiUrl + '/merchants/' + environment.ApiPublicKeyWompi;
      this.http.get(apiPath).subscribe((data: any) => {
        if (data) {
          console.log('Peticion obtener token -> ', JSON.stringify(data));
          resolve(data.data.presigned_acceptance.acceptance_token);
        } else {
          reject(false);
        }
      }, (err: any) => {
        console.log('Seguimiento de errores -> HTTP: ', err);
        reject(false);
      });
    });
  }

  async getTokenPay() {
    return this.tokenPay;
  }

  async sendCard(getCard: Card): Promise<any[]> {

    const loading = await this.loadingCtrl.create({
      message: 'Verificando tarjeta...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    return new Promise((resolve, reject) => {
      /** 
       * En este caso, obtengo el resultado de otra función para usarlo
       * dentro del cuerpo de mi promesa
       */

      this.httpHeaders = new HttpHeaders({
        'Authorization': 'Bearer ' + environment.ApiPublicKeyWompi
      });

      const apiPath = this.apiUrl + '/tokens/cards';
      this.http.post(apiPath, getCard, { headers: this.httpHeaders }).subscribe((card: any) => {
        if (card.status === 'CREATED') {
          // Registrar 
          console.log('Pasarela -> Guardar tarjeta: ', JSON.stringify(card));
          resolve(card);
        } else {
          this.alertController.msgAppCenter('Cartera', 'No pudimos registrar tu tarjeta.');
          reject(false);
        }
        loading.dismiss();
      }, (err: any) => {
        console.log('Seguimiento de errores -> HTTP: ', err);
        loading.dismiss();
        reject(false);
      });

    });

  }

  getEmailVentas() {
    return this.emailVentas;
  }

  async payRecargarTarjeta(tarjeta: any, total: number): Promise<any[]> {
    const loading = await this.loadingCtrl.create({
      message: 'Verificando...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    return new Promise((resolve, reject) => {
      if (tarjeta && total > 0) {

        console.log('Pay -> Recargar tarjeta. ' + JSON.stringify(tarjeta));

        /* POST Pago */
        let totalNumber = parseInt(total + '00');
        this.transaccion = {
          amount_in_cents: totalNumber,
          currency: "COP",
          customer_email: this.emailVentas,
          reference: 'AppBancodelagua-' + Date.now(),
          acceptance_token: this.tokenPay,
          payment_method: {
            type: "CARD",
            installments: 1,
            token: tarjeta.tarjeta.token_tarjeta
          }
        }
        this.httpHeaders = new HttpHeaders({
          'Authorization': 'Bearer ' + environment.ApiPublicKeyWompi
        });

        const apiPath = this.apiUrl + '/transactions';
        this.http.post(apiPath, this.transaccion, { headers: this.httpHeaders }).subscribe((data: any) => {
          //console.log('Registrando transaccion ->', data);

          if (data.data.status === "PENDING") {
            console.log('Wompi -> Transacción ', data);
            loading.dismiss();
            resolve(data);
          } else {
            console.log('Wompi -> Transacción incorrecta.');
            //this.alertController.msgAppCenter('¡Cartera!', 'Transacción incorrecta.');
            loading.dismiss();
            reject(false);

          }

        });
      } else {
        loading.dismiss();
        reject(false);
      }
    });
  }


  async verificarPagoTarjeta(data: any): Promise<any> {

    return new Promise((resolve, reject) => {
      const apiPathTransaccion = this.apiUrl + '/transactions/' + data.data.id;
      //console.log('Test Url ->', apiPathTransaccion);
      this.http.get(apiPathTransaccion, { headers: this.httpHeaders }).subscribe((dataTransaccion: any) => {
        console.log('Wompi -> Verificar Pago:', JSON.stringify(dataTransaccion));

        if (dataTransaccion.data.status === 'PENDING') {

          console.log('Seguimiento -> Re consultando...');
          const parametrosReturn = {
            estado: 'esperando',
            data: dataTransaccion
          }
          resolve(parametrosReturn);

        } else {
          if (dataTransaccion.data.status === 'APPROVED') {

            console.log('Seguimiento -> Transaccion aprobada!!');
            const parametrosReturn = {
              estado: 'aprobado',
              data: dataTransaccion
            }
            resolve(parametrosReturn);

          } else {
            console.log('Seguimiento -> Transaccion Rechazada');
            const parametrosReturn = {
              estado: 'rechazado',
              //estado: 'esperando',
              data: dataTransaccion
            }
            resolve(parametrosReturn);

          }
        }

      }, (err: any) => {
        console.log('Seguimiento de errores -> HTTP: ', err);

        reject(false);
      });
    });

  }

  // ENVIAR PAGO PARA PEDIDO DE AGUA
  async payPedidoAgua(tarjeta: any, total: number, email?: string): Promise<any[]> {
    const loading = await this.loadingCtrl.create({
      message: 'Verificando...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    return new Promise((resolve, reject) => {
      if (tarjeta && total > 0) {

        console.log('Pay -> Pedido de agua. ' + JSON.stringify(tarjeta));
        let enviarA = this.emailVentas;
        if (email) {
          enviarA = email;
        }
        /* POST Pago */
        let totalNumber = parseInt(total + '00');
        this.transaccion = {
          amount_in_cents: totalNumber,
          currency: "COP",
          customer_email: enviarA,
          reference: 'AppBancodelagua-' + Date.now(),
          acceptance_token: this.tokenPay,
          payment_method: {
            type: "CARD",
            installments: 1,
            token: tarjeta.tarjeta.token_tarjeta
          }
        }
        this.httpHeaders = new HttpHeaders({
          'Authorization': 'Bearer ' + environment.ApiPublicKeyWompi
        });

        const apiPath = this.apiUrl + '/transactions';
        this.http.post(apiPath, this.transaccion, { headers: this.httpHeaders }).subscribe((data: any) => {
          //console.log('Registrando transaccion ->', data);

          if (data.data.status === "APPROVED") {
            console.log('Wompi -> Transacción ', data);
            loading.dismiss();
            resolve(data);

          } else if (data.data.status === "PENDING") {
            console.log('Wompi -> Transacción ', data);
            loading.dismiss();
            resolve(data);
          } else {
            console.log('Wompi -> Transacción incorrecta.');
            //this.alertController.msgAppCenter('¡Cartera!', 'Transacción incorrecta.');
            loading.dismiss();
            reject(false);
          }

        }, (err: any) => {
          console.log('Seguimiento de errores -> HTTP: ', err);
          loading.dismiss();
          reject(false);
        });
      } else {
        reject(false);
        loading.dismiss();
      }
    });
  }

  // ENVIAR PAGO CARRITO DE COMPRAS
  async payCarritoCompras(tarjeta: any, total: number, email?: string): Promise<any[]> {
    const loading = await this.loadingCtrl.create({
      message: 'Verificando...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    return new Promise((resolve, reject) => {
      if (tarjeta && total > 0) {

        console.log('Pay -> Pedido de Carrito. ' + JSON.stringify(tarjeta));
        let enviarA = this.emailVentas;
        if (email) {
          enviarA = email;
        }
        /* POST Pago */
        let totalNumber = parseInt(total + '00');
        this.transaccion = {
          amount_in_cents: totalNumber,
          currency: "COP",
          customer_email: enviarA,
          reference: 'AppBancodelagua-' + Date.now(),
          acceptance_token: this.tokenPay,
          payment_method: {
            type: "CARD",
            installments: 1,
            token: tarjeta.tarjeta.token_tarjeta
          }
        }
        this.httpHeaders = new HttpHeaders({
          'Authorization': 'Bearer ' + environment.ApiPublicKeyWompi
        });

        const apiPath = this.apiUrl + '/transactions';
        this.http.post(apiPath, this.transaccion, { headers: this.httpHeaders }).subscribe((data: any) => {
          //console.log('Registrando transaccion ->', data);

          if (data.data.status === "APPROVED") {
            console.log('Wompi -> Transacción ', data);
            loading.dismiss();
            resolve(data);

          } else if (data.data.status === "PENDING") {
            console.log('Wompi -> Transacción ', data);
            loading.dismiss();
            resolve(data);
          } else {
            console.log('Wompi -> Transacción incorrecta.');
            //this.alertController.msgAppCenter('¡Cartera!', 'Transacción incorrecta.');
            loading.dismiss();
            reject(false);
          }

        }, (err: any) => {
          console.log('Seguimiento de errores -> HTTP: ', err);
          loading.dismiss();
          reject(false);
        });
      } else {
        reject(false);
        loading.dismiss();
      }
    });
  }

  async enviarPagoNequi(metodoPay: any): Promise<any> {
    return new Promise((resolve, reject) => {
      /* POST Pago */
      let totalNumber = parseInt(metodoPay.payTotal + '00');
      this.transaccion = {
        amount_in_cents: totalNumber,
        currency: "COP",
        customer_email: this.getEmailVentas(),
        reference: 'APP-BANCODELAGUA-' + Date.now() + '-' + this.utilitarios.generarIdUnico(),
        acceptance_token: this.tokenPay,
        payment_method: {
          type: "NEQUI",
          phone_number: metodoPay.nequi.celular,

        }
      }
      this.httpHeaders = new HttpHeaders({
        'Authorization': 'Bearer ' + environment.ApiPublicKeyWompi
      });

      const apiPath = this.apiUrl + '/transactions';
      this.http.post(apiPath, this.transaccion, { headers: this.httpHeaders }).subscribe((data: any) => {
        //console.log('Seguimiento -> Registrando transaccion: ', data);
        if (data.data.status === "PENDING") {
          resolve(data);
        } else {
          console.log('Seguimiento -> Transacción NEQUI incorrecta');
          //this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, this.itemsIdiomaApp.app_pay_nequi_incorrecto);
          resolve(false);
        }
      }, (err: any) => {
        console.log('Seguimiento de errores -> HTTP: ', err);
        resolve(false);
      })


    });
  }

  async verificarNequiPago(data: any): Promise<any> {

    return new Promise((resolve, reject) => {
      const apiPathTransaccion = this.apiUrl + '/transactions/' + data.data.id;
      //console.log('Test Url ->', apiPathTransaccion);
      this.http.get(apiPathTransaccion, { headers: this.httpHeaders }).subscribe((dataTransaccion: any) => {
        console.log('Seguimiento -> Verificar Pago: ', dataTransaccion);
        if (dataTransaccion.data.status === 'PENDING') {

          console.log('Seguimiento -> Re consultando...');
          const parametrosReturn = {
            estado: 'esperando',
            data: dataTransaccion
          }
          resolve(parametrosReturn);

        } else {
          if (dataTransaccion.data.status === 'APPROVED') {

            console.log('Seguimiento -> Transaccion aprobada!!');
            const parametrosReturn = {
              estado: 'aprobado',
              data: dataTransaccion
            }
            resolve(parametrosReturn);

          } else {
            console.log('Seguimiento -> Transaccion Rechazada');
            const parametrosReturn = {
              estado: 'rechazado',
              //estado: 'esperando',
              data: dataTransaccion
            }
            resolve(parametrosReturn);

          }
        }
      }, (err: any) => {
        console.log('Seguimiento de errores -> HTTP: ', err);
        reject(false);
      })

    });

  }

}
