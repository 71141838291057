import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Ciudad, Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-registro-domiciliario',
  templateUrl: './registro-domiciliario.component.html',
  styleUrls: ['./registro-domiciliario.component.scss'],
})
export class RegistroDomiciliarioComponent implements OnInit {

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_QUIERO_DOMICILIARIO';

  formUsuario = { // Usuario
    tipo_documento_id: 0,
    numero_documento: '',
    ciudad_documento: '',
    fecha_documento: '',
    terminos: false
  }

  frontImage: File;
  backImage: File;
  frontImagePreview: any;
  backImagePreview: any;

  imagenPerfilVistaPrevia: any;
  imagenPerfil: File;

  ciudad: Ciudad;
  ciudades: Ciudad[];

  @ViewChild('seleccionarComponent') seleccionarComponent: IonicSelectableComponent;

  constructor(
    private translateConfigService: TranslateConfigService,
    private toast: ToastService,
    private loadingCtrl: LoadingController,
    private modalController: ModalController,
    private redatwater: RedatwaterService,
    private aquabot: AquabotService,
    private ventanaAlerta: AlertService
  ) {
    console.log('Ventana -> Quiero ser repartidor');
    this.header.pagina = '/redatwater-cliente';
    this.header.titulo = 'Quiero ser repartidor';
  }


  ngOnInit() {
    this.listaCiudades();
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();
        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {
          //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
          //console.log('Parametros de idioma -> ', this.itemsIdioma);
          //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  async listaCiudades() {
    const loading = await this.loadingCtrl.create({
      message: 'Verificando ciudades...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    this.redatwater.listaCiudades(this.redatwater.getPaisSeleccionado()).then(data => {
      //console.log('Ciudades del Pais -> ' + data);
      this.ciudades = data;
      loading.dismiss();
    });
  }

  ciudadChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    const ciudad = event.value;
    console.log('Ciudad -> ', ciudad);
    this.formUsuario.ciudad_documento = ciudad.ciudad_id;

  }

  showLoading() {
    this.seleccionarComponent.showLoading();
  }


  onFrontImageChange(event: any) {
    this.frontImage = event.target.files[0];
    this.previewImage(this.frontImage, 'front');
  }

  onBackImageChange(event: any) {
    this.backImage = event.target.files[0];
    this.previewImage(this.backImage, 'back');
  }

  previewImage(image: File, type: string) {
    const reader = new FileReader();
    reader.onload = () => {
      //const base64String = reader.result.toString();
      if (type === 'front') {
        this.frontImagePreview = reader.result;
      } else {
        this.backImagePreview = reader.result;
      }
    };
    reader.readAsDataURL(image);
  }

  seleccionarImagenPerfil() {
    const input = document.querySelector('.input-imagen-perfil') as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  cambiarImagenPerfil(evento: any) {
    const archivo = evento.target.files[0];
    if (archivo) {
      const lector = new FileReader();
      lector.onload = (e) => {
        this.imagenPerfilVistaPrevia = e.target.result;
        this.imagenPerfil = archivo;
      };
      lector.readAsDataURL(archivo);
    }
  }

  aceptarTerminos() {
    console.log('Aceptar terminos:' + this.formUsuario.terminos);
    if (this.formUsuario.terminos) {
      //this.toast.msgAppCenter('A través de www.bancodelagua.com encuentra información y acceso de tus productos y servicios.', 'success');
    } else {
      this.toast.msgAppCenter('Para nosotros es muy importante el derecho a la privacidad.', 'danger');
    }
  }

  async terminos_web() {
    console.log('Navegador -> Portal Transaccional: Terminos y condiciones');
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/terminosycondiciones', 'portal transaccional');
  }

  establecerFechaDocumento() {
    console.log('Ventana -> fecha de documento: ' + this.formUsuario.fecha_documento);
    this.toast.msgAppBottom(this.formUsuario.fecha_documento + ' Esta fecha debe coincidir con tu documento de identidad', 'warning');
  }

  async confirmarSolicitud() {

    console.log('Data Registro', this.formUsuario.tipo_documento_id,
      this.formUsuario.numero_documento,
      this.formUsuario.ciudad_documento,
      this.formUsuario.fecha_documento,
      this.formUsuario.terminos,
      this.imagenPerfil,
      this.frontImage,
      this.backImage);

    if (this.formUsuario.tipo_documento_id >= 1 &&
      this.formUsuario.numero_documento &&
      this.formUsuario.ciudad_documento &&
      this.formUsuario.fecha_documento &&
      this.formUsuario.terminos &&
      this.imagenPerfil &&
      this.frontImage &&
      this.backImage
    ) {

      this.solicitudRegistro();

    } else {
      this.toast.msgAppBottom('Asegúrate de llenar todos los campos.', 'danger');
      console.log('Seguimiento de errores -> Campos obligatorios');
    }
  }

  async solicitudRegistro() {

    console.log('Seguimiento -> Solicitud de registro');

    let dataPrametros = {
      tipo_documento: this.formUsuario.tipo_documento_id,
      numero_documento: this.formUsuario.numero_documento,
      ciudad_documento: this.formUsuario.ciudad_documento,
      fecha_documento: this.formUsuario.fecha_documento,
      foto_perfil: this.imagenPerfil,
      front_documento: this.frontImage,
      back_documento: this.backImage
    }

    this.redatwater.confirmarRegistroDomiciliario(dataPrametros, this.idiomaSeleccionado).then((getData: any) => {
      if (getData) {

        console.log('Seguimiento -> ', getData);

        this.ventanaAlerta.msgAppCenter('¡Bienvenido al Equipo!', 'Registro completado con éxito. <span class="text-success">Estás a punto de marcar la diferencia en cada entrega.</span>');
        this.cerrarVentana();

      } else {

        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '¡Oops! Un Pequeño Percance <span class="text-success">Por favor, escríbenos a contacto@bancodelagua.com</span>');
        console.log('Seguimiento de errores -> ', getData);

      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
      this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
    });

  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss({ registro: true });
  }

}
