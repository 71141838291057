import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoading = false;

  constructor(private alertController: AlertController) { }

  async showLoading(title: string, imageUrl: string): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    const loadingAlert = await this.alertController.create({
      header: title,
      message: `<img src="${imageUrl}" />`,
      backdropDismiss: false
    });

    await loadingAlert.present();
  }

  async hideLoading(): Promise<void> {
    if (!this.isLoading) {
      return;
    }

    this.isLoading = false;
    await this.alertController.dismiss();
  }
}
