import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Header } from 'src/app/interfaces/book.interface';
import { AppUsabilidadService } from 'src/app/services/app-usabilidad.service';
import { TextSpeechService } from 'src/app/services/text-speech.service';

@Component({
  selector: 'app-app-usabilidad',
  templateUrl: './app-usabilidad.component.html',
  styleUrls: ['./app-usabilidad.component.scss'],
})
export class AppUsabilidadComponent implements OnInit, AfterViewInit {
  authUsuario: any = null;
  cuentaUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_USABILIDAD';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  };

  onHelpVoz: boolean = false;
  onHelpAudio: boolean = false;
  onHelpVozText: string = 'off';
  onHelpAudioText: string = 'off';

  constructor(
    private appUsabilidad: AppUsabilidadService,
    private textSpeech: TextSpeechService
    ) {
    console.log('App: Ajustes App.');
    this.header.pagina = '/';
    this.header.titulo = 'AJUSTES';
    // INIT
    this.increaseFontSize();
    this.decreaseFontSize();
  }

  ngOnInit() {
    this.consultarParametrosApp();
  }

  ngAfterViewInit() {
    this.appUsabilidad.establecerFontSize();
  }

  consultarParametrosApp() {
    this.appUsabilidad.getAppUsabilidad().then((getApp: any) => {

      this.appUsabilidad.establecerFontSize();

      if (getApp?.value) {
        this.establecerParametros(JSON.parse(getApp.value));
        
      } else {
        console.log('Seguimiento de errores -> ', getApp);
      }
    }).catch((error: any) => {
      console.log('Seguimiento de errores -> ', error);
    });
  }

  async establecerParametros(parametros: any) {
    if (parametros) {

      console.log('Seguimiento -> Estableciendo parametros de App Usabilidad.');

      this.appUsabilidad.establecerFontSize();

      if (parametros?.fontsize) {
        
        this.appUsabilidad.setFontSize(parametros.fontsize);
      }

      if (parametros?.help_audio_text === 'on') {
        this.onHelpAudio = true;
      } else {
        this.onHelpAudio = false;
      }

      this.establecerHelpAudio(this.onHelpAudio);

      if (parametros?.help_voz_text === 'on') {
        this.onHelpVoz = true;
      } else {
        this.onHelpVoz = false;
      }
      this.establecerHelpVoz(this.onHelpVoz);
    }
  }

  increaseFontSize() {
    this.appUsabilidad.increaseFontSize();
    
  }

  decreaseFontSize() {
    this.appUsabilidad.decreaseFontSize();
    
  }

  guardarConfiguracion() {
    let parametrosData: any = {
      fontsize: this.appUsabilidad.fontSize,
      help_audio: this.onHelpAudioText,
      help_voz: this.onHelpVozText
    }
    console.log('Parametros -> Guardar: ', parametrosData);
    this.appUsabilidad.saveAppUsabilidad(parametrosData);
    this.consultarParametrosApp();
  }

  establecerHelpVoz(opcion: any) {
    if (opcion) {
      this.onHelpVoz = true;
      this.onHelpVozText = 'on';
      this.textSpeech.hablar('Puedo hablar.');
    } else {
      this.onHelpVoz = false;
      this.onHelpVozText = 'off';
    }
  }

  establecerHelpAudio(opcion: any) {
    if (opcion) {
      this.onHelpAudio = true;
      this.onHelpAudioText = 'on';
      this.textSpeech.hablar('Puedo escuchar y hablar.');
    } else {
      this.onHelpAudio = false;
      this.onHelpAudioText = 'off';
    }
  }
}
