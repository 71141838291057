import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, NavController } from '@ionic/angular';
import { Card, Header } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { WompiService } from 'src/app/services/wompi.service';

@Component({
  selector: 'app-agregar-tarjeta',
  templateUrl: './agregar-tarjeta.component.html',
  styleUrls: ['./agregar-tarjeta.component.scss'],
})
export class AgregarTarjetaComponent implements OnInit {

  formTarjeta: Card = { // Tarjeta = Card
    card_holder: '',
    number: null,
    cvc: null,
    exp_month: null,
    exp_year: null,
  }

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: ''
  }

  constructor(
    private modalController: ModalController,
    private loadingCtrl: LoadingController,
    private wompi: WompiService,
    private redatwater: RedatwaterService,
    public navCtrl: NavController,
    public toast: ToastService,
    public alertCtrl: AlertService
  ) {
    console.log('Ventana -> asociar tarjeta');
    this.header.pagina = '/redatwater';
    this.header.titulo = 'Asociar tarjeta';
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  async asociarTarjeta() {
    if (this.formTarjeta.cvc &&
      this.formTarjeta.number &&
      this.formTarjeta.card_holder &&
      this.formTarjeta.exp_month &&
      this.formTarjeta.exp_year) {
      //console.log('Ok para continuar...');
      this.guardarTarjeta();
    } else {
      this.toast.msgAppBottom('Asegúrate de llenar todos los campos.', 'danger');
    }
  }

  async cerrarVentana() {
    this.modalController.dismiss();
  }

  guardarTarjeta() {
    console.log('Ventana -> Guardar tarjeta: ' + this.formTarjeta);
    this.wompi.sendCard(this.formTarjeta).then((dataTarjeta: any) => {
      console.log('Pasarela Wompi -> Tarjeta guardada. ' + dataTarjeta);
      this.redatwater.saveTarjetaWompi(dataTarjeta.data.id, dataTarjeta.data.name).then((dataApi:any) => {
        if (dataApi) {
          if (dataApi) {
            console.log('Ventana -> Tarjeta guardada.');
          this.modalController.dismiss(dataTarjeta);
          }
        }
      }).catch(error => {
        console.log('Ventana -> Error Api guardar tarjeta: ' + error);
      });
    }).catch(error => {
      console.log('Ventana -> Error guardar tarjeta: ' + error);
    });

  }

}
