import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { IAuth } from '../interfaces/book.interface';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authUsuarioSubject = new BehaviorSubject<any>(null); // Inicializar con valor null
  authUsuario$ = this.authUsuarioSubject.asObservable();

  constructor(
    private navCtrl: NavController
  ) {
    this.getAuthUsuarioObservable('app_auth').subscribe();
  }

  /* ************************************************************ */
  /* ***** Auth Banco del Agua **** */
  /* ************************************************************ */

  // Lo demas componentes se ponen a la esucha de cambio
  // a través de esté método
  getAuthUsuarioObservable(identifier: string): Observable<any> {
    return from(this.loadAuthToken()).pipe(
      map(data => ({ identifier, data })), // Agregar una etiqueta a los datos emitidos
      shareReplay(1)
    );
  }

  setAuthUsuario(auth: IAuth) {
    if (auth) {
      this.authUsuarioSubject.next({ identifier: 'app_auth', data: auth }); // Enviar el nuevo valor a través del BehaviorSubject
    }
  }

  getAuthUsuario() {
    return this.authUsuarioSubject.getValue(); // Obtener el valor actual del BehaviorSubject
  }

  // Iniciar sesion
  async setAuthToken(auth: any) {
    if (auth) {
      this.setAuthTokenSession(auth); // Almacenar Session
      this.setAuthUsuario({
        id: auth.id,
        usuario: auth.usuario,
        usuario_id: auth.usuario_id,
        modulo: auth.modulo,
        codigo: auth.codigo,
        fecha_nacimiento: auth.fecha_nacimiento,
        grupo: auth.grupo,
        grupo_id: auth.grupo_id,
        img: auth.img,
        logged_in: auth.logged_in,
        app_token: auth.app_token,
        aprobado: auth.aprobado,
        cant_login: auth.cant_login
      });
    }
  }

  // Crear registro token session
  async setAuthTokenSession(auth: any) {
    if (auth) {
      Preferences.set({
        key: 'app_auth',
        value: JSON.stringify(auth),
      });
      console.log('Auth -> Session almacenada.');

    }
  }

  setUpdateAuth(auth: IAuth) {
    if (auth) {
      console.log('Actualizando Login......');
      this.authUsuarioSubject.next({ identifier: 'app_auth', data: auth }); // Enviar el nuevo valor a través del BehaviorSubject
    }
  }

  // Get registro token session
  async getAuthTokenSession() {
    const appToken = Preferences.get({ key: 'app_auth' });
    if (appToken) {
      return appToken;
    }
  }

  async loadAuthToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAuthTokenSession().then((authData: any) => {
        if (authData.value) {
          console.log("Auth -> " + authData.value);
          resolve(JSON.parse(authData.value));
        } else {
          console.log('Auth token -> no hay sesion.');
          resolve(false);
        }
      }).catch((error: any) => {
        console.log('Auth token -> Error al cargar.', error);
        resolve(false);
      });
    });
  }



  async logout() {
    console.log('Auth -> Cerrar sesion.');
    await Preferences.remove({ key: 'app_auth' });
    await Preferences.remove({ key: 'app_ubicacion_actual' });
    this.authUsuarioSubject.next(null); // Establecer el valor del BehaviorSubject como null al cerrar sesión
    this.navCtrl?.navigateRoot(['/home']);
  }


  async appConfig(pais: any, divisa: any) {

    let parametros: any = {
      pais: pais,
      divisa: divisa
    }

    this.setAppCofigSession(parametros);
  }

  async setAppCofigSession(app: any) {
    if (app) {
      Preferences.set({
        key: 'app_config_ubicacion',
        value: JSON.stringify(app),
      });
      console.log('App Config -> Session almacenada.');

    }
  }

  async getAppConfigSession() {
    const appConfig = Preferences.get({ key: 'app_config_ubicacion' });
    if (appConfig) {
      return appConfig;
    }
  }


  async loadAppConfig(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAppConfigSession().then((authApp: any) => {
        if (authApp.value) {
          console.log("App Config -> " + authApp.value);
          resolve(JSON.parse(authApp.value));
        } else {
          console.log('App config -> no hay sesion.');
          resolve(false);
        }
      }).catch((error: any) => {
        console.log('App config -> Erro al cargar.', error);
        resolve(false);
      });
    });
  }


}
