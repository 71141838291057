import { Injectable, OnDestroy } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { IAuth, Usuario } from '../interfaces/book.interface';
import { AuthService } from './auth.service';
import { ToastService } from './toast.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilitariosService } from './utilitarios.service';

@Injectable({
  providedIn: 'root'
})
export class CarritoService implements OnDestroy {

  authUsuario: IAuth;
  pedido: any;
  private pedidoSubject: any;

  constructor(
    private auth: AuthService,
    private loadingCtrl: LoadingController,
    private toast: ToastService,
    private utilitarios: UtilitariosService,
    private navCtrl: NavController
  ) {
    console.log('Service -> Carrito de compras');
    this.pedidoSubject = new BehaviorSubject(this.pedido);
    this.loadUsuario();

  }

  ngOnDestroy() {

  }

  // Init Carrito
  initCarrito() {
    this.pedido = {
      id: this.utilitarios.generarIdUnico(),
      usuario: this.authUsuario.usuario,
      usuario_id: this.authUsuario.usuario_id,
      productos: [],
      precioTotal: 0,
      precioEnvio: 0,
      createdAt: Date.now()
    }
    this.pedidoSubject.next(this.pedido);

  }

  // Auth usuario
  loadUsuario() {
    this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
      console.log('Usuario Auth ->', getAuth);
      if (getAuth?.data) {

        this.authUsuario = getAuth.data;
        this.loadCarrito();

      } else {
        console.log('Seguimiento de errores -> ', getAuth);
      }
    });
  }

  async loadCarrito() {

    this.getAppCarrito().then((getData: any) => {
      console.log('Carrito -> ', getData.value);
      if (getData.value) {

        this.pedido = JSON.parse(getData.value);

        if (this.pedido.usuario === this.authUsuario.usuario && this.pedido.usuario_id === this.authUsuario.usuario_id) {
          
        console.log('Pedido productos ->', this.pedido.productos);
        this.pedidoSubject.next(this.pedido);

        } else {
          console.log('No es pedido del usuario ->', this.pedido);
          this.clearCarrito();
        }

      } else {
        this.initCarrito();
      }
    }).catch((err: any) => {
      console.log('Seuimiento de errores -> ', err);
    });

  }

  // Cargar Carrito
  getPedidoObservable(): Observable<any> {
    console.log('Escuchando metodo carrito.....');
    return this.pedidoSubject.asObservable();
  }


  setPedido(pedido: any) {
    if (pedido) {
      this.pedido = pedido;
      this.pedidoSubject.next(this.pedido);
    }
  }

  getPedido() {
    return this.pedido;
  }

  // Actualizar producto 
  async actualizarProducto(tienda: any, producto: any, cantidad: any) {

    console.log('Seguimiento -> ', tienda, producto, cantidad);

    if (cantidad >= 1) {

      const item = this.pedido.productos.find(productoPedido => {

        return (productoPedido.producto === producto.id && productoPedido.tienda === tienda.id);
      });

      if (item !== undefined) {

        const parametrosPedido: any = {
          cantidad: cantidad,
          producto: producto.id,
          titulo: producto.titulo,
          precio: producto.precio,
          foto: producto.img,
          tienda: tienda.id,
          tienda_titulo: tienda.titulo,
          tienda_img: tienda.imagen,
        };

        console.log('Editar pedido -> ', parametrosPedido);

      } else {

        const parametrosPedido: any = {
          cantidad: cantidad,
          producto: producto.id,
          titulo: producto.titulo,
          precio: producto.precio,
          foto: producto.img,
          tienda: tienda.id,
          tienda_titulo: tienda.titulo,
          tienda_img: tienda.imagen
        };

        console.log('Agregar pedido -> ', parametrosPedido);
        this.pedido.productos.push(parametrosPedido);

      }

      this.setAppCarrito(this.pedido);
      this.setPedido(this.pedido);

      return this.pedido;

    } else {
      console.log('Seguimiento de errores -> Actualizar producto.');
    }

  }

  // Agregar producto
  async agregarProducto(tienda: any, producto: any) {

    const item = this.pedido.productos.find(productoPedido => {
      return (productoPedido.producto === producto.id && productoPedido.tienda === tienda.id);
    });

    if (item !== undefined) {

      item.cantidad++;

    } else {
      const parametrosPedido: any = {
        cantidad: 1,
        producto: producto.id,
        titulo: producto.titulo,
        precio: producto.precio,
        foto: producto.img,
        tienda: tienda.id,
        tienda_titulo: tienda.titulo,
        tienda_img: tienda.imagen
      };
      this.pedido.productos.push(parametrosPedido);
    }


    console.log('Agregado al Carrito ->', this.pedido);

  }

  async setAppCarrito(pedido: any) {
    if (pedido) {

      Preferences.set({
        key: 'app_carrito_compras',
        value: JSON.stringify(pedido),
      });

      console.log('App Carrito compras -> Session almacenada.', pedido);

    }
  }

  async getAppCarrito() {
    const appCarrito: any = Preferences.get({ key: 'app_carrito_compras' });
    if (appCarrito) {
      console.log('App Carrito pedido -> ', appCarrito);
      if (appCarrito.value) {
        this.setPedido(JSON.parse(appCarrito.value));
      }
      return appCarrito;
    }
  }


  async getAppProductoCarrito(tienda: any, producto: any) {
    console.log('Carrito -> Get producto');
    let appCarrito: any = await this.getAppCarrito();

    if (appCarrito) {

      console.log('Carrito ->', appCarrito);

      const item = appCarrito.productos.find(productoPedido => {
        return (productoPedido.producto === producto.id && productoPedido.tienda === tienda.id);
      });

      if (item !== undefined) {

        console.log('Producto no se encuentra registrado');

      } else {

      }

    } else {
      console.log('Seguimiento de errores -> ', appCarrito);
    }
  }

  // Eliminar producto
  async removeProducto(producto: string) {

    console.log('Eliminar pedido de -> ', this.authUsuario);
    if (this.authUsuario.id) {

      let position = 0;
      const item = this.pedido.productos.find((productoPedido, index) => {
        position = index;
        return (productoPedido.producto === producto);
      });

      if (item !== undefined) {

        item.cantidad--;
        if (item.cantidad === 0) {
          this.pedido.productos.splice(position, 1);
        }

        console.log('Eliminado: ', this.pedido);
        this.setAppCarrito(this.pedido);

      } else {


      }

    } else {


    }

  }

  getProducto(producto: string): any {
    console.log('Seguimineto -> Data: ', producto);
    if (producto) {
      let position = 0;
      const item = this.pedido.productos.find((productoPedido, index) => {
        position = index;
        return (productoPedido.producto === producto);
      });

      if (item !== undefined) {
        return item;
      }
    } else {
      console.log('Seguimiento de errores -> ', producto);
    }
  }

  // Limpiar carrito
  async clearCarrito() {

    this.initCarrito();
    this.setAppCarrito(this.pedido);

  }


}
