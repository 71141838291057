import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalController, LoadingController, NavController, AlertController } from '@ionic/angular';
import { Header, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { ToastService } from 'src/app/services/toast.service';
import { GooglemapsService } from 'src/app/utilitarios/googlemaps/googlemaps.service';

declare var google: any;

@Component({
  selector: 'app-verificar-ubicacion',
  templateUrl: './verificar-ubicacion.component.html',
  styleUrls: ['./verificar-ubicacion.component.scss'],
})
export class VerificarUbicacionComponent implements OnInit, OnDestroy {

  @Input() inputUbicacion: IUbicacionActual;

  label = {
    titulo: ' ',
    subtitulo: ''
  }
  directionsDisplay: any;
  map: any;
  marker: any;
  infowindow: any;
  positionSet: any;

  @ViewChild('map') divMap: ElementRef;

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: 'Verifica la ubicación'
  }

  ponerTitulo = true;
  direccionTitulo = '';

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document,
    private geolocalizacion: GeolocalizacionService,
    private googlemapsService: GooglemapsService,
    private ngZone: NgZone,
    private modalController: ModalController,
    private alertController: AlertController,
    private loadingCtrl: LoadingController,
    public navCtrl: NavController,
    public alertCtrl: AlertService,
    public toast: ToastService
  ) {
    console.log('Ventana -> verificar dirección');
    this.header.pagina = '/redatwater';
    this.header.titulo = 'Verifica la ubicación';

  }

  ngOnInit() {
    console.log('Input Ubicacion -> ' + JSON.stringify(this.inputUbicacion));
    if (this.inputUbicacion !== undefined && this.inputUbicacion !== null) {
      this.googlemapsService.init(this.renderer, this.document).then(() => {
        this.initGoogleMaps();
      }).catch((err) => {
        this.alertCtrl.msgAppCenter('Oops!', 'Activa tú ubicación.');
        console.log(err);
      });
    }
  }

  ngOnDestroy() { }

  async initGoogleMaps() {

    console.log('Google Mapa -> ', google);
    this.directionsDisplay = new google.maps.DirectionsRenderer();

    let latLng = new google.maps.LatLng(this.inputUbicacion.coordenadas.lat, this.inputUbicacion.coordenadas.lng);

    let mapOptions = {
      center: latLng,
      zoom: 15,
      disableDefaultUI: true,
      clickableIcons: false,
    };

    this.map = new google.maps.Map(this.divMap.nativeElement, mapOptions);
    this.marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      //icon: 'assets/img/marker_biciya.png',
      draggable: true
    });

    this.clickHandleEvent();
    this.infowindow = new google.maps.InfoWindow();
    if (this.label.titulo.length) {
      this.addMarker(this.inputUbicacion.coordenadas);
    }

  }

  clickHandleEvent() {
    this.map.addListener('click', (event: any) => {
      const coordenadas = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      console.log('Click en Mapa -> ', coordenadas);
      this.addMarker(coordenadas);
      //this.alertController.msgAppCenter('¡Geolocalización!', 'Actualizamos tu ubicación.');
      this.geolocalizacion.consultarDireccionCoordenadas(coordenadas.lat, coordenadas.lng).then((dataUbicacion: any) => {
        console.log('Coordenadas, consultar direccion -> ', dataUbicacion);

        this.inputUbicacion = {
          place_id: dataUbicacion.place_id,
          direccion: dataUbicacion.formatted_address,
          coordenadas: {
            lat: dataUbicacion.geometry.location.lat,
            lng: dataUbicacion.geometry.location.lng
          }
        }

      }).catch(error => {
        console.log('Error Consultar direccion -> ' + error);
      });
    })
  }

  async addMarker(position: any) {
    const loading = await this.loadingCtrl.create({
      message: 'Geolocalización……',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    if (position?.lat) {

    let latLng = new google.maps.LatLng(position.lat, position.lng);
    this.marker.setPosition(latLng);

      this.map.panTo(position);
      console.log('Posicion Inicial', position);
      /*
          this.ubicacion.positionOrigen = { // Ubicacion de origen
            lat: position.lat,
            lng: position.lng
          }
          */


    }

    loading.dismiss();

  }

  setInfoWindow(marker: any, titulo: string, subtitulo: string) {
    const contentString = '<div id="contentInsideMap>' +
      '<div>' +
      '</div>' +
      '<p style="font-weight: bold; margin-bottom: 5px;"' +
      '<div id="bodyContent">' +
      '<p class="normal m-0">' + subtitulo + '</p>' +
      '</div>' +
      '</div>';
    this.infowindow.setContent(contentString);
    this.infowindow.open(this.map, marker);
  }


  async confirmarUbicacion() {
    if (this.inputUbicacion.place_id) {
      console.log('Ventana -> Informacion de ubicación confirmada.');
      const direccion = {
        ponerTitulo: this.ponerTitulo,
        titulo: this.direccionTitulo,
        ubicacion: this.inputUbicacion
      }
      this.modalController.dismiss(direccion);
    } else {
      console.log('Ventana -> Error informacion.');
    }
  }

  async encuentrameUbicacion() {
    this.geolocalizacion.establecerUbicacionActual('geolocalizacion').then((data: any) => {
      const coordenadas = data.coordenadas;
      console.log('Coordenadas -> ' + JSON.stringify(coordenadas));
      this.addMarker(coordenadas);
      //this.alertController.msgAppCenter('¡Geolocalización!', 'Actualizamos tu ubicación.');
      this.geolocalizacion.consultarDireccionCoordenadas(coordenadas.lat, coordenadas.lng).then((dataUbicacion: any) => {
        console.log('Coordenadas, consultar direccion -> ', dataUbicacion);

        this.inputUbicacion = {
          place_id: dataUbicacion.place_id,
          direccion: dataUbicacion.formatted_address,
          coordenadas: {
            lat: dataUbicacion.geometry.location.lat,
            lng: dataUbicacion.geometry.location.lng
          }
        }

      }).catch(error => {
        console.log('Error Consultar direccion -> ' + error);
      });
    }).catch(error => {
      console.log('Geolocalizacion -> Error con coordenadas.');
    });
  }


  async tituloDireccion() {
    console.log('Ventana -> Guardar titulo de direccion');
    if (this.ponerTitulo) {
      console.log('Nombrar direccion -> ');
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Guardar Dirección',
        inputs: [
          {
            name: 'titulo',
            type: 'text',
            placeholder: 'Titulo:'
          }
        ],
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Emergente -> Cancelar.');
              this.direccionTitulo = '';
              this.ponerTitulo = false;
            }
          }, {
            text: 'Continuar',
            handler: (data) => {
              console.log('Emergente -> Data: ', data);
              this.direccionTitulo = data.titulo;
            }
          }
        ]
      });

      await alert.present();


    } else {
      this.direccionTitulo = '';
    }
  }

  async cerrarVentana() {
    this.closeModal();
  }

  closeModal() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss({ verificarUbicacion: false }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

}
