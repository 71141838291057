import { Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, LoadingController, ModalController } from '@ionic/angular';
import { Header, Transaccion } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { WompiService } from 'src/app/services/wompi.service';
import { SeleccionarTarjetaComponent } from '../seleccionar-tarjeta/seleccionar-tarjeta.component';
import { PayMetodosComponent } from '../pay-metodos/pay-metodos.component';
import { Subscription, interval } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-info-tarjeta',
  templateUrl: './info-tarjeta.component.html',
  styleUrls: ['./info-tarjeta.component.scss'],
})
export class InfoTarjetaComponent implements OnInit {

  @Input() tarjetaAquahabiente: any;
  @Input() listaTarjetasCartera: any[];

  private subscriptionTransaccion: Subscription = new Subscription();

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  formRecargarCuenta = {
    monto: null
  }


  metodoSeleccionado = {
    metodo: null
  };
  metodoPay: any = '';
  procesoTransaccion: any;
  tiempoRecargaTransaccion = 6000;
 
  montoMinimoRecargaTarjeta = 0;
  viewBtnRecargar: boolean = true;

  intSolicitud: boolean = true;

  private tokenPay: any;
  private httpHeaders: any;
  private transaccion: Transaccion;

  constructor(
    private ventanaAlerta: AlertService,
    private wompi: WompiService,
    private loadingCtrl: LoadingController,
    private loadingService: LoadingService,
    private actionSheetCtrl: ActionSheetController,
    private redatwater: RedatwaterService,
    private modalController: ModalController
  ) {
    this.header.pagina = '/redatwater';
    this.header.titulo = 'Tu Tarjeta';
  }

  ngOnInit() {
    console.log('Ventana -> info tarjeta aquahabiente: ' + JSON.stringify(this.tarjetaAquahabiente));
    this.montoMinimoRecargaTarjeta = this.wompi.getmontoMinimoRecargaTarjeta();
  }

  async recargarTarjeta() {
    if (this.formRecargarCuenta.monto) {
      if (this.formRecargarCuenta.monto !== null) {
        if (this.formRecargarCuenta.monto !== '') {
          if (this.formRecargarCuenta.monto <= 0) {
            console.log('Ventana -> Error monto es <= 0.');
            this.formRecargarCuenta.monto = null;
          } else {
            if (this.formRecargarCuenta.monto >= this.montoMinimoRecargaTarjeta) {
              console.log('Monto a recargar -> ' + this.formRecargarCuenta.monto);
              this.metodosPay();
            } else {
              console.log('Ventana -> Error monto es < ' + this.montoMinimoRecargaTarjeta);
              this.formRecargarCuenta.monto = null;
              this.ventanaAlerta.msgAppCenter('¡Recargar saldo!', 'Recarga minima de ' + this.montoMinimoRecargaTarjeta);
            }
          }
        } else {
          console.log('Ventana -> Error mont es vacio.');
          this.formRecargarCuenta.monto = null;
        }
      } else {
        console.log('Ventana -> Error monto es null.');
        this.formRecargarCuenta.monto = null;
      }
    }
  }



  async seleccionarTarjeta() {

    const listaTarjetas = this.listaTarjetasCartera;
    const modalAdd = await this.modalController.create({
      component: SeleccionarTarjetaComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { listaTarjetas }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    if (data?.id) {
      //console.log('Finalizar datos -> ' + JSON.stringify(data));
      this.metodoSeleccionado.metodo = 'tarjeta';
      this.loadingService.showLoading('¡Tu transacción en proceso!', 'assets/img/loading_splash.png');
      this.procesoPay(data);
    } else {
      this.ventanaAlerta.msgAppCenter('¡Recargar saldo!', '¡Oops! Información de tarjeta.');
      this.viewBtnRecargar = true;
    }

  }

  async procesoPay(data: any) {
    const loading = await this.loadingCtrl.create({
      message: 'Cartera...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    if (data) {
      this.wompi.payRecargarTarjeta(data, this.formRecargarCuenta.monto).then((dataPagoOnline: any) => {

       
        this.viewBtnRecargar = false;
       
        this.verificarProcesoTransaccion(dataPagoOnline);
        loading.dismiss();

      }).catch(error => {
        console.log('Ventana -> Error recargar tarjeta. ' + error);
        this.viewBtnRecargar = true;
        loading.dismiss();
      });
    }
  }

  async finalizarPayTarjeta(dataPagoOnline: any) {
    if (dataPagoOnline) {
      const parametros = {
        tipo: 5,
        total_pagar: this.formRecargarCuenta.monto,
        pay: dataPagoOnline.data.data
      };
      this.redatwater.almacenarCompraOnlineWompi(parametros).then((getData: any) => {
        console.log('Ventana -> Recarga exitosa.');

        if (getData.cartera?.id) {
          this.ventanaAlerta.msgAppCenter('¡Cartera!', 'Recargaste <span class="text-success">Tu tarjeta Aquahabiente.</span>');
          this.tarjetaAquahabiente.saldo = parseInt(this.tarjetaAquahabiente.saldo) + parseInt(this.formRecargarCuenta.monto);
          this.formRecargarCuenta.monto = 0;
          this.viewBtnRecargar = true;
        } else {
          this.viewBtnRecargar = true;
          console.log('Seguimiento de errores -> ', parametros);
        }

      }).catch(error => {
        console.log('Ventana -> Error almacenar compra: ' + error);
        this.viewBtnRecargar = true;

      });
    } else {
      console.log('Ventana -> Error info recargar tarjeta. ');
      this.viewBtnRecargar = true;

    }

  }

  async metodosPay() {
    //1. Declaring an empty array
    let actionLinks = [];

    actionLinks.push({
      text: 'Cartera',
      icon: 'wallet-outline',
      handler: () => {
        this.seleccionarTarjeta();
      }
    });

    actionLinks.push({
      text: 'Métodos de pago',
      icon: 'ribbon-outline',
      handler: () => {
        this.carteraMetodosPay();
      }
    });

    actionLinks.push({
      text: 'Cerrar ventana',
      icon: 'close',
      role: 'cancel',
      handler: () => {

      }
    })

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Paga a tu manera:',
      buttons: actionLinks
    });
    await actionSheet.present();

  }

  async carteraMetodosPay() {
    const totalPago = this.formRecargarCuenta.monto;
    const categoriaPago = 'DIGITAL_PAY';
    const modalAdd = await this.modalController.create({
      component: PayMetodosComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { totalPago, categoriaPago }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();
    console.log('Seguimiento modal -> Metodo de Pago: ', data);
    if (data) {
      this.metodoPay = data['metodo'];
      console.log('Seguimiento -> Parametros metodo: ', this.metodoPay);

      this.loadingService.showLoading('¡Tu transacción en proceso!', 'assets/img/loading_splash.png');

      if (this.metodoPay) {

        this.metodoSeleccionado.metodo = this.metodoPay.metodo;

        if (this.metodoSeleccionado.metodo === 'nequi') {

          if (this.metodoPay.nequi?.celular) {
            
            this.metodoPay.payTotal = totalPago;
            this.viewBtnRecargar = false;
            

            this.wompi.enviarPagoNequi(this.metodoPay).then((dataPay: any) => {
              if (dataPay) {

                console.log('Seguimiento -> Pay ', dataPay);
                this.verificarProcesoTransaccion(dataPay);

              } else {
                console.log('Seguimiento de errores -> ', dataPay);
                this.metodoPay.payTotal = 0;
                this.loadingService.hideLoading();
                this.viewBtnRecargar = true;
              }
            }).catch((err: any) => {
              this.loadingService.hideLoading();
              console.log('Seguimiento de errores -> ', err);
            });

            

          } else {
            console.log('Seguimiento de errores -> ', this.metodoPay.nequi);
          }

        } else {
          console.log('Seguimiento de errores -> ¿Metodo de pago?');
        }


      } else {
        this.ventanaAlerta.msgAppCenter('¡Recargar saldo!', '¡Oops! Información de tarjeta.');
      }
    }
  }

  getTRecargaProcesoTransaccion() {
    return this.tiempoRecargaTransaccion;
  }

  async verificarProcesoTransaccion(data: any) {
    this.procesoTransaccion = interval(this.getTRecargaProcesoTransaccion());
    this.subscriptionTransaccion.add(
      this.procesoTransaccion.subscribe(() => {
        console.log('Procesando...');
        if (this.metodoSeleccionado.metodo === 'tarjeta') {
          this.verificarTarjeta(data);
        } else if (this.metodoSeleccionado.metodo === 'nequi') {
          this.verificarNequi(data);
        } else {

        }
      })
    );
  }

  async verificarTarjeta(data: any) {

    this.wompi.verificarPagoTarjeta(data).then((dataPay: any) => {
      if (dataPay?.estado) {
        console.log('Seguimiento -> ', dataPay);
        if (dataPay.estado === 'esperando') {

          console.log('Wompi: Transaccion Tarjeta -> Esperado....');
          this.viewBtnRecargar = false;

        } else if (dataPay.estado === 'aprobado') {

          console.log('Wompi: Transaccion Tarjeta -> Aprobado!');
          if (this.subscriptionTransaccion) {
            this.subscriptionTransaccion.unsubscribe();
          }
          this.subscriptionTransaccion = null;
          clearInterval(this.procesoTransaccion);

          this.loadingService.hideLoading();

          this.viewBtnRecargar = true;

          if (this.intSolicitud) {
            this.finalizarPayTarjeta(dataPay);
            this.wompi.establecerTokerPay();
            this.intSolicitud = false;
          }


        } else if (dataPay.estado === 'rechazado') {

          console.log('Wompi: Transaccion Tarjeta -> Rechazado!');
          if (this.subscriptionTransaccion) {
            this.subscriptionTransaccion.unsubscribe();
          }
          this.subscriptionTransaccion = null;
          clearInterval(this.procesoTransaccion);

          this.viewBtnRecargar = true;
          
          this.loadingService.hideLoading();
          this.ventanaAlerta.msgAppCenter('AVISO', 'Tu transacción ha sido rechazada.');
          this.wompi.establecerTokerPay();

        } else {
          console.log('Seguimiento de errores -> ', dataPay);
          this.ventanaAlerta.msgAppCenter('¡Oops! Recarga de saldo', 'Parece que algo salió mal.');
          this.loadingService.hideLoading();
        }
      } else {
        console.log('Seguimiento de errores -> ', dataPay);
        this.loadingService.hideLoading();
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
      this.ventanaAlerta.msgAppCenter('¡Oops! Recarga de saldo', 'Parece que algo salió mal con la petición.');
      this.loadingService.hideLoading();
    });
  }


  async verificarNequi(data: any) {
    this.wompi.verificarNequiPago(data).then((dataPay: any) => {
      if (dataPay?.estado) {
        console.log('Seguimiento -> ', dataPay);
        if (dataPay.estado === 'esperando') {

          console.log('Wompi: Transaccion NEQUI -> Esperado....');
          this.viewBtnRecargar = false;

        } else if (dataPay.estado === 'aprobado') {

          console.log('Wompi: Transaccion NEQUI -> Aprobado!');
          if (this.subscriptionTransaccion) {
            this.subscriptionTransaccion.unsubscribe();
          }
          this.subscriptionTransaccion = null;
          clearInterval(this.procesoTransaccion);

          this.metodoPay.payTotal = 0;
          this.viewBtnRecargar = true;
          this.loadingService.hideLoading();

          if (this.intSolicitud) {
            this.transaccionPayProceso(dataPay);
            this.wompi.establecerTokerPay();
            this.intSolicitud = false;
          }


        } else if (dataPay.estado === 'rechazado') {

          console.log('Wompi: Transaccion NEQUI -> Rechazado!');
          if (this.subscriptionTransaccion) {
            this.subscriptionTransaccion.unsubscribe();
          }
          this.subscriptionTransaccion = null;
          clearInterval(this.procesoTransaccion);

        
          this.metodoPay.payTotal = 0;
          this.viewBtnRecargar = true;

          this.ventanaAlerta.msgAppCenter('AVISO', 'Tu transacción ha sido rechazada.');
          this.loadingService.hideLoading();

          this.wompi.establecerTokerPay();

          

        } else {
          console.log('Seguimiento de errores -> ', dataPay);
          this.loadingService.hideLoading();
        }
      } else {
        console.log('Seguimiento de errores -> ', dataPay);
        this.loadingService.hideLoading();
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
      this.loadingService.hideLoading();
    });
  }


  async transaccionPayProceso(transaccion: any) {
    const parametros = {
      tipo: 5,
      total_pagar: this.formRecargarCuenta.monto,
      name: 'NEQUI APP',
      divisa: 'NEQUI',
      status: 'aprobado',
      pay: transaccion
    };
    this.redatwater.almacenarCompraOnlineWompiNEQUI(parametros).then(() => {
      console.log('Ventana -> Recarga exitosa.');
      this.ventanaAlerta.msgAppCenter('¡Cartera!', 'Recargaste tu tarjeta Aquahabiente.');
      this.tarjetaAquahabiente.saldo = parseInt(this.tarjetaAquahabiente.saldo) + parseInt(this.formRecargarCuenta.monto);
      this.formRecargarCuenta.monto = 0;

    }).catch(error => {
      console.log('Ventana -> Error almacenar compra: ' + error);
    });
  }

}
