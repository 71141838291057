import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController, ModalController, LoadingController, AlertController, Platform } from '@ionic/angular';
import { Ciudad, Header, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { EscanerService } from 'src/app/services/escaner.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TextSpeechService } from 'src/app/services/text-speech.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { AppComollegarComponent } from '../app-comollegar/app-comollegar.component';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { InfosistemaCercaComponent } from './infosistema-cerca/infosistema-cerca.component';
import { IonicSelectableComponent } from 'ionic-selectable';


@Component({
  selector: 'app-app-sistemascerca',
  templateUrl: './app-sistemascerca.component.html',
  styleUrls: ['./app-sistemascerca.component.scss'],
})
export class AppSistemascercaComponent implements OnInit {

  //private subscription: Subscription = new Subscription();

  authUsuario: any = null;
  cuentaUsuario: any = null;

  ciudad: Ciudad;
  ciudades: Ciudad[];
  ubicacion_ciudad: String = '';

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_SISTEMASCERCA';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 1,
    pagina: '',
    titulo: ''
  }

  precioActualLitroSistema = 0;
  coberturaSistemasATWATER: boolean = false;
  coberturaSistemas: any[] = [];
  sistemasCercanos: Array<any> = Array();
  listaModelosSistemas: any[] = [];

  slidePubliAqua = [];
  slideItem: any = {
    'titulo': '',
    'info': '',
    'boton': '',
    'boton_url': '',
    'imagen': 'assets/img/default.png'
  };

  anunciosPubliAqua: Array<any> = Array();
  anunciosLista: any = [];
  slideAnuncios: any[] = [];
  totalAnuncios = 0;

  buscador: '';
  modeloSeleccionado = '';
  finalBuscador: any = '';
  tipoBusqueda: string = 'ciudad';

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Establecer ubicación.',
    coordenadas: {
      lat: null,
      lng: null
    }
  };


  defaultImage = 'assets/img/default.png';

  totalRows: number;
  currentPage: number = 1;
  limit: number = 8;

  @ViewChild('seleccionarComponent') seleccionarComponent: IonicSelectableComponent;

  constructor(
    private translateConfigService: TranslateConfigService,
    private ventanaAlerta: AlertService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private navCtrl: NavController,
    private modalController: ModalController,
    private alertCtr: AlertController,
    private escaner: EscanerService,
    private geolocalizacion: GeolocalizacionService,
    private loadingCtrl: LoadingController,
    private platform: Platform,
    private aquabot: AquabotService,
    private utilitario: UtilitariosService,
    private textSpeech: TextSpeechService
  ) {
    console.log('App -> Sistemas cerca!');
    this.header.pagina = '/';
    this.header.titulo = 'Sistemas alrededor';
  }

  ngOnInit() {

    this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getUsuario: any) => {
      console.log('Usuario Auth ->', getUsuario);
      if (getUsuario?.data) {
        this.authUsuario = getUsuario.data;
        console.log('Seguimiento -> Auth dispositivo: ', this.authUsuario);
        this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
          this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
            this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
            this.itemsIdioma = this.translateConfigService.getItemsIdioma();

            if (this.itemsIdiomaApp && this.itemsIdioma) {
              //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
              //console.log('Parametros de idioma -> ', this.itemsIdioma);
              //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);

              this.aquaBot = this.aquabot.appMsgAquaBot({
                msg: this.itemsIdiomaApp.aquabot_msg_cajeros,
                estado: 'text-primary'
              });

              this.listaCiudades();
              this.publiAquaSlide();
              this.consultarModelos();

              this.loadUbicacionActual();
              this.infoCuenta();

            } else {
              this.translateConfigService.resetIdioma(this.idiomaPagina);
            }
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      } else {
        this.navCtrl?.navigateRoot(['/logout']);
      }
    });

  }


  ngOnDestroy() {

  }

  async infoCuenta() {
    this.redatwater.consultarFullCuentaUsuario().then((getData: any) => {
      if (getData) {

        console.log('Full data cuenta usuario -> ', getData);

        if (getData?.infoUsuario) {

          this.cuentaUsuario = getData.infoUsuario;

          if (this.cuentaUsuario?.ciudad_id) {

            this.ubicacion_ciudad = this.cuentaUsuario.ciudad_id;

            console.log('Consulta inicial -> ', this.ubicacion_ciudad);

            this.finalBuscador = this.ubicacion_ciudad;

            this.busquedaSistemas(this.currentPage, this.limit);

          }


        } else {
          console.log('Seguimiento de errores -> ', getData);
        }

      } else {
        console.log('Seguimiento de errores -> ', getData);

        //this.navCtrl?.navigateRoot(['/logout']);
      }

    }).catch((err: any) => {

      console.log('Seguimiento de errores -> ', err);
    });
  }

  async descargarAppAtwater() {
    console.log('Seguimiento -> Descargar App');
    if (this.platform.is('ios')) {
      this.aquabot.enlaceSugerido(this.redatwater.getEnlaceAppAtWaterIOS(), 'AT-WATER para iOS');
    } else { // Android
      this.aquabot.enlaceSugerido(this.redatwater.getEnlaceAppAtWaterAndroid(), 'AT-WATER para Android');
    }

  }

  async listaCiudades() {
    const loading = await this.loadingCtrl.create({
      message: 'Verificando ciudades...',
      spinner: 'crescent',
      showBackdrop: true
    });
    loading.present();

    this.redatwater.listaCiudades(this.redatwater.getPaisSeleccionado()).then(data => {
      //console.log('Ciudades del Pais -> ' + data);
      this.ciudades = data;
      loading.dismiss();
    });
  }


  async consultarModelos() {

    console.log('Consultar modelos de sistemas! ');
    let modelos;
    this.listaModelosSistemas = [];

    this.redatwater.listaModelosSistemas().then((getModelos: any) => {
      console.log('Listado de modelos -> ', getModelos);
      if (getModelos) {
        modelos = getModelos;
        this.listaModelosSistemas = modelos;
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }


  transformarTituloModelos(titulo: string, reemplazar: string) {
    const tituloTransformado = titulo.replace('_', reemplazar);
    return tituloTransformado;
  }

  async consultarSistemas(page: number, limit: number) {

    this.sistemasCercanos = [];
    this.coberturaSistemas = new Array<any>();

    console.log('Consultar sistemas cercanos!');
    const pagination = {
      page: page,
      limit: limit
    }

    this.redatwater.sistemasCercanos(this.geolocalizacion, pagination).then((getSistemas: any) => {
      console.log('Sistemas -> ', getSistemas);
      if (getSistemas) {

        this.coberturaSistemas = getSistemas.sistemas;
        this.totalRows = getSistemas.total_registros;

        this.procesarSistemasCerca();
      } else {
        console.log('Seguimiento de errores -> ', getSistemas);
      }
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    })

  }

  ciudadChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    const ciudad = event.value;
    if (ciudad) {
      console.log('Ciudad seleccionada -> ', ciudad);
      this.ubicacion_ciudad = ciudad.ciudad_id;
      this.buscarSistemas();
    }
  }

  async loadUbicacionActual() {
    this.geolocalizacion.loadUbicacionActual().then((ubicacion: any) => {
      console.log('App Ubicacion actual -> ' + JSON.stringify(ubicacion));
      this.direccionActual = ubicacion;
      this.consultarSistemas(this.currentPage, this.limit);
    }).catch(error => {
      console.log('App -> Error: ' + error);
    });
  }


  seleccionarIdioma(idioma) {
    if (idioma) {
      //console.log('Seleccionar idioma -> ', idioma);
      this.selectedLanguage = idioma;
      //console.log('Idioma seleccionado: ' + this.selectedLanguage);
      this.languageChanged(this.selectedLanguage);
      this.idiomaSeleccionado = idioma;

      this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
        this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
          this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
          this.itemsIdioma = this.translateConfigService.getItemsIdioma();
          this.translateConfigService.getItemsIdioma();
          if (this.itemsIdiomaApp && this.itemsIdioma) {
            //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
            //console.log('Parametros de idioma -> ', this.itemsIdioma);
            //console.log('Test 2 -> ', this.itemsIdiomaApp.app_loading);

            this.textSpeech.hablar(this.itemsIdioma.hablar_app_intrucciones);
          } else {
            this.translateConfigService.resetIdioma(this.idiomaPagina);
          }
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }
  }

  languageChanged(idioma: string) {
    this.translateConfigService.setLanguage(idioma);
  }


  async publiAquaSlide() {

    // Consultar Anuncios
    this.redatwater.consultarAnunciosFull().then((dataAnuncios: any) => {
      if (dataAnuncios) {
        //console.log("Full Anuncios -> " + JSON.stringify(dataAnuncios));
        dataAnuncios.forEach((anuncio: any) => {
          console.log('Anuncio -> ' + anuncio.id);
          this.slidePubliAqua.push({
            'titulo': anuncio.titulo,
            'info': this.utilitario.removeHtmlTags(anuncio.descripcion),
            'boton': 'Ver promocion ...',
            'boton_url': anuncio.link,
            'imagen': anuncio.file
          });
        });

        this.slidePubliAqua.push({
          'titulo': '¡10% de descuento!',
          'info': 'Disfruta en tiendas seleccionadas',
          'boton': 'Ver tiendas ...',
          'boton_url': '',
          'imagen': 'assets/img/publiaqua/descuento.png'
        });

      }

      const item = Math.floor(Math.random() * this.slidePubliAqua.length);
      if (this.slidePubliAqua[item]) {
        console.log('Listado de anuncios -> ' + this.slidePubliAqua[item]);
        this.slideItem = this.slidePubliAqua[item];
      }
    }).catch(error => {
      console.log('App -> Error consultar anuncions: ' + error);
    });

  }

  async procesarSistemasCerca() {
    console.log('Seguimiento -> Sistemas cercanos', this.coberturaSistemas);
    if (this.coberturaSistemas) {
      let dataSistema: any = null;
      let parametrosSistemas: any = null;
      this.coberturaSistemas.forEach((getSistema: any) => {
        if (getSistema?.sistema.id) {

          if (getSistema.sistema.modelo_id) {
            dataSistema = getSistema;
            const img = 'assets/img/sistemas/' + dataSistema.sistema.modelo_id + '.png';
            parametrosSistemas = {
              data: dataSistema,
              img: img
            }
            this.sistemasCercanos.push(parametrosSistemas);
          }

        }
        dataSistema = null;
        parametrosSistemas = null;
      });

      console.log('Sistemas cercanos ->', this.sistemasCercanos);

    } else {
      console.log('Seguimiento de errores -> ', this.coberturaSistemas);
    }
  }

  async comoLlegar(cajero: any) {
    if (cajero.data) {

      console.log('Seguimiento -> Consultar ¿Como llegar a sistema? ');
      const sistemaSeleccionado = cajero;

      const modalAdd = await this.modalController.create({
        component: AppComollegarComponent,
        mode: 'ios',
        swipeToClose: true,
        componentProps: { sistemaSeleccionado }
      });
      await modalAdd.present();
      const { data } = await modalAdd.onWillDismiss();
      if (data) {
        console.log('Seguimiento -> Data de ¿Como llegar?: ', data);

      }

    } else {
      console.log('Seguimiento de errores -> ', cajero);
    }
  }

  async generarCodigo(cajero: any) {
    if (cajero.data) {
      console.log('Seguimiento -> Generar QR de cajero');
      let codigoArray = {
        token: this.escaner.getTokenApp(),
        categoria: 'sistema_key',
        sistema_key: cajero.data.private_key
      };
      this.escaner.generarQr(codigoArray).then(() => { }).catch((err) => { console.log('Error: ', err); });
    } else {
      console.log('Seguimiento de errores -> ', cajero);
    }
  }


  nextPage() {
    if (this.currentPage < Math.ceil(this.totalRows / this.limit)) {
      this.currentPage++;
      this.consultarSistemas(this.currentPage, this.limit);
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.consultarSistemas(this.currentPage, this.limit);
    }
  }

  isLastPage(): boolean {
    return this.currentPage === this.getLastPage();
  }

  getLastPage(): number {
    return Math.ceil(this.totalRows / this.limit);
  }

  isBothButtonsDisabled(): boolean {
    return this.currentPage === 1 && this.isLastPage();
  }

  async instruccionesApp(texto: String) {
    this.ventanaAlerta.msgAppCenter(this.itemsIdiomaApp.app_titulo_alertas, ("<b><u>" + this.itemsIdioma.app_intrucciones + ":</u></b> <span class='text-success'>" + texto + "</span>"));
  }


  async cerrarVentana() {
    this.closeModal();
  }

  closeModal() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss({ sistemasCerca: this.sistemasCercanos }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  detalleSistema(sistema: any) {
    if (sistema) {
      this.infoSistema(sistema);
    }
  }

  async infoSistema(sistema: any) {
    if (sistema.data) {

      console.log('Seguimiento -> Info de sistema ');
      const sistemaSeleccionado = sistema;
      this.cerrarVentana();
      const modalAdd = await this.modalController.create({
        component: InfosistemaCercaComponent,
        mode: 'ios',
        swipeToClose: true,
        componentProps: { sistemaSeleccionado }
      });
      await modalAdd.present();
      const { data } = await modalAdd.onWillDismiss();
      if (data) {
        console.log('Seguimiento -> Info de sistema: ', data);
      }

    } else {
      console.log('Seguimiento de errores -> ', sistema);
    }
  }


  cambiarTipoBusqueda() {
    console.log('Seleccionar tipo de busqueda');
    this.seleccionarTipoBusqueda();
  }

  async seleccionarTipoBusqueda() {

    console.log('Seleccionar otro tipo de busqueda: ');

    let metodosPermitidos = [];

    metodosPermitidos.push({
      label: 'Ciudad',
      type: 'radio',
      name: 'ciudad_sistema',
      value: 1,
    });
    /*
        metodosPermitidos.push({
          label: 'Código de sistema',
          type: 'radio',
          name: 'codigo_sistema',
          value: 1,
        });
    
        metodosPermitidos.push({
          label: 'Modelo',
          type: 'radio',
          name: 'modelo_sistema',
          value: 2,
        });
    */
    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: '¡Busqueda personalizada!',
      //message: '',
      inputs: metodosPermitidos,
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cerrar!');
          }
        }, {
          text: 'Aceptar',
          handler: (data: any) => {
            console.log('Ok!');

            if (data === 1) { // Codigo de sistema


              this.sistemasCercanos = [];
              this.coberturaSistemas = new Array<any>();

              this.currentPage = 1;

              this.tipoBusqueda = 'ciudad';
              this.buscarSistemas();

            } else if (data === 2) { // Modelo

              this.sistemasCercanos = [];
              this.coberturaSistemas = new Array<any>();

              this.currentPage = 1;

              this.tipoBusqueda = 'codigo';
              this.buscarSistemas();


            } else if (data === 3) {

              this.sistemasCercanos = [];
              this.coberturaSistemas = new Array<any>();

              this.currentPage = 1;

              this.tipoBusqueda = 'modelo';
              this.buscarSistemas();


            } else {
              console.log('Seguimiento de errores -> seleccionar tipo de busqueda.');
              this.seleccionarTipoBusqueda();
            }
          }
        }
      ]
    });

    await alert.present();

  }

  async buscarSistemas() {

    console.log('Buscar -> ', this.tipoBusqueda, this.modeloSeleccionado);

    if (this.tipoBusqueda) {

      if (this.tipoBusqueda === 'codigo') {

        if (this.buscador) {
          this.sistemasCercanos = [];
          this.coberturaSistemas = new Array<any>();

          this.finalBuscador = this.buscador;

          this.busquedaSistemas(this.currentPage, this.limit);
        } else {
          console.log('Seguimiento de errores -> ', this.buscador);
          this.consultarSistemas(this.currentPage, this.limit);
        }

      } else if (this.tipoBusqueda === 'modelo') {

        if (this.modeloSeleccionado) {

          this.sistemasCercanos = [];
          this.coberturaSistemas = new Array<any>();

          this.finalBuscador = this.modeloSeleccionado;

          this.busquedaSistemas(this.currentPage, this.limit);

        } else {
          console.log('Seguimiento de errores -> ', this.finalBuscador);
          //this.consultarSistemas(this.currentPage, this.limit);
        }

      } else if (this.tipoBusqueda === 'ciudad') {
        if (this.ciudades?.length) {

          this.sistemasCercanos = [];
          this.coberturaSistemas = new Array<any>();

          this.finalBuscador = this.ubicacion_ciudad;

          this.busquedaSistemas(this.currentPage, this.limit);

        } else {
          console.log('Seguimiento de errores -> Ciudades para busqueda', this.ciudades);
          //this.consultarSistemas(this.currentPage, this.limit);
        }
      }

    } else {
      console.log('Seguimiento de errores -> ', this.buscador);
      this.consultarSistemas(this.currentPage, this.limit);
    }
  }


  async busquedaSistemas(page: number, limit: number) {

    console.log('Seguimiento -> Busqueda de sistemas');
    this.ubicacion_ciudad = '';

    this.sistemasCercanos = [];
    this.coberturaSistemas = new Array<any>();
    this.totalRows = 0;
    this.currentPage = 1;

    const pagination = {
      page: page,
      limit: limit
    }
    this.redatwater.getSistemasBusqueda(this.tipoBusqueda, this.finalBuscador, this.direccionActual.coordenadas, pagination).then((getData: any) => {

      if (getData) {

        console.log('Lista de sistemas cerca -> ', getData);
        this.coberturaSistemas = getData.sistemas;
        this.totalRows = getData.total_registros;
        console.log('Seguimiento -> ', this.coberturaSistemas);
        this.procesarSistemasCerca();

      } else {

        console.log('Seguimiento de errores -> ', getData);

      }

    }).catch((err: any) => {

      console.log('Seguimiento de errores -> ', err);
    });

  }

  verPagina(pagina: string) {
    if (pagina) {
      console.log('Ver pagina: ' + pagina);
      this.aquabot.enlaceSugerido(pagina, 'PubliAqua');
    }
  }




}
