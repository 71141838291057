// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ApiKeyGoogleMaps: 'AIzaSyCHjX6xr4eBMjKNjj9Eu0WcYD3mW_Ee68o',  
  // Wompi
  ApiPublicKeyWompi: 'pub_prod_Iw7hqt3dHn7HPPA0oCuktdFn4WGnDyBu',  // GUIA developer COPIA Y PEGAR ***
  //ApiPublicKeyWompi: 'pub_test_WfXURaN3EFia79nWb4tQMzXDlkYl8uQB',
  // ApiPrivateKeyWompi: 'pub_prod_QqZ11XeRwx9fNnI6hVkGeRMovXlAAZDd' // GUIA produccion COPIA Y PEGAR ***
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
