import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController } from '@ionic/angular';
import { Header, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { EscanerService } from 'src/app/services/escaner.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TextSpeechService } from 'src/app/services/text-speech.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';

@Component({
  selector: 'app-ok-pedido-cliente',
  templateUrl: './ok-pedido-cliente.component.html',
  styleUrls: ['./ok-pedido-cliente.component.scss'],
})
export class OkPedidoClienteComponent implements OnInit {

  @Input() pedido: any;

  authUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'APP_CLIENTE_OK_PEDIDO_TIENDA';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 1,
    pagina: '',
    titulo: ''
  }

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Establecer ubicación.',
    coordenadas: {
      lat: null,
      lng: null
    }
  };

  defaultImage = 'assets/img/default.png';

  imgFactura = 'assets/img/iconos/factura.png';
  productoSeleccionado = 0; // Inicialmente seleccionamos el producto 0

  imgSuministroAgua = '';
  domiciliario = '';
  calificacionDomiciliario = '';
  calificacionCliente = '';
  calificacionTienda = '';
  estadoPedidoEntrega = '';
  intercambiarEnvase = '';
  viewOpcion: string = '';

  constructor(
    private translateConfigService: TranslateConfigService,
    private ventanaAlerta: AlertService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private navCtrl: NavController,
    private alertCtr: AlertController,
    private aquabot: AquabotService,
  ) {
    console.log('App -> Sistemas cerca!');
    this.header.pagina = '/redatwater-cliente';
    this.header.titulo = 'DETALLE PEDIDO';

  }

  ngOnInit() {

    console.log('Seguimiento -> Pedido: ', this.pedido);
    this.detallePedido();


    this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getUsuario: any) => {
      console.log('Usuario Auth ->', getUsuario);
      if (getUsuario?.data) {
        this.authUsuario = getUsuario.data;
        console.log('Seguimiento -> Auth dispositivo: ', this.authUsuario);
        this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
          this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
            this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
            this.itemsIdioma = this.translateConfigService.getItemsIdioma();

            if (this.itemsIdiomaApp && this.itemsIdioma) {
              //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
              //console.log('Parametros de idioma -> ', this.itemsIdioma);
              //console.log('Test 1 -> ', this.itemsIdiomaApp.app_loading);

              this.aquaBot = this.aquabot.appMsgAquaBot({
                msg: this.itemsIdiomaApp.aquabot_msg_cajeros,
                estado: 'text-primary'
              });

              this.viewOpcion = 'info_pedido';

            } else {
              this.translateConfigService.resetIdioma(this.idiomaPagina);
            }
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      } else {
        this.navCtrl?.navigateRoot(['/logout']);
      }
    });

  }

  detallePedido() {
    if (this.pedido.pedido?.domiciliario_id) {
      this.domiciliario = this.pedido.pedido.domiciliario_id;
    } else {
      this.domiciliario = '';
    }

    if (this.pedido.pedido?.calificacion_cliente_domiciliario) {
      this.calificacionDomiciliario = this.pedido.pedido.calificacion_cliente_domiciliario;
    } else {
      this.calificacionDomiciliario = '';
    }


    if (this.pedido.pedido?.calificacion_usuario_cliente) {
      this.calificacionCliente = this.pedido.pedido.calificacion_usuario_cliente;
    } else {
      this.calificacionCliente = '';
    }

    if (this.pedido.pedido?.calificacion_cliente_tienda) {
      this.calificacionTienda = this.pedido.pedido.calificacion_cliente_tienda;
    } else {
      this.calificacionTienda = '';
    }


    if (this.pedido.pedido.pedido_agua[0]?.intercambiar === '2') {
      this.intercambiarEnvase = 'Si';
    } else {
      this.intercambiarEnvase = 'No';
    }

    switch (this.pedido.pedido?.entregado) {

      case '1':
        this.estadoPedidoEntrega = 'solicitado';
        break;

      case '2':
        this.estadoPedidoEntrega = 'procesado';
        break;

      case '3':
        this.estadoPedidoEntrega = 'enviado';
        break;

      case '4':
        this.estadoPedidoEntrega = 'entregado';
        break;

      default:
        this.estadoPedidoEntrega = '';
        break;

    }
  }


  ngOnDestroy() {

  }

  seleccionarViewOpcion(view: any) {
    if (view) {
      console.log('Seleccionar view -> ', view);
      this.viewOpcion = view;
    }
  }

  establecerImagenAgua(presentacion: any) {
    this.imgSuministroAgua = '';
    if (parseInt(presentacion) >= 1) {
      console.log('Seguimiento -> Presentacion ID', presentacion);
      switch (presentacion) {

        case '1':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/1litro.png';
          break;

        case '5':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/5litros.png';
          break;

        case '10':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/10litros.png';
          break;

        case '19':
          this.imgSuministroAgua = 'assets/img/solicitar_agua/19litros.png';
          break;

        default:
          this.imgSuministroAgua = '';
          break;

      }

    } else {
      console.log('Seguimiento de errores -> ', presentacion);
    }
  }

  seleccionarProducto(index: number) {
    this.productoSeleccionado = index; // Cambiar la selección al índice del producto seleccionado
  }
  
  async calificarTienda() {
    console.log('Calificar a la tienda');

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: 'Califica tu experiencia',
      message: 'Por favor, selecciona una opción para <span class="text-success">calificar a la tienda:</span>',
      inputs: [
        {
          type: 'radio',
          label: 'Excelente',
          value: 3,
          checked: false
        },
        {
          type: 'radio',
          label: 'Bueno',
          value: 2,
          checked: false
        },
        {
          type: 'radio',
          label: 'Malo',
          value: 1,
          checked: false
        }
      ],
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Calificación cancelada');
          }
        },
        {
          text: 'Enviar Calificación',
          handler: (data: any) => {
            if (data === 1 || data === 2 || data === 3) {

              const seleccionado = data;
              console.log('Calificación enviada:', seleccionado);
              this.confirmarCalificacionTienda(seleccionado);

            } else {
              console.log('Seguimiento de errores -> ', data);
              this.calificarTienda();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async confirmarCalificacionTienda(calificacion: any) {
    console.log('Confirmar Entrega de pedido');
    if (this.pedido.pedido?.id) {

      this.redatwater.confirmarCalificacionTienda(this.pedido.pedido.id, calificacion).then((pedido: any) => {

        if (pedido?.pedido) {

          this.pedido = pedido;
          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-success">¡Gracias por tu calificación! </span> tu opinión es importante para nosotros.');
          this.detallePedido();

        } else {
          console.log('Seguimiento de errores -> ', pedido);
          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Información incorrecta, Portal Transaccional.');
        }

      }).catch((error: any) => {
        console.log('Seguimiento de errores -> ', error);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
      });

    } else {
      console.log('Seguimiento de errores ->', this.pedido);
    }
  }

  calificarDomiciliario() {
    console.log('Calificar domiciliario');
    this.ventanaCalificarDomiciliario();
  }


  async ventanaCalificarDomiciliario() {
    console.log('Calificar al domiciliario');

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: 'Califica tu experiencia',
      message: 'Por favor, selecciona una opción para <span class="text-success">calificar al domiciliario:</span>',
      inputs: [
        {
          type: 'radio',
          label: 'Excelente',
          value: 3,
          checked: false
        },
        {
          type: 'radio',
          label: 'Bueno',
          value: 2,
          checked: false
        },
        {
          type: 'radio',
          label: 'Malo',
          value: 1,
          checked: false
        }
      ],
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Calificación cancelada');
          }
        },
        {
          text: 'Enviar Calificación',
          handler: (data: any) => {
            if (data === 1 || data === 2 || data === 3) {

              const seleccionado = data;
              console.log('Calificación enviada:', seleccionado);
              this.confirmarCalificacionDomiciliario(seleccionado);

            } else {
              console.log('Seguimiento de errores -> ', data);
              this.ventanaCalificarDomiciliario();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async confirmarCalificacionDomiciliario(calificacion: any) {
    console.log('Confirmar Entrega de pedido');
    if (this.pedido.pedido?.id) {

      this.redatwater.confirmarCalificacionClienteDomiciliario(this.pedido.pedido.id, calificacion).then((pedido: any) => {

        if (pedido?.pedido) {

          this.pedido = pedido;
          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-success">¡Gracias por tu calificación! </span> tu opinión es importante para nosotros.');
          this.detallePedido();

        } else {
          console.log('Seguimiento de errores -> ', pedido);
          this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Información incorrecta, Portal Transaccional.');
        }

      }).catch((error: any) => {
        console.log('Seguimiento de errores -> ', error);
        this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
      });

    } else {
      console.log('Seguimiento de errores ->', this.pedido);
    }
  }



}
