import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AlertController, IonSlides, ModalController } from '@ionic/angular';
import { Header, ITiendaCercana, IUbicacionActual } from 'src/app/interfaces/book.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';

@Component({
  selector: 'app-agregar-pedido-vendedor',
  templateUrl: './agregar-pedido-vendedor.component.html',
  styleUrls: ['./agregar-pedido-vendedor.component.scss'],
})
export class AgregarPedidoVendedorComponent implements OnInit, OnDestroy {

  @Input() cliente: any;

  authUsuario: any = null;

  cuentaUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'AGREGAR_PEDIDO_VENDEDOR';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  defaultImage = 'assets/img/default.png';

  @ViewChild(IonSlides) slides: IonSlides;
  slideOptsThumbs = {
    spaceBetween: 0,
    slidesPerView: 3,
  };

  buscarCuenta: any = {
    tipo_documento: 'aquahabiente',
    documento: ''
  }


  slideOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    on: {
      transitionEnd: () => {
        this.slideChanged();
      }
    }
  };

  tiendaSeleccionada: any = null;
  listaTiendas: any = null;
  listaTiendasAsociadas: any = new Array<any>();
  listaProductosCombo: any = null;
  listaProductos: any = null;
  listaCajerosAgua: any = null;

  direccionActual: IUbicacionActual = {
    place_id: null,
    direccion: 'Establecer ubicación.',
    coordenadas: {
      lat: null,
      lng: null
    }
  };


  categoriaSeleccionada = '';
  formaPagoSeleccionada = 'contado';
  comentarioVenta = '';

  viewProductos: boolean = false;
  viewProcesoProductos: boolean = false;
  viewCategorias: boolean = false;
  viewBtnRegistrarVenta: boolean = false;
  totalVenta: number = 0;
  limiteProductosCompra: number = 10;
  viewExitoVenta: boolean = false;

  listaFullProductos: any = new Array<any>();

  constructor(
    private translateConfigService: TranslateConfigService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
    private utilitario: UtilitariosService,
    private geolocalizacion: GeolocalizacionService,
    private alertCtr: AlertController,
    private ventanaAlerta: AlertService,
    private alertController: AlertService,
    private modalController: ModalController,
  ) {
    console.log('App: Agregar venta');
    this.header.pagina = '/';
    this.header.titulo = 'Registrar venta';
  }

  ngOnInit() {

    console.log('Cliente -> ', this.cliente);
    this.buscarCuenta.documento = this.cliente.cuenta.codigo;

    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.loadAuthToken().then(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);

            if (getAuth) {

              this.authUsuario = getAuth;
              this.loadUbicacionActual();

            } else {
              console.log('Seguimiento de errores -> ', getAuth);
              this.cerrarVentana();
            }
          }).catch((err: any) => {
            console.log('Seguimiento de errores -> ', err);
          });


        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  ngOnDestroy() {

  }

  async loadUbicacionActual() {
    this.geolocalizacion.loadUbicacionActual().then((ubicacion: any) => {
      console.log('App Ubicacion actual -> ' + JSON.stringify(ubicacion));
      this.direccionActual = ubicacion;
      this.consultarTiendasAsociadas();
    }).catch(error => {
      console.log('App -> Error: ' + error);
    });
  }

  seleccionarIdioma(idioma) {
    if (idioma) {
      //console.log('Seleccionar idioma -> ', idioma);
      this.selectedLanguage = idioma;
      //console.log('Idioma seleccionado: ' + this.selectedLanguage);
      this.languageChanged(this.selectedLanguage);
      this.idiomaSeleccionado = idioma;

      this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
        this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
          this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
          this.itemsIdioma = this.translateConfigService.getItemsIdioma();
          this.translateConfigService.getItemsIdioma();
          if (this.itemsIdiomaApp && this.itemsIdioma) {
            //console.log('Parametros de idioma App -> ', this.itemsIdiomaApp);
            //console.log('Parametros de idioma -> ', this.itemsIdioma);
            //console.log('Test 2 -> ', this.itemsIdiomaApp.app_loading);

          } else {
            this.translateConfigService.resetIdioma(this.idiomaPagina);
          }
        }).catch((err: any) => {
          console.log('Seguimiento de errores -> ', err);
        });
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }
  }

  languageChanged(idioma: string) {
    this.translateConfigService.setLanguage(idioma);
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss({ cliente: this.cliente });
  }

  slideChanged() {
    this.slides.getActiveIndex().then(index => {

    });
  }


  calcularTotalVenta() {
    this.totalVenta = 0;
    let hayProductoSeleccionado = false;

    for (let producto of this.listaFullProductos) {
      if (producto.checked) {
        const cantidad = parseInt(producto.cantidad.toString());
        const precio = parseInt(producto.precio);

        if (cantidad <= 0) {
          producto.checked = false; // Deseleccionar el producto si la cantidad es 0
        } else {
          const subtotal = cantidad * precio;
          this.totalVenta += subtotal;
          producto.total = subtotal;
          hayProductoSeleccionado = true;
        }
      } else {
        producto.cantidad = 0;
        producto.total = 0;
      }
    }

    this.viewProcesoProductos = hayProductoSeleccionado;

    if (this.totalVenta <= parseInt(this.cliente.tarjeta.saldo)) {
      this.viewBtnRegistrarVenta = true;
    } else {
      this.viewBtnRegistrarVenta = false;
    }

  }

  updateCantidad(producto: any) {
    if (producto.checked) {
      producto.cantidad = 1;
      this.updateTotal(producto);
    } else {
      if (producto.cantidad <= 0) {
        producto.cantidad = 0;
        producto.total = 0;
      } else if (producto.cantidad > this.limiteProductosCompra) {
        producto.cantidad = this.limiteProductosCompra;
      }
      this.updateTotal(producto);
    }
  }

  updateTotal(producto: any) {
    const cantidad = parseInt(producto.cantidad.toString());
    const precio = parseInt(producto.precio);

    if (producto.checked || cantidad <= 0) {
      producto.total = 0;
    } else {
      const subtotal = cantidad * precio;
      producto.total = subtotal;
    }

    this.calcularTotalVenta();
  }

  async consultarTiendasAsociadas() {
    this.redatwater.consultarTiendasAsociadasVendedores(this.direccionActual.coordenadas).then((getData: any) => {
      if (getData) {

        console.log('Tiendas asociadas -> ', getData);

        if (getData?.tiendas) {

          console.log('Seguimiento ->', getData);
          this.listaTiendas = getData.tiendas;
          this.ordenarTiendas();

        } else {
          console.log('Seguimiento de errores -> ', getData);

        }

      } else {
        console.log('Seguimiento de errores -> ', getData);

      }

    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
      this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
    });
  }

  async ordenarTiendas() {

    const tiendasAgua = this.listaTiendas;
    tiendasAgua.forEach((itemTienda) => {
      //console.log('Tienda -> ' + JSON.stringify(itemTienda));

      const dataTienda: ITiendaCercana = {
        id: itemTienda.id,
        tipo: 'tiendas',
        categoria: itemTienda.categoria,
        categoria_id: itemTienda.categoria_id,
        descripcion: itemTienda.descripcion,
        estado: itemTienda.estado,
        estado_id: itemTienda.estado_id,
        celular: itemTienda.celular,
        email: itemTienda.email,
        empresa: itemTienda.empresa,
        empresa_id: itemTienda.empresa_id,
        titulo: itemTienda.titulo,
        indicativo_movil: itemTienda.indicativo_movil,

        ubicacion_ciudad: itemTienda.ubicacion_ciudad,
        ubicacion_ciudad_id: itemTienda.ubicacion_ciudad_id,
        ubicacion_lat: itemTienda.ubicacion_lat,
        ubicacion_long: itemTienda.ubicacion_long,
        direccion: itemTienda.direccion,
        imagen: itemTienda.img,

        analisis: itemTienda.analisis,

        horario_inicio: itemTienda.horario_inicio,
        horario_fin: itemTienda.horario_fin,
        horario_inicio_indicativo: itemTienda.horario_inicio_indicativo,
        horario_fin_indicativo: itemTienda.horario_fin_indicativo,
        envio_gratis: itemTienda.envio_gratis,

        fechaRegistro: itemTienda.created_at,
        fechaModificacion: itemTienda.updated_at,

        lista_productos: itemTienda.lista_productos,
        lista_combos: itemTienda.lista_combos,
        lista_cajeros: itemTienda.lista_cajeros,

        geolocalizacion: {
          distancia: itemTienda.geolocalizacion.distancia,
          distancia_text: itemTienda.geolocalizacion.distancia_text,
          unidad: itemTienda.geolocalizacion.unidad,
        }

      }

      //console.log('Tiendas cercanas -> ' + JSON.stringify(dataTienda));
      this.listaTiendasAsociadas.push(dataTienda);


    });

    this.listaTiendasAsociadas.sort((a, b) => {
      if (a.geolocalizacion.distancia == b.geolocalizacion.distancia) {
        return 0;
      }

      if (a.geolocalizacion.distancia < b.geolocalizacion.distancia) {
        return -1;
      }

      return 1;
    });

  }

  seleccionarTienda(tienda: any) {
    console.log('Seguimiento -> ', tienda);
    if (tienda) {

      this.viewCategorias = true;
      this.tiendaSeleccionada = tienda;

    } else {
      console.log('Seguimiento de errores -> ', tienda);
    }
  }

  categoriaVenta(event) {
    if (event) {

      console.log('Categoria input -> ' + event.target.value);

      if (event.target.value === 'combo') {
        this.ordenarProuctos(this.tiendaSeleccionada.lista_combos);
      } else if (event.target.value === 'producto') {
        this.ordenarProuctos(this.tiendaSeleccionada.lista_productos);
      } else {
        console.log('Seguimiento de errores -> ', event);
      }

    } else {
      console.log('Seguimiento de errores -> ', event);
    }
  }


  ordenarProuctos(productos: any) {

    console.log('Seguimiento -> Ordenar productos');

    if (productos) {

      this.listaFullProductos = new Array<any>();

      if (this.categoriaSeleccionada === 'combo') {

        productos.forEach((producto: any) => {

          this.listaFullProductos.push({

            id: producto.id,
            nombre: producto.titulo,
            checked: false,
            cantidad: 0,
            precio: producto.precio,
            total: 0

          });

        });

        this.viewProductos = true;
        console.log('Combos -> ', this.listaFullProductos);

      } else if (this.categoriaSeleccionada === 'producto') {

        productos.forEach((producto: any) => {

          this.listaFullProductos.push({

            id: producto.id,
            nombre: producto.titulo,
            checked: false,
            cantidad: 0,
            precio: producto.precio,
            total: 0

          });

        });

        this.viewProductos = true;
        console.log('Productos -> ', this.listaFullProductos);

      } else {
        console.log('Seguimiento de errores -> ', productos);
        this.viewProductos = false;
      }

    } else {
      console.log('Seguimiento de errores -> ', productos);
      this.viewProductos = false;
    }

  }

  registrarVenta() {

    if (this.tiendaSeleccionada.id && this.formaPagoSeleccionada) {

      if (this.totalVenta <= parseInt(this.cliente.tarjeta.saldo)) {

        // Verificar si hay productos seleccionados
        const productosSeleccionados = this.listaFullProductos.filter(producto => producto.checked);
        if (productosSeleccionados.length === 0) {
          // No hay productos seleccionados, mostrar un mensaje o realizar alguna acción aquí
          console.log('Seguimiento de errors ->', productosSeleccionados);

        } else {

          // Obtener los datos necesarios para la venta

          // Recoger los IDs de los productos seleccionados y la cantidad comprada
          const productosVenta = productosSeleccionados.map(producto => ({
            productoId: producto.id,
            cantidad: producto.cantidad
          }));

          // Calcular el total de la venta (esto ya se hace en la función calcularTotalVenta, no es necesario aquí)

          if (!this.comentarioVenta) {
            this.comentarioVenta = 'Registro de venta / App Banco del Agua'
          }

          // Preparar los datos para la petición HTTP
          const datosVenta = {
            tiendaId: this.tiendaSeleccionada.id,
            formaPago: this.formaPagoSeleccionada,
            saldo_total: this.totalVenta,
            comentario: this.comentarioVenta,
            tipoProductos: this.categoriaSeleccionada,
            productos: productosVenta
          };

          console.log('Registrar venta -> ', datosVenta);

          this.redatwater.registrarVentaPrevendedor(datosVenta, this.cliente).then((getData: any) => {

            console.log('Seguimiento -> ', getData);

            if (getData) {

              if (getData?.venta) {

                this.viewExitoVenta = true;
                this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-success">¡Venta registrada! </span> Una venta más hacia el triunfo.');

              } else {
                console.log('Seguimiento de errores -> ', getData);
                this.viewExitoVenta = false;
              }

            } else {
              console.log('Seguimiento de errores -> ', getData);
              this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Información incorrecta, Portal Transaccional.');
              this.viewExitoVenta = false;
            }

          }).catch((error: any) => {

            console.log('Seguimiento de errores -> ', error);
            this.ventanaAlerta.msgAppCenter('RED AT-WATER', '<span class="text-danger">¡Oops!</span> Conexión fallida, Portal Transaccional.');
            this.viewExitoVenta = false;

          });

        }


      } else {
        console.log('Seguimiento de errores -> ', this.totalVenta);
        this.viewExitoVenta = false;
      }

    } else {
      console.log('Seguimiento de errores -> ', this.tiendaSeleccionada.id, this.formaPagoSeleccionada);
      this.viewExitoVenta = false;
    }
  }


}
