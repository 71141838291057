import { Component, OnInit } from '@angular/core';
import { NavController, ModalController, ActionSheetController, MenuController, AlertController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { EscanerService } from 'src/app/services/escaner.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';

@Component({
  selector: 'app-pedidos-vendedores',
  templateUrl: './pedidos-vendedores.component.html',
  styleUrls: ['./pedidos-vendedores.component.scss'],
})
export class PedidosVendedoresComponent implements OnInit {

  authUsuario: any = null;

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'PEDIDOS_VENDEDOR_USUARIO';

  aquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: ''
  };

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  listaVentas: any = [];
  listaVentaAsociados = new Array<any>();

  totalRows: number;
  currentPage: number = 1;
  limit: number = 8;

  constructor(
    private navCtrl: NavController,
    private geolocalizacion: GeolocalizacionService,
    private redatwater: RedatwaterService,
    private modalController: ModalController,
    private aquabot: AquabotService,
    private escaner: EscanerService,
    private auth: AuthService,
    private actionSheetCtrl: ActionSheetController,
    private menu: MenuController,
    private alertCtr: AlertController,
    private translateConfigService: TranslateConfigService,
    private utilitario: UtilitariosService,
    private toast: ToastService,
  ) {
    console.log('App: Historial de ventas');
    this.header.pagina = '/redatwater-usuario';
    this.header.titulo = 'Historial de ventas';
  }

  ngOnInit() {
    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data.id) {
              this.authUsuario = getAuth.data;

              this.historialVentas(this.currentPage, this.limit);

            } else {
              this.navCtrl?.navigateRoot(['/logout']);
            }

          });

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });
  }

  // INICAR SESION => PORTAL TRANSACCIONAL
  iniciarPortalTransaccional() {
    console.log('Portal transaccional -> Auto Iniciar sesion. ', this.authUsuario.codigo, this.authUsuario.app_token);
    if (this.authUsuario.codigo && this.authUsuario.app_token) {
      this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/auth/loginApp?numero=' + this.authUsuario.codigo + '&auth_app=' + this.authUsuario.app_token, 'portal transaccional');
    } else {
      this.portalTransaccional();
    }
  }

  portalTransaccional() {
    console.log('Portal transaccional -> Iniciar sesion. ', this.authUsuario.codigo);
    this.aquabot.enlaceSugerido(this.redatwater.getWebUrl() + '/' + this.idiomaSeleccionado + '/homepage/auth?us_co=' + this.authUsuario.codigo, 'portal transaccional');
  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    this.modalController.dismiss();
  }

  historialVentas(page: number, limit: number) {
    console.log('Seguimiento -> Historial de ventas');
    const pagination = {
      page: page,
      limit: limit
    }
    this.redatwater.historialVentasVendedores(pagination).then((ventas: any) => {

      console.log('Seguimiento -> ', ventas);

      if (ventas) {

        this.listaVentas = ventas;
        this.totalRows = ventas.total_registros;
        this.procesarHistorial();

      } else {
        console.log('Seguimiento de errores -> ', ventas);
      }

    }).catch((error: any) => {
      console.log('Seguimiento de errores -> ', error);
    });
  }

  procesarHistorial() {
    console.log('Seguimiento de ventas -> ', this.listaVentas);
    if (this.listaVentas?.ventas) {

      this.listaVentas.ventas.forEach((dataVenta: any) => {
        if (dataVenta?.id) {

          console.log('Seguimiento -> ', dataVenta);
          this.listaVentaAsociados.push(dataVenta);

        } else {
          console.log('Seguimiento de errores -> ', dataVenta);
        }
      });

    } else {
      console.log('Seguimiento de errores -> ', this.listaVentas);
    }
  }

  nextPage() {
    if (this.currentPage < Math.ceil(this.totalRows / this.limit)) {
      this.currentPage++;
      this.historialVentas(this.currentPage, this.limit);
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.historialVentas(this.currentPage, this.limit);
    }
  }

  isLastPage(): boolean {
    return this.currentPage === this.getLastPage();
  }

  getLastPage(): number {
    return Math.ceil(this.totalRows / this.limit);
  }

  isBothButtonsDisabled(): boolean {
    return this.currentPage === 1 && this.isLastPage();
  }



}
