import { Injectable } from '@angular/core';
import { Platform, NavController, AlertController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { RedatwaterService } from './redatwater.service';
import { TextSpeechService } from './text-speech.service';
import { TranslateConfigService } from './translate-config.service';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class AquabotService {

  msgAquaBot: any = {
    guia_referencia: '',
    msg: '',
    estado: '',
    estado_animado: '',
    objeto_animado: {
      cabeza: '',
      ojos: 'eye-casual',
      ojos_pupila: 'pupil-casual',
      boca: 'mouth-casual',
      cuerpo: 'body-casual',
      nucleo: 'energy',
      brazos: {
        der: 'limb-left-casual',
        izq: 'limb-right-casual'
      }
    }
  };

  entoronoAnimacion: any = {
    entorno_dia: {
      fondo: 'entorno_dia',
      tipo_animacion: 'casual',
      objetos: {
        sol: 'sun',
        luna: 'moon',
        arbol: 'tree',
        cielo: 'headerEntorno',
        estrellas: 'star',
        nubes: 'cloud',
        zepelin: 'zepelinEntornoDia',
        contenido: 'contenidoEntorno',
        suelo: 'footerEntorno'
      }
    },
    entorno_tarde: {
      fondo: 'entorno_dia_tarde',
      tipo_animacion: 'casual',
      objetos: {
        sol: 'sun',
        luna: 'moon',
        arbol: 'tree',
        cielo: 'headerEntorno',
        estrellas: 'star',
        nubes: 'cloud',
        zepelin: 'zepelinEntornoDia',
        contenido: 'contenidoEntorno',
        suelo: 'footerEntorno'
      }
    },
    entorno_noche: {
      fondo: 'entorno_noche',
      tipo_animacion: 'casual',
      objetos: {
        luna: 'moon',
        sol: 'sun',
        arbol: 'tree',
        cielo: 'headerEntorno',
        nubes: 'cloud',
        estrellas: 'star',
        contenido: 'contenidoEntorno',
        suelo: 'footerEntorno'
      }
    },
    entorno_agua: {
      fondo: 'entorno_agua',
      tipo_animacion: 'casual',
      objetos: {
        pez_1: 'fish',
        pez_2: 'second-fish',
        pez_3: 'third-fish',
        cielo: 'headerEntorno',
        contenido: 'contenidoEntorno',
        algas: 'algae',
        suelo_algas: 'seaweed',
        suelo: 'footerEntorno'
      },

    },
    entorno_oficina: {
      fondo: 'entorno_oficina',
      tipo_animacion: 'casual',
      objetos: {
        reloj: 'clock',
        planta: 'plant',
        pintura: 'painting',
        cielo: 'headerEntorno',
        contenido: 'contenidoEntorno',
        suelo: 'footerEntorno'
      }
    }
  }


  estadosAnimacion = { // BACKUP DE ANIMACIONES 
    hablando: {
      cabeza: '',
      ojos: 'eye-casual',
      ojos_pupila: 'pupil-casual',
      boca: 'mouth',
      cuerpo: 'body-casual',
      nucleo: 'energy',
      brazos: {
        der: 'limb-left-casual',
        izq: 'limb-right-casual'
      }
    },
    hablando_full: {
      cabeza: '',
      ojos: 'eye-casual',
      ojos_pupila: 'pupil',
      boca: 'mouth',
      cuerpo: 'body',
      nucleo: 'energy',
      brazos: {
        der: 'limb-left',
        izq: 'limb-right'
      }
    },
    casual: {
      cabeza: '',
      ojos: 'eye-casual',
      ojos_pupila: 'pupil-casual',
      boca: 'mouth-casual',
      cuerpo: 'body-casual',
      nucleo: 'energy',
      brazos: {
        der: 'limb-left',
        izq: 'limb-right-casual'
      }
    },
    casual_1: {
      cabeza: '',
      ojos: 'eye',
      ojos_pupila: 'pupil',
      boca: 'mouth-casual',
      cuerpo: 'body',
      nucleo: 'energy',
      brazos: {
        der: 'limb-left',
        izq: 'limb-right-casual'
      }
    },
    casual_2: {
      cabeza: '',
      ojos: 'eye-casual',
      ojos_pupila: 'pupil-casual',
      boca: 'mouth-casual',
      cuerpo: 'body-casual',
      nucleo: 'energy',
      brazos: {
        der: 'limb-left',
        izq: 'limb-right'
      }
    },
    casual_ok: {
      cabeza: '',
      ojos: 'eye-casual',
      ojos_pupila: 'pupil-casual',
      boca: 'mouth-casual',
      cuerpo: 'body-ok',
      nucleo: 'energy-ok',
      brazos: {
        der: 'limb-left',
        izq: 'limb-right-casual'
      }
    },
    casual_ok_hablando: {
      cabeza: '',
      ojos: 'eye-casual',
      ojos_pupila: 'pupil-casual',
      boca: 'mouth',
      cuerpo: 'body-ok',
      nucleo: 'energy-ok',
      brazos: {
        der: 'limb-left',
        izq: 'limb-right-casual'
      }
    },
    error_hablando: {
      cabeza: '',
      ojos: 'eye-casual',
      ojos_pupila: 'pupil-casual',
      boca: 'mouth',
      cuerpo: 'body-error',
      nucleo: 'energy-error',
      brazos: {
        der: 'limb-left-error',
        izq: 'limb-right-error'
      }
    },
    error_casual: {
      cabeza: '',
      ojos: 'eye-casual',
      ojos_pupila: 'pupil-casual',
      boca: 'mouth-casual',
      cuerpo: 'body-error',
      nucleo: 'energy-error',
      brazos: {
        der: 'limb-left-error',
        izq: 'limb-right'
      }
    }
  };

  constructor(
    private platform: Platform,
    private alertCtr: AlertController,
    private navCtrl: NavController,
    private translateConfigService: TranslateConfigService,
    private textSpeech: TextSpeechService,
    private redatwater: RedatwaterService,
    private auth: AuthService,
  ) {
    console.log('Service -> AquaBot');
    console.log('Iniciando.... AQUABOT');
  }

  appMsgAquaBot(msg: any): any {
    if (msg) {
      console.log('Seguimiento -> Msg: ', msg);
      this.setMsgAquaBot(msg);
      return msg;
    }
  }

  setMsgAquaBot(msg: any) {
    if (msg) {
      //console.log('Seguimiento -> Msg: ', msg);
      this.msgAquaBot = msg;
    }
  }

  getAquaBot() {
    return this.msgAquaBot;
  }


  getEstadosAnimacion(tipo: any) {
    if (tipo) {
      return this.estadosAnimacion[tipo];
    }
  }

  getEntoronoAnimacion(tipo: any) {
    if (tipo) {
      return this.entoronoAnimacion[tipo];
    }
  }


  async redireccionarInterface(tipo: string) {
    console.log('Seguimiento -> Redireccionar por ID tipo: ', tipo);
    switch (tipo) {
      case 'redatwater':
        this.navCtrl?.navigateRoot(['/redatwater']);
        break;

      case 'redatwater-usuario':
        this.navCtrl?.navigateRoot(['/redatwater-usuario']);
        break;

      case 'redatwater-cliente':
        this.navCtrl?.navigateRoot(['/redatwater-cliente']);
        break;

      default:
        this.navCtrl?.navigateRoot(['/logout']);
        break;

    }
  }

  async enlaceSugerido(enlace: any, titulo: string) {
    if (enlace) {
      console.log('Seguimiento -> Alerta de redireccionamiento');
      const alert = await this.alertCtr.create({
        cssClass: 'my-custom-class',
        header: 'RED AT-WATER',
        message: 'El enlace sugerido <span class="text-success"> te llevará a nuevas experiencias:</span> <br> <span class="text-uppercase"><b>* ' + titulo + ' </b></span>',
        buttons: [
          {
            text: 'Cerrar ventana',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cerrar!');
            }
          }, {
            text: 'Abrir enlace',
            handler: () => {
              console.log('Abrir enlace sugerido!');

              this.abrirNavegador(enlace);

            }
          }
        ]
      });
      await alert.present();
    } else {
      console.log('Seguimiento de errores -> Parametros incompletos para enlace ', enlace);
    }
  }

  async abrirNavegador(enlace: any) {
    if (enlace) {
      Browser.open({ url: enlace }).then((getData) => {
        console.log('App data Web Oficial -> ', getData);
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    } else {
      console.log('Seguimiento de errores -> ', enlace);
    }
  }


  async enlaceContacto(enlace: any, titulo: string) {
    if (enlace) {
      console.log('Seguimiento -> Alerta de redireccionamiento para contacto');
      const alert = await this.alertCtr.create({
        cssClass: 'my-custom-class',
        header: 'RED AT-WATER',
        message: 'El enlace sugerido <span class="text-success"> te llevará a nuevas experiencias:</span> <br> <span class="text-uppercase"><b>* ' + titulo + ' </b></span>',
        buttons: [
          {
            text: 'Cerrar ventana',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cerrar!');
            }
          }, {
            text: 'Abrir enlace',
            handler: () => {
              console.log('Abrir enlace sugerido!');

              this.linkContacto(enlace);

            }
          }
        ]
      });
      await alert.present();
    } else {
      console.log('Seguimiento de errores -> Parametros incompletos para enlace ', enlace);
    }
  }

  linkContacto(url: string) {
    window.open(url, "_blank");
  }

}
