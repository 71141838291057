import { Component, OnInit } from '@angular/core';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import { Header } from 'src/app/interfaces/book.interface';
import { AquabotService } from 'src/app/services/aquabot.service';
import { AuthService } from 'src/app/services/auth.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';
import { RedatwaterService } from 'src/app/services/redatwater.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UtilitariosService } from 'src/app/services/utilitarios.service';
import { CarritoPedidoComponent } from './carrito-pedido/carrito-pedido.component';

@Component({
  selector: 'app-app-carrito',
  templateUrl: './app-carrito.component.html',
  styleUrls: ['./app-carrito.component.scss'],
})
export class AppCarritoComponent implements OnInit {

  header: Header = {
    nivel: 2,
    pagina: '',
    titulo: '',
  }

  aquaBot = {
    msg: '',
    estado: ''
  };

  idiomaSeleccionado: string = null;
  selectedLanguage: string;
  itemsIdiomaApp: any = [];
  itemsIdioma: any = [];
  idiomaPagina = 'TIENDAS_AGUA_DETALLE_TIENDA';

  authUsuario: any = null;
  defaultImage = 'assets/img/default.png';

  comercioProductos: any;
  cantidadComercios: number = 0;
  tarifaDomicilio: number = 0;
  tarifaPropina: number = 0;

  pedido: any = null;
  subTotal: number = 0;
  domicilio: number = 0;
  totalPedido: number = 0;

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private toast: ToastService,
    private redatwater: RedatwaterService,
    private translateConfigService: TranslateConfigService,
    private aquabot: AquabotService,
    private geolocalizacion: GeolocalizacionService,
    private auth: AuthService,
    private carrito: CarritoService,
    private utilitario: UtilitariosService,
    private alertCtr: AlertController
  ) {
    console.log('Ventana -> Carrito de compras');
    this.header.pagina = '/tiendas';
    this.header.titulo = 'Carrito';
  }

  ngOnInit() {

    let tarifas = this.redatwater.getTarifasDomicilio();
    this.tarifaDomicilio = tarifas.tarifa_base_tienda;
    console.log('Tarifa base de domicilio -> ', this.tarifaDomicilio);

    this.translateConfigService.initAppIdioma().then((dataIdioma: any) => {
      this.translateConfigService.itemsIdiomaPagina(this.idiomaPagina).then((dataPagina: any) => {
        this.itemsIdiomaApp = this.translateConfigService.getItemsIdiomaApp();
        this.itemsIdioma = this.translateConfigService.getItemsIdioma();
        this.translateConfigService.getItemsIdioma();
        this.idiomaSeleccionado = this.translateConfigService.getDefaultLanguage();

        console.log('Idioma seleccionado -> ', this.idiomaSeleccionado);

        if (this.itemsIdiomaApp && this.itemsIdioma) {

          
          this.auth.getAuthUsuarioObservable('app_auth').subscribe(async (getAuth: any) => {
            console.log('Usuario Auth ->', getAuth);
            if (getAuth?.data) {

              this.authUsuario = getAuth.data;
              this.loadPedido();

            } else {
              this.navCtrl?.navigateRoot(['/logout']);
            }
          });

        } else {
          this.translateConfigService.resetIdioma(this.idiomaPagina);
        }
      }).catch((err: any) => {
        console.log('Seguimiento de errores -> ', err);
      });
    }).catch((err: any) => {
      console.log('Seguimiento de errores -> ', err);
    });


  }

  async loadPedido() {
    this.carrito.getPedidoObservable().subscribe((getData: any) => {
      console.log('Carrito -> ', getData);
      if (getData) {
        this.pedido = getData;
        if (this.pedido.productos?.length) {
          this.ordenarPedido();
        } else {
          this.cerrarVentana();
        }
      } else {
        console.log('Seguimiento de errores -> ', getData);

      }
    });
  }


  async ordenarPedido() {
    if (this.pedido?.id) {
      let productosPorTienda = {};
      let tiendasDiferentes = new Set();
      this.subTotal = 0;

      this.pedido.productos.forEach(pedido => {
        if (pedido.producto && pedido.tienda) {
          if (!productosPorTienda[pedido.tienda]) {
            productosPorTienda[pedido.tienda] = {
              productos: [],
              tituloComercio: pedido.tienda_titulo,
              imagenComercio: pedido.tienda_img,
              subtotalComercio: 0,
              totalProductosComercio: 0
            };
          }
          productosPorTienda[pedido.tienda].productos.push(pedido);
          productosPorTienda[pedido.tienda].subtotalComercio += pedido.cantidad * pedido.precio;
          productosPorTienda[pedido.tienda].totalProductosComercio += pedido.cantidad;
          tiendasDiferentes.add(pedido.tienda);
          this.subTotal += productosPorTienda[pedido.tienda].subtotalComercio;
        } else {
          console.log('Seguimiento de errores -> ', pedido);
        }
      });

      // Imprimir los pedidos separados por tienda
      console.log('Pedidos por tienda:', productosPorTienda);
      this.comercioProductos = productosPorTienda;

      // El número de tiendas diferentes es igual al tamaño del conjunto "tiendasDiferentes"
      const numeroTiendasDiferentes = tiendasDiferentes.size;
      console.log('Número de tiendas diferentes:', numeroTiendasDiferentes);
      this.cantidadComercios = numeroTiendasDiferentes;

      this.domicilio = this.cantidadComercios * this.tarifaDomicilio;
      this.totalPedido = this.domicilio + this.subTotal;

    } else {
      console.log('Seguimiento de errores -> ', this.pedido);
    }
  }

  confirmarPedido() {

    console.log('Carrito -> confirmar pedido! ', this.pedido);
    if (this.totalPedido > 0 && this.subTotal > 0 && this.domicilio > 0) {
      this.detalleCarritoPedido();
    } else {
      console.log('Seguimiento de errores -> ', this.totalPedido, this.subTotal, this.domicilio);
    }

  }

  limpiarCarrito() {

    console.log('Carrito -> limpiar carrito! ');
    this.carrito.clearCarrito();
  }

  async vaciarCarrito() {
    console.log('Confirma -> Vaciar carrito ');

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: 'CARRITO',
      message: '<span><span class="text-success">Vacía tu carrito en un clic!</span></span>',
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cerrar!');
          }
        }, {
          text: 'Aceptar',
          handler: () => {

            console.log('Aceptar vaciar carrito -> ');
            this.limpiarCarrito();

          }
        }
      ]
    });

    await alert.present();

  }

  cerrarVentana() {
    console.log('Cerrar ventana!');
    if (this.modalController) {
      this.modalController.dismiss({carrito:true});
    }
  }


  // Función para agregar cantidad a un producto de un comercio
  agregarCantidad(comercioId: string, productoId: string) {
    if (this.comercioProductos[comercioId]) {
      const comercio = this.comercioProductos[comercioId];
      const producto = comercio.productos.find(p => p.producto === productoId);
      if (producto) {
        producto.cantidad++;
        comercio.subtotalComercio += parseInt(producto.precio);
        comercio.totalProductosComercio++;
        this.subTotal += parseInt(producto.precio);
        this.actualizarDomicilioYTotal();
      }
    }
  }

  // Función para quitar cantidad a un producto de un comercio
  quitarCantidad(comercioId: string, productoId: string) {
    if (this.comercioProductos[comercioId]) {
      const comercio = this.comercioProductos[comercioId];
      const producto = comercio.productos.find(p => p.producto === productoId);
      if (producto && producto.cantidad > 0) {
        producto.cantidad--;
        comercio.subtotalComercio -= parseInt(producto.precio);
        comercio.totalProductosComercio--;
        this.subTotal -= parseInt(producto.precio);
        this.actualizarDomicilioYTotal();
      }
    }
  }

  // Función para actualizar el valor de domicilio y totalPedido
  actualizarDomicilioYTotal() {
    this.domicilio = this.cantidadComercios * this.tarifaDomicilio;
    this.totalPedido = (this.domicilio + this.subTotal) + this.tarifaPropina;
  }


  otraPropina() {
    console.log('Seguimiento -> Digital propina!');
    this.digitarPropipna();
  }

  seleccionarPropina(propina: number) {
    if (propina >= 0) {
      console.log('Seleccionar propina -> ', propina);
      this.tarifaPropina = propina;
      this.totalPedido = (this.domicilio + this.subTotal) + this.tarifaPropina;
    }
  }


  async digitarPropipna() {
    console.log('Seguimiento -> Digitar otra propina: ');

    const alert = await this.alertCtr.create({
      cssClass: 'my-custom-class',
      header: 'PROPINA',
      message: '<span>Las entregas son posibles <span class="text-success">¡Gracias a ellos!</span></span>',
      inputs: [
        {
          name: 'propina',
          type: 'number',
          placeholder: '$'
        }
      ],
      buttons: [
        {
          text: 'Cerrar ventana',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cerrar!');
          }
        }, {
          text: 'Aceptar',
          handler: (data: any) => {

            console.log('Propina -> ', data);

            if (data.propina >= 0) { // PROPINA

              console.log('Propina digitada -> ', data.propina);
              this.tarifaPropina = data.propina;
              this.totalPedido = (this.domicilio + this.subTotal) + this.tarifaPropina;

            } else {
              this.digitarPropipna();
            }
          }
        }
      ]
    });

    await alert.present();

  }

  async ventanaDetallePedido() { // Detalle pedido

    const pedidoOriginal = this.pedido;
    const pedidoComercios = this.comercioProductos;
    const preciosPedido = {
      subTotal: this.subTotal,
      domicilio: this.domicilio,
      totalPedido: this.totalPedido,
      propina: this.tarifaPropina,
    }

    const modalAdd = await this.modalController.create({
      component: CarritoPedidoComponent,
      mode: 'ios',
      swipeToClose: true,
      componentProps: { pedidoOriginal, pedidoComercios, preciosPedido }
    });
    await modalAdd.present();
    const { data } = await modalAdd.onWillDismiss();

    this.loadPedido();
    this.cerrarVentana();

    if (data) {
      
      console.log('Finalizar datos -> ' + data);

      

    } else {
      this.toast.msgAppBottom('¡Oops! Detalle de pedido incorrecta.', 'danger');
    }
  }

  async detalleCarritoPedido() {
    console.log('Seguimiento -> Detalle de carrito ');
    this.ventanaDetallePedido();
  }

}
